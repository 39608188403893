
export const serverURL = process.env.REACT_APP_DASHBOARD_API_ROOT;

export const getDefaultHeaders = (type='json') =>{
    const accessToken = localStorage.getItem("auth_access_token");
    return {
        'Accept': 'application/json',
        'Authorization': `JWT ${accessToken}`,
        "Content-Type": "application/json"
    }
};
