import React, { useState, useEffect } from 'react';
import Progress from "./Progress";
import OpenText from "./QuestionTypes/OpenText";
import SingleChoice from "./QuestionTypes/SingleChoice";
import Rating from "./QuestionTypes/Rating";
import {Accordion, Card, Container} from "react-bootstrap";
import classNames from "classnames";
import {Trans, withTranslation} from 'react-i18next';
import _ from 'underscore';
import Intro from "./QuestionTypes/Intro";
import GlobalSurveyErrors from "./GlobalSurveyErrors";

const Create = ({ selectedSurvey = {}, updateSurveyQuestions, updateSurveyIntro, isSaving, surveyErrors, handleCreateBack, handleCreateNext, handleReset, draftSurveys, company, getCoreSurveys, t }) => {
    const [tab, setTab] = useState("en");

    const selectTab = lang => {
        setTab(lang);
    };

    useEffect(() => {
        const dom = document.querySelector('.survey-create .show');
        dom && dom.parentNode.querySelector(".card-header").classList.add("open");
    }, []);

    useEffect(() => {
        const { surveys } = selectedSurvey;
        if (!surveys) { return; }
        let allLangs = surveys.map(survey => survey.supported_language);
        if (!allLangs.includes("en")) {
            selectTab(allLangs[0]);
        }
    }, [selectedSurvey.surveys]);

    if (_.isEmpty(selectedSurvey)) {
        return null;
    }
    const { surveys } = selectedSurvey;
    // if (_.isEmpty(selectedSurvey.surveys)) {
    //     getCoreSurveys({languages: selectedSurvey.supported_languages, type: selectedSurvey.deploying_for})
    // }

    const _rotateToggle = (e) => {
        const hasClass = e.target.classList.contains("open");
        document.querySelectorAll('.survey-create .collapse').forEach(el => el.parentNode.querySelector(".card-header").classList.remove("open"));
        e.target.classList[hasClass ? "remove" : "add"]("open");
    };

    const _handleQuestionChange = (question) => {
        updateSurveyQuestions(question);
    };

    const _handleIntroChange = (intro, surveyId) => {
        updateSurveyIntro({ intro, surveyId });
    };

    const _renderSurveyTableContent = (survey) => {
        if (!survey || !survey.questions) {
            return null;
        }

        const surveyIdx = _.findIndex(surveys, survey => survey.supported_language === tab);
        const errors = surveyErrors[surveyIdx];
        survey.questions = survey.questions.map((question, id) => {
            return {
                ...question,
                qnum: id + 1,
                error: _.maybe(errors, "questions", id)
            }
        });
        const groupedQuestions = _.groupBy(survey.questions, "page_category");

        return <tbody>
            <tr className={"datatable-content"}>
                <GlobalSurveyErrors errors={errors}/>
                <Accordion defaultActiveKey="1" onClick={_rotateToggle}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            { t("SURVEY.INTRODUCTION_HEADING") }
                            <img className={"icon icon-caret"}/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className={"intro-content"}>
                                <Intro content={survey.intro} company={company} handleChange={data => _handleIntroChange(data.intro, survey.id)}></Intro>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                {
                    Object.keys(groupedQuestions).map((groupKey, idx) => {
                        const questions = groupedQuestions[groupKey];

                        return <Accordion defaultActiveKey={idx+1} onClick={_rotateToggle}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={idx+1}>
                                    {t(`SURVEY.${groupKey.toUpperCase()}`)}
                                    <img className={"icon icon-caret"}/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={idx+1}>
                                    <Card.Body>
                                        <ul className={"survey-create__content-container__questions"}>
                                            {
                                                questions.map((question, id) => {
                                                    switch(question.field_type) {
                                                        case 'open_text':
                                                            return <OpenText key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange}/>;
                                                        case 'single_choice':
                                                        case 'multiple_choice':
                                                            return <SingleChoice key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange}/>;
                                                        case 'single_choice_with_open_text':
                                                        case 'multiple_choice_with_open_text':
                                                            return <SingleChoice key={id} openText error={question.error} question={question} company={company} handleChange={_handleQuestionChange}/>;
                                                        case 'rating':
                                                            return <Rating key={id} error={question.error} question={question} company={company} handleChange={_handleQuestionChange}/>;
                                                    }
                                                })
                                            }
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    })
                }
            </tr>
        </tbody>
    };

    const _renderSurveyTable = (surveys) => {
        return <table className={"datatable"}>
            <thead>
            <tr className={"datatable-filters"}>
                {
                    surveys.map(survey => <th onClick={() => selectTab(survey.supported_language)}
                                              className={classNames({"selected": tab === survey.supported_language})}>
                                                { t(`LANGUAGES.${survey.supported_language.toUpperCase()}`) }
                                        </th>)
                }
            </tr>
            <tr className={"datatable-border"}></tr>
            </thead>
            {
                _renderSurveyTableContent(surveys.filter(survey => survey.supported_language === tab)[0])
            }
        </table>
    }

    return (
        <div className={"survey-create"}>
            <Progress selected={"create"} supportedLanguages={_.pluck(surveys, "supported_language")} errors={surveyErrors} handleBack={handleCreateBack} handleNext={handleCreateNext} handleReset={handleReset} draftSurveys={draftSurveys} isSaving={isSaving}/>
            <Container className="survey-create__content-container">
                <div className={"header"}>
                    <div className={"header-content-container"}>
                        <h5> {company} Diversity & Inclusion Survey</h5>
                        <p>This is a template survey tailored to your country, language and type of respondents. Please read through carefully and customize the relevant fields to your company. </p>
                    </div>
                    <div className={"note-container"}>
                        <h5>NOTE: </h5>
                        <ul>
                            <li>1. Additional questions have a standard fee</li>
                            <li>2. If you choose to modify questions, the survey will need to be approved by a Diversio teammember</li>
                        </ul>
                    </div>
                </div>
                {
                    _.isEmpty(surveys)
                        ? <p>No questions to display</p>
                        : _renderSurveyTable(surveys)
                }
            </Container>
        </div>
    );
};

export default withTranslation()(Create);
