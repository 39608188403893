import React, {Component} from 'react';
import { Actions } from '../../actions';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../../components/Auth/login';
import _ from 'underscore';

class LoginContainer extends Component {
    _handleOnSubmit (values) {
        this.props.doLogin(values)
    }

    render() {
        const { from, message } = this.props.location.state || { from: { pathname: '/' } };
        const { 
            company_permission: permissions = null,
            companies: companyList = null
         } = this.props.companyInformation;

        if (this.props.isAuthenticated) this.props.getCompanyInformation();

        if (this.props.isAuthenticated && permissions) {
            if (_.isEmpty(companyList)) {
                this.props.doSignout()
                return <Redirect to="login" />
            }

            if (companyList.length === 1) {
                if(permissions.iln_dashboard_home) return <Redirect to="iln-dashboard/home" />
                if(permissions.diversity_pe) return <Redirect to="diversity_pe/about" />
                if(permissions.diversity_vc) return <Redirect to="diversity_vc/about" />
                if(permissions.home) return <Redirect to="home" />
            }
            
            return <Redirect to={'choose-dashboard'} />
        }

        return <Login message={message} onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth,
        ...state.home
    }
};

export default connect(mapStateToProps, Actions)(LoginContainer);
