import React, { Component } from 'react';
import './Answer.scss';


function sort_by_kpi(kpi){
	var page = window.location.href;
	var to_display = "inline";
	if(page.search("#") === -1){
		return to_display;
	}
	var kpi_filter = page.split("#").pop().toUpperCase().replace(/\s/g,'');
	var clean_kpi = kpi.replace(/\s/g,'');
	if(clean_kpi === kpi_filter){
		return to_display;
	}
	to_display = "none";
	return to_display;
}

const kpi_color = {
    "CULTURE": "purple",
    "NETWORK": "gold",
    "BIAS": "darkolivegreen",
    "SAFETY": "orange",
    "LEADERSHIP": "slategray",
    "RECRUITING": "#53A8E2",
    "PAY EQUITY": "lemonchiffon",
    "LEADERSHIP & ACCOUNTABILITY": "plum"
};

class AnswerItem extends Component {
    state = { answerinput: ""}

    render () {
        return (
            <div className="Answer">
				<li key={this.props.key}>
						<div style={{display: sort_by_kpi(this.props.answer.KPI)}}className="answerBlock">
							<div className="answerTitle">{this.props.answer.title}</div>
							<div className="answerContent">{this.props.answer.content}</div>
							<span className="answerAnswers"> {this.props.answer.answers}</span>
							<span className="answerLine"> | </span>
							<span style={{color: kpi_color[this.props.answer.KPI]}} className="answerTopicKPI">{this.props.answer.KPI} </span>
							<div className="answerInput">
                                <div>
                                <textarea 
                                    className="answerInput answerText"
                                    placeholder="Type your answer here"
                                    value={this.state.answerinput}
                                    onChange={ (e) => this.setState( { answerinput: e.target.value} )}/> 
                                </div>
                                <button 
                                    className="submitButton"
                                    onClick={() => alert(this.state.answerinput)}>
                                        Submit
                                </button>
							</div>
						</div>
				</li>
			</div>
        )
    }

}

export default AnswerItem;