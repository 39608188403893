import React from 'react';
import { Row, Column } from '../../utils/FlexBox';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import './index.scss';

const data = [
    {
        quartile: '1st Quartile',
        color: "#5E76FF",
        score: 6,
        curve: 0
    },
    {
        quartile: '2nd Quartile',
        color: "#58D0FF",
        score: 7,
        curve: 3
    },
    {
        quartile: '3rd Quartile',
        color: "#59FBCB",
        score: 8,
        curve: 6
    },
    {
        quartile: '4th Quartile',
        color: "#3EEFEF",
        score: 9,
        curve: 3
    },
    {
        quartile: '4th Quartile',
        color: "#3EEFEF",
        score: 10,
        curve: 0
    },
];

const BellcurveChart = () => {
    return (
        <AreaChart
            width={900}
            height={250}
            data={data}
            margin={{
                top: 10, right: 30, left: 0, bottom: 0,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="score" padding={{ left: 100, right: 100 }} tickLine={false} axisLine={false} />
            <Tooltip />
            <Area type="monotone" dataKey="curve" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
    )
}

const Benchmark = () => {
    return (
        <div id="benchmark">
            <h2 className="overview_title">How do you compare against other investment porfolios?</h2>
            <Row>
                <Column grow={1}>
                    <img src="images/bellCurve.png" />
                </Column>
            </Row>
        </div>
    );
};

export default Benchmark;
