import React from 'react';

const ProgramList = ({ title, items }) => {
    return (
        <div className="program-list">
            <h3>{title}</h3>
            <ol className="red-number-list">
                {items.map(({ label, link }, index) => {
                    return (
                        <li className="red-number-list__list-item" key={label}>
                            <div className="red-number-list__list-number">{index + 1}</div>
                            {label}</li>
                    )
                })}
            </ol>
        </div>
    )
};

export default ProgramList;
