import React, { useState, useEffect } from 'react';
import { Nav, Tab, Row, Col, Dropdown } from 'react-bootstrap';
import './index.scss';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Button} from "react-bootstrap";
import _ from 'underscore';
import {getDepartmentLabel, getOfficeRegionLabel, getTenureLabel} from "../../../../utils/labels";
import { withTranslation } from 'react-i18next';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import Filters, {Department, Level, Office, Tenure, Year} from "../../Filters";
import {createFiltersPayload} from "../../Filters/utils"

const FiltersComponent = ({ company, filters, applyFilters, onChange, t }) => {
    const [dataMap] = useState({
        'Year': 'years',
        'Office': 'offices',
        'Level': 'levels',
        'Department': 'departments',
        'Tenure': 'tenures',
        'Kpis': 'kpis',
        'Group': 'groups'
    });
    const [valMap] = useState({
        'Year': 'year',
        'Office': 'office',
        'Level': 'level',
        'Department': 'department',
        'Tenure': 'tenure',
        'Kpis': 'kpi',
        'Group': 'group'
    });
    const ogState = {
        office: null,
        level: null,
        department: null,
        group_a: {"genders": ["Male"]},
        group_b: {"genders": ["Female"]}
    };
    const [values, setValues] = useState(ogState);

    useEffect(() => {
        onChange(values);
    }, []);

    const _clearFilters = () => {
        setValues(ogState);
        onChange(ogState);
        setTimeout(() => { applyFilters(createFiltersPayload(ogState, filters)) }, 0)
    };

    const _addOrRemoveValues = (key, option, val) => {
        let clonedValues = {};
        if (option in values[key]) {
            if (values[key][option].includes(val)){
                values[key][option] = _.reject(values[key][option], function(d){ return d === val});
            } else {
                values[key][option].push(val);
            }
        } else {
            values[key][option] = [val];
        }
        clonedValues = {...values};
        _setValues(clonedValues);
    };

    const _setValues = (values) => {
        setValues(values);
        onChange(values);
    };

    const _getCheckbox = (name, label, checked, onChange) => {
        return (
            <>
                <Dropdown.ItemText as={"span"}>
                    <FormControlLabel
                        className={"option-label"}
                        control={
                            <Checkbox
                                color={"primary"}
                                name={name}
                                checked={checked}
                                onChange={onChange}
                            />
                        }
                        label={mapT(m.GROUP_BREAKDOWN, label)}
                    />
                </Dropdown.ItemText>
            </>
        )
    };

    return (
        <div id={"inclusion_breakdown-filters__container"}>
            <div className={"filters-container"}>
                <div className={"filter-actions"}>
                    <p>{t("ANALYZE.INCLUSION_BREAKDOWN.FILTERS_TITLE")}</p>
                    <div className={"filter-actions-btns"}>
                        <Button variant={"primary"} className={"apply-filter"} onClick={() => applyFilters(createFiltersPayload(values, filters))}>{t("ANALYZE.FILTERS.SUBMIT")}</Button>
                        <Button variant={"light"} className={"clear-filter"} onClick={_clearFilters}>{t("ANALYZE.FILTERS.RESET")}</Button>
                    </div>
                </div>
            </div>
            <div className={"inclusion_breakdown-filters"}>
                <Tab.Container defaultActiveKey="group_a">
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey="group_a">{t("ANALYZE.FILTERS.GROUP_A")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="group_b">{t("ANALYZE.FILTERS.GROUP_B")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="office">{getOfficeRegionLabel(company)}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="level">{t("ANALYZE.FILTERS.LEVEL")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="department">{getDepartmentLabel(company)}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="group_a">
                            <Row>
                                {
                                    _.keys(filters.groups).map((group, i) => {
                                        return (
                                            <Col key={i} className={"options-column"}>
                                                <Dropdown>
                                                    <Dropdown.Toggle as={"h6"} id={`dropdown-group-a-${i}`}>
                                                        {mapT(m.GROUP_BREAKDOWN, filters.metadata.groups[group])}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {
                                                            filters.groups[group].map((option, i) => {
                                                                return _getCheckbox(group, option,
                                                                    values.group_a[group] && values.group_a[group].includes(option),
                                                                    () => _addOrRemoveValues("group_a", group, option))
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="group_b">
                            <Row>
                            {
                                _.keys(filters.groups).map((group, i) => {
                                    return (
                                        <Col key={i} className={"options-column"}>
                                            <Dropdown>
                                                <Dropdown.Toggle as={"h6"} id={`dropdown-group-b-${i}`}>
                                                    {mapT(m.GROUP_BREAKDOWN, filters.metadata.groups[group])}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        filters.groups[group].map((option, i) => {
                                                            return _getCheckbox(group, option,
                                                                values.group_b[group] && values.group_b[group].includes(option),
                                                                    () => _addOrRemoveValues("group_b", group, option))
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="office">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        filters={filters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => {newValues.office && _setValues({ ...values, office: newValues.office })}}
                                        companyName={company}>
                                        <Office label={getOfficeRegionLabel(company)}/>
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="level">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        filters={filters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => {newValues.level && _setValues({ ...values, level: newValues.level })}}
                                        companyName={company}>
                                        <Level />
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="department">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        filters={filters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => {newValues.department && _setValues({ ...values, department: newValues.department })}}
                                        companyName={company}>
                                        <Department label={getDepartmentLabel(company)}/>
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};

export default withTranslation()(FiltersComponent);
