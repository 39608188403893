import React from 'react'
export default (props) => {
    const { fill = "#6221EA" } = props;

    return (<svg {...props} dangerouslySetInnerHTML={undefined} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.91667 0C0.991667 0 0.25 0.741667 0.25 1.66667V13.3333C0.25 13.7754 0.425595 14.1993 0.738155 14.5118C1.05072 14.8244 1.47464 15 1.91667 15H13.5833C14.0254 15 14.4493 14.8244 14.7618 14.5118C15.0744 14.1993 15.25 13.7754 15.25 13.3333V7.5H13.5833V13.3333H1.91667V1.66667H7.75V0H1.91667ZM12.5667 0.833333C12.4167 0.834599 12.2732 0.894406 12.1667 1L11.15 2.00833L13.2333 4.09167L14.25 3.08333C14.4667 2.86667 14.4667 2.5 14.25 2.29167L12.9583 1C12.85 0.891666 12.7083 0.833333 12.5667 0.833333ZM10.5583 2.6L4.41667 8.75V10.8333H6.5L12.6417 4.68333L10.5583 2.6Z" fill={fill}/>
    </svg>);
}





