import {Actions} from '../../actions';
import {connect} from 'react-redux';
import Resources from '../../components/DiversityVC/Resources';

const mapStateToProps = (state, ownProps) => {
    return {
        diversityvc: state.diversityvc,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(Resources);
