import React, {Component} from 'react';
import './index.scss'
import Card from '../../../utils/Card'
import DeleteIcon from '../../../../images/delete.jsx';
import EditIcon from '../../../../images/edit';
import CloseIcon from '../../../../images/close';

class Goal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            order: this.props.order + 1,
            title: this.props.title
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.order !== this.props.order) {
            this.setState({order: this.props.order + 1})
        }
    }

    edit() {
        this.setState({editing: true});
    }

    delete() {
    }

    onGoalChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    updateGoal() {
        let {order, title} = this.state;
        order = order !== "" ? Math.max(parseInt(order), 1) - 1 : this.props.order;
        if (order !== this.props.order || title !== this.props.title) {
            this.props.doUpdateDiversityvcGoal({pk: this.props.pk, order, title});
        }
        this.setState({editing: false, order: order + 1, title});
    }

    render() {
        if (this.state.editing) {
            return this.renderEditing();
        }

        return (
            <tr className={`goal ${this.props.index % 2 !== 0 ? 'purple-bg' : ''}`}>
                <td className={'order'}>{this.state.order}</td>
                <td className={'title'}>{this.state.title}</td>
                <td className={'edit'}>
                    <EditIcon onClick={this.edit.bind(this)}/>
                    <DeleteIcon onClick={this.props.delete}/>
                </td>
            </tr>
        )
    }

    renderEditing() {
        return (
            <tr className={`goal ${this.props.index % 2 === 0 ? 'purple-bg' : ''}`}>
                <td className={'order'}>
                    <input onChange={this.onGoalChange.bind(this)} name='order' type="number" step="1" min="1"
                           value={this.state.order}/>
                </td>
                <td className={'title'}>
                    <input onChange={this.onGoalChange.bind(this)} name='title' type="text" value={this.state.title}/>
                </td>
                <td className={'edit'}>
                    <span onClick={this.updateGoal.bind(this)}> Update</span>
                    <CloseIcon onClick={() => {this.setState({editing: false})}}/>
                </td>
            </tr>
        )
    }
}

class Goals extends Component {

    state = {newGoal: ''};

    componentDidMount() {
        this.props.getDiversityvcGoal();
    }

    submitGoal() {
        if (this.state.newGoal !== '') {
            this.props.doCreateDiversityvcGoal({title: this.state.newGoal});
            this.setState({newGoal: ''});
        }
    }

    render() {
        const goals = [...this.props.diversityvc.goals];
        goals.sort((a, b) => a.order - b.order);
        const goalComponents = goals.map((x, index) =>
            <Goal
                key={x.pk}
                index={index}
                delete={() => {
                    this.props.doDeleteDiversityvcGoal({pk: x.pk})
                }}
                doUpdateDiversityvcGoal={this.props.doUpdateDiversityvcGoal}
                {...x}
            />
        );
        const showGoals = goals.length > 0;
        return (
                <Card className={'goals'}>
                    <h1>Your Diversity & Inclusion Goals</h1>
                    <p>
                    Use this section to record your D&I goals for the year ahead. Consider creating goals related to hiring and dealflow demographics first.
                    </p>
                    {
                        !showGoals ? null :
                            <table>
                                <thead>
                                <tr>
                                    <td></td>
                                    <td>Goal</td>
                                    <td>Edit/Delete</td>
                                </tr>
                                </thead>
                                <tbody>
                                {goalComponents}
                                </tbody>
                            </table>
                    }
                    <div className={'new-goal'}>
                        <input placeholder="eg. Mentorship program for portfolio companies" onChange={e => {
                            this.setState({newGoal: e.target.value})
                        }} name="newGoal" value={this.state.newGoal}/>
                        <button onClick={this.submitGoal.bind(this)}>Add Goal</button>
                    </div>
                </Card>
        );
    }
}

export default Goals;
