import React, { Component } from 'react';
import CreatePost from './CreatePost';
import Post from '../Post';
import _ from 'lodash';
import './index.scss';

class AllPost extends Component {
    componentWillReceiveProps() {
    }
    
    // handleFilter = () => {
    //     console.log('filter')
    // }

    reverseChronologicalSort(list) {
        return _.orderBy(list, ['id'], ['desc']);
    }

    render() {
        let sorted = [];
        if (this.props.isFiltered) {
            sorted = this.reverseChronologicalSort(this.props.filteredPosts);
        } else {
            sorted = this.reverseChronologicalSort(this.props.allPosts);
        }
        return (
            <div id="all-post">
                <CreatePost handleCreatePost={this.props.handleCreatePost} profileName={this.props.profileName} kpis={this.props.kpis}/>
                {/* <div className="filter-container">
                    <button className="filter-button" onClick={() => this.handleFilter()}>
                        <span>Filter</span><img src="/images/svg/Connect/Post/filter.svg" alt="filter icon" />
                    </button>
                </div> */}
                <div className="all-post-container">
                    <div className="subtitle">
                        Post
                    </div>
                    <hr />
                </div>
                {
                    sorted.map((post, id) => {
                        let isBookmarked = 0;
                        if (this.props.bookmark.length >= 0) {
                            isBookmarked = this.props.bookmark.filter((bookmark => {
                                if (bookmark.post.id === post.id) {
                                    return bookmark.is_bookmarked;
                                }
                                return 0
                            }) )
                        } 
                        
                        return (
                            <Post key={id} 
                                  post={post} 
                                  profileInfo={this.props.profileInfo}
                                  handleLikePost={this.props.handleLikePost} 
                                  handleEditPost={this.props.handleEditPost} 
                                  handleDeletePost={this.props.handleDeletePost} 
                                  isBookmarked={isBookmarked[0] ? isBookmarked[0].is_bookmarked : 0 } 
                                  handleUpdateBookmark={this.props.handleUpdateBookmark}
                                  kpis={this.props.kpis} />
                        )
                    })
                }
            </div>
        );
    }
};

export default AllPost;