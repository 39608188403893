import React from 'react';
import { connect } from 'react-redux';
import Portco from "../../components/Portco";

// import { 
//     InclusionScore,
// } from '../components/Home/';
import { Row, Column } from '../../components/utils/FlexBox';

const PortcoContainer = (props) => {
	return (
		<>
			<Portco companyInformation={props.companyInformation}/>
		</>
	);
}


const mapStateToProps = state => {
    return {
        ...state.home,
    };
};

export default connect(mapStateToProps)(PortcoContainer);
