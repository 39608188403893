import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CertifiedModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                name:'',
                email:'',
                comment:''
            }
        }
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            }
        });
    };

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="modal-title"
                aria-describedby="modal-content"
                fullWidth={true}
                id="certified-modal"
            >
                <DialogTitle className="header">Diversio Certification</DialogTitle>
                <DialogContent className="form-container">
                    <div className="personal-container">
                        <div className="personal-info">
                            <InputLabel className="text-title">Full Name</InputLabel>
                            <TextField
                                required
                                label="Full Name"
                                margin="normal"
                                className="textfield"
                                name="name"
                                variant="outlined"
                                value={this.state.form.name}
                                onChange={(e) => this.handleSelect(e)}
                            />
                        </div>
                        <div className="personal-info">
                            <InputLabel className="text-title">Work Email</InputLabel>
                            <TextField
                                required
                                label="Email"
                                margin="normal"
                                className="textfield"
                                name="email"
                                variant="outlined"
                                value={this.state.form.email}
                                onChange={(e) => this.handleSelect(e)}
                            />
                        </div>
                    </div>
                    <div className="comment-container">
                        <InputLabel className="comment-title">Do you have a specific interest? (optional)</InputLabel>
                        <TextField
                            label="Comment"
                            margin="normal"
                            multiline
                            className="textfield"
                            name="comment"
                            variant="outlined"
                            rows="4"
                            value={this.state.form.comment}
                            onChange={(e) => this.handleSelect(e)}
                        />
                    </div>
                    
                </DialogContent>
                <DialogActions className="action-container">
                    <Button onClick={() => {this.props.handleSubmit(this.state.form)}} className="save-button">
                        Submit
                    </Button>
                    <Button onClick={() => {this.props.handleCancel()}} className="cancel-button">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
};

export default CertifiedModal;