import React from 'react';
import Company from './Company';
import { Link } from 'react-router-dom';

const companyList = [
    {
        id: 1,
        imgURL: "images/portfolios/companyLogos/slack.png",
        certification: "Platinum",
        score: "9.2/10",
        risk: "Low (!)",
        isWomenLed: false,
        isMinorityLed: false
    },
    {
        id: 2,
        imgURL: "images/portfolios/companyLogos/shopify.png",
        certification: "Gold",
        score: "8.9/10",
        risk: "Low (!)",
        isWomenLed: false,
        isMinorityLed: true
    },
    {
        id: 3,
        imgURL: "images/portfolios/companyLogos/sales.png",
        certification: "Gold",
        score: "8.8/10",
        risk: "Low (!)",
        isWomenLed: true,
        isMinorityLed: true
    },
    {
        id: 4,
        imgURL: "images/portfolios/companyLogos/intuit.png",
        certification: "Silver",
        score: "8.5/10",
        risk: "Low (!)",
        isWomenLed: true,
        isMinorityLed: true
    },
    {
        id: 5,
        imgURL: "images/portfolios/companyLogos/borrowell.png",
        certification: "Silver",
        score: "8.1/10",
        risk: "Low (!)",
        isWomenLed: true,
        isMinorityLed: false
    },
    {
        id: 6,
        imgURL: "images/portfolios/companyLogos/facebook.png",
        certification: "Bronze",
        score: "7.8/10",
        risk: "Medium (!!)",
        isWomenLed: true,
        isMinorityLed: false
    },
    {
        id: 7,
        imgURL: "images/portfolios/companyLogos/amazon.png",
        certification: "Bronze",
        score: "7.7/10",
        risk: "Medium (!!)",
        isWomenLed: false,
        isMinorityLed: false
    },
    {
        id: 8,
        imgURL: "images/portfolios/companyLogos/google.png",
        certification: "Not",
        score: "6.6/10",
        risk: "High (!!!)",
        isWomenLed: false,
        isMinorityLed: true
    },
    {
        id: 9,
        imgURL: "images/portfolios/companyLogos/drop.png",
        certification: "Not",
        score: "6.5/10",
        risk: "High (!!!)",
        isWomenLed: true,
        isMinorityLed: true
    }
]


class CompanyCard extends React.Component {
    render() {
        const company = companyList.map(company => {
            return (
                <div>
                    {
                        company.id !== 5
                        ? <Link to="/">
                            <Company key={company.id} company={company} />
                        </Link>
                        : <a href="http://diversio-fe-staging.s3-website.ca-central-1.amazonaws.com/" target="_blank" rel="noopener noreferrer" >
                            <Company key={company.id} company={company} />
                        </a>
                    }
                </div>

            )
        })

        return (
            <div className="company">
                {company}
            </div>
        )
    }
}

export default CompanyCard;
