import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import PilotTemplate from '../components/Connect/Pilots/Pilots';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';

class Pilots extends Component {
    state = { pilots: [] };
    componentDidMount() {
        fetchPilots(pilots =>
          this.setState({ pilots: pilots }));
      }

    render() {

        return (
                <div>
                    <div className="Header"> Pilots </div>
                    <div>
                        <TopBar />
                    </div>

                    <div>
                        <PilotTemplate pilots={this.state.pilots} />
                    </div>
                    
                </div>
        );
    }
}

const fetchPilots = cb =>
  cb([
    { company: "WestJet", title: "Women leadership mentors for male entry workers", progress: "Launch" },
    { company: "McKinsey", title: "VR Game to 'Step in the Shows' of your coworker", progress: "Pre-launch" },
    { company: "Sherritt", title: "Coincil.io: Inclusive problem-solving through AI", progress: "Feedback" },
    { company: "BlackRock", title: "Young Women's mentorship app", progress: "default" },
    { company: "Coinbase", title: "Diversity by Design: Inclusive AI Recruiting Tool", progress: "Impact" },
]);

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(Pilots);
