import React, { Component } from 'react';

class Score extends Component {

    getLabel() {
        if (this.props.companyType == 'diversitype') {
            return {
                recruiting: "Human Capital & Recruitment",
                culture: "Internal Policy & Culture",
                dealflow_sources: "Dealflow Sources",
                portfolio_guidance: "Portfolio Company Guidance"
            }
        }
        else {
            return {
                recruiting: "Recruiting & HR",
                culture: "Internal Policy & Culture",
                dealflow_sources: "Dealflow Sources",
                portfolio_guidance: "Portfolio Guidance"
            }
        }
    }

    scoreToComponent(score) {
        return (
            <div>
                <div className="number">{this.props[score]}%</div>
                <p>{this.getLabel()[score]}</p>
            </div>
        )
    }

    render() {
        const overallTitle = this.props.isIndustry ? "Benchmark Score" : "Your Score";

        return (
            <div className="score">
                <div className="overall">{overallTitle} <span className={'red-color'}>{this.props.overall}%</span></div>
                <div>
                    <table className={'details'}>
                        <tbody>
                            <tr>
                                <td>{this.scoreToComponent('recruiting')}</td>
                                <td>{this.scoreToComponent('culture')}</td>
                            </tr>
                            <tr>
                                <td>{this.scoreToComponent('dealflow_sources')}</td>
                                <td>{this.scoreToComponent('portfolio_guidance')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Score;
