import React from 'react';
import Card from '../../utils/Card';
import Select from '../../utils/Select';
import './index.scss';

const PortcoChooseBar = (props) => {

	const {
		options,
		activeCompany,
		setActiveCompany,
	} = props;

	return (
		<div className="portco__wrapper portco__width">
		<Card>
		<div className="portco__chooser-wrapper">
			<div className="portco__select-company-label">
			<h3 className="portco__name-logo-card-title">Choose portfolio company</h3>
			</div>
			<div className="portco__select-company">
							{/* 
							From @sometimescasey
							For some reason the Select component does not re-render immediately
							when 'this.state.selectedGroups' changes. There is a delay of a few seconds.
							So we manually flip the value on "key" in order to trigger a re-render.
							*/}
							<Select
								key={null}
								multiple={false}
								options={options}
								selectedValues={activeCompany}
								label={"Company"}
								onChange={(value) => { setActiveCompany([value]) }}
								renderValue={selected => selected}
								mapChoiceLabel={(a) => {return a}}
								extraClasses=""
							/>
						</div>	
				</div>
		</Card>
		</div>
	);

}

export default PortcoChooseBar;
