import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import './index.scss';
import { getOfficeRegionLabel } from '../../../../utils/labels';


class Filter extends Component {
    _getRenderValue = (value) => {
        return <MenuItem value={value}>{value}</MenuItem>
    }

    _initializeAllFilter = (filter, specialLabel) => {
        let hasAll = false;
        let filters = filter;
        if (!filters.includes("All") && !specialLabel) {
            filters = ["All", ...filters];
        } else if (specialLabel) {
            filters.map(filter => {
                if (filter.key === 'All') {
                    hasAll = true
                }
            })
            if (!hasAll) {
                filters = [{
                    "key": "All",
                    "label": "All",
                    options: ["All"]
                }, ...filter];
            }
        }
        return filters
    }

    render() {
        const { companyName } = this.props;

        let offices = ["All"];
        let levels = ["All"];
        if (Array.isArray(this.props.filters?.offices) && Array.isArray(this.props.filters?.levels)) {
            offices = ["All",...this.props.filters.offices];
            levels = ["All",...this.props.filters.levels];
        }
        

        return (
            <div id="solution-filter">
                <div className="filter">
                    <InputLabel>{getOfficeRegionLabel(companyName)}</InputLabel>
                    <TextField
                        required
                        select
                        name="office"
                        value={this.props.filter.office}
                        onChange={(e) => this.props.handleFilterSelect(e)}
                        margin="normal"
                        className="text-field"
                    >
                        {
                            offices.map((option, id) => (
                                <MenuItem key={id} value={option}>
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </div>
                <div className="filter">
                    <InputLabel>Level</InputLabel>
                    <TextField
                        required
                        select
                        name="level"
                        value={this.props.filter.level}
                        onChange={(e) => this.props.handleFilterSelect(e)}
                        margin="normal"
                        className="text-field"
                    >
                        {
                            levels.map((option, id) => {
                                if (option !== "Prefer not to answer") {
                                    return (
                                        <MenuItem key={id} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                }
                            })
                        }
                    </TextField>
                </div>
                <div className="filter">
                    <InputLabel>Target Group</InputLabel>
                    <TextField
                        required
                        select
                        name="target_demographic"
                        value={this.props.filter.target_demographic}
                        onChange={(e) => this.props.handleFilterSelect(e)}
                        margin="normal"
                        className="text-field"
                        SelectProps={{
                            renderValue: selected => this._getRenderValue(selected)
                        }}
                    >   
                        {
                            this.props.demographicTarget.map((option, id) => {
                                if (typeof(option) === "object") {
                                    const key = option.key;
                                    const label = option.label;
                                    return option.options.reduce((allOptions, option, id) => {
                                        if (id === 0) {
                                            allOptions.push(<MenuItem value={label} disabled>{label}</MenuItem>)
                                        }
                                        allOptions.push(<MenuItem value={{key, label, option}}>{option}</MenuItem>)
                                        return allOptions;
                                    }, [])
                                }
                            })
                        }
                    </TextField>
                </div>
            </div>
        );
    };
};

export default Filter;
