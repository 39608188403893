import React from 'react';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import truncate from 'lodash/truncate';
import { kpiFormatter } from '../../../../utils';

const KpiSelector = (props) => {
    const { availableKpis, selectedKpis, onKpiChange } = props;
    return (<FormControl component="fieldset" className="form-set fieldset kpi-filters">
        <Select
            multiple
            value={selectedKpis}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => {
                return truncate(selected.map(s => kpiFormatter(s)).join(", "), {'length': 70})
                }}
        >
            <FormGroup>
                {
                    availableKpis.map((value, index) => {
                        return (
                            <FormControlLabel
                                className="filter-options"
                                key={`category_${index}`}
                                control={
                                    <Checkbox className="primary check-box"
                                                checked={selectedKpis.includes(value)}
                                                onChange={e => onKpiChange(e.target.value, e.target.checked)}
                                                color="primary"
                                                value={value}/>
                                }
                                label={kpiFormatter(value)}
                            />
                        )
                    })
                }
            </FormGroup>
        </Select>
    </FormControl>);
}

export default KpiSelector;
