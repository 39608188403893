import React from 'react';
import classNames from 'classnames';
import './index.scss';
import Button from "@material-ui/core/Button/Button";

const InclusionScore = ({data, width, deploySurvey, isDeploySurveyProcessing}) => {

    let current = 0;
    let month = '';
    let points = 0;
    let isPositive = true;
    let varianceFromAverage = 0;
    let isHigherThanAverage = false;
    let lastSurveyDate = '';
    let companyIndustry = '';
    let scoreDifference = 0;
    let isHigher = false;

    /* Hard coding the industry average for now (July 9 2019) */
    let industryAverage = {
        "IT" : 90,
        "Finance": 80,
        "Health care": 78,
        "Manufacturing": 79,
        "Marketing": 82,
        "Retail": 74,
        "Earth": 80.5
    }

    if (data !== undefined){
        current = data.current_inclusion_score?data.current_inclusion_score:0;
        points = current - data.last_survey_kpi;
        isPositive = points > 0;
        varianceFromAverage = current - data.industry_kpi_difference;
        isHigherThanAverage = varianceFromAverage > 0;
        lastSurveyDate = data['last_survey_date'];
        companyIndustry = data.company_industry;
        scoreDifference = Math.abs(current - industryAverage[companyIndustry]).toFixed(1);
        isHigher = (current - industryAverage[companyIndustry]) > 0;
    }

    function is_decimal(n)
    {
        return (n - Math.floor(n)) !== 0;
    }

    current = typeof current === "number" ? current : Number(current);
    return (
        <div className="portfolio-inclusion-score" style={{ width }}>
            <Button className={"deploy-survey__btn"} onClick={deploySurvey} disabled={isDeploySurveyProcessing} variant="contained" color="primary">
                {
                    isDeploySurveyProcessing
                        ? "Running Assessment"
                        : "Run Assessment"
                }
            </Button>
            <div className="inclusion-score-container">
                <h2>{ is_decimal(current) ? current.toFixed(1) : current }</h2>
                <h4>Inclusion Score</h4>
            </div>
        </div>
    )
};

export default InclusionScore;
