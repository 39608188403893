import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TrainingModal from './TrainingModal';
import ConfirmationModal from '../../utils/RequestSent';
import './index.scss';

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openTrainingModal: false,
            openConfirmationModal: false
        }
    }

    openTrainingModal = () => {
        this.setState({
            openTrainingModal: true
        })
    };

    handleCloseTrainingModal = () => {
        this.setState({
            openTrainingModal: false
        })
    };

    handleSubmitTraining = (formData) => {
        const info = Object.keys(formData).map(x=>`${x}: ${formData[x]}`).join('\n');
        const context = "Training Request";
        const detail = `User would like to request training.\nDetails:\n${info}`;
        this.props.sendSlackPing(context, detail).then(() => {
            this.setState({openTrainingModal: false, openConfirmationModal: true});
        }, reason => {
            this.setState({openTrainingModal: false, openConfirmationModal: false})
        });
    };

    handleCloseConfirmationModal = () => {
        this.setState({
            openConfirmationModal: false
        })
    };

    render() {
        return (
            <div id="training">
                <div className="title">Diversio Training</div>
                <div className="training-detail-container">
                    <img src="/images/LSO/Training/training.png" alt="training icon" />
                    <div className="training-detail">
                        <div className="description">Access Diversio & HRPA training for employees and satisfy the LSO’s new professional development requirements for 2020</div>
                        <Button className="waitlist-button" onClick={() => this.openTrainingModal()}>Click Here to Join the Waitlist</Button>
                        <TrainingModal isOpen={this.state.openTrainingModal}
                                       handleSubmit={this.handleSubmitTraining}
                                       handleCancel={this.handleCloseTrainingModal} />
                        <ConfirmationModal isOpen={this.state.openConfirmationModal}
                                           handleCancel={this.handleCloseConfirmationModal} />
                    </div>
                </div>
            </div>
        );
    };
};

export default Training;