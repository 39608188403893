import React from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import Progress from "./Progress";

const Launch = () => {
    return (
        <div className={"survey-review"}>
            <Progress selected={"review"}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img  src="/images/launch.svg" alt="Survey Launch"/>
                    <h5>Welcome to Your D&I Survey Dashboard</h5>
                    <p>Your journey to an inclusive workplace is about to begin.</p>
                    <Link to={"/survey/setup"}>
                        <Button variant={"primary"}>Create Your First Survey</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Launch;
