import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { getAllQuestions } from './network';

// All data sagas to add to middleware.
export default [
    // [ActionTypes.LOAD_DATA, loadData]
    [ActionTypes.GET_QUESTIONS, getQuestions]
];

// Load Data
// function* loadData () {
// yield put(Actions.loadingAllMonitorListData());
//
// yield [call(getAllPopulatedCollections), call(getEnvironments)];
//
// yield put(Actions.loadedAllMonitorListData());
// }


function * getQuestions () {
    yield put(Actions.loadingQuestions());

    const response = yield call(getAllQuestions);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedQuestions(response.data));
    }
}