import React from 'react';
import { Row, Column } from '../../utils/FlexBox';
import PortcoEmployeesSurveyed from './PortcoEmployeesSurveyed';
import PortcoNameLogo from './PortcoNameLogo';
import { 
    Certification
} from '../../../components/Home/';

const PortcoTopRow = (props) => {
	const {
		activeCompany,
		setActiveCompany,
		options
	} = props;

	return (
		<div className="App">
			<Row>
				<Column width="30%">
					<PortcoNameLogo companyInformation={props.companyInformation}
						activeCompany={activeCompany}
						setActiveCompany={setActiveCompany}
						options={options}
					/>
				</Column>
				<Column width="40%">
					<PortcoEmployeesSurveyed activeCompany={activeCompany}/>
				</Column>
				<Column width="30%">
					<Certification/>
				</Column>
			</Row>
		</div>
	);
}

export default PortcoTopRow;
