import React, {Component} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import {PiePlaceholder} from '../../../utils/Placeholders';
import './index.scss';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import { reorder } from '../DiversitySnapshotDetail';

const renderActiveShape = (props) => {
    const {
        cx, cy, innerRadius, outerRadius, startAngle, endAngle,
        fill
    } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                startAngle={0}
                endAngle={360}
                fill={fill}
            />
        </g>
    );
};


class PiChart extends Component {

    render() {
        const { clickCallback, selected, profile } = this.props;
        let _sortedPieData = this.props.data.sort((a, b) => b.value - a.value);
        const sortedPieData = reorder(_sortedPieData);


        if (!(sortedPieData && sortedPieData.length)) {
            // this.props.showMessage({type: "error", message: "Data cannot be displayed, minimum threshold not met."});
            return (
                <div className="pie-container">
                    <PiePlaceholder isLoading={false}/>
                </div>
            )
        }
        return (
            <div className="diversity-snapshot-pie-chart" onClick={clickCallback}>
                <ResponsiveContainer height={145}>
                    <PieChart
                    >
                        <Pie
                            stroke="none"
                            blendstroke
                            isAnimationActive={false}
                            activeShape={renderActiveShape}
                            data={this.props.data}
                            innerRadius={this.props.innerRadius}
                            outerRadius={this.props.outerRadius}
                            fill="#8884d8"
                            className="diversity-snapshot-pie-chart__pie"
                        >
                            {
                                this.props.data.map((entry, index) => <Cell key={index}
                                                                            fill={this.props.colors[index]}/>)
                            }
                        </Pie>
                        {/* Outside circle on select */}
                        {( selected && 
                            <Pie
                            stroke="none"
                            blendstroke
                            isAnimationActive={false}
                            data={this.props.data}
                            innerRadius={this.props.outerRadius + 6}
                            outerRadius={this.props.outerRadius + 10}
                            className="diversity-snapshot-pie-chart__pie"
                        >
                            {
                                this.props.data.map((entry, index) => <Cell key={index}
                                                                            fill={"#6221EA"}/>)
                            }
                            </Pie>
                        )}
                        
                        
                    </PieChart>
                </ResponsiveContainer>
                <div className="diversity-snapshot-pie-chart__legend-container">
                    <div className={`diversity-snapshot-pie-chart__label ${selected ? "selected-label" : ""}`}> {
                        mapT(m.GROUP, this.props.label)} </div>
                    <div className={"diversity-snapshot-pie-chart__all-labels"}>
                        {
                            sortedPieData.map((entry, index) => {
                                return (
                                    <div key={index}
                                        className="diversity-snapshot-pie-chart__pie-label">
                                        <div className="diversity-snapshot-pie-chart__label-container">
                                            <div className={"diversity-snapshot-pie-chart__pie-label-dot-wrap"}>
                                                <div className={"diversity-snapshot-pie-chart__pie-label-dot"}
                                                    style={{backgroundColor: this.props.colors[index % this.props.colors.length]}}/>
                                            </div>
                                            {`${
                                                (profile.email == 'amazing_investor_demo_2@diversio.com' && entry.name == 'White') ?
                                                'Dominant group' :
                                                mapT(m.GROUP_DOM_NONDOM, entry.name)}`}
                                        </div>
                                        <div className="diversity-snapshot-pie-chart__score">
                                            {`${entry.value ? entry.value : 0}%`}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        );
    };
};

export default PiChart;
