import React from 'react';
import './Experts.scss';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import Label from '@material-ui/icons/Label';

import im2 from'../../../images/NancyCushing-Jones.jpeg';
import im1 from'../../../images/KellyNolan.jpeg';
import im3 from'../../../images/NoraJenkinsTownson.jpeg';
import im0 from'../../../images/RebeccaHill.jpg';
import im4 from '../../../images/DunolaOladapo.jpg';


const styles = theme => ({
    textField: {
        width: 200,
        fontSize: 12
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    root: {
        color: green[600],
        '&$checked': {
          color: green[500],
        },
      },
      checked: {},
  });

var state = {
    topic: '',
    industry: '',
};
var handleChangeTopic = name => event => {
    state.topic = event.target.value;
};
var handleChangeIndustry = name => event => {
    state.industry = event.target.value;
};
var handleChangeProfessional = name => event => {
    state.industry = event.target.value;
};
// var handleChangeAmbassador = name => event => {
//     state.industry = event.target.value;
// };
// var handleChangeBoth = name => event => {
//     state.industry = event.target.value;
// };

function random_color() {
    var color;
    color = "hsl(" + 360 * Math.random() + ',' + (25 + 70 * Math.random()) + '%,' +
    (85 + 10 * Math.random()) + '%)';
    return color;
}

const AllExperts = props => {
    var images = [im2, im1, im3, im0, im4]
    var getTopics = topics => {
        var added = [];
        for (var i = 0; i < topics.length; i++) {
            var topic = topics[i]
            added.push(<span style={{backgroundColor: random_color()}} className="expertTopic">{topic}</span>);
        }
        return added;
    }
    return (
        <div className="experts-card">
            <div className="expertsTitle">Find an Expert</div>
            <div className="expertInputs">
                <div className="locationSearch">
                    <TextField
                        id="standard-search"
                        label="Location"
                        type="search"
                        className={styles.textField}
                        margin="none"
                        labelMargin="none"
                    />
                </div>
                <div className="topicArea">
                    <FormControl className={styles.formControl}>
                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                            <div className="labels">Topic Area</div>
                        </InputLabel>
                        <NativeSelect
                            value={state.age}
                            onChange={handleChangeTopic('age')}
                            input={<Input style= {{fontSize: 12}} name="age" id="age-native-label-placeholder" />}
                        >
                            <option value="Culture">Culture</option>
                            <option value="Networks">Networks</option>
                            <option value="Bias">Bias</option>
                            <option value="Safety">Safety</option>
                        </NativeSelect>
                    </FormControl>
                </div>
                <div className="topicArea">
                    <FormControl className={styles.formControl}>
                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                            <div className="labels">Industry</div>
                        </InputLabel>
                        <NativeSelect
                            value={state.age}
                            onChange={handleChangeIndustry('age')}
                            input={<Input style= {{fontSize: 12}} name="age" id="age-native-label-placeholder" />}
                        >
                            <option value="Technology">Technology</option>
                            <option value="Chemical">Chemical</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Manufacturing">Manufacturing</option>
                        </NativeSelect>
                    </FormControl>
                </div>
                <button class="searchButton">Search</button>
            </div>
            <div className="checkBoxes">
                <FormGroup row>
                     <FormControlLabel
                        control={
                            <Checkbox
                            checked={state.checkedProfessional}
                            onChange={handleChangeProfessional('')}
                            value="checkedProfessional"
                            color="default"
                            style= {{marginLeft: -120}}
                            />
                        }
                        label={<Typography fontSize="10" style={{ color: 'rgb(167, 166, 167)' }}>Professional</Typography>}
                     />
                     <FormControlLabel
                        control={
                            <Checkbox
                            checked={state.checkedAmbassador}
                            onChange={handleChangeProfessional('')}
                            value="checkedProfessional"
                            color="default"
                            />
                        }
                        label={<Typography fontSize="10" style={{ color: 'rgb(167, 166, 167)' }}>Ambassador</Typography>}
                     />
                     <FormControlLabel
                        control={
                            <Checkbox
                            checked={state.checkedBoth}
                            onChange={handleChangeProfessional('')}
                            value="checkedProfessional"
                            color="default"
                            />
                        }
                        label={<Typography fontSize="10" style={{ color: 'rgb(167, 166, 167)' }}>Both</Typography>}
                     />

                </FormGroup>
            </div>

            <div>
                <div className="resultsTitle">{props.experts.length} Results</div>
                <ul>
                    {props.experts.map((expert, i) => (
                        <div className="oneExpert">
                            <div className="containerExperts">
                                <div className="leftDiv">
                                    <div>
                                        <img className="profilePic" alt="" src={images[expert.id]} />
                                    </div>
                                    <div className="textAllgin">
                                        {expert.position}
                                    </div>
                                </div>
                                <div className="middlDiv">
                                    <div className="expertName">{expert.name}</div>
                                    <span className="expertCompany">{expert.company.join(' and ')}</span>
                                    <span className="expertLocation"> • {expert.location}</span>
                                    <div className="expertDescription">{expert.description}</div>
                                </div>
                                <div className="rightDiv">
                                    <div className="topicsExperts">Topics:</div>{
                                    getTopics(expert.topics)}
                                </div>

                            </div>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    )
};

export default AllExperts;
