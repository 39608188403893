import { put} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { Actions, ActionTypes } from '../actions';

// All data sagas to add to middleware.
export default [
    [
        ActionTypes.SHOW_MESSAGE
        , showMessage
    ]
];

function * showMessage ({ payload }) {
    let delayTime = 5000;
    if(payload.delay!==undefined){
        delayTime = payload.delay
    }
    yield put(Actions.setGlobalMessage({type: payload.type, code: payload.code, message: payload.message}));
    yield delay(delayTime);
    yield put(Actions.resetGlobalMessage());
}
