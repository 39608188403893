import * as Sentry from '@sentry/browser';

const url = `${process.env.REACT_APP_DASHBOARD_API_ROOT}/configuration/`;

export default function fetchSentryDsn(retries=10, wait=60000){

    if (retries < 0) {
        console.warn("Could not fetch sentry config - max retries reached.");
        return;
    }

    if (process.env.REACT_APP_DISABLE_SENTRY === "true") {
        console.log("Local Sentry config disabled.");
        return;
    }

    fetch(url).then(response=>response.json()).then(data=>{
        Sentry.init({dsn:data.sentry_dsn});
    }).catch(e=>{
        console.warn(`Could not fetch sentry config, trying again in ${wait/60000} min...`);
        setTimeout(() => fetchSentryDsn(retries=retries-1, wait=wait*2), wait);
    });
}
