import React from 'react';
import { Row, Column } from '../../utils/FlexBox';
import './index.scss';

const data = [
    {
        "category": "Certified",
        "percentage": 68,
        "description": "Percent of companies who are Inclusion Certified, with either a Bronze, Silver, Gold or Platnium Diversio seal"
    },
    {
        "category": "Women Led",
        "percentage": 10,
        "description": "Percent of companies with  at least one co-founder and half of executive leadership self-identifying as female"
    },
    {
        "category": "Minority Led",
        "percentage": 11,
        "description": "Percent of companies with at least one co-founder and half of executive leadship being visible minorities"
    },
    {
        "category": "At Risk",
        "percentage": 6,
        "description": "Percent of companies who are in the bottom quartile of D&I performance, as ranked by inclusion score"
    },
];

const colors = ["FF647C", "#0DCAA8", "#0084F4", "#58D0FF"];

const Overview = () => {
    return (
        <div id="portfolio-overview">
            <Row>
                {
                    data.map((category, id) => {
                        return (
                            <Column width="25%" key={id}>
                                <h2>{category.category}</h2>
                                {
                                    category.category === 'Certified'
                                    ? <div className="certified">{category.percentage}%</div>
                                    : null
                                }
                                {
                                    category.category === 'Women Led'
                                    ? <div className="women">{category.percentage}%</div>
                                    : null
                                }
                                {
                                    category.category === 'Minority Led'
                                    ? <div className="minority">{category.percentage}%</div>
                                    : null
                                }
                                {
                                    category.category === 'At Risk'
                                    ? <div className="risk">{category.percentage}%</div>
                                    : null
                                }
                                <p className="description">{category.description}</p>
                            </Column>
                        )
                    })
                }
            </Row>
        </div>
    );
};

export default Overview;
