import React from 'react';

const RedNumberList = ({ items }) => {
    return (
        <ol className="red-number-list">
            {items.map((label, index) => {
                return (
                    <li className="red-number-list__list-item" key={index}>
                        <div className="red-number-list__list-number">{index + 1}</div>
                        {label}
                    </li>
                )
            })}
        </ol>
    )
};

export default RedNumberList;
