import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import './index.scss';

class AddComment extends Component {
    state = {
        form: {
        }
    };

    handleComment = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
          }
        });
    };

    handlePost = () => {
        if (this.state.form.comment) {
            this.props.handleCreateComment({
                "comment": this.state.form.comment,
                "post": this.props.postInfo.id
            })
        }
        this.setState({
            form: {
                comment: ''
            }
        })
    };

    render() {
        if (this.props.profile.full_name) {
            return (
                <div id="add-comment">
                    <div className="profile-name">
                        {this.props.profile.full_name.toUpperCase().match(/\b(\w)/g)}
                    </div>
    
                    <FormControl className="comment" variant="outlined">
                        <OutlinedInput
                            id="comment-section"
                            aria-describedby="outlined-comment"
                            inputProps={{
                            'aria-label': 'comment',
                            }}
                            multiline
                            labelWidth={0}
                            placeholder="Add a comment"
                            margin='dense'
                            fullWidth={true}
                            name="comment"
                            value={this.state.form.comment}
                            onChange={(e) => this.handleComment(e)}
                        />
                    </FormControl>
    
                    <button onClick={() => this.handlePost()} className="post-button">Post</button>
                </div>
            );
        }
        return null;
    };
};

export default AddComment;