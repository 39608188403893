import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'underscore';

import Card from '../../utils/Card';
import { DashboardTooltip } from '../../utils/DashboardTooltip';
import { getTranslation } from "../../../translations/utils"
import { stableSort, getComparator } from '../../../utils/tableSorting'
import { usePrevious } from '../../../utils';

import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../actions';

import './index.scss';

const Resources = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [resources, setResources] = React.useState();

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoDownload = (pdfKey) => {
        props.getDiversityvcPdf(pdfKey);
    }

    const handleChangePage = (event, newPage) => {
        // event is needed for this to work
        setPage(newPage);
    };

    useEffect(() => {
        setPage(0)
    }, []);

    useEffect(() => {
        if (props.diversityvc.resource) {
            setResources([...props.diversityvc.resource])
        }
        else {
            props.getDiversityvcResource()
        }
    }, [props.diversityvc.resource]);

    const dispatch = useDispatch();
    const prevPdfUrl = usePrevious(props.diversityvc.pdf_url);

    useEffect(() => {
        // if the url link exists and has changed, open a new tab with it
        if (_.maybe(props.diversityvc, "pdf_url", "url") && !_.isEqual(props.diversityvc.pdf_url, prevPdfUrl)) {

            window.open(props.diversityvc.pdf_url.url , "_blank");
            // now clear the state
            dispatch({type: ActionTypes.CLEAR_DIVERSITYVC_PDF});
        }
    }, [props.diversityvc.pdf_url]);

    return (
        <Card className="diversityvc-resources">
            <div className="diversityvc-resources__card-content">
                <div className="diversityvc-resources__title-tooltip-wrapper">
                    <span className="diversityvc-resources__title">Key Resources</span>
                    <DashboardTooltip content={getTranslation("DIVERSITY_VC.RESOURCES.TOOLTIP")}>
                        <img className="icon-question-mark" />
                    </DashboardTooltip>
                </div>
                <p className="diversityvc-resources__subtext">
                    This section includes a selection of curated resources that are intended to assist funds in implementing
                    the policies and programs measured by the Standard. Should you wish to have a more in-depth discussion
                    about a particular resource, please reach out to one of the Diversity VC Program Managers.
                </p>
                {!resources
                    ? <>
                        <br />
                        <p style={{ fontSize: '14px' }}>Loading...</p>
                    </>
                    : [resources.length == 0
                        ? <> {console.log(resources)}
                            <br />
                            <p className='diversityvc-resources__empty-message'>Your resources list is currently empty. Please check back at a later date.</p>
                        </>
                        : <>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 25]}
                                component="div"
                                count={resources.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                            <Table className='diversityvc-resources__table' striped>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>Resource</th>
                                        <th style={{ width: '50%' }}>Description</th>
                                        <th style={{ width: '20%' }}>Category</th>
                                        <th style={{ width: '10%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stableSort(resources, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((data) => {
                                            return (
                                                <tr key={data.name} >
                                                    <td className='resource-name'>{data.name}</td>
                                                    <td>{data.description}</td>
                                                    <td>{data.category}</td>
                                                    <td className='resource-download'>
                                                        <span className="resource-download__link"
                                                            onClick={() => {
                                                                handleDoDownload(data.link);
                                                            }
                                                            }>Download</span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                    ]
                }
            </div>
        </Card>
    )
}

export default Resources;
