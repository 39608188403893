import React, {useEffect} from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import './index.scss'
import edit from "../../../images/edit";
import data from "../../../sagas/data";
import GlobalSurveyErrors from "../GlobalSurveyErrors";

const SingleChoice = ({ question, openText, handleChange, company, error }) => {
    const { title, required, type, placeholder, editable, choices } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);

    const _handleChoiceChange = (val, idx) => {
        handleChange({
            ...question,
            choices: choices.map((choice, id) => {
                if (idx != id) {
                    return choice;
                } else {
                    return {
                        ...choice,
                        label: val,
                        value: val
                    }
                }
            })
        })
    };

    const _deleteChoice = (choiceToDeleteId) => {
        // Remove a choice and update the existing
        // placeholder `Choice n` values.
        const choicePattern = /^Choice \d+$/
        const newChoices = [];
        let nextCount = 1
        for (let i = 0; i < choices.length; i++) {
            if(choiceToDeleteId !== undefined && i === choiceToDeleteId) {
                continue;
            }
            if(choices[i].value.match(choicePattern)) {
                newChoices.push(
                    {
                        label: `Choice ${nextCount}`,
                        value: `Choice ${nextCount}`,
                        question: question.id
                    }
                )
            } else {
                newChoices.push(choices[i]);
            }
            nextCount++;
        }
        handleChange({
            ...question,
            choices: newChoices
        })
    };

    const _addChoice = () => {
        handleChange({
            ...question,
            choices: [
                ...choices,
                {
                    label: `Choice ${choices.length + 1}`,
                    value: `Choice ${choices.length + 1}`,
                    question: question.id
                }
            ]
        });

    };

    const _checkValidity = () => {
        let dataAfterValidation = {
            ...question,
            // choices: [
            //     ..._.uniq(choices, choice => choice.value.toLowerCase()).filter(choice => !!choice.value.trim().length),
            // ]
        };

        if (!dataAfterValidation.choices.length) {
            dataAfterValidation.choices.push({
                label: `Choice 1`,
                value: `Choice 1`,
                question: question.id
            })
        }
        handleChange(dataAfterValidation)
    };

    return (
        <div className={classNames("question", "sc-question", { "required": required }, { "has-error": !_.isEmpty(error) })}>
            <GlobalSurveyErrors errors={error} />
            <p className={"question-title"}>{question.qnum}. {parsedTitle}<span className={"asterisk"}>*</span></p>
            <div className={"sc-question__choice-container"}>
                <ul>
                    {
                        choices.map((choice, i) => {
                            const choiceErrors = _.maybe(error, "choices", i, "value") || [];
                            return <li>
                                {
                                    choices.length > 1 && <img className={classNames("icon icon-cross", { hidden: !editable })} onClick={() => _deleteChoice(i)}/>
                                }
                                {/*<input type="radio" disabled/>*/}
                                <input onBlur={e => _checkValidity()}
                                       className={classNames("input-box", { "error": choiceErrors.length })}
                                       disabled={!editable}
                                       onChange={e => _handleChoiceChange(e.target.value, i)} value={choice.label} />
                                {
                                    choiceErrors.map(err => <span className={"error-message"}>{err}</span>)
                                }
                            </li>
                        })
                    }
                    {
                        editable && <img className={"icon icon-add"} onClick={() => _addChoice()}/>
                    }
                    {
                        openText && <li className={"others"}>
                            <img className={"icon icon-cross hidden"}/>
                            {/*<input type="radio" disabled/>*/}
                            <label className={"no-border"}>{ "Other" }</label>
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
};

export default SingleChoice;
