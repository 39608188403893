import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '../../utils/Card';
import TextField from '@material-ui/core/TextField';
import _ from 'underscore';
import { stableSort, getComparator, numericalList } from '../../../utils/tableSorting'
import './index.scss';

const MilestoneTable = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterText, setFilterText] = React.useState('')

    useEffect(() => {
        setPage(0)
    }, [props.selectedProgram])

    const handleChangePage = (event, newPage) => {
        // event is needed for this to work
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterTextChange = (event) => {
        setFilterText(event.target.value)
    };

    const setStatus = (row) => {
        let statusColor = ''
        let statusMessage = ''

        const startDate = new Date(row.start_date)
        const endDate = new Date(row.end_date)
        const currentDate = new Date()

        if (startDate < currentDate && endDate < currentDate) {
            // Event has passed - Mark finished
            statusColor = 'complete'
            statusMessage = 'Complete'
        }

        if (startDate < currentDate && endDate > currentDate) {
            // Event is in progress
            statusColor = 'in-progress'
            statusMessage = 'In Progress'
        }

        if (startDate > currentDate && endDate > currentDate) {
            // Event has not started yet
            statusColor = 'not-started'
            statusMessage = 'Not Active'
        }

        const color = statusColor
        const message = statusMessage

        const result = {
            color,
            message
        }
        return result
    }

    const Row = (props) => {
        const { row, key } = props;
        const [open, setOpen] = React.useState(false);

        const status = setStatus(row)

        return (
            <React.Fragment>
                <TableRow key={key} style={row.index % 2 ? { background: "#F6F6F6" } : { background: "white" }}>
                    <TableCell className="iln-cell-style" align="center">{row.index}</TableCell>
                    <TableCell className="iln-cell-style" align="center">{row.childrenRows && <img className={open ? "icon-iln-minus" : "icon-iln-plus"} onClick={() => setOpen(!open)}/>}</TableCell>
                    <TableCell className="iln-member-name">{row.name}</TableCell>
                    <TableCell className="iln-cell-style">{row.milestone_type}</TableCell>
                    <TableCell className="iln-cell-style">{row.start_date}</TableCell>
                    <TableCell className="iln-cell-style ">{row.end_date}</TableCell>
                    <TableCell className="iln-cell-style" style={{ width: '100px' }}><span className={"dot " + status.color} />{status.message}</TableCell>
                    <TableCell className="iln-cell-style">{row.responsible}</TableCell>
                </TableRow>
                <>
                    {open ? row.childrenRows && row.childrenRows.map((childRow, index) => {
                        const status = setStatus(childRow)
                        return (
                            <TableRow className="child-row">
                                <TableCell></TableCell>
                                <TableCell className="iln-cell-style">{row.index + '.' + (index+1)}</TableCell>
                                <TableCell className="iln-member-name" style={{ width: '200px' }}>{childRow.name}</TableCell>
                                <TableCell className="iln-cell-style">{childRow.milestone_type}</TableCell>
                                <TableCell className="iln-cell-style">{childRow.start_date}</TableCell>
                                <TableCell className="iln-cell-style ">{childRow.end_date}</TableCell>
                                <TableCell className="iln-cell-style" style={{ width: '100px' }}><span className={"dot " + status.color} />{status.message}</TableCell>
                                <TableCell className="iln-cell-style">{childRow.responsible}</TableCell>
                            </TableRow>
                        )
                    }) : null}
                </>
            </React.Fragment>
        )
    }

    const filterMilestones = (milestones) => {
        if (_.isEmpty(milestones)) return null
        if (_.isEmpty(props.selectedProgram)) return null

        const programFilter = milestones.filter(function (milestone) {
            if (milestone.initiative_milestone.toLowerCase().trim().indexOf(props.selectedProgram.toLowerCase().trim()) === -1) {
                return
            }
            return milestone.initiative_milestone.toLowerCase().trim().indexOf(props.selectedProgram.toLowerCase().trim()) >= 0;
        })


        let filteredMilestones = programFilter.filter(function (milestone) {
            if (milestone.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1) {
                return
            }
            return milestone.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
        })

        // Apply numerical list values for displaying the data
        numericalList(filteredMilestones)

        // Process for expanding rows to show child events
        // Each row has a property called "milestone_parent" which we use to combine rows into child rows
        // ex.) milestone_parent = Industry Day, all rows with that property will fall under an expandable row called "Industry Day"

        // First we find the milestone_parent keys and children
        let milestoneParents = []
        let arr = []
        for (var i in filteredMilestones) {
            if (filteredMilestones[i].milestone_parent) {
                milestoneParents.push(filteredMilestones[i])
                arr.push(filteredMilestones[i].milestone_parent)
            }
        }

        const milestoneKeys = [...new Set(arr)];

        // Once we have the keys and children we want to create an object that the table can use to display the info

        let newObjects = []
        for (var j in milestoneKeys) {
            let newObj = {}
            // use same naming convention as how single rows will be displayed
            newObj['name'] = milestoneKeys[j]
            newObj['childrenRows'] = []
            for (var i in milestoneParents) {
                if (milestoneParents[i].milestone_parent === newObj['name']) {
                    newObj['childrenRows'].push(milestoneParents[i])
                }
            }
            newObjects.push(newObj)
        }

        // Now that we need to replace the rows we just added as children, while keeping similar row structure

        // Remove the new children milestones

        for (var x in filteredMilestones) {
            for (var y in milestoneParents) {
                if (filteredMilestones[x].id === milestoneParents[y].id) {
                    filteredMilestones.splice(x, 1)
                }
            }
        }

        // Add new objects back into the array
        for (var z in newObjects) {
            const index = newObjects[z].childrenRows[0].index
            const newRow = newObjects[z]
            filteredMilestones.splice(index, 0, newRow)
        }

        // Apply numerical a second time to show the correct numbering
        numericalList(filteredMilestones)

        return (
            <div className="iln-milestone-container">
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25]}
                    component="div"
                    count={filteredMilestones.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="styled-tablecell"></TableCell>
                                <TableCell className="styled-tablecell"></TableCell>
                                <TableCell className="styled-tablecell">Milestone</TableCell>
                                <TableCell className="styled-tablecell">Type</TableCell>
                                <TableCell className="styled-tablecell">Start Date</TableCell>
                                <TableCell className="styled-tablecell">End Date</TableCell>
                                <TableCell className="styled-tablecell">Status</TableCell>
                                <TableCell className="styled-tablecell">Responsible</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(filteredMilestones, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <Row key={row.index} row={row} />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    return (
        <div className="iln-milestones">
                <h2>Upcoming Milestones for {props.selectedProgram ? props.selectedProgram : null}</h2>
                <div className="iln-table-search milestones-table">
                    <h3>Search Milestone</h3>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        label="Search"
                        variant="outlined"
                        value={filterText}
                        onChange={handleFilterTextChange}
                    // TODO: Figure out icon styling to fit properly in the textbox
                    // InputProps={{
                    //     endAdornment: (
                    //       <InputAdornment position="start">
                    //         <div className="icon-iln-search"/>
                    //       </InputAdornment>
                    //     ),
                    //   }}
                    />
                </div>
                {filterMilestones(props.milestones)}
        </div>
    )
}

export default MilestoneTable;
