import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import { 
    InclusionScore,
    InclusionScoreCustom,
    EmployeesSurveyed,
    MetricOverview,
    HeatMap,
    FocusAreas,
    Certification,
    BusinessKeyPerformanceIndicator,
    ExecutiveSummary,
    DiversityComposition
} from '../components/Home/';
import { Row, Column } from '../components/utils/FlexBox';
import PaymentModal from '../components/utils/Modal/PaymentModal';
import { isCifarGlobalCall, isCifarScholars, isBessemerBriefPortfolio, isBessemerAnonymized } from '../utils/companyChecks'
import './App.scss';

/* Data for BusinessKeyPerformanceIndicator. Delete once backend has this */
const BKPIdata = [
    {
        "description": "Employee Attrition Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "40"
            },
            {
                "month": "April 2019",
                "rate": "30"
            },
            {
                "month": "May 2019",
                "rate": "20",
                "difference": "10"
            },
        ]
    },
    {
        "description": "Offer Acceptance Rate",
        "rates": [
            {
                "month": "March 2019",
                "rate": "75"
            },
            {
                "month": "April 2019",
                "rate": "80"
            },
            {
                "month": "May 2019",
                "rate": "88",
                "difference": "8"
            },
        ]
    },
    {
        "cost": 0,
        "description": "Spent on discrimination litigation"
    }
]

// temp for demo - find better solution to this if it becomes a trend
export const getSkinnedCompanyName = (originalCompanyName, profile) => {
    /* Use the username info in profile payload to do some conditional skinning for demos. */

    if (!originalCompanyName) {
        return '';
    }
    
    if (originalCompanyName && !profile) {
        return originalCompanyName;
    }
    
    const usernameCompanynameMap = {
        "demo_amazing_procurer": "Amazing Procurer"
    };

    if (profile.username && usernameCompanynameMap[profile.username]) {
        return usernameCompanynameMap[profile.username];
    }

    return originalCompanyName;

}

class Home extends Component {
    componentWillMount () {
        this.props.getEmployeeOverviewData();
        this.props.getInclusionMetricsData({ data: { year: "", level: "", office: "", department: "" } });
        this.props.getInclusionTrackerData({ data: { year: "", level: "", office: "", department: "" } });
        this.props.getDiversityCompositionData();
        this.props.getMetricOverviewData();
        this.props.getFocusAreaData();
        this.props.getInclusionScoreData();
        this.props.getExecSummaryData();
        this.props.getCompanyInformation();
        this.props.getCompanyFilters();
    }

    modalSwitch(oneElevenCompany, loggedInCompany) {
        let modal = 'none';

        for (let company = 0; company < oneElevenCompany.length; company++) {
            if (loggedInCompany === oneElevenCompany[company]) {
                modal = 'oneEleven';
                return modal;
            }
        }
        return modal;
    }
    
    render() {
        const xLabels = ['Inclusive culture', 'Unbiased feedback', 'Access to networks', 'Flexible work', 'Safe work'];

        const oneElevenCompanyModal = ['Altus Assessments', 'Blue J Legal', 'd1g1t Inc.', 'GrowthGenius', 'Hockeystick', 'Humi', 'Nudge.ai', 'OneElevenCommunity'];

        const modal = this.modalSwitch(oneElevenCompanyModal, this.props.companyInformation.name);

        let oneElevenCompany = false;
        if (modal === 'oneEleven') {
            oneElevenCompany = true;
        }

        /* DEMO DASHBOARD */
        let isDemo = false;
        if (this.props.companyInformation.name === 'DEMO') {
            isDemo = true;
        }

        const companyName = getSkinnedCompanyName(this.props.companyInformation.name, this.props.profile)
        const isCustom = (isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isBessemerBriefPortfolio(companyName) || isBessemerAnonymized(companyName))
        return (
            <div className="App">
                <Row>
                    <Column width="30%">
                        {isCustom ? 
                            <InclusionScoreCustom
                                companyName={this.props.companyInformation.name} 
                            /> : 
                            <InclusionScore data={this.props.inclusionScore} isDeploySurveyProcessing={this.props.isDeploySurveyProcessing} deploySurvey={this.props.doDeploySurvey} companyName={companyName} />
                        }   
                    </Column>
                    <Column width="40%">
                        <EmployeesSurveyed data={this.props.analyze.employeeOverview} colors={this.props.colors}/>
                    </Column>
                    <Column width="30%">
                        <Certification inclusionScore={this.props.inclusionScore}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <ExecutiveSummary data={this.props.execSummary}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <DiversityComposition
                        breakdown={this.props.diversityCompositionBreakdown}
                        data={this.props.diversityComposition}
                        ndg={this.props.analyze.filters.non_dominant_group}
                        colors={this.props.colors}
                        industryData={this.props.industryDiversityComposition}
                        companyName={companyName}
                        profile={this.props.profile}
                        />
                    </Column>
                </Row>
                {isCustom ? null : (
                    <>
                    <Row>
                        <Column grow={1}>
                            <HeatMap data={this.props.analyze.inclusionMetrics} 
                            prevData={this.props.analyze.previousInclusionMetrics}
                            metadata={this.props.analyze.metadata}
                            companyName={companyName}
                            years={this.props.analyze.filters.years}
                            />
                        </Column>
                    </Row>
                   <Row>
                        <Column width="100%">
                            <FocusAreas data={this.props.focusArea} 
                            companyName={companyName}/>
                        </Column>
                    </Row>
                    </>
                ) }
                {(this.props.metricOverview.length > 1) && <Row>
                    <Column width="100%">
                        <MetricOverview data={this.props.metricOverview} BKPIdata={BKPIdata} isDemo={isDemo} 
                        companyName={companyName} />
                    </Column>
                </Row>}
                {
                    isDemo
                    ? <Row>
                        <Column grow={1}>
                            {/* data will pass through once backend is setup */}
                            <h2 className="transparent-header">Business Key Performance Indicators</h2>
                            <BusinessKeyPerformanceIndicator BKPIdata={BKPIdata} />
                        </Column>
                    </Row>
                    : <div></div>
                }
                {
                    oneElevenCompany
                    ? <Row>
                        <Column grow={1}>
                            <PaymentModal isInformingPurchase={this.props.isInformingPurchase} informPurchase={this.props.doInformPurchase} companyPurchasedStatus={this.props.companyInformation.purchased} />
                        </Column>
                    </Row>
                    : <div></div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.home,
        ...state.auth,
        analyze: state.analyze,
        colors: state.colors,
        profile: state.profile,
    };
};

export default connect(mapStateToProps, Actions)(Home);
