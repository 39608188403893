import React, { Component } from 'react';
import {Row, Column} from '../../utils/FlexBox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '../../utils/Card';
import './index.scss';

class Budget extends Component {
    state = {
        budget: this.props.isBudget,
        solution: {
            budget: this.props.budget
        }
    }

    handleReset = () => {
        this.setState({
            solution: {
                ...this.state.solution,
                budget: null
            },
            budget: false
        })
    }

    handleChange = (e, v) => {
        const name = e.target.name || e.target.getAttribute('name');
        const value = (v !== undefined && v.newValue !== undefined) ? v.newValue : e.target.value;

        this.setState({
            solution: {
                ...this.state.solution,
                [name]: value,
            }
        })
    }

    handleBudget = () => {
        this.props.handleUpdateBudget({
            "solution_budget": this.state.solution.budget
        })
        this.setState({
            budget: true
        })
    }

    render() {
        let totalCost = 0;
        this.props.chosenSolutions.map((solution, id) => {
            totalCost += solution.monthly_cost;
        })
        
        return (
            <Card>
            <div id="budget">
                <Row>
                    <div className="overview-title">Total Annual Budget</div>
                </Row>
                <Row>
                    <div className="description">Enter the total annual budget allocated
                        to diversity & inclusion solutions. Our calculator will combine the cost of your
                        selected programs and display the budget you have left to spend.
                    </div>
                </Row>
                <Row>
                    {
                        this.state.solution.budget && this.state.budget
                        ? <div className="budgeted-container">
                            <div className="budgeted-action-container">
                                <div className="action-title">Total Annual Budget</div>
                                <div className="action-container">
                                    <div className="program-budget">${this.state.solution.budget}</div>
                                </div>
                            </div>
                            <div className="budgeted-action-container">
                                <div className="action-title">Remaining</div>
                                <div className="action-container">
                                    <div
                                        className="remain-budget">${this.state.solution.budget - totalCost}
                                    </div>
                                </div>
                            </div>
                            <Button className="reset-button" onClick={() => this.handleReset()}>Reset</Button>
                        </div>
                        : <div className="budget-action-container">
                            <div className="action-title">Total Annual Budget</div>
                            <div className="action-container">
                                <TextField
                                    required
                                    type="number"
                                    className="budget-input"
                                    label="e.g. 10000"
                                    name="budget"
                                    value={this.state.solution.budget}
                                    onChange={(e) => this.handleChange(e)}
                                    variant="outlined"/>
                                <Button onClick={() => this.handleBudget()} 
                                        className="budget-button"
                                        placeholder="e.g. 10000">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    }
                </Row>
            </div>
            </Card>
        );
    };
};

export default Budget;
