export const SCORE_DATA = {
	"url": "",
	"commitment_score": 100
}

export const PROGRAM_DATA = {
	"ATN": {
		"policy": 1,
		"recruiting": 1,
		"engagement": 2,
		"transparency": 3,
		"commitment_score": 100,
		"policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	},
	"Vaulter": {
		"policy": 1,
		"recruiting": 2,
		"engagement": 2,
		"transparency": 3,
		"commitment_score": 100,
		"policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	},
	"Brightstar Cruises": {
		"policy": 0,
		"recruiting": 0,
		"engagement": 0,
		"transparency": 0,
		"commitment_score": 100,
		// "policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		// "recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		// "engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		// "transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	},
	"Brightstar Adventure Park": {
		"policy": 1,
		"recruiting": 1,
		"engagement": 2,
		"transparency": 3,
		"commitment_score": 100,
		"policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	},
	"Dust": {
		"policy": 3,
		"recruiting": 3,
		"engagement": 3,
		"transparency": 3,
		"commitment_score": 100,
		"policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	},
	"PGM": {
		"policy": 1,
		"recruiting": 1,
		"engagement": 2,
		"transparency": 2,
		"commitment_score": 100,
		// "policy_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"recruiting_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"engagement_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07",
		"transparency_link": "https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07"
	}
}
