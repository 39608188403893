import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import history from "./history";
import PrivateRoute from './containers/PrivateRoute';
import AdminRoute from './containers/AdminRoute';
import Home from './containers/Home';
import Analyze from './containers/Analyze';
import Improve from './containers/Improve';
import UploadCSV from './containers/UploadCSV';
import DavosPDF from './containers/DavosPDF';
import Portfolio from './containers/PortfolioHome';
import Connect from './containers/Connect';
import LSO from "./containers/LSO";
import Account from "./containers/Account";
import Communications from './containers/Communications';
import DiversityPE from "./containers/DiversityPE/DiversityPE";
import DiversityVC from "./containers/DiversityVC/DiversityVC";
import Oauth from './components/Oauth';
import SurveyContainer from "./containers/Survey";
import ILNHome from './containers/ILNDashboard/ILNHome';
import ILNImpact from './containers/ILNDashboard/ILNImpact';
import ChooseDashboard from './containers/ChooseDashboard';
import Portco from './containers/Portco';
import {Redirect} from 'react-router-dom';
import _ from 'lodash';

import { PORTCO_NAME } from "./utils";

// Map from Permission tag to appropriate URL (these match exactly what BE company_information api returns)
export const urlMap = {
    home: '/home',
    analyze: '/analyze',
    improve: '/improve',
    diversity_pe: '/diversity_pe',
    diversity_vc: '/diversity_vc',
    connect: '/connect',
    lso: '/overview',
    portfolio: '/portfolio',
    voyager: '/communications',
    survey: '/survey',
    iln_dashboard_home: '/iln-dashboard/home',
    iln_dashboard_impact: '/iln-dashboard/impact',
    choose_dashboard: '/choose-dashboard',
    portco: '/portco'
};

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const DashboardRoutes = ({ permissions, url, companyInformation }) => {
    const [noPermissions, setNoPermissions] = React.useState(false)
    const [isPortco, setIsPortco] = React.useState(false)

    const isChooseDashboard = url === "/choose-dashboard"

    // We invert the urlMap key value pair to match the url agaisnt the inverted key
    const invertedUrlMap = _.invert(urlMap)
    const prevCompany = usePrevious(companyInformation)
    
    React.useEffect(() => { 
        // UseEffect hook that runs when a user's permissions change.
        if (companyInformation?.companies[0]?.company == PORTCO_NAME) {
            setIsPortco(true);
        } else if (url != "/choose-dashboard") {
            
            for (const key in invertedUrlMap) {
                // We iterate over urlMap to try to match the url we are on.
                
                const cleanedKey = key.split('/')[1].replace('/', '')
                const cleanedUrl = url.split('/')[1]
                const permissionKey = invertedUrlMap[key]

                if (cleanedKey === cleanedUrl) {
                    // If the permission is set to False we want to redirect
                    if (!permissions[permissionKey]) {
                        setNoPermissions(true)
                    } else {
                        // In some use cases the switching of a dashboard might have the same permissions for the dashboard
                        // a user switches between, so we check to see what the previous companyinfo props name is compared
                        // to the current one.

                        // If the previous company name is not the same as the current company name we want to redirect
                        // Causing the page to reload with the correct information. If we don't do this the user would have to
                        // manually reload the page themselves to see the updated dashboard
                        if (prevCompany != undefined) {
                            if(prevCompany.name != companyInformation.name) {
                                setNoPermissions(true)
                            }
                        }
                    }
                }
            }
        }
    }, [companyInformation])

    // The redirect mentioned above
    // @sometimescasey hack: remove
    if (isPortco && url != "/portco") {
        return <Redirect to="/portco" />
    } else if (noPermissions) {
        return <Redirect to="/" />
    }

    return (
        <div className={isChooseDashboard ? "choose-dashboard-container" : "content-container"}>
            <Router history={history}>
                <Switch>
                    <PrivateRoute key="home" exact path={"/"} isActive={permissions.home} permissions={permissions} component={Home}/>
                    <PrivateRoute key="home" exact path={urlMap.home} isActive={permissions.home} permissions={permissions} component={Home}/>
                    <PrivateRoute key="analyze" path={urlMap.analyze} isActive={permissions.analyze} permissions={permissions} component={Analyze}/>,
                    <PrivateRoute key="inclusion" path={urlMap.analyze} isActive={permissions.analyze} permissions={permissions} component={Analyze}/>,
                    <PrivateRoute key="improve" path={urlMap.improve} isActive={permissions.improve} permissions={permissions}  component={Improve}/>,
                    <PrivateRoute key={'diversity_pe'} path={urlMap.diversity_pe} isActive={permissions.diversity_pe} permissions={permissions}  component={DiversityPE}/>,
                    <PrivateRoute key={'diversity_vc'} path={urlMap.diversity_vc} isActive={permissions.diversity_vc} permissions={permissions}  component={DiversityVC}/>,
                    <PrivateRoute key="connect" path={urlMap.connect} isActive={permissions.connect} permissions={permissions}  component={Connect}/>,
                    <PrivateRoute key="lso" path={urlMap.lso} isActive={permissions.lso} permissions={permissions}  component={LSO}/>,
                    <PrivateRoute key="portfolio"  exact path={urlMap.portfolio} isActive={permissions.portfolio} permissions={permissions}  component={Portfolio}/>,
                    <PrivateRoute key="portco" exact path={urlMap.portco} isActive={isPortco} permissions={permissions} component={Portco} />
                    <PrivateRoute key={'voyager'} exact path={urlMap.voyager} isActive={permissions.voyager} permissions={permissions}  component={Communications}/>,
                    <PrivateRoute path='/oauth' permissions={permissions} component={Oauth}/>
                    <PrivateRoute path='/account' permissions={permissions} component={Account}/>
                    <PrivateRoute path='/survey' permissions={permissions} isActive component={SurveyContainer}/>
                    <AdminRoute path="/admin/upload" component={UploadCSV}/>
                    <PrivateRoute exact path='/davos/pdf' permissions={permissions} component={DavosPDF}/>
                    <PrivateRoute exact path="/iln-dashboard/home" permissions={permissions} component={ILNHome} />
                    <PrivateRoute exact path="/iln-dashboard/impact" permissions={permissions} component={ILNImpact} />
                    <PrivateRoute exact path="/choose-dashboard" permissions={permissions} component={ChooseDashboard} />
                </Switch>
            </Router>
        </div>
    )
};

export default DashboardRoutes;
