import React, {Component} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {urlMap} from "../dashboardRoutes";
import history from "../history";

class PrivateRoute extends Component {
    render() {
        let { isAuthenticated, isLoading, isActive = true, component: Comp, permissions, ...rest } = this.props;
        if (!isActive || !permissions) { return null; }
        let navigateTo = Object.keys(urlMap).filter(urlKey => permissions[urlKey])[0];
        navigateTo = rest.path || navigateTo || "/home";
        if (navigateTo === "/") {
            navigateTo = "/home"
        }

        return (
            <Route exact {...rest} render={(props) => (
                isAuthenticated
                    ? <Comp {...props} />
                    : <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                prevLocation: navigateTo,
                                error: "You need to login first!",
                            },
                        }} />
            )} />
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps)(PrivateRoute);
