import {call, put} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {
    loadDiversityPEPdf,
    loadDiversityPECompanyPolicy,
    loadDiversityPEPolicy,
    loadDiversityPEGoal,
    loadDiversityPEScore,
    loadDiversityPEBenchmark,
    loadDiversityPEResource,
    updateDiversityPEGoal,
    postDiversityPEGoal,
    deleteDiversityPEGoal} from './network';

export default [
    [ActionTypes.GET_DIVERSITYPE_PDF, getDiversityPEPdf],
    [ActionTypes.GET_DIVERSITYPE_COMPANY_POLICY, getDiversityPECompanyPolicy],
    [ActionTypes.GET_DIVERSITYPE_POLICY, getDiversityPEPolicy],
    [ActionTypes.GET_DIVERSITYPE_SCORE, getDiversityPEScore],
    [ActionTypes.GET_DIVERSITYPE_BENCHMARK, getDiversityPEBenchmark],
    [ActionTypes.GET_DIVERSITYPE_RESOURCE, getDiversityPEResource],
    [ActionTypes.GET_DIVERSITYPE_GOAL, getDiversityPEGoal],
    [ActionTypes.DO_UPDATE_DIVERSITYPE_GOAL, doPatchDiversityPEGoal],
    [ActionTypes.DO_CREATE_DIVERSITYPE_GOAL, doPostDiversityPEGoal],
    [ActionTypes.DO_DELETE_DIVERSITYPE_GOAL, doDeleteDiversityPEGoal]
];

function createError(id, timestamp, httpStatusCode, httpResponseBody) {
    return {id, timestamp, httpStatusCode, httpResponseBody}
}

function* getDiversityPEScore({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypeScore());
    const response = yield call(loadDiversityPEScore);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypeScore(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypeScore(error));
    }
}

function* getDiversityPECompanyPolicy({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypeCompanyPolicy());
    const response = yield call(loadDiversityPECompanyPolicy);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypeCompanyPolicy(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypeCompanyPolicy(error));
    }
}

function* getDiversityPEPolicy({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypePolicy());
    const response = yield call(loadDiversityPEPolicy);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypePolicy(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypePolicy(error));
    }
}



function* getDiversityPEBenchmark({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypeBenchmark());
    const response = yield call(loadDiversityPEBenchmark);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypeBenchmark(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypeBenchmark(error));
    }
}

function* getDiversityPEResource({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypeResource());
    const response = yield call(loadDiversityPEResource);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypeResource(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypeResource(error));
    }
}

function* getDiversityPEGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypeGoal());
    const response = yield call(loadDiversityPEGoal);
    if (response.successful && !response.data.error) {
        yield put(Actions.loadedDiversitypeGoal(response.data));
    } else {
        const error = createError(requestId, Date.now(), response.code, response.data);
        yield put(Actions.failedLoadingDiversitypeGoal(error));
    }
}

function* doPatchDiversityPEGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingUpdateDiversitypeGoal());
    const response = yield call(updateDiversityPEGoal, payload);
    if (response.successful) {
        yield put(Actions.doneUpdateDiversitypeGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityPEGoal,{requestId})
    } else {
        yield put(Actions.failedUpdateDiversitypeGoal({id: requestId}))
    }
}

function* getDiversityPEPdf({ payload }) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.loadingDiversitypePdf());
    const response = yield call(loadDiversityPEPdf, payload);
    if (response.successful) {
        yield put(Actions.loadedDiversitypePdf(response.data));
    } else {
        yield put(Actions.failedLoadingDiversitypePdf({id: requestId}))
    }
}

function* doPostDiversityPEGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingCreateDiversitypeGoal());
    const response = yield call(postDiversityPEGoal, payload);
    if (response.successful) {
        yield put(Actions.doneCreateDiversitypeGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityPEGoal,{requestId})
    } else {
        yield put(Actions.failedCreateDiversitypeGoal({id: requestId}))
    }
}

function* doDeleteDiversityPEGoal({payload}) {
    const requestId = payload ? payload.requestId : null;
    yield put(Actions.doingDeleteDiversitypeGoal());
    const response = yield call(deleteDiversityPEGoal, payload);
    if (response.successful) {
        yield put(Actions.doneDeleteDiversitypeGoal(response.data));
        // Since order of other goals may change w/ update, re-fetch goals after update
        yield call(getDiversityPEGoal,{requestId})
    } else {
        yield put(Actions.failedDeleteDiversitypeGoal({id: requestId}))
    }
}
