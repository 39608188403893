import React from 'react';
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "../../utils/Select";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import SelectFilter from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

class Filters extends React.Component {
    //TODO:: @sahanarula, final a better way to handle `all` in the filters
    state = {
        values: {
            d: [],
            nd: [],
            offices: {},
            roles: {},
            selectedValues: []
        }
    };

    _initializeFilterValues (filters) {
        return filters.reduce((fData, filter) => {
            fData[filter] = true;
            return fData;
        }, {});
    }

    componentWillReceiveProps (nextProps) {
        const { filters: { offices = [], roles = [] } = { } } = nextProps;
        if(nextProps.filters !== this.props.filters )
        {
            this.setState({
                values: {
                    ...this.state.values,
                    offices: {
                        ...this.state.values.offices,
                        ...this._initializeFilterValues(offices)
                    },
                    roles: {
                        ...this.state.values.roles,
                        ...this._initializeFilterValues(roles)
                    }
                }
            });
        }
    }

    _handleChange (type, value) {
        if (type.office) {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        offices: {
                            ...prev.values.offices,
                            [type.office]: this.state.values.offices.hasOwnProperty(type.office) ? value : true,
                            all: type.office !== "all" && !value ? false : prev.values.offices.all
                        }
                    }
                }
            })
        } else if (type.role) {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        roles: {
                            ...prev.values.roles,
                            [type.role]: this.state.values.roles.hasOwnProperty(type.role) ? value : true,
                            all: !value ? false : prev.values.roles.all
                        }
                    }
                }
            })
        } else {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        [type]: value
                    }
                }
            })
        }
    }

    _applyFilters () {
        this.props.handleApplyFilters(this.state.values);
    }

    _clearFilters () {
        this.setState(prev => {
            return {
                ...prev,
                values: {
                    ...prev.values,
                    offices: {
                        ...this._initializeFilterValues(this.props.filters.offices)
                    },
                    roles: {
                        ...this._initializeFilterValues(this.props.filters.roles)
                    },
                    d: [],
                    nd: []
                }
            }
        });

        setTimeout(() => {
            this._applyFilters();
        }, 0);
    }

    render () {
        return <FiltersComponent
                    applyFilters={this._applyFilters.bind(this)}
                    clearFilters={this._clearFilters.bind(this)}
                    values={this.state.values}
                    onChange={this._handleChange.bind(this)}
                    groups={this.props.groups}
                    filters={this.props.filters}/>
    }
}

const FiltersComponent = ({ values, onChange, groups, filters, applyFilters, clearFilters }) => {
    const { offices = [], roles = []} = filters;

    /* DISPLAY SELECTED OPTIONS IN THE DROPDOWN */
    let selectedOffices = [];
    let selectedRoles = [];

    /* DISPLAY ALL THE OPTIONS FIRST THING */
    if (Object.keys(values.roles).length === 1 && filters.roles) {
        if (Object.keys(values.roles)[0] === 'all') {
            for (let role = 0; role < filters.roles.length; role++) {
                selectedRoles.push(filters.roles[role]);
            }
        }
    }
    if (Object.keys(values.offices).length === 1 && filters.offices) {
        if (Object.keys(values.offices)[0] === 'all') {
            for (let office = 0; office < filters.offices.length; office++) {
                selectedOffices.push(filters.offices[office]);
            }
        }
    }

    /* DISPLAY BASED ON OPTION SELECTION */
    Object.keys(values.offices).forEach((office) => {
        if (office !== 'all' && values.offices[office] === true) {
            selectedOffices.push(office);
        }
    });
    Object.keys(values.roles).forEach((role) => {
        if (role !== 'all' && values.roles[role] === true) {
            selectedRoles.push(role);
        }
    });

    return (
        <div className="filters">
            <div className={"comparison"}>
                <FormControl className="form-set">
                    <Select selectedValues={values.d} options={groups} onChange={val => { onChange("d", val) }} label={"Group A"} disabledOptions={[]} optionType={"checkbox"} multiple/>
                </FormControl>
                <FormControl className="form-set">
                    <Select selectedValues={values.nd} options={groups} onChange={val => { onChange("nd", val) }} label={"Group B"} disabledOptions={[]} optionType={"checkbox"} multiple/>
                </FormControl>
            </div>

            <div className="filter">
                <FormControl component="fieldset" className="form-set fieldset office-filters">
                    <InputLabel>Filter by office</InputLabel>
                    <SelectFilter
                        multiple
                        value={selectedOffices}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                    >
                        <FormGroup>
                            {
                                offices.map(office => (
                                    <FormControlLabel
                                        className="filter-options"
                                        control={
                                            <Checkbox className="primary check-box"
                                                      checked={values.offices[office] || values.offices.all}
                                                      onChange={e => onChange({ office }, e.target.checked)}
                                                      color="primary"
                                                      value={ office }/>
                                        }
                                        label={ office }
                                    />
                                ))
                            }
                        </FormGroup>
                    </SelectFilter>
                </FormControl>

                <FormControl component="fieldset" className="form-set fieldset role-filters">
                    <InputLabel>Filter by role</InputLabel>
                    <SelectFilter
                            multiple
                            value={selectedRoles}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join(', ')}
                    >
                        <FormGroup className="field-wrap">
                            {
                                roles.map(role =>
                                    <FormControlLabel
                                        className="filter-options"
                                        control={
                                            <Checkbox className="primary check-box"
                                                      checked={values.roles[role] || values.roles.all}
                                                      onChange={e => onChange({role}, e.target.checked)}
                                                      color="primary"
                                                      value={ role } />
                                        }
                                        label={ role }
                                    />
                                )
                            }
                        </FormGroup>
                    </SelectFilter>
                </FormControl>
            </div>


{/* FILTER OPTIONS LAYED OUT */}
{/*
            <FormControl component="fieldset" className="form-set fieldset">
                <FormLabel component="legend">Filter by office</FormLabel>
                <FormGroup>
                    {
                        offices.map(office => (
                            <FormControlLabel
                                control={
                                    <Checkbox className="primary check-box"
                                              checked={values.offices[office] || values.offices.all}
                                              onChange={e => onChange({ office }, e.target.checked)}
                                              color="primary"
                                              value={ office }/>
                                }
                                label={ office }
                            />
                        ))
                    }
                    {/*<FormControlLabel*/}
                        {/*control={*/}
                            {/*<Checkbox className="primary check-box"*/}
                                      {/*checked={values.offices.all}*/}
                                      {/*onChange={e => onChange({ office: "all" }, e.target.checked)}*/}
                                      {/*value="all"*/}
                                      {/*color="primary" />*/}
                        {/*}*/}
                        {/*label="All"*/}
                    {/*/>
                </FormGroup>
            </FormControl>


            <FormControl component="fieldset" className="form-set fieldset role-filters">
                <FormLabel component="legend">Filter by role</FormLabel>
                <FormGroup className="field-wrap">
                    {
                        roles.map(role => <FormControlLabel
                            control={
                                <Checkbox className="primary check-box"
                                          checked={values.roles[role] || values.roles.all}
                                          onChange={e => onChange({role}, e.target.checked)}
                                          color="primary"
                                          value={ role } />
                            }
                            label={ role }
                        />)
                    }
                    {/*<FormControlLabel*/}
                        {/*control={*/}
                            {/*<Checkbox className="primary check-box"*/}
                                      {/*checked={values.roles.all}*/}
                                      {/*onChange={e => onChange({ role: "all" }, e.target.checked)}*/}
                                      {/*value="all"*/}
                                      {/*color="primary" />*/}
                        {/*}*/}
                        {/*label="All"*/}
                    {/*/>
                </FormGroup>
            </FormControl>
*/}

            <div className="filter-actions">
                <button className="apply-filter" onClick={applyFilters}>Apply Filter</button>
                <button className="clear-filter" onClick={clearFilters}>Clear Filter</button>
            </div>
        </div>
    )
};

export default Filters;
