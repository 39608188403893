import React from 'react';

const EmailResults = ({ handleEmailSubmit, handleEmailChange, email }) => {
    return (
        <div id={"email-results"}>
            <p>Email me my results:</p>
            <input type="text" value={email} onChange={(e) => handleEmailChange(e.target.value)}/>
            <button className={"btn-orange"} onClick={ handleEmailSubmit }>Send</button>
        </div>
    );
};

export default EmailResults;
