export const MOCK_INDUSTRY_DATA = {
    "Female":{"entry_level_or_analyst":40,"manager":10,"executive":0},
    "POC":{"entry_level_or_analyst":42,"manager":26,"executive":26}}

export const MOCK_INDUSTRY_DATA_2 = {
    "Female":{"entry_level_or_analyst":40,"manager":10,"executive":0},
    "POC":{"entry_level_or_analyst":42,"manager":26,"executive":26},
    "LGBTQ+":{"entry_level_or_analyst":13,"manager":15,"executive":1},
}

export const MOCK_INDUSTRY_DATA_3 = {
    "Female":{"entry_level_or_analyst":40,"associate": 20,"manager":10,"vice_president": 10,"executive":0},
    "POC":{"entry_level_or_analyst":42,"manager":26,"executive":26},
    "LGBTQ+":{"entry_level_or_analyst":13,"manager":15,"executive":1},
    "Persons with disability":{"entry_level_or_analyst":25,"associate": 20,"manager":12, "vice_president": 10, "executive":7}
}

export const MOCK_DATA = {
    "Female":{"entry_level_or_analyst":40, "associate": 50, "manager":10, "vice_president": 10, "executive":0},
    "POC":{"entry_level_or_analyst":42, "associate": 30, "manager":26, "executive":26},
    "LGBTQ+":{"entry_level_or_analyst":13, "associate": null, "manager":15, "executive":1},
    "Persons with disability":{"entry_level_or_analyst":30, "associate": 10, "manager":null, "vice_president": 15, "executive":10}
}
