import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import Regulatory from '../components/Connect/Resources/Regulatory';
import Trending from '../components/Connect/Resources/Trending';
import Popular from '../components/Connect/Resources/Popular';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';

class Resources extends Component {
    state = { regulatory: [], trending: [], popular: [], mostPopular: []};
    componentDidMount() {
        fetchRegulatory(regulatory =>
          this.setState({ regulatory: regulatory }));
        fetchTrending(trending =>
            this.setState({ trending: trending }));
        fetchPopular(popular =>
            this.setState({ popular: popular }));
        fetchMostPopular(mostPopular =>
            this.setState({ mostPopular: mostPopular }));
      }

    handleChange = (event, value) => {
        this.setState({ value });
    };


    render() {

        return (
            <div>
                <div className="Header"> Resources </div>
                <div>
                    <TopBar />
                </div>

                <div>
                    <div>
                        <Popular mostPopular={this.state.mostPopular} popular={this.state.popular}/>
                    </div>
                    <div>
                        <Trending trending={this.state.trending}/>
                        <Regulatory regulatory={this.state.regulatory} />
                    </div>
                </div>
                
            </div>
        );
    }
}

const fetchRegulatory = cb =>
  cb([
    { title: "Explaining the UK Pay Gap Reporting Regulations", company: "MERCER", KPI: "PAY EQUITY" },
    { title: "Bill C-25- A Catalyst for Corporate Diversity in Canada", company: "LAWSON LUDELL", KPI: "REPORTING" },
    { title: "Diversity and Employment Equity", company: "LAWSON LUDELL", KPI: "REPORTING" },
    { title: "Gender Inclusivity and Human Rights", company: "LAWSON LUDELL", KPI: "REPORTING" },
]);

const fetchTrending = cb =>
  cb([
    { title: "Women and Ninorities in Tech, By the Numbers", company: "MERCER", KPI: "PAY EQUITY" },
    { title: "What Does it Mean to be an Inclusive Leader", company: "MERCER", KPI: "PAY EQUITY" },
    { title: "How and Where Diversity Drives Financial Performance", company: "MERCER", KPI: "PAY EQUITY" },
    { title: "Leadership Forum: The Role of the LGBTQ+ Ally", company: "MERCER", KPI: "PAY EQUITY" },
]);

const fetchPopular = cb =>
  cb([
    { KPI: "CULTURE", source: "HARVARD BUSINESS REVIEW", title: "The Myriad Benefits of Diversity in the Workplace", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis sagittis sagittis. In mauris nibh, fringilla id dapibus eget, malesuada sit amet orci. Sed vestibulum dapibus enim, at condimentum risus aliquam a. Ut aliquet purus augue, nec lobortis justo lobortis quis. Pellentesque feugiat sed velit at sollicitudin. Proin vitae malesuada." },
    { KPI: "SAFETY", source: "HARVARD BUSINESS REVIEW", title: "The Problem of Visibility for Women in Engineering, and How They Manage It", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus arcu non tellus facilisis, vitae euismod odio tempor. In hac habitasse platea dictumst. Donec quis elementum risus, ut pretium justo. Suspendisse elementum leo sem. Vestibulum rhoncus faucibus urna, non condimentum eros blandit a. Proin in porta erat, at tristique magna." },
    { KPI: "BIAS", source: "HARVARD BUSINESS REVIEW", title: "Why Diversity Programs Fail", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lectus felis, porta ac scelerisque id, fringilla non nibh. Mauris aliquet sapien sit amet blandit pellentesque. Nullam vel est tempor, euismod lorem eget, aliquam ligula. Quisque malesuada quam nulla, non euismod justo pulvinar et. Proin eget accumsan risus. Curabitur elementum interdum." },
    { KPI: "CULTURE", source: "HARVARD BUSINESS REVIEW", title: "Forget a Mentor, Find a Sponsor", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ac mollis nisl. Nulla neque lectus, semper sit amet mi quis, congue malesuada augue. Donec eleifend porta tellus, dapibus interdum ex molestie vitae. Suspendisse pulvinar placerat risus. In cursus orci ut leo bibendum, quis viverra lacus eleifend. Class aptent taciti sociosqu." },
]);

const fetchMostPopular = cb =>
  cb([
    { KPI: "CULTURE", source: "HARVARD BUSINESS REVIEW", title: "Four Ways Managers can be more inclusive", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", img: './../images/popular.jpg' },
]);


const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(Resources);
