import React from 'react';
import './Topics.scss'

const Dashboard = props =>
	<div className="cardFeatured">
		<div>
			{props.featured.map((feature, i) => (
				<div className="featured">
					<div className="title">Featured</div>
					<div className="pill">Ask a Q</div>
					<div className="topicTitle">{feature.title}</div>
					<div className="topicContent">{feature.content}</div>
					<div className="topicKPI">{feature.KPI}</div>
				</div>
			))}
		</div>
	</div>

export default Dashboard;
