import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../../actions';
import PostCard from '../PostCard';
import AddComment from '../AddComment';
import Comment from '../Comment';
import './index.scss';

class SpecificPost extends Component {
    constructor(props) {
        super(props);
        let is_bookmarked = 0;
    }

    componentWillMount () {
        this.props.handleGetComments({ post_id : this.props.location.state.post.id.toString()});
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    render() {
        this.is_bookmarked = this.props.bookmark.filter(post => {
            if (post.post.id === this.props.location.state.post.id) {
                return post.is_bookmarked
            }
            return 0
        });
        return (
            <div>
                <PostCard 
                    post={this.props.post}
                    specificPost={this.props.specificPost}
                    profileInfo={this.props.profile}
                    handleEditPost={this.props.handleEditPost} 
                    handleDeletePost={this.props.handleDeletePost}
                    handleLikePost={this.props.handleLikePost}
                    handleUpdateBookmark={this.props.handleUpdateBookmark}
                    isBookmarked={ this.is_bookmarked.length ? 1 : 0 }
                    isSpecificPost={true}
                    kpis={this.props.kpis} />

                <AddComment handleCreateComment={this.props.handleCreateComment}
                            postInfo={this.props.location.state.post}
                            profile={this.props.profile} />
                
                {
                    this.props.comments.map((comment, id) => {
                        return (
                            <Comment key={id} 
                                     comment={comment} 
                                     profileInfo={this.props.profile}
                                     handleGetComments={this.props.handleGetComments} 
                                     postInfo={this.props.location.state.post} 
                                     handleLikeComment={this.props.handleLikeComment}
                                     handleEditComment={this.props.handleEditComment}
                                     handleDeleteComment={this.props.handleDeleteComment} />    
                        )
                    })
                }
            </div>
        );
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.connect,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(SpecificPost);