import { ActionTypes } from '../actions';

const defaultState = {
    error: []
};


export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.SET_GLOBAL_MESSAGE:{
                return {
                    ...state,
                    ...action.payload
                };
            }
            case ActionTypes.RESET_GLOBAL_MESSAGE:{
                return {
                    ...state,
                    code: undefined,
                    type: "error",
                    message: ""
                };
            }
            default:
                return state;

        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }
};
