import { call, put, apply } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { stringifyQueryParams } from '../utils';
import {
    applyTarget,
    getAllTargets,
    deleteTarget,
    updateTarget,
    getAllRecommendations,
    createActionPlan,
    getAllActionPlans,
    deleteActionPlan,
    getAllTargetOptions,
    loadAllSolutions,
    updateCompanySolutions,
    deleteSolution,
    updateBudget,
    createNewSolution,
    createNewCompanySolution, loadFilterMap
} from './network';

// All data sagas to add to middleware.
export default [
    [ActionTypes.GET_TARGETS, getTargets],
    [ActionTypes.DO_APPLY_TARGET, doApplyTarget],
    [ActionTypes.DO_DELETE_TARGET, doDeleteTarget],
    [ActionTypes.DO_UPDATE_TARGET, doUpdateTarget],
    [ActionTypes.GET_RECOMMENDATIONS, getRecommendations],
    [ActionTypes.GET_ACTION_PLANS, getActionPlans],
    [ActionTypes.DO_CREATE_ACTION_PLAN, doCreateActionPlan],
    [ActionTypes.DO_DELETE_ACTION_PLAN, doDeleteActionPlan],
    [ActionTypes.GET_ALL_SOLUTIONS, getAllSolutions],
    [ActionTypes.DO_UPDATE_COMPANY_SOLUTIONS, doUpdateCompanySolutions],
    [ActionTypes.DO_DELETE_SOLUTION, doDeleteSolution],
    [ActionTypes.DO_UPDATE_BUDGET, doUpdateBudget],
    [ActionTypes.DO_CREATE_NEW_SOLUTION, doCreateNewSolution],
    [ActionTypes.DO_CREATE_NEW_COMPANY_SOLUTION, doCreateNewCompanySolution]
];

// function * doApplyTarget ({ payload: target }) {
//     const belongs_to = Object.keys(target)[0];
//     const payload = {
//         "belongs_to": belongs_to,
//         "current_percent": target[belongs_to].current,
//         "last_percent": target[belongs_to].previous,
//         "target_percent": target[belongs_to].target,
//         "target_year": target[belongs_to].targetYear
//     };

//     yield put(Actions.doingApplyTarget());

//     const response = yield call(applyTarget, payload);

//     if (!response.successful) {
//         yield put(Actions.showMessage({type: "error", code: response.code}));
//     } else {
//         yield put(Actions.doneApplyTarget(response.data));
//     }
// }

function * doApplyTarget ({ payload }) {
    yield put(Actions.doingApplyTarget(payload));

    const response = yield call(applyTarget, payload);
    
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error}));
    } else {
        yield put(Actions.doneApplyTarget(response.data));
    }
}

function * getTargets () {
    yield put(Actions.loadingTargets());
    yield put(Actions.loadingTargetOptions());

    const allTargetsResponse = yield call(getAllTargets);
    const allTargetOptionsResponse = yield call(getAllTargetOptions);
    
    if (!allTargetsResponse.successful) {
        yield put(Actions.showMessage({type: "error", code: allTargetsResponse.code}));
    } else if (!allTargetOptionsResponse) {
        yield put(Actions.showMessage({type: "error", code: allTargetOptionsResponse.code}));
    } else {
        yield put(Actions.loadedTargets(allTargetsResponse.data));
        yield put(Actions.loadedTargetOptions(allTargetOptionsResponse.data));
    }
}

function * doDeleteTarget ({ payload }) {
    yield put(Actions.doingDeleteTarget());
    
    const response = yield call(deleteTarget, stringifyQueryParams(payload));
    
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneDeleteTarget(payload));
    }
}

function * doUpdateTarget ({ payload }) {
    yield put(Actions.doingUpdateTarget());
    
    const response = yield call(updateTarget, payload);
    
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error}));
    } else {
        yield put(Actions.doneUpdateTarget(response.data));
    }
}

function * getRecommendations () {
    yield put(Actions.loadingRecommendations());

    const recommendationsResponse = yield call(getAllRecommendations);
    const actionPlansResponse = yield call(getAllActionPlans);

    if (!recommendationsResponse.successful || !actionPlansResponse.successful) {
        yield put(Actions.showMessage({type: "error", code: !recommendationsResponse.successful ? recommendationsResponse.code : actionPlansResponse.code}));
    } else {
        yield put(Actions.loadedRecommendations({ recommendations: recommendationsResponse.data, actionPlans: actionPlansResponse.data }));
    }
}

function * getActionPlans () {
    yield put(Actions.loadingActionPlans());

    const response = yield call(getAllActionPlans);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedActionPlans(response.data));
    }
}

function * doCreateActionPlan ({ payload }) {
    yield put(Actions.doingCreateActionPlan());
    yield put(Actions.markSolutionSelected(payload));

    const response = yield call(createActionPlan, { recommendation_id: payload.recommendationId });

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.unmarkSolutionSelected(payload));
    } else {
        yield put(Actions.doneCreateActionPlan(response.data));
    }
}

function * doDeleteActionPlan ({ payload }) {
    yield put(Actions.doingDeleteActionPlan());
    yield put(Actions.unmarkSolutionSelected(payload));

    const response = yield call(deleteActionPlan, payload.actionPlanId);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.markSolutionSelected(payload));
    } else {
        yield put(Actions.doneDeleteActionPlan(payload));
    }
}

function * getAllSolutions () {
    yield put(Actions.loadingAllSolutions());

    const response = yield call(loadAllSolutions);
    
    if (!response) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedAllSolutions(response.data));
    }
}

function * doUpdateCompanySolutions ({ payload }) {
    yield put(Actions.doingUpdateCompanySolutions());
    
    const response = yield call(updateCompanySolutions, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneUpdateCompanySolutions(response.data));
    }
}

function * doDeleteSolution ({ payload }) {
    yield put(Actions.doingDeleteSolution());
    
    const response = yield call(deleteSolution, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneDeleteSolution(payload));
    }
}

function * doUpdateBudget ({ payload }) {
    yield put(Actions.doingUpdateBudget());

    const response = yield call(updateBudget, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneUpdateBudget(payload));
    }
}

function * doCreateNewSolution ({ payload }) {
    yield put(Actions.doingCreateNewSolution());

    const response = yield call(createNewSolution, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneCreateNewSolution(response.data));
    }
}

function * doCreateNewCompanySolution ({ payload }) {
    yield put(Actions.doingCreateNewCompanySolution());

    const response = yield call(createNewCompanySolution, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneCreateNewCompanySolution(response.data));
    }
}
//
// function *doCreateNewCompanySolution ({payload}){
//     yield put(Actions.doingCreateNewCompanySolution());
//
//     const filters = yield call(loadFilterMap());
//     let solution = filters.solutions.map(x=>x.name).find(payload.solution.name)
//
//     if (solution===undefined){
//         solution = yield call(createNewSolution());
//     }
//     payload.solution = solution.id;
//     const response = yield call(createNewCompanySolution, payload);
//
//     if (!response.successful) {
//         yield put(Actions.showMessage({type: "error", code: response.code}));
//     } else {
//         yield put(Actions.doneCreateNewCompanySolution(response.data));
//     }
// }
//
//
//



//