import React from 'react';
import './Popular.scss';
import mostPopular from'../../../images/popular.jpg';
import im0 from'../../../images/0.jpg';
import im1 from'../../../images/1.jpg';
import im2 from'../../../images/2.jpg';
import im3 from'../../../images/3.jpg';

function sort_by_kpi(kpi){
    var page = window.location.href;
    var to_display = "inline";
    if(page.search("#") === -1){
        return to_display;
    }
    var kpi_filter = page.split("#").pop().toUpperCase().replace(/\s/g,'');
    var clean_kpi = kpi.replace(/\s/g,'');
    if(clean_kpi === kpi_filter){
        return to_display;
    }
    to_display = "none";
    return to_display;
}

const Popular = props => {
    var sub_popular = [];
    var images = [im0, im1, im2, im3]
    for (var i = 0; i < props.popular.length; i++) {
        sub_popular.push(
            <div style={{display: sort_by_kpi(props.popular[i].KPI)}}className="cell">
                <img className="smallImage" alt="" src={images[i]} />
                <a href="http://google.com">
                    <span className="smallTopic">{props.popular[i].title}</span><br/>
                </a>
                <span className="popularKPI"> {props.popular[i].KPI} </span>
				<span className="popularLine"> | </span>
                <span className="popularSource"> {props.popular[i].source}</span>
                <div className="smallContent"> {props.popular[i].content} </div>
            </div>);
       
    }  
    return (
        <div className="popular-card">
            <div className="popularTitle">Popular</div>
            {props.mostPopular.map((topic, i) => (
                <div className="most-popular">
                    <img className="mostPopularImg" alt="" src={mostPopular} />
                    <a href="http://google.com">
                        <div className="popularTopic2">{topic.title} </div>
                    </a>
                    <span className="popularKPI"> {topic.KPI} </span>
					<span className="popularLine"> | </span>
                    <span className="popularSource"> {topic.source}</span>
                    <div className="popularContent"> {topic.content} </div>
                </div>
            ))}
                <div className="popular-grid">
                    {sub_popular}
                </div>
        </div>
    )
};


export default Popular;