import React, {useEffect} from 'react';
import Progress from "./Progress";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const Draft = ({ savedSurveys, setSelectedSurvey }) => {
    useEffect(() => {
        setSelectedSurvey(savedSurveys[0]);
    }, []);

    return (
        <div className={"survey-review"}>
            <Progress selected={"review"}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src="/images/survey-draft.svg" alt="Survey Draft"/>
                    <h5>Welcome to Your D&I Survey Dashboard</h5>
                    <p>Create your survey to get a deep dive analysis of your organization’s culture</p>
                    <Link to={"/survey/create"}>
                        <Button variant={"primary"}>Continue Drafted Survey</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Draft;
