import React from 'react';

// At one point these were going to have links, left these in jic they are added again.
export const PROGRAMS = [
    {
        title: "Human Capital & Recruitment",
        items: [
            { label: "Interview Quotas", link: '' },
            { label: "Inclusive Mentorship Program", link: '' },
            { label: "Family Leave Policy", link: '' },
            { label: "Returnship Program", link: '' },
            { label: "Flexible Work Policies", link: '' },
            { label: "Emergency Care Options", link: '' },
            { label: "Conducting Annual Equal Pay Analysis", link: '' },
            { label: "Training and Support Programs for Underrepresented Hires", link: '' },
        ]
    }, {
        title: "Internal Policy & Culture",
        items: [
            { label: "Harassment and Discrimination Policies", link: "" },
            { label: "Organizational Culture Surveys", link: "" },
            { label: "Inclusive Office Spaces", link: "" },
            { label: "Annual Leadership Training on D&I", link: "" },
            { label: "Anti-Harassment Training for All Employees", link: "" },
            { label: "Promotions & Attritions Tracking", link: "" },
            { label: "Employee Resource Groups or Circles", link: "" },
            { label: "Harassment Reporting System", link: "" },
            { label: "Cultural Competency Training for All Employees", link: "" },
            { label: "Internship Program for Individuals from Underrepresented Groups", link: "" }
        ]
    }, {
        title: "Dealflow Sources",
        items: [
            { label: "Diverse Investment Strategy with Quantifiable Targets", link: "" },
            { label: "Conduct a Review of Corporate Policies on D&I", link: "" },
            { label: "Reducing Bias from the Investment Screening Process", link: "" },
            { label: "Partnerships with Non-Traditional Sourcing Networks", link: "" },
            { label: "D&I Incorporated Early in the Investment Process as a Decision-Making Metric", link: "" },
            { label: "Tracking the Diversity of Referred Founders / Executives", link: "" }
        ]
    }, {
        title: "Portfolio Company Guidance",
        items: [
            { label: "Diversity Targets for Boards", link: "" },
            { label: "Diversity Targets for Executive Teams", link: "" },
            { label: "Executive D&I Training", link: "" },
            { label: "Integrate D&I into Portfolio Engagement Plan", link: "" },
            { label: "Mandate D&I Scorecards in Regular Reporting to GP", link: "" },
            { label: "Institute Diversity Focused Internship Programs", link: "" },
            { label: "Independent HR Ombudsman", link: "" }
        ]
    }
];


export const L1REQUIREMENTS = [
    'Organizational D&I Statement and Strategy',
    'Include D&I in Due Diligence and Entire Deal Life Cycle',
    'D&I Accountability at Leadership Level (i.e. a Chief Diversity Officer or D&I Committee)',
    'Policies Widening Applicant Pool Beyond Traditional Sources',
    'Policies Reducing Biases in Hiring Processes',
    'Tracking & Reporting Dealflow Demographics*'
];

export const L2REQUIREMENTS = [
    <span>All <b>Level 1</b> Requirements</span>,
    'Diverse Investment Strategy',
    'Portfolio Inclusion Assessment'
];
