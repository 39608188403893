import { ActionTypes } from '../../actions';

const defaultState = {
    allSolutions: [],
    budget: 0,
    newSolutions: {},
};

export default (state = defaultState, action) => {
    try {
        let solutions, prevSolutions;
        switch(action.type) {
            case ActionTypes.LOADED_ALL_SOLUTIONS:
                solutions = action.payload;
                return {
                    ...state,
                    allSolutions: solutions
                };
            case ActionTypes.DONE_UPDATE_COMPANY_SOLUTIONS:
                solutions = action.payload;
                prevSolutions = state.allSolutions;

                let newSolutions = prevSolutions.map(solution=>{
                    if(solution.id === action.payload.id){
                        return Object.assign(solution, action.payload)
                    }
                    return solution
                });
                return {
                    ...state,
                    allSolutions: [...newSolutions]
                };
            case ActionTypes.DONE_DELETE_SOLUTION:
                solutions = action.payload;
                prevSolutions = state.allSolutions.filter(x=>x.id!==Number(solutions.id));

                return {
                    ...state,
                    allSolutions: [...prevSolutions]
                };
            case ActionTypes.DONE_UPDATE_BUDGET:
                return {
                    ...state,
                    budget: action.payload
                };
            case ActionTypes.DONE_CREATE_NEW_SOLUTION:
                solutions = action.payload;
                return {
                    ...state,
                    newSolutions: solutions
                };
            case ActionTypes.DONE_CREATE_NEW_COMPANY_SOLUTION:
                prevSolutions = state.allSolutions;
                solutions = action.payload;
                return {
                    ...state,
                    allSolutions: [...prevSolutions, action.payload]
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;
        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
