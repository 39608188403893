import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parseDate } from "../../../utils";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditPost from '../EditPost';
import DeletePost from '../DeleteModal';
import './index.scss';

class Post extends Component {
    state = {
        anchorEl: null,
        isModalOpen: false,
        deleteConfirmationModal: false
    }

    componentWillReceiveProps() {
    }

    handleOpenAction = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        })
    }

    handleCloseAction = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleOpenModal = () => {
        this.setState({
            isModalOpen: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false
        });
        this.handleCloseAction();
    }

    handleOpenDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: false
        });
        this.handleCloseAction();
    }
    
    handleLike = () => {
        // backend needs to change toggle liked_by_user each time as well
        if (this.props.post.liked_by_user) {
            this.props.handleLikePost({
                post_id: this.props.post.id.toString(),
                like_count: {
                    "is_liked" : this.props.post.likes_count - 1
                }
            })
        } else {
            this.props.handleLikePost({
                post_id: this.props.post.id.toString(),
                like_count: {
                    "is_liked" : this.props.post.likes_count + 1
                }
            })
        }
    }

    handleBookmark = () => {
        let isBookmarked = 1;
        if (this.props.isBookmarked) {
            isBookmarked = 0;
        }
        
        this.props.handleUpdateBookmark({
            post_id : this.props.post.id.toString(),
            is_bookmarked : {
                "is_bookmarked" : isBookmarked
            }
        });
    }

    handleEdit = (post) => {
        this.props.handleEditPost({
            id : this.props.post.id.toString(),
            post : {
                "title": post.title,
                "description": post.description,
                "kpi": post.kpi
            }
        })
        this.handleCloseAction();
    }

    handleDelete = () => {
        this.props.handleDeletePost({ id: this.props.post.id.toString() });
        this.handleCloseDeleteModal();
    }

    render() {
        return (
            <div id ="post">
                <div className="user-info-container">
                    <div className="profile-name">
                        {this.props.post.user.full_name.toUpperCase().match(/\b(\w)/g)}
                    </div>
                    <div className="user-container">
                        <div className="user">{this.props.post.user.full_name} - {this.props.post.user.role}</div>
                        <div className="date">{parseDate(this.props.post.created_at)}</div>
                    </div>
                </div>

                <div className="bookmark-container">
                    <button onClick={() => this.handleBookmark()} className="bookmark"><img src={ this.props.isBookmarked ? "/images/svg/Connect/Post/bookmarked.svg" : "/images/svg/Connect/Post/bookmark.svg" } alt="bookmark icon" />Bookmark</button>
                    <button className={this.props.profileInfo.company === this.props.post.user.company ? "action" : "hidden"}>
                        <IconButton
                            id="action-menu"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => this.handleOpenAction(e)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="post-action-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => this.handleCloseAction()}
                        >
                            <MenuItem onClick={() => this.handleOpenModal()}>Edit post</MenuItem>
                            <MenuItem onClick={() => this.handleOpenDeleteModal()}>Delete post</MenuItem>
                        </Menu>
                    </button>
                    {
                        this.props.kpis.length
                        ? <EditPost isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} handleEdit={this.handleEdit} postInfo={this.props.post} kpis={this.props.kpis} />
                        : null
                    }
                    <DeletePost isOpen={this.state.deleteConfirmationModal} handleCloseModal={this.handleCloseDeleteModal} handleDelete={this.handleDelete} type={"Post"} />
                </div>
                
                <div className="question">
                    <Link to={{
                        pathname: "/connect/post-id",
                        state: {
                            post: this.props.post
                        }
                    }} >
                        {this.props.post.title}
                    </Link>
                </div>

                <div className="post-info-container">
                    <span className="category">{this.props.post.kpi.name}</span>
                    |
                    <span className="comment"><img src="/images/svg/Connect/Post/comment.svg" alt="comment icon" /> {this.props.post.comments_count} Comments</span>
                    |
                    <button onClick={() => this.handleLike()} className="like"><img src={ this.props.post.liked_by_user ? "/images/svg/Connect/Post/liked.svg" : "/images/svg/Connect/Post/like.svg" } alt="like icon" />{this.props.post.likes_count} People Find This Helpful</button>
                </div>
                <hr />
            </div>
        );
    };
};

export default Post;