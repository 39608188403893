import React, { Component } from 'react';
import Trend from './Trend';
import './index.scss';

const TrendingPosts = [
    {
        "question": "21 diversity and inclusion influencers you should know",
        "comment": 39,
        "date": "Jul 8 2019"
    },
    {
        "question": "21 diversity and inclusion influencers you should know",
        "comment": 39,
        "date": "Jul 8 2019"
    },
    {
        "question": "21 diversity and inclusion influencers you should know",
        "comment": 39,
        "date": "Jul 8 2019"
    }
]
class Trending extends Component {
    loadMore = () => {
        console.log('load more');
    }

    render() {
        return (
            <div id="trending">
                <div className="header">
                    <img src="/images/svg/Connect/trending.svg" alt="trending icon" />
                    <span className="header-title">Trending</span>
                </div>
                
                {
                    TrendingPosts.map((post, id) => {
                        return (
                            <div className="trend-container" key={id}>
                                <Trend post={post} />
                            </div>
                        )
                    })
                }
                <div className="button-container">
                    <button onClick={() => this.loadMore()} className="more-button">See All</button>
                </div>
            </div>
        );
    };
};

export default Trending