import React, {Component} from 'react';
import Card from '../Card'
import './index.scss'

// Card w/ title, left side image, right side detail text, and action link below
class ContactCard extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    onClick(){
        this.ref.current.href = `mailto:${this.props.link}`;
        this.ref.current.click();
        this.ref.current.href = '';
    }

    render() {
        return (
                <Card className={'contact-card'}>
                    <h1>{this.props.title}</h1>
                    <div className="container">
                        <img src={this.props.imgSrc}/>
                        <div>
                            <p>
                                {this.props.detail}
                            </p>
                            <div className="link" onClick={this.onClick.bind(this)}>{this.props.linkText}</div>
                            <a hidden target="_blank" ref={this.ref}/>
                        </div>
                    </div>
                </Card>
        );
    }
}

export default ContactCard;
