import React, { Component } from 'react';
import PostCard from '../PostCard';
import _ from 'lodash';
import './index.scss';

class MyPost extends Component {
    componentWillReceiveProps() {
    }

    reverseChronologicalSort(list) {
        return _.orderBy(list, ['id'], ['desc']);
    }

    render() {
        const sorted = this.reverseChronologicalSort(this.props.myPosts);
        return (
            <div id="my-post">
                {
                    sorted.map((post, id) => {
                        if (post.user.company === this.props.profileInfo.company) {
                            const isBookmarked = this.props.bookmark.filter((bookmark => {
                                if (bookmark.post.id === post.id) {
                                    return bookmark.is_bookmarked;
                                }
                                return 0
                            }) )
                            return (
                                <PostCard key={id} 
                                          post={post} 
                                          profileInfo={this.props.profileInfo}
                                          handleEditPost={this.props.handleEditPost} 
                                          handleDeletePost={this.props.handleDeletePost}
                                          handleLikePost={this.props.handleLikePost}
                                          handleUpdateBookmark={this.props.handleUpdateBookmark}
                                          isBookmarked={isBookmarked[0] ? isBookmarked[0].is_bookmarked : 0 }
                                          kpis={this.props.kpis}
                                          specificPost={false}
                                          isSpecificPost={false}
                                />
                            )                            
                        }
                    })
                }
            </div>
        );
    }
};

export default MyPost;