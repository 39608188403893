import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import history from "./history";
import Dashboard from './containers/Dashboard';
import Login from './containers/Auth/Login';
import Register from './containers/Auth/Register';
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";

const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path='/login' component={Login}/>
                <Route path='/register' component={Register}/>
                <Route path='/forgot-password' component={ForgotPassword}/>
                <Route path='/reset-password/:token' component={ResetPassword}/>
                <Route path={'/'} component={Dashboard}></Route>
            </Switch>
        </Router>
    )
};

export default Routes;
