import React from 'react';
import {Actions} from '../../actions';
import {connect} from 'react-redux';
import Goals from '../../components/DiversityVC/NextSteps/Goals';

const mapStateToProps = (state, ownProps) => {
    return {
        diversityvc: state.diversityvc,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(Goals);
