import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import Bookmark from '../components/Connect/Bookmark';
import './App.scss';

class BookmarkContainer extends Component {
    render() {
        if (this.props.bookmark.length) {
            return (
                <div>
                    <Bookmark
                        handleEditPost={this.props.handleEditPost}
                        handleDeletePost={this.props.handleDeletePost}
                        handleLikePost={this.props.handleLikePost}
                        handleUpdateBookmark={this.props.handleUpdateBookmark}
                        bookmark={this.props.bookmark}
                        profileInfo={this.props.profileInfo}
                        kpis={this.props.kpis} />
                </div>
            );
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(BookmarkContainer);
