import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import Dashboard from '../components/Connect/Topics/Topics';
import Answers from '../components/Connect/Topics/Answer';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';

class Answer extends Component {
    render() {
        this.forceUpdate();
        return (
            <div>
                <div className="Header"> Topics </div>
                <div>
                    <TopBar />
                </div>

                <div>
                    <Dashboard />
					<Answers />
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(Answer);
