import React, { Component } from 'react';
import './index.scss';

class OverallScore extends Component {
    render() {
        return (
            <div id="overall-score-container">
                <div className="title">Overall Score</div>
                <div className="score"><span className="highlight">{this.props.data.overall_score}</span>/100</div>
                <div className="breakdown-container">
                    <div className="breakdown">
                        <div className="breakdown-title">Diversity</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.diversity_score}</span>/100</div>
                    </div>
                    <div className="breakdown">
                        <div className="breakdown-title">Inclusion</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.inclusion_score}</span>/100</div>
                    </div>
                    <div className="breakdown">
                        <div className="breakdown-title">Commitment</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.commitment_score}</span>/100</div>
                    </div>
                </div>
            </div>
        );
    };
};

export default OverallScore;