import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadPainpointData, loadDiversityCompositionData, loadMetricOverviewData
    , loadFocusAreaData, loadInclusionScoreData, loadCompanyInformation,
    loadExecSummaryData, deploySurvey, informPurchase, setActiveDashboard} from './network';
import i18n from "../i18n";
import _ from "underscore";
// All data sagas to add to middleware.
export default [
    [
        ActionTypes.GET_PAINPOINT_DATA
        , getPainpointData
    ],
    [
        ActionTypes.GET_DIVERSITY_COMPOSITION_DATA
        , getDiversityCompositionData
    ],
    [
        ActionTypes.GET_METRIC_OVERVIEW_DATA
        , getMetricOverviewData
    ],
    [
        ActionTypes.GET_FOCUS_AREA_DATA
        , getFocusAreaData
    ],
    [
        ActionTypes.GET_INCLUSION_SCORE_DATA
        , getInclusionScoreData
    ],
    [
        ActionTypes.GET_EXEC_SUMMARY_DATA
        , getExecSummaryData
    ],
    [
        ActionTypes.DO_DEPLOY_SURVEY,
        doDeploySurvey
    ],
    [
        ActionTypes.DO_INFORM_PURCHASE,
        doInformPurchase
    ],
    [
        ActionTypes.GET_COMPANY_INFORMATION,
        getCompanyInformation
    ],
    [   ActionTypes.DO_ACTIVE_DASHBOARD,
        createActiveDashboard
    ]
];

function * getPainpointData ({payload}) {
    yield put(Actions.loadingPainpointData());

    const response = yield call(loadPainpointData, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedPainpointData(response.data));
    }
}

function * getDiversityCompositionData () {
    yield put(Actions.loadingDiversityCompositionData());

    const response = yield call(loadDiversityCompositionData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityCompositionData(response.data));
    }
}

function * getMetricOverviewData () {
    yield put(Actions.loadingMetricOverviewData());

    const response = yield call(loadMetricOverviewData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedMetricOverviewData(response.data));
    }
}


function * getFocusAreaData () {
    yield put(Actions.loadingFocusAreaData());

    const response = yield call(loadFocusAreaData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedFocusAreaData(response.data));
    }
}


function * getInclusionScoreData () {
    yield put(Actions.loadingInclusionScoreData());

    const response = yield call(loadInclusionScoreData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionScoreData(response.data));
    }
}

function * getExecSummaryData () {
    yield put(Actions.loadingExecSummaryData());

    const response = yield call(loadExecSummaryData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedExecSummaryData(response.data));
    }
}

function * doDeploySurvey () {
    yield put(Actions.doingDeploySurvey());

    const response = yield call(deploySurvey);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.doneDeploySurvey());
    } else {
        yield put(Actions.doneDeploySurvey());
        yield put(Actions.showMessage({type: "success", message: "All set! you will be contacted by our executive soon"}));
    }
}

function * doInformPurchase () {
    yield put(Actions.doingInformPurchase());

    const response = yield call(informPurchase);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.doneInformPurchase());
    } else {
        yield put(Actions.doneInformPurchase());
    }
}

function * getCompanyInformation () {
    yield put(Actions.loadingCompanyInformation());
    const response = yield call(loadCompanyInformation);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
        yield put(Actions.showMessage({
            type: "error",
            code: response.code,
            delay: response.code === 404 ? 15000 : 5000,
            message: _.maybe(response, "data", "detail") ? i18n.t(`AUTH.LOGIN.${_.maybe(response, "data", "detail")}`) : null
        }));
        yield put(Actions.doSignout());
    } else {
        const sortedResponse = {
            ...response.data,
            companies: response.data.companies.sort((a,b) => {return a.company.localeCompare(b.company)})
        };
        
        yield put(Actions.loadedCompanyInformation(sortedResponse));
    }
}

function * createActiveDashboard({payload}) {
    yield put(Actions.loadingCompanyInformation());
    const response = yield call(setActiveDashboard, payload);
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        // The order of the call/put matters
        yield call(getCompanyInformation)
        yield put(Actions.doneActiveDashboard())
    }
}
