import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import './navbar.scss';
import { urlMap } from "../../dashboardRoutes";
import { nameToInitials } from "../../utils";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { PORTCO_NAME } from "../../utils";

const linkPropsMap = {
    home: { path: urlMap.home, icon: 'home', label: 'NAVBAR.HOME' },
    analyze: { path: urlMap.analyze, icon: 'analyze', label: 'NAVBAR.ANALYZE' },
    improve: { path: urlMap.improve, icon: 'improve', label: 'NAVBAR.IMPROVE' },
    diversity_pe: { path: urlMap.diversity_pe, icon: 'improve', label: 'NAVBAR.PEDNISTANDARD' },
    diversity_vc: { path: urlMap.diversity_vc, icon: 'improve', label: 'NAVBAR.VCDNISTANDARD' },
    connect: { path: urlMap.connect, icon: 'connect', label: 'NAVBAR.CONNECT' },
    lso: { path: urlMap.lso, icon: 'home', label: 'NAVBAR.HOME' },
    voyager: { path: urlMap.voyager, icon: 'communication', label: 'NAVBAR.COMMUNICATION' },
    survey: { path: urlMap.survey, icon: 'survey', label: 'NAVBAR.SURVEY' },
    iln_dashboard_home: { path: urlMap.iln_dashboard_home, icon: 'home', label: 'NAVBAR.HOME' },
    iln_dashboard_impact: { path: urlMap.iln_dashboard_impact, icon: 'iln-impact', label: 'NAVBAR.IMPACT' },
    portco: { path: urlMap.portco, icon: 'portco', label: 'NAVBAR.PORTCO' },
};

class NavBar extends Component {
    constructor(props) {
        super(props);
    }

    _permissionToListItem(permission) {
        const path = window.location.pathname;
        const props = linkPropsMap[permission];
        const selected = props.path === '/' ? props.path === path : (path.indexOf(props.path) > -1);
        const isDiversityVC = props.path === urlMap.diversity_vc;
        const isDiversityPE = props.path === urlMap.diversity_pe;
        const isPortco = props.path === urlMap.portco;
        const ilnArr = ['/iln-dashboard/home', '/iln-dashboard/impact']
        const isILN = ilnArr.includes(props.path);
        const { t } = this.props;

        const getClassName = () => {
            if (isILN) return classNames({ "selected": selected, "iln-dashboard": isILN });
            if (isDiversityVC) return classNames({ "selected": selected, [props.path.slice(1)]: isDiversityVC });
            if (isDiversityPE) return classNames({ "selected": selected, [props.path.slice(1)]: isDiversityPE });
            if (isPortco) return classNames({ "selected": selected, [props.path.slice(1)]: isPortco });
            return classNames({ "selected": selected });
        }

        return (
            <li key={permission} className={getClassName()}>
                <Link to={props.path}>
                    <img className={classNames("icon", `icon-${props.icon}`)} />
                    <span>{t(props.label)}</span>
                </Link>
            </li>
        )
    }

    _adminLink() {
        return (
            <li key={'admin'} className={classNames(window.location.pathname.indexOf('/admin') > -1 && "selected")}>
                <Link to="/admin/upload">Admin</Link>
                <ul>
                    <li>
                        <Link to="/admin/upload"
                            className={classNames(window.location.pathname === '/admin/upload' && "selected")}>
                            Upload CSV
                        </Link>
                    </li>
                </ul>
            </li>
        )
    }

    _onSignout = (props) => {
        props.doClearCompanyInformation()
        return props.doSignout()
    }

    handleCompanyClick = async (e, company) => {
        e.preventDefault()
        await this.props.doActiveDashboard(company.id)
    }

    dashboardList = (dashboards, activeDashboard) => {
        return (
            <TextField
                required
                select
                value={activeDashboard}
                margin="normal"
                className="text-field"
                SelectProps={{ MenuProps: { classes: { paper: 'dashboard-switch-dropdown' } } }}
            >
                {dashboards && dashboards.map((company, index) => {
                    const name = company.company
                    const isActive = (name === activeDashboard)
                    return (
                        <MenuItem key={index} value={name} onClick={(e) => this.handleCompanyClick(e, company)}>
                            <div className="dashboard-value-container">
                                <div className={`${isActive ? 'show-icon' : 'hide-icon'}`} />
                                {/* The elipses css doesn't work in className, only inline. Material UI is something else */}
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="dashboard-name-container">{name}</div>
                            </div>
                        </MenuItem>
                    )
                })}
            </TextField>
        )
    }

    render() {
        // @sometimescasey Hack. Change back to const
        let {
            company_permission: permissions = null,
            companies: companyList = null
        } = this.props.companyInformation;

        if (permissions === undefined) {
            return null;
        }

        // @sometimescasey Hack. Remove
        if (this.props.companyInformation?.companies[0]?.company == PORTCO_NAME) {
            permissions = {
                ...permissions,
                "portco": true
            }
        }

        const dashboards = companyList
        const links = [
            ...Object.keys(linkPropsMap)
                .filter(x => permissions[x])
                .map(this._permissionToListItem.bind(this)),
            this.props.isAdmin ? this._adminLink.call(this) : null,
        ];
        const { t, profile } = this.props;

        const nameInitials = nameToInitials(profile['full_name']);
        const isILN = permissions.iln_dashboard_home
        const hasCommunicationsTab = permissions.voyager

        let activeDashboard = ''
        const activeCompanyName = this.props.companyInformation.name

        for (var i in dashboards) {
            if (dashboards[i]['company'] === activeCompanyName) activeDashboard = dashboards[i]['company']
        }

        let showDashboardList = companyList && companyList.length > 1

        const url = this.props.history.location.pathname
        const ilnUrl = (url === '/iln-dashboard/home') || (url === '/iln-dashboard/impact')
        return (
            <div className="NavBar bootstrap-override" id="navbar">
                <div>
                    <ul className="navbar_list">
                        {links}
                    </ul>
                </div>
                <div className={isILN ? "navbar_profile-container iln-border" : "navbar_profile-container"}>
                    <Dropdown>
                        <Dropdown.Toggle as={Link}>
                            <div className="nav-settings-container">
                                <img className="icon icon-three-dots" />
                                <h6>{t("NAVBAR.SETTINGS")}</h6>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu flip={true}>
                            <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/profile' })} to="/account/profile">{t("NAVBAR.EDIT_PROFILE")}</Dropdown.Item>
                            <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/password' })} to="/account/password">{t("NAVBAR.EDIT_PASSWORD")}</Dropdown.Item>
                            {hasCommunicationsTab &&
                                <Dropdown.Item as={Link} className={classNames({ selected: window.location.pathname === '/account/tokens' })} to="/account/tokens">{t("NAVBAR.TOKENS")}</Dropdown.Item>}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => this._onSignout(this.props)}>{t("NAVBAR.SIGNOUT")}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {nameInitials
                        ? <div className={"profile-initials"}>{nameInitials}</div>
                        : null}
                    {/* Show the dashboard list if there if UserMetadata companies is greater than 1 */}
                    {showDashboardList &&
                        <div className="nav-dashboard-list-container">
                            {ilnUrl ? <h6 className="iln-h6">Switch Dashboard</h6> : <h6>Switch Dashboard</h6>}
                            {this.dashboardList(dashboards, activeDashboard)}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(NavBar);
