import React from 'react';
import { Provider } from 'react-redux';
import Routes from './routes';
import GlobalMessage from "./containers/GlobalMessage";
import DialogBox from "./components/utils/DialogBox";
import mixpanel from 'mixpanel-browser';

export default function App ({ store }) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL);

    return (
        <Provider store = { store }>
            <React.Fragment>
                <Routes />
                <GlobalMessage />
                <DialogBox />
            </React.Fragment>
        </Provider>
    );
}
