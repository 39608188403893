import React, { Component } from 'react';
import './index.scss';

class Trend extends Component {
    render() {
        return (
            <div id="trend">
                <div className="question">{this.props.post.question}</div>
                <div className="post-detail">
                    <span className="detail">{this.props.post.comment} Comments</span>
                    <span className="circle"></span>
                    <span className="detail">{this.props.post.date}</span>
                </div>
            </div>
        );
    };
};

export default Trend;