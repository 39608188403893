import React from 'react';

const Step = ({ id, data }) => {
    return (
        <div className={"step"}>
            <div className={"thunder"}></div>
            <div className={"label-container"}>
                <div className={"label"}>{ data.action }</div>
                <div className={"sublabel"}>{ data.description }</div>
            </div>
        </div>
    )
};

const ToolsForChange = ({ actions, hackinclusion }) => {
    return (
        <div id={"tools-for-change"}>
            <h2>YOUR TOOLS FOR CHANGE</h2>
            <h4>Customized solutions from our global database</h4>
            <div className={"steps-container"}>
                <h4 className={"action-title"}>BEST IN CLASS</h4>
                { actions.map((d,id) => <Step key={id} id={id} data={d}/>) }
                <h4 className={"action-title hackinclusion"}>EQUALITY HACK</h4>
                { hackinclusion.map((d,id) => <Step key={id} id={id} data={d}/>) }
            </div>
        </div>
    );
};

export default ToolsForChange;
