import React, {useEffect} from 'react';
import history from "../../history";
import SurveyInit from "./SurveyInit";
import './index.scss';
import Setup from "./Setup";
import Create from "./Create";
import Review from "./Review";
import Live from "./Live";
import Launch from './Launch';
import Approved from "./Approved";
import Draft from "./Draft";
import RequireUpdates from "./RequireUpdates";
import Loader from "../utils/Loader";

const Survey = (props) => {
    const { pathname } = history.location;

    useEffect(() => {
        history.replace("/survey/init");
        checkSurveyStatus();
    }, []);

    useEffect(() => {
        props.setCurrentSelectedSurveyPage(window.location.pathname.split("/").pop())
    }, [window.location.pathname]);

    useEffect(() => {
        history.push(`/survey/${props.currentSelected}`);
    }, [props.currentSelected]);

    useEffect(() => {
        props.setSelectedSurvey(props.savedSurveys[0]);
        checkSurveyStatus();
    }, [props.savedSurveys]);

    const checkSurveyStatus = () => {
        if (props.savedSurveys && props.savedSurveys.length) {
            const currentSurvey = props.savedSurveys[0];
            switch(currentSurvey.status) {
                case "DRF":
                    history.push("/survey/draft");
                    break;
                case "US":
                    history.push("/survey/review");
                    break;
                case "REV":
                    history.push("/survey/review");
                    break;
                case "LIV":
                    history.push("/survey/live");
                    break;
            }
        }
    };

    const renderComp = (pathname) => {
        switch(pathname) {
            case '/survey/init':
                return <SurveyInit { ...props } />;
            case '/survey/setup':
                return <Setup { ...props } />;
            case '/survey/create':
                return <Create { ...props } />;
            case '/survey/review':
                return <Review { ...props } />;
            case '/survey/approved':
                return <Approved { ...props } />;
            case '/survey/draft':
                return <Draft { ...props } />;
            case '/survey/requires-updates':
                return <RequireUpdates { ...props } />;
            case '/survey/launch':
                return <Launch { ...props } />;
            case '/survey/live':
                return <Live { ...props } />;
        }
    };

    if (!props.company) {
        return null;
    }

    return (
        <div id={"survey"} className={"bootstrap-override"}>
            { renderComp(pathname) }
        </div>
    );
};

export default Survey;
