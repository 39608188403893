import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import MyPost from '../components/Connect/MyPost';
import './App.scss';

class MyPostContainer extends Component {
    render() {
        return (
            <div>
                <MyPost myPosts={this.props.myPosts} 
                        profileInfo={this.props.profileInfo}
                        handleEditPost={this.props.handleEditPost}
                        handleDeletePost={this.props.handleDeletePost}
                        handleLikePost={this.props.handleLikePost}
                        handleCreateComment={this.props.handleCreateComment}
                        handleUpdateBookmark={this.props.handleUpdateBookmark}
                        bookmark={this.props.bookmark}
                        kpis={this.props.kpis} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(MyPostContainer);
