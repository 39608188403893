import { ActionTypes } from '../actions';
import {decode} from "jsonwebtoken";


const accessToken = localStorage.getItem("auth_access_token");
const refreshToken = localStorage.getItem("auth_refresh_token");

let userId = -1;
let decoded = null;
if(accessToken){
    try {
        decoded = decode(accessToken);
        userId = decoded.user
    } catch (e){}
}

let isAuthenticated = decoded && decoded.exp?
    decoded.exp*1000>(new Date().getTime()/1000):
    false;

const defaultState = {
    isAuthenticated,
    isAdmin: false,
    isRegistrationDone: false,
    accessToken: accessToken || null,
    refreshToken: refreshToken || null,
    loggedinUserId: userId,
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    isLoading: true,
    isChangePasswordSuccessful: false,
    isResetPasswordRequestSuccessful: null,
    isResetPasswordSuccessful: null,
    resetPasswordMessage: ""
};


export default (state = defaultState, action) => {
    try {

        switch(action.type) {

            case ActionTypes.DOING_REFRESH_TOKEN:
            case ActionTypes.DOING_REGISTRATION:
            case ActionTypes.DOING_LOGIN:
            case ActionTypes.DOING_SIGNUP:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.HANDLE_FIELD_CHANGE:
                return {
                    ...state,
                    ...action.payload
                };

            case ActionTypes.DONE_REGISTRATION:
                return {
                    ...state,
                    ...defaultState,
                    isRegistrationDone: true,
                    isLoading: false
                };

            case ActionTypes.DONE_LOGIN:
            case ActionTypes.DONE_REFRESH_TOKEN:
                return {
                    ...state,
                    isAuthenticated: true,
                    accessToken: action.payload.access_token,
                    refreshToken: action.payload.refresh_token,
                    loggedinUserId: action.payload.user.user,
                    isAdmin: action.payload.user.is_admin,
                    companyId: action.payload.user.company_id,
                    isLoading: false,
                };

            case ActionTypes.RESET_AUTH:
                return {
                    ...defaultState,
                    isAuthenticated: false,
                    isLoading: false
                };

            case ActionTypes.DONE_SIGNUP:
                return {
                    ...defaultState,
                    isLoading: false,
                    isAuthenticated: false,
                    accessToken: undefined,
                    refreshToken: undefined,
                    loggedinUserId: 0,
                    isAdmin: false,
                    companyId: undefined,
                };
            case ActionTypes.DONE_CHANGE_PASSWORD:
                if (action.payload.data.message.length) {
                    return {
                        ...defaultState,
                        isChangePasswordSuccessful: true
                    }
                };

            case ActionTypes.REQUEST_RESET_PASSWORD_FAIL:
                return {
                    ...defaultState,
                    isResetPasswordRequestSuccessful: false,
                    response: action.payload
                };

            case ActionTypes.DONE_REQUEST_RESET_PASSWORD:
                return {
                    ...defaultState,
                    isResetPasswordRequestSuccessful: true,
                    response: action.payload
                };

            case ActionTypes.RESET_PASSWORD_FAIL:
                return {
                    ...defaultState,
                    isResetPasswordSuccessful: false,
                    response: action.payload
                };

            case ActionTypes.DONE_RESET_PASSWORD:
                return {
                    ...defaultState,
                    isResetPasswordSuccessful: true,
                    response: action.payload
                };

            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState,
                    isAuthenticated: false
                };

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
