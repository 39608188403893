import React from 'react';
import Progress from "./Progress";

const Review = () => {
    return (
        <div className={"survey-review"}>
            <Progress selected={"review"}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src="/images/survey-brb.svg" alt="Survey Review"/>
                    <h5>We’ll Be Back with Your Survey</h5>
                    <p>We’re in the process of creating your survey. A Diversio rep will email you when your survey and ready to go.</p>
                </div>
            </div>
        </div>
    );
};

export default Review;
