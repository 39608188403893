import React from 'react';
import './index.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Card from '../../utils/Card';

const ILNPartners = () => {
    return (
        <Card className="iln-card">
            <div className="iln-partners">
                <h2>Member Institutions</h2>
                <TableContainer >
                    <Table >
                        <TableBody>
                            <TableRow key={1} style={{ height: '84px' }}>
                                <TableCell align="center">
                                    <a href="https://www.aimco.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-aimco" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.allianz.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-allianz" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://apg.nl/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-apg" />
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow key={2} style={{ height: '84px' }}>
                                <TableCell align="center">
                                    <a href="https://www.ardian.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-ardian" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.cdpq.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-cdpq" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.cdpq.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-ssga" />
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow key={3} style={{ height: '84px' }}>
                                <TableCell align="center">
                                    <a href="https://www.cppinvestments.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-cpp" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.generali.it/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-assi" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.natixis.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-natixis" />
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow key={4} style={{ height: '84px' }}>
                                <TableCell align="center">
                                    <a href="https://www.omers.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-omers" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.otpp.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-teachers" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.optrust.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-optrust" />
                                    </a>
                                </TableCell>
                            </TableRow>
                            <TableRow key={5} style={{ height: '84px' }}>
                                <TableCell align="center">
                                    <a href="https://www.pggm.nl/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-pggm" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                    <a href="https://www.investpsp.com/" target="_blank" rel="noopener noreferrer">
                                        <div className="icon-partner-psp" />
                                    </a>
                                </TableCell>
                                <TableCell align="center">
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Card>
    )
}

export default (ILNPartners);
