import React from 'react';

const data = [
    "Set targets and key performance indicators",
    "Measure baseline and identify pain points",
    "Implement data-driven solutions",
    "Track progress and hold yourself accountable",
    "Share learnings and celebrate success"
];

const Step = ({ id, label }) => {
    return (
        <div className={"step"}>
            <div className={"number"}>{ id + 1 }</div>
            <div className={"label"}>{ label }</div>
        </div>
    )
};

const UnlockSteps = () => {
    return (
        <div id={"unlock-steps"}>
            <h2>5 STEPS TO UNLOCK EQUALITY</h2>
            <div className={"steps-container"}>
                { data.map((d,id) => <Step key={id} id={id} label={d}/>) }
            </div>
            <div id={"estimate-note"}>
                <p>*Potential increase in profit relies on rough estimates of profit margin. Measures correlation not causation.</p>
            </div>
        </div>
    );
};

export default UnlockSteps;
