import React, {useContext} from 'react';
import Select from "../../utils/Select";
import {FilterContext} from "./index";
import _ from "underscore";
import {withTranslation} from "react-i18next";

const Department = React.memo(({ label = "Department", valId = "department", dataId = "departments", t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    if (_.isEmpty(data[dataId])) { return null; }
    label = label || t("ANALYZE.FILTERS.DEPARTMENT");
    return <Select options={data[dataId]} label={label} multiple={true} onChange={value => onChange(valId, value)} selectedValues={_.deepClone(values[valId] || data[dataId])}/>;
});

export default withTranslation()(Department);
