import React, { Component } from 'react';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import './index.scss';

class InclusiveInvesting extends Component {
    state = {
        gender: true,
        selectedValue: "Gender"
    }

    onChange = (group, value) => {
        if ("Gender" === group.group) {
            this.setState({
                gender: true,
                selectedValue: "Gender"
            })
        } else {
            this.setState({
                gender: false,
                selectedValue: "Ethnicity"
            })
        }
    }

    render() {
        const filters = ["Gender", "Ethnicity"];
        let selected = ["gender"]
        return (
            <div id="inclusive-investing">
                <h2 className="overview_title">Inclusive Investment</h2>

                <div className="filter">
                    <FormControl component="fieldset" className="form-set">
                        <InputLabel>Target Group</InputLabel>
                        <Select
                            value={this.state.selectedValue}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={() => this.state.selectedValue}
                        >
                            <FormGroup className={"field-wrap"}>
                                {
                                    filters.map((group, index) => (
                                        <FormControlLabel
                                            className="filter-options"
                                            key={`group_filter_label_${index}`}
                                            control={
                                                <Checkbox className="primary check-box"
                                                          checked={group === 'Gender' ? this.state.gender : !this.state.gender}
                                                          onChange={e => this.onChange({ group }, e.target.checked)}
                                                          color="primary"
                                                          value={ group }/>
                                            }
                                            label={ group }
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Select>
                    </FormControl>
                </div>
                <div className="image-container">
                    {
                        this.state.gender
                        ? <img src="images/portfolios/inclusionInvesting/Gender.png" alt="Gender" />
                        : <img src="images/portfolios/inclusionInvesting/Ethnicity.png" alt="Ethnicity" />
                    }
                </div>
            </div>
        );
    };
};

export default InclusiveInvesting;
