import React, { Component } from 'react';
import PiChart from './PiChart';
import Filters from './Filters';
import './index.scss';

class DiversitySnapshot extends Component {
    render () {
        return (
            <div id="diversity-snapshot">
                <h2 className="overview_title">Diversity Breakdown</h2>
                <Filters groups={this.props.groups} filters={this.props.filters} handleApplyFilters={this.props.handleApplyFilters} />
                <div className="pie-chart-container">
                    {
                        this.props.data.map((d, id) => <PiChart colors={this.props.colors} key={id} id={id} data={d.data} label={d.label} showMessage={this.props.showMessage}/>)
                    }
                </div>
            </div>
        );
    };
};

export default DiversitySnapshot;
