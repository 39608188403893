import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ProfileSetting extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isModalOpen: false,
            anchorEl: null,
            form: {
                full_name: "",
                email: "",
                companyName: "",
                role: "",
                profileImage: null
            }
        }
        this.inputFile = React.createRef();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profileInfo !== this.props.profileInfo) {
            const { profileInfo } = nextProps;
            this.setState({
                form: {
                    full_name: profileInfo.full_name,
                    email: profileInfo.email,
                    companyName: profileInfo.company,
                    role: profileInfo.role,
                    profileImage: profileInfo.profile_image
                }
            })
        }
    }

    handleOpen = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        })
    }
    
    handleClose = () => {
        this.setState({
            isOpen: false,
            anchorEl: null
        })
    }

    handleOpenModal = () => {
        this.setState({
            isModalOpen: true
        })
        this.handleClose();
    }

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    _uploadProfileImage = () => {
        let formData = new FormData();
        if (this.inputFile.current.files[0]) {
            formData.append("file", this.inputFile.current.files[0]);
            this.props.handleUploadProfileImage(formData);
        }
    }

    handleSave = () => {
        this.props.handleUpdateProfile({
            'role': this.state.form.role,
            'full_name': this.state.form.full_name
        })
        // this._uploadProfileImage();
        this.handleCloseModal();
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
          }
        });
    }

    render() {
        const { form } = this.state;
        return (
            <div id="profile">
                <div className="profile-container">
                    <button className="setting-button" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleOpen(e)}>
                        {/* <span className={form.profileImage ? "profile-image" : "hidden"}>{form.profileImage ? <img src={form.profileImage} alt ="profile" /> : null}</span> */}
                        <span className={"profile-name"}>{this.props.profileInfo.full_name ? this.props.profileInfo.full_name.toUpperCase().match(/\b(\w)/g) : null}</span>
                        <img src="/images/svg/Connect/Profile/dropdown.svg" alt="dropdown icon" />
                    </button>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleClose()}
                    >
                        <MenuItem onClick={() => this.handleOpenModal()}>Edit profile</MenuItem>
                    </Menu>

                    <Dialog
                        open={this.state.isModalOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-content"
                        fullWidth={true}
                        id="profile-modal"
                    >
                        <DialogTitle className="header">Profile Setting</DialogTitle>
                        <DialogContent>
                            <div className="profile-setting-container">
                                {/* <div className="image-container">
                                    <img src={form.profileImage || "/images/svg/Connect/Profile/add_image.svg"} alt="add profile" />
                                    <input type="file" ref={this.inputFile} className="image-upload" />
                                    <p className="image-description">Images must be .png or .jpg format</p>
                                    <button onClick={() => this._uploadProfileImage()}>Upload</button>
                                </div> */}
                                <div className="full-name-container">
                                    <InputLabel>Full Name</InputLabel>
                                    <TextField
                                        required
                                        name="full_name"
                                        label="Full Name"
                                        value={form.full_name}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </div>
                                {/*<div className="email-container">*/}
                                {/*    <InputLabel>Work Email</InputLabel>*/}
                                {/*    <TextField*/}
                                {/*        required*/}
                                {/*        name="email"*/}
                                {/*        label="Email"*/}
                                {/*        value={form.email}*/}
                                {/*        onChange={(e) => this.handleSelect(e)}*/}
                                {/*        margin="normal"*/}
                                {/*        disabled={true}*/}
                                {/*        variant="outlined"*/}
                                {/*    >*/}
                                {/*    </TextField>*/}
                                {/*</div>*/}
                                {/*<div className="company-name-container">*/}
                                {/*    <InputLabel>Company Name</InputLabel>*/}
                                {/*    <TextField*/}
                                {/*        required*/}
                                {/*        name="company"*/}
                                {/*        label="Company Name"*/}
                                {/*        value={form.companyName}*/}
                                {/*        onChange={(e) => this.handleSelect(e)}*/}
                                {/*        margin="normal"*/}
                                {/*        disabled={true}*/}
                                {/*        variant="outlined"*/}
                                {/*    >*/}
                                {/*    </TextField>*/}
                                {/*</div>*/}
                                <div className="company-role-container">
                                    <InputLabel>Company Role</InputLabel>
                                    <TextField
                                        required
                                        name="role"
                                        label="Company Role"
                                        value={form.role}
                                        onChange={(e) => this.handleSelect(e)}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                    </TextField>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className="action-container">
                            <Button onClick={() => {this.handleSave()}} className="save-button" >
                                Save
                            </Button>
                            <Button onClick={() => {this.handleCloseModal()}}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    };
};

export default ProfileSetting;