import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {
    handleLogin,
    handleRegistration,
    refreshToken,
    changePassword,
    requestResetPassword,
    resetPassword
} from './network';
import mixpanel from "mixpanel-browser"
import {decode} from "jsonwebtoken";
import i18n from '../i18n';
import _ from "underscore";
// All data sagas to add to middleware.
export default [
    [ActionTypes.DO_LOGIN, doLogin],
    [ActionTypes.DO_REGISTRATION, doRegistration],
    [ActionTypes.DO_REFRESH_TOKEN, doRefreshToken],
    [ActionTypes.DO_SIGNOUT, doSignout],
    [ActionTypes.DO_CHANGE_PASSWORD, doChangePassword],
    [ActionTypes.DO_REQUEST_RESET_PASSWORD, doRequestResetPassword],
    [ActionTypes.DO_RESET_PASSWORD, doResetPassword]
];

function * doLogin ({ payload }) {
    yield put(Actions.doingLogin());

    const response = yield call(handleLogin, payload);

    // The explicit response.code check is because the backend will set response.successfull = True,
    // when throttling the request. This will result in the error message not being shown without it.
    if (!response.successful || response.code == 429) {
        yield put(Actions.showMessage({
            type: "error",
            code: response.code,
            delay: response.code === 404 ? 15000 : 5000,
            message: _.maybe(response, "data", "detail") ? i18n.t(`AUTH.LOGIN.${_.maybe(response, "data", "detail")}`) : null
        }));
        yield put(Actions.doSignout());
    } else {
        const { access_token , refresh_token} = response.data;
        const user = decode(access_token)
        const responseObject = {
            "access_token": access_token,
            "refresh_token": refresh_token,
            "user": user
        }
        if (access_token && refresh_token) {
            localStorage.setItem("auth_access_token", access_token);
            localStorage.setItem("auth_refresh_token", refresh_token);
        }
        yield put(Actions.doneLogin(responseObject));
    }
}

function * doRegistration ({ payload }) {
    yield put(Actions.doingRegistration());
    const response = yield call(handleRegistration, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));
        yield put(Actions.resetAuth());
    } else {
        mixpanel.track("Sign Up", {
            $email: payload.email,
            $username: payload.username,
            user_id: response.data.data.user_id
        });
        mixpanel.alias(response.data.data.user_id);

        yield put(Actions.doneRegistration(response.data));
    }
}

function * doRefreshToken ({ payload }) {
    yield put(Actions.doingRefreshToken());
    const requestPayload = {refresh: payload.refreshToken}

    const response = yield call(refreshToken, requestPayload)

    if (!response.successful) {
        yield put(Actions.resetAuth());
    } else {
        const { access_token , refresh_token} = response.data;
        const user = decode(access_token)
        const responseObject = {
            "access_token": access_token,
            "refresh_token": refresh_token,
            "user": user
        }
        if (access_token && refresh_token) {
            window.localStorage.setItem('auth_access_token', access_token);
            window.localStorage.setItem('auth_refresh_token', refresh_token);
        }
        yield put(Actions.doneRefreshToken(responseObject));
    }
}

export function * doSignout () {
    yield put(Actions.doingSignout());
    localStorage.removeItem("auth_access_token");
    localStorage.removeItem("auth_refresh_token");
    yield put(Actions.doneSignout());
    yield put(Actions.resetAuth());
    mixpanel.reset()
}

function * doChangePassword ({ payload }) {
    yield put(Actions.doingChangePassword());

    const response = yield call(changePassword, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));
    } else {
        yield put(Actions.doneChangePassword(response.data));
    }
}

function * doRequestResetPassword ({ payload }) {
    yield put(Actions.doingRequestResetPassword());

    const response = yield call(requestResetPassword, payload);

    if (!response.successful) {
        yield put(Actions.requestResetPasswordFail(response.data));
    } else {
        mixpanel.track("Reset Password", {
            email: payload.email,
            username: payload.username
        })
        yield put(Actions.doneRequestResetPassword(response.data));
    }
}

function * doResetPassword ({ payload }) {
    yield put(Actions.doingResetPassword());

    const response = yield call(resetPassword, payload);

    if (!response.successful) {
        yield put(Actions.resetPasswordFail(response.data));
    } else {
        yield put(Actions.doneResetPassword(response.data));
    }
}
