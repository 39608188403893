import React, {useEffect} from 'react';
import './index.scss';
import Card from '../../utils/Card';
import PiChart from './PiChart';
import { levelMap } from '../../utils';
import _ from "underscore";
import { DashboardHoverTooltip } from '../../utils/DashboardHoverTooltip';
import {withTranslation} from "react-i18next";

const EmployeesSurveyed = ( props ) => {
    const { data, colors, t } = props;
    const { percentages, count } = data;

    if (!percentages) {
        return null;
    }

    const total = percentages.map((o) => (o.value)).reduce((a,b) => a+b, 0);

    const getPercent = (value) => {
        return value + "%";
    }

    const makeRoleName = (roleName) => {
        return (
            <DashboardHoverTooltip content={levelMap(roleName)}>
                {<div className="employees-surveyed__level-text-ellipse">
                    {levelMap(roleName)}
                </div> }
            </DashboardHoverTooltip>
        );
    }

    const emptyRoleMapping = (roles) => {
        if (_.isEmpty(roles) || roles.percentages[0]['name'] === '') return false

        return true
    }

    const showPercentages = emptyRoleMapping(data)

    return (
        <Card>
        <div className="employees-surveyed">
            <h3 className="employees-surveyed__card-title">{t("HOME.CARD_HEADERS.EMPLOYEES_SURVEYED.title")}</h3>
            <div className="employees-surveyed__container">
                <div className={`employees-surveyed__pi-wrapper${showPercentages ? "" : "-no-percentage"}`}>
                    {showPercentages ? 
                        <PiChart colors={colors} data={percentages} total={total} count={count} /> :
                        <div className="employees-surveyed__circle-wrapper">
                        <h2>{count}</h2>
                        </div>
                    }
                </div>
                {showPercentages && <div className="employees-surveyed__legend-wrapper">
                    <div className="employees-surveyed__legend-grid">
                    {
                        percentages
                        .sort((a,b) => {
                            if (a.value >= b.value) {
                                return -1;
                            };
                            return 1;
                        })
                        .map((role, index)=> {
                            if (role.value == 0) { return null; }

                            return (
                                <div className="employees-surveyed__legend-row" key={index}>
                                    <div className="employees-surveyed__dot-wrapper">
                                        <div className="employees-surveyed__dot" style={{backgroundColor: colors[index]}}/>
                                    </div>
                                    <div className="employees-surveyed__level">
                                        {makeRoleName(role.name)}
                                    </div>
                                    <div className="employees-surveyed__percent">
                                        <div>{getPercent(role.value)}</div>
                                    </div>
                                </div>
                            )}
                        )
                    }
                    </div>
                </div>}
            </div>
       </div>
       </Card>
    );
};

export default withTranslation()(EmployeesSurveyed);
