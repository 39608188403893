import React from 'react';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import './index.scss';

class Filters extends React.Component {
    //TODO:: @sahanarula, final a better way to handle `all` in the filters
    state = {
        values: {
            offices: {},
            roles: {},
        },
        selectedValues: [],
        initiateOffice: false,
        initiateRole: false,
    };

    _initializeFilterValues (filters) {
        return filters.reduce((fData, filter) => {
            fData[filter] = true;
            return fData;
        }, {});
    }

    componentWillReceiveProps (nextProps) {
        const { filters: { offices = [], roles = [] } = { } } = nextProps;
        if(nextProps.filters !== this.props.filters )
        {
            this.setState({
                values: {
                    ...this.state.values,
                    offices: {
                        ...this.state.values.offices,
                        ...this._initializeFilterValues(offices)
                    },
                    roles: {
                        ...this.state.values.roles,
                        ...this._initializeFilterValues(roles)
                    }
                }
            });
        }
    }

    _handleChange (type, value) {
        if (type.office) {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        offices: {
                            ...prev.values.offices,
                            [type.office]: this.state.values.offices.hasOwnProperty(type.office) ? value : true,
                            all: type.office !== "all" && !value ? false : prev.values.offices.all
                        }
                    },
                    initiateOffice: true
                }
            })
        } else if (type.role) {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        roles: {
                            ...prev.values.roles,
                            [type.role]: this.state.values.roles.hasOwnProperty(type.role) ? value : true,
                            all: !value ? false : prev.values.roles.all
                        }
                    },
                    initiateRole: true
                }
            })
        } else {
            this.setState(prev => {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        [type]: value
                    }
                }
            })
        }
    }

    _applyFilters () {
        this.props.handleApplyFilters(this.state.values);
    }

    _clearFilters () {
    this.setState(prev => {
        return {
            ...prev,
            values: {
                ...prev.values,
                offices: {
                    ...this._initializeFilterValues(this.props.filters.offices)
                },
                roles: {
                    ...this._initializeFilterValues(this.props.orderedRoles)
                },
            }
        }
    });

    setTimeout(() => {
        this._applyFilters()
    }, 0);
}

    render () {
        return <FiltersComponent
                    applyFilters={this._applyFilters.bind(this)}
                    clearFilters={this._clearFilters.bind(this)}
                    values={this.state.values}
                    onChange={this._handleChange.bind(this)}
                    groups={this.props.groups}
                    filters={this.props.filters}
                    initiateOffice={this.state.initiateOffice}
                    initiateRole={this.state.initiateRole} />
    }
}


const FiltersComponent = ({ values, onChange, groups, filters, applyFilters, initiateOffice, initiateRole, clearFilters }) => {
    const { offices = [], roles = [] } = filters;
    /* DISPLAY SELECTED OPTIONS IN THE DROPDOWN */
    let selectedOffices = [];
    let selectedRoles = [];
    let orderedRoles = [];

    /* DISPLAY BASED ON OPTION SELECTION */
    if (initiateOffice) {
        selectedOffices = [];
        Object.keys(values.offices).forEach((office) => {
            if (office !== 'all' && values.offices[office] === true && initiateOffice) {
                selectedOffices.push(office);
            }
        });
    }

    if (initiateRole) {
        selectedRoles = [];
        Object.keys(values.roles).forEach((role) => {
            if (role !== 'all' && values.roles[role] === true && initiateRole) {
                selectedRoles.push(role);
            }
        });
    }

    /* DISPLAY ALL THE OPTIONS FIRST THING */
    if (Object.keys(values.roles).length > 1 && filters.roles) {
        orderedRoles = [filters.roles[4], filters.roles[1], filters.roles[0], filters.roles[2], filters.roles[3]];
        if (!initiateRole) {
            for (let role = 0; role < orderedRoles.length; role++) {
                selectedRoles.push(orderedRoles[role]);
            }
        }
    }

    if (Object.keys(values.offices).length >= 1 && filters.offices) {
        if (Object.keys(values.offices)[0] !== 'all' && !initiateOffice) {
            for (let office = 0; office < filters.offices.length; office++) {
                selectedOffices.push(filters.offices[office]);
            }
        }
    }

    return (
        <div id="portfolio-diversity-filters">
            <div className="filter-fields">
                <FormControl component="fieldset" className="office-filter">
                    <InputLabel>Office / Region</InputLabel>
                    <Select
                        multiple
                        value={selectedOffices}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                    >
                        <FormGroup className={"field-wrap"}>
                            {
                                offices.map((office, index) => (
                                    <FormControlLabel
                                        className="filter-options"
                                        key={`diversityBreakdown_office_filter_label_${index}`}
                                        control={
                                            <Checkbox className="primary check-box"
                                                      checked={values.offices[office] || values.offices.all}
                                                      onChange={e => onChange({ office }, e.target.checked)}
                                                      color="primary"
                                                      value={ office }/>
                                        }
                                        label={ office }
                                    />
                                ))
                            }
                        </FormGroup>
                    </Select>
                </FormControl>

                <FormControl component="fieldset" className="form-set fieldset role-filters">
                    <InputLabel>Level</InputLabel>
                    <Select
                            multiple
                            value={selectedRoles}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join(', ')}
                    >
                        <FormGroup className={"field-wrap"}>
                            {
                                orderedRoles.map((role, index) =>
                                    <FormControlLabel
                                        className="filter-options"
                                        key={`diversityBreakdown_role_filter_label_${index}`}
                                        control={
                                            <Checkbox
                                                className="primary check-box"
                                                checked={values.roles[role] || values.roles.all}
                                                onChange={e => onChange({role}, e.target.checked)}
                                                color="primary"
                                                value={ role } />
                                        }
                                        label={ role }
                                    />
                                )
                            }
                        </FormGroup>
                    </Select>
                </FormControl>

            </div>

            <div className="filter-actions">
                <button className="apply-filter" onClick={applyFilters}>Apply Filter</button>
                <button className="clear-filter" onClick={clearFilters}>Clear Filter</button>
            </div>
        </div>
    )
};

export default Filters;
