import React, {Component} from 'react';
import {Row, Column} from '../../utils/FlexBox';
import Button from '@material-ui/core/Button';
import Help from "../../utils/Help";
import {demographicToName} from '../../../utils/demographicMapping';
import './index.scss';

class TopSolution extends Component {
    state = {
        active: 0
    }

    handleClick = (id) => {
        this.setState({
            active: id
        })
    }

    render() {
        if (!this.props.topSolutions.length) {
            return null;
        }
        return (
            <div id="LSO-top-solution">
                <Row>
                    <h2 className="overview-title">Top Recommended Solutions</h2>
                    <Help description={'How does Diversio identify these solutions? Our algorithms combine your ' +
                                        'organization’s data with our global solutions catalogue of 1,500+ programs, ' +
                                        'policies and tools. The algorithm prioritizes solutions that have had a material ' +
                                        'impact on organizations with a similar profile to yours.'}
                          className="help" icon="i"
                    />
                </Row>
                <Row>
                    <div className="subtitle">New programs, policies and solutions that are most likely to improve diversity & inclusion at our organization</div>
                </Row>
                <Row>
                    <div className="subtitle">Solutions</div>
                </Row>
                <Row>
                    <div className="solutions-container">
                        {
                            this.props.topSolutions.map((solution, id) => {
                                return (
                                    <Column key={id}>
                                        <div onClick={() => this.handleClick(id)} className="solution-button">
                                            <div
                                                className={this.state.active === id ? "solution-title-active" : "solution-title"}>
                                                <h3>{solution.solution.title}</h3>
                                            </div>
                                        </div>
                                    </Column>
                                )
                            })
                        }

                    </div>
                </Row>
                <div className="solutions-number-container">
                        <div className="solution-number">
                            <span className={this.state.active === 0 ? "solution-left-gray" : "solution-left-purple"}></span>
                            {`${this.state.active + 1}/${this.props.topSolutions.length}`}
                            <span className={this.state.active + 1 === this.props.topSolutions.length ? "solution-right-gray" : "solution-right-purple"}></span>
                        </div>
                    </div>
                <hr/>
                <Row>
                    <div className="solution-detail-container">
                        <div className="info">
                            <h3>{this.props.topSolutions[this.state.active].solution.title}</h3>
                            <p>{this.props.topSolutions[this.state.active].solution.description}</p>
                            {
                                this.props.topSolutions[this.state.active].solution.resources.map((url, id) => {
                                    if (this.props.topSolutions[this.state.active].solution.resources.length > 1) {
                                        return (
                                            <a key={id} href={url} target="_blank"
                                               rel="noopener noreferrer">{`Resource ${id + 1}`}</a>
                                        )
                                    } else {
                                        return (
                                            <a key={id} href={url} target="_blank"
                                               rel="noopener noreferrer">{`Resource`}</a>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className="relevant">
                            <div className="detail-subtitle">Relevant KPI</div>
                            {
                                this.props.topSolutions[this.state.active].solution.kpis.map((kpi, id) => {
                                    return (
                                        <p key={id}>{kpi.name}</p>
                                    )
                                })
                            }
                        </div>

                        <div className="painpoint">
                            <div className="detail-subtitle">Pain Point(s)</div>
                            {
                                this.props.topSolutions[this.state.active].solution.pain_points.map((painpoint, id) => {
                                    return (
                                        <p key={id}>{painpoint.title}</p>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
};

export default TopSolution;
