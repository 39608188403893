import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const categories = [
    "Culture",
    "Feedback",
    "Networks",
    "Flexible Work",
    "Safe Work",
    "Recruiting & Hiring"
];

class CreatePostModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            kpi: 0,
            form: {
                question: '',
                description: ''
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.postInfo !== this.props.postInfo) {
            const {postInfo} = nextProps;
            this.setState({
                form: {
                    question: postInfo.title,
                    description: postInfo.description,
                }
            })
        }
    }

    onChange = (selected) => {
        const kpi = this.props.kpis.find(x => x.id === selected);
        this.setState({
            category: kpi.name,
            kpi: kpi.id
        })
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            }
        });
    }

    handlePost = () => {
        this.props.handlePost({
            "title": this.state.form.question,
            "description": this.state.form.description,
            "kpi": this.state.kpi
        })

        this.handleCancel();
    }

    handleCancel = () => {
        this.setState({
            category: "",
            kpi: 0,
            form: {
                question: "",
                description: "",
            }
        })
        this.props.handleCloseModal();
    }

    render() {
        const {form} = this.state;
        let selectedCategory = this.state.category;
        return (
            <div id="create-post-modal">
                <Dialog
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                    fullWidth={true}
                    id="post-modal"
                >
                    <DialogTitle className="header">Create Post</DialogTitle>
                    <DialogContent>
                        <div className="question-container">
                            <TextField
                                required
                                multiline
                                label="What is your question?"
                                margin="normal"
                                className="textfield"
                                name="question"
                                value={form.question}
                                onChange={(e) => this.handleSelect(e)}
                            />
                            <TextField
                                required
                                multiline
                                label="Give more detail."
                                margin="normal"
                                className="textfield"
                                name="description"
                                value={form.description}
                                onChange={(e) => this.handleSelect(e)}
                            />
                            <FormControl className="category-textfield">
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={selectedCategory || ''}
                                    renderValue={() => selectedCategory || ''}
                                >
                                    <FormGroup>
                                        {
                                            this.props.kpis.length ? this.props.kpis.map(x => {
                                                return (
                                                    <MenuItem
                                                        value={x.id}
                                                        selected={x.id === selectedCategory}
                                                        onClick={e => this.onChange(e.target.value)}
                                                    >
                                                        {x.name}
                                                    </MenuItem>
                                                )
                                            }) : null
                                        }
                                    </FormGroup>
                                </Select>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions className="action-container">
                        <Button
                            disabled={!(this.state.form.question && this.state.form.description && this.state.category)}
                            onClick={() => this.handlePost()}
                            className={!(this.state.form.question && this.state.form.description && this.state.category) ? "disable-save-button" : "save-button"}>
                            Save
                        </Button>
                        <Button onClick={() => {
                            this.handleCancel()
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
};

export default CreatePostModal;