import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation } from 'react-i18next';
import "./index.scss";
import {Link} from "react-router-dom";

const Login = ({ onSubmit, t, message }) => {

    const loginSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required(),
    });

    const {register: login, handleSubmit, errors} = useForm({
        resolver: yupResolver(loginSchema)
    });

    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            <img src="/images/diversio-logo.png" className="logo" alt="Diversio Global"/>
            {
                message && <div className={'auth-form__success-message'}>{message}</div>
            }
            <TextField
                className={"connect-form__text-field"}
                inputRef={login}
                error={!!errors.username}
                helperText={errors.username ? errors.username.message : ''}
                name={'username'}
                label={t("AUTH.LOGIN.USERNAME")}
                margin="normal"
                variant="outlined"
                type={"text"}
            />

            <TextField
                autoComplete={"new-password"}
                className={"connect-form__text-field"}
                inputRef={login}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                name={'password'}
                label={t("AUTH.LOGIN.PASSWORD")}
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.LOGIN.LOGIN")}
            </Button>

            <div className="forgot-password-button text-center">
                {t("AUTH.LOGIN.FORGOT_PASSWORD")} <br/> <Link to={"/forgot-password"}>{t("AUTH.LOGIN.RESET_PASSWORD")}</Link>
            </div>
        </form>

    );
};

export default withTranslation()(Login);
