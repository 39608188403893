import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import './index.scss';
import DeleteIcon from '../../../../images/delete';
import EditIcon from '../../../../images/edit2';
import {demographicToName} from "../../../../utils/demographicMapping";
import { getOfficeRegionLabel } from "../../../../utils/labels";

class SolutionInfo extends Component {
    render() {
        if (this.props.chosenSolutions) {
            return (
                <div className="solution-detail-container">
                    <div className="info">
                        <h3>{this.props.chosenSolutions.solution.title}</h3>
                        <p>{this.props.chosenSolutions.solution.description}</p>
                        {
                            this.props.chosenSolutions.solution.resources.map((url, id) => (
                                <a key={id} href={url} target="_blank" rel="noopener noreferrer">{`Resource ${id + 1}`}</a>
                            ))
                        }
                        <div className="action-container">
                            <Button className="action-button"
                                    onClick={() => this.props.handleDeleteSolution()}>
                                <DeleteIcon/>
                            </Button>
                            { this.props.filterMapLoaded ? <Button className="action-button"
                                    onClick={() => this.props.handleEditSolution(this.props.chosenSolutions)}>
                                <EditIcon></EditIcon>
                            </Button>
                            : 
                            <EditIcon fill="#E0E0E0"/> }
                        </div>
                    </div>
                    <div className="target">
                        <div className="detail-subtitle">Target Group</div>
                        <p>{this.props.chosenSolutions.target_demographic?demographicToName(this.props.chosenSolutions.target_demographic):null}</p>
                    </div>
                    {/* <div className="program-lead">
                        <div className="detail-subtitle">Program Lead</div>
                        <p>{this.props.chosenSolutions.program_lead.username}</p>
                    </div> */}
                    <div className="cost">
                        <div className="detail-subtitle">Annual Cost</div>
                        <p>{this.props.chosenSolutions.monthly_cost ? this.props.chosenSolutions.monthly_cost : ''}</p>
                    </div>
                    <div className="office">
                        <div className="detail-subtitle">{getOfficeRegionLabel(this.props.companyName)}</div>
                        <p>{this.props.chosenSolutions.office ? this.props.chosenSolutions.office : 'All'}</p>
                    </div>
                    <div className="level">
                        <div className="detail-subtitle">Level</div>
                        <p>{this.props.chosenSolutions.level ? this.props.chosenSolutions.level.label : 'All'}</p>
                    </div>
                    <div className="relevant">
                        <div className="detail-subtitle">Relevant KPI</div>
                        {
                            this.props.chosenSolutions.solution.kpis.map((kpi, id) => {
                                return (
                                    <p key={id}>{kpi.name}</p>
                                )
                            })
                        }
                    </div>
                    <div className="painpoint">
                        <div className="detail-subtitle">Pain Point</div>
                        {
                            this.props.chosenSolutions.solution.pain_points.map((painpoint, id) => {
                                return (
                                    <p key={id}>{painpoint.title}</p>
                                )
                            })
                        }
                    </div>
                    <div className="department">
                        <div className="detail-subtitle">Department</div>
                        <p>{this.props.chosenSolutions.department ? this.props.chosenSolutions.department : 'All'}</p>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    };
};

export default SolutionInfo;
