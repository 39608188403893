import React from 'react';
import './Answer.scss';
import AnswerItem from './AnswerItem';


const Answers = props => 
    	<ul>
		{props.answer.map((answer, i) => (
			<AnswerItem key={i} answer={answer}/>
		))}
		</ul>


export default Answers;