import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation } from 'react-i18next';
import "./index.scss";

const Register = ({ onSubmit, filledEmail, validUrl, t }) => {

    let schemaObj = {
        username: yup.string().required(),
        password: yup.string()
            .required()
            .min("8", t("AUTH.REGISTER.LENGTH_ERR"))
            .max("128", t("AUTH.REGISTER.LENGTH_ERR"))
            .matches(/^(?=.*[!@#$%^&*])/, t("AUTH.REGISTER.SPECIAL_CHAR_ERR"))
            .matches(/^(?=.*[a-z])/, t("AUTH.REGISTER.LOWER_CASE_ERR"))
            .matches(/^(?=.*[A-Z])/, t("AUTH.REGISTER.UPPER_CASE_ERR"))
            .matches(/^(?=.*[0-9])/, t("AUTH.REGISTER.NUMERIC_ERR")),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    }

    if (!filledEmail) {
        schemaObj["email"] = yup.string().required().email();
    }

    const registerSchema = yup.object().shape(schemaObj);

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(registerSchema)
    });

    if (!validUrl) {
        return (
            <div className="auth-form">
                <img src="/images/diversio-logo.png" className="logo" alt="Diversio Global"/>
                <span className='auth-form__request-link-text' >{t("AUTH.REGISTER.BAD_URL")}</span>
            </div>
        );
    }

    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            <img src="/images/diversio-logo.png" className="logo" alt="Diversio Global"/>

            <TextField
                className={"register-form__text-field register-form__email-field"}
                inputRef={filledEmail ? null : register}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                name={'email'}
                id="outlined-name"
                label={filledEmail ? filledEmail : t("AUTH.LOGIN.EMAIL")}
                margin="normal"
                variant="outlined"
                type={"email"}
                disabled={ filledEmail ? true : false }
            />
            { filledEmail &&
                <div className="register-form__email-note">{
                    t("AUTH.REGISTER.EMAIL_NOTE")
                }</div>
            }

            <TextField
                className={"register-form__text-field"}
                inputRef={register}
                error={!!errors.username}
                helperText={errors.username ? errors.username.message : ''}
                name={'username'}
                id="outlined-name"
                label={t("AUTH.LOGIN.USERNAME")}
                margin="normal"
                variant="outlined"
                type={"text"}
            />

            <TextField
                autoComplete={"new-password"}
                className={"register-form__text-field"}
                inputRef={register}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                id="outlined-name"
                name={'password'}
                label={t("AUTH.LOGIN.PASSWORD")}
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <TextField
                className={"register-form__text-field"}
                inputRef={register}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                id="outlined-name"
                name={'confirmPassword'}
                label={t("AUTH.LOGIN.CONFIRM_PASSWORD")}
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.REGISTER.REGISTER")}
            </Button>

            <div className="forgot-password-button text-center">
                {t("AUTH.REGISTER.EXISTING_ACCOUNT")} <br/> <Link to={"/login"}>{t("AUTH.REGISTER.LOGIN_HERE")}</Link>
            </div>
        </form>
    );
};

export default withTranslation()(Register);
