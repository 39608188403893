import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation, Trans } from 'react-i18next';
import "./index.scss";

const ForgotPassword = ({ onSubmit, isResetPasswordRequestSuccessful, response, t }) => {

    const forgotPasswordSchema = yup.object().shape({
        email: yup.string().required().email(),
    });

    const {register: login, handleSubmit, errors} = useForm({
        resolver: yupResolver(forgotPasswordSchema)
    });

    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            <img src="/images/diversio-logo.png" className="logo" alt="Diversio Global"/>
            <div className={'auth-form__forgot-help-text'}>
            {
                <Trans i18nKey={'AUTH.FORGOT_PASSWORD.HELP_TEXT'} components={{strong: <strong />}}></Trans>
            }
            </div>
            <TextField
                className={"connect-form__text-field"}
                inputRef={login}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                name={'email'}
                label={t("AUTH.LOGIN.EMAIL")}
                margin="normal"
                variant="outlined"
                type={"text"}
            />


            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.FORGOT_PASSWORD.RESET_PASSWORD")}
            </Button>

            <div className="forgot-password-button text-center">
                {
                    isResetPasswordRequestSuccessful === true
                        && <div className={"success-message" }>{ t(`AUTH.FORGOT_PASSWORD.${response.data.message}`) }</div>
                }
                {
                    isResetPasswordRequestSuccessful === false
                        && <div className={"error-message"}>{ t(`AUTH.FORGOT_PASSWORD.${response.error.message}`) }</div>
                }
            </div>

        </form>
    );
};

export default withTranslation()(ForgotPassword);
