import React from 'react';
import Dashboard from '../components/Connect/Topics/Topics';
import Questions from '../components/Connect/Topics/Discover';
import Answers from '../components/Connect/Topics/Answer';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
  
class Topics extends React.Component {

state = { value: 0, discover: [], answer: [], featured: []};
    componentDidMount() {
        fetchDiscover(discover =>
          this.setState({ discover: discover }));
        fetchAnswer(answer =>
          this.setState({ answer: answer }));
        fetchFeatured(featured =>
            this.setState({ featured: featured }));
      }

handleChange = (event, value) => {
    this.setState({ value });
};

render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
    <div>
        <div className="Header"> Topics </div>

        <div>
            <TopBar />
        </div>

        <div>
            <Dashboard featured={this.state.featured}/>
        </div>

        <div className={classes.root}>
        <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
            <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Discover"
            />
            <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Answer"
            />
        </Tabs>
            {value === 0 && <TabContainer>
                <div>
                    <Questions discover={this.state.discover} />
                </div>
                </TabContainer>}
                {value === 1 && <TabContainer>
                <div>
                    <Answers answer={this.state.answer} />
                </div>
                </TabContainer>}
        </div>

    </div>
    );
}
}

const fetchDiscover = cb =>
  cb([
    { KPI: "CULTURE", answers: "3 answers", title: "What are you doing for International Women's Day?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu " },
    { KPI: "NETWORKS", answers: "6 answers", title: "Is there a backlash to mentorship from #MeToo?", content: "Nulla pharetra imperdiet dui, pretium rhoncus lectus facilisis tincidunt." },
    { KPI: "BIAS", answers: "2 answers", title: "How do you ensure mid-level managers don’t give biased feedback (tactically)?", content: "Aliquam tortor neque, iaculis nec lacinia quis, egestas eu purus. Rortor nquis, egestas eu purus." },
    { KPI: "RECRUITING", answers: "6 answers", title: "Do matching programs really work?", content: "Fusce at dui cursus, tincidunt dolor vel, fermentum diam. " },
]);

const fetchAnswer = cb =>
  cb([
    { KPI: "CULTURE", answers: "3 answers", title: "What are you doing for International Women's Day?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu " },
    { KPI: "NETWORKS", answers: "6 answers", title: "Is there a backlash to mentorship from #MeToo?", content: "Nulla pharetra imperdiet dui, pretium rhoncus lectus facilisis tincidunt." },
    { KPI: "BIAS", answers: "2 answers", title: "How do you ensure mid-level managers don’t give biased feedback (tactically)?", content: "Aliquam tortor neque, iaculis nec lacinia quis, egestas eu purus. Rortor nquis, egestas eu purus." },
    { KPI: "RECRUITING", answers: "6 answers", title: "Do matching programs really work?", content: "Fusce at dui cursus, tincidunt dolor vel, fermentum diam. " },
]);

const fetchFeatured = cb =>
  cb([
    { KPI: "RECRUITING AND HIRING", answers: "3 answers", title: "What is the best blind recruiting tool?", content: "Bias in recruiting and hiring is challenge. There are several new tools on the market to fight this" },
]);


//tab function
function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

//styles of tabs
const styles = theme => ({
    root: {
      flexGrow: 1,
      border: '1px solid #DBD9DB',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderTop: 'none',
      marginLeft: '40px',
      marginRight: '40px',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      minWidth: '698px',
    },
    tabsRoot: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
      backgroundColor: '#1890ff',
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing.unit * 4,
      fontFamily: [
        "Lato",
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$tabSelected': {
        color: '#5E77FF;',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    tabSelected: {},
    typography: {
      padding: theme.spacing.unit * 3,
    },
});
  
Topics.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Topics);