import React from 'react';
import BKPICard from './BKPICard';
import { Row, Column } from '../../utils/FlexBox';

const BusinessKeyPerformanceIndicator = ( BKPIdata ) => {
    return (
        <div>
            <Row>
                <Column width="33.33%">
                    <BKPICard BKPIdata={BKPIdata.BKPIdata[0]} />
                </Column>
                <Column width="33.33%">
                    <BKPICard BKPIdata={BKPIdata.BKPIdata[1]} />
                </Column>
                <Column width="33.33%">
                    <BKPICard BKPIdata={BKPIdata.BKPIdata[2]} />
                </Column>
            </Row>
        </div>
    )
};

export default BusinessKeyPerformanceIndicator;
