import React, {Component} from 'react';
import {Row, Column} from '../../utils/FlexBox';
import Card from '../../utils/Card';
import NewTargetButton from "./NewTargetButton";
import Targets from './Targets';
import './index.scss';


class DiversityCompositionTarget extends Component {
    render() {
        const targets = this.props.data ? this.props.data : [];
        
        return (
            <Card>
            <div id="target">
                <Row>
                    <Column width="50%">
                        <div className="overview-title">Diversity Composition Targets</div>
                    </Column>
                </Row>
                <Row>
                    <div className="description">
                        Set targets for improved representation of minority groups
                    </div>
                </Row>
                <div className="target-container">
                    <Row>
                        {
                            targets.slice(0,4).filter(t => t.id!==undefined).map((data, id) => {
                                return (
                                    <Column width="33.33%" key={id}>
                                        <Targets buttonNumber={id + 1}
                                                 filters={this.props.filters}
                                                 handleSave={this.props.handleSave}
                                                 handleDelete={this.props.handleDelete}
                                                 handleUpdate={this.props.handleUpdate}
                                                 data={data}
                                                 filterMap={this.props.filterMap}
                                                 showMessage={this.props.showMessage}
                                                 demographicTarget={this.props.demographicTarget}
                                                 companyName={this.props.companyName} />
                                    </Column>
                                )
                            })
                        }
                        {
                            Array.from(Array(Math.max(3-targets.length,0)).keys()).map(i=>
                                <Column width="33.33%" key={i}>
                                    <NewTargetButton buttonNumber={i + 1}
                                                     filters={this.props.filters}
                                                     handleSave={this.props.handleSave}
                                                     handleDelete={this.props.handleDelete}
                                                     handleUpdate={this.props.handleUpdate}
                                                     filterMap={this.props.filterMap}
                                                     showMessage={this.props.showMessage}
                                                     demographicTarget={this.props.demographicTarget} />
                                </Column>
                            )
                        }
                    </Row>
                </div>
            </div>
            </Card>
        );
    }
};

export default DiversityCompositionTarget;
