import React from 'react';
import './index.scss';
import downArrow from './downArrow.svg';
import upArrow from './upArrow.svg';
import neutral from './neutral.svg';

const BKPICard = ( BKPIdata ) => {
    {/*
        arrow = true => Up Arrow svg
        arrow = false => Down Arrow svg
    */}
    let arrow = true;
    if (BKPIdata.BKPIdata.description !== "Offer Acceptance Rate") {
        arrow = false;
    }

    return (
        <div id="business-key-performance-indicator">
            <div className="BKPI-container">
                {
                    BKPIdata.BKPIdata.rates
                    ? <div className="value">{Object.values(BKPIdata.BKPIdata.rates)[2].rate}%</div>
                    : <div className="value">${BKPIdata.BKPIdata.cost}</div>
                }
                <div className="description">
                    {BKPIdata.BKPIdata.description}
                </div>
                {
                    arrow
                    ? <div className="percentage-difference">
                        <img src={ upArrow } alt="up arrow" />
                        <div className="up">{Object.values(BKPIdata.BKPIdata.rates)[2].difference}%</div>
                    </div>
                    : <div className="percentage-difference">
                        {
                            BKPIdata.BKPIdata.description === 'Spent on discrimination litigation'
                            ? <img src={ neutral } alt="neutral " />
                            : <img src={ downArrow } alt="down arrow" />
                        }
                        {
                            BKPIdata.BKPIdata.rates
                            ? <div className="down">{Object.values(BKPIdata.BKPIdata.rates)[2].difference}%</div>
                            : <div></div>
                        }
                    </div>
                }
            </div>
        </div>
    )
};

export default BKPICard;
