import {ActionTypes} from '../actions';
import {likePost} from '../sagas/network';

const defaultState = {
    // profile: {},
    isUpdated: false,
    posts: [],
    comments: [],
    bookmark: [],
    filteredPosts: [],
    isFiltered: false,
    kpis: []
}

export default (state = defaultState, action) => {
    try {
        switch (action.type) {
            case ActionTypes.LOADED_ALL_POSTS:
                return {
                    ...state,
                    posts: action.payload
                };
            case ActionTypes.DONE_CREATE_POST:
                return {
                    ...state,
                    posts: [...state.posts, action.payload]
                };
            case ActionTypes.DONE_EDIT_POST:
                let newPost;
                const posts = [...state.posts].map(post => {
                    if (post.id === action.payload.id) {
                        newPost = Object.assign({}, post, action.payload);
                        return newPost;
                    }
                    return post
                });
                const bookmark = [...state.bookmark].map(bmk => {
                    if (bmk.post.id === action.payload.id) {
                        return Object.assign({}, bmk, {post: newPost})
                    }
                    return bmk
                });
                return {...state, posts, bookmark};
            case ActionTypes.DONE_DELETE_POST:
                let updatedPost = [];
                state.posts.filter(post => {
                    if (post.id.toString() !== action.payload.id) {
                        updatedPost.push(post);
                    }
                });
                return {
                    ...state,
                    posts: updatedPost
                };
            case ActionTypes.DONE_LIKE_POST:
                let likePostUpdate = [];
                let updatePost = {};

                if (action.payload.postType === 'bookmark') {
                    state.bookmark.filter(post => {
                        if (post.post.id.toString() === action.payload.post_id) {
                            updatePost = post;
                            updatePost.post.likes_count = action.payload.like_count.is_liked;
                            updatePost.post.liked_by_user = !post.post.liked_by_user;
                            likePostUpdate.push(updatePost);
                        } else {
                            likePostUpdate.push(post);
                        }
                    });
                    return {
                        ...state,
                        bookmark: likePostUpdate
                    };
                } else {
                    state.posts.filter(post => {
                        if (post.id.toString() === action.payload.post_id) {
                            updatePost = post;
                            updatePost.likes_count = action.payload.like_count.is_liked;
                            updatePost.liked_by_user = !post.liked_by_user;
                            likePostUpdate.push(updatePost);
                        } else {
                            likePostUpdate.push(post);
                        }
                    });
                    return {
                        ...state,
                        posts: likePostUpdate
                    }
                }
            case ActionTypes.LOADED_COMMENTS:
                return {
                    ...state,
                    comments: action.payload
                };
            case ActionTypes.DONE_CREATE_COMMENT:
                return {
                    ...state,
                    comments: [...state.comments, action.payload]
                };
            case ActionTypes.DONE_EDIT_COMMENT:
                let editedComment = [];
                state.comments.filter(comment => {
                    if (comment.id === action.payload.id) {
                        editedComment.push(action.payload);
                    } else {
                        editedComment.push(comment);
                    }
                });
                return {
                    ...state,
                    comments: editedComment
                };
            case ActionTypes.DONE_DELETE_COMMENT:
                let updateDeleteComment = [];
                state.comments.filter(comment => {
                    if (comment.id.toString() !== action.payload.id) {
                        updateDeleteComment.push(comment);
                    }
                });
                return {
                    ...state,
                    comments: updateDeleteComment
                };
            case ActionTypes.DONE_LIKE_COMMENT:
                let likeCommentUpdate = [];
                let updateComment = {};
                state.comments.filter(comment => {
                    if (comment.id.toString() === action.payload.comment_id) {
                        updateComment = comment;
                        updateComment.likes_count = action.payload.like_count.is_liked;
                        updateComment.liked_by_user = !comment.liked_by_user;
                        likeCommentUpdate.push(updateComment);
                    } else {
                        likeCommentUpdate.push(comment);
                    }
                });
                return {
                    ...state,
                    comments: likeCommentUpdate
                };
            case ActionTypes.LOADED_BOOKMARK:
                return {
                    ...state,
                    bookmark: action.payload
                };
            case ActionTypes.DONE_UPDATE_BOOKMARK:
                let bookmarkedPosts = [];

                state.bookmark.filter(post => {
                    if (post.is_bookmarked && post.post.id !== action.payload.post.id) {
                        bookmarkedPosts.push(post)
                    }
                });

                if (action.payload.is_bookmarked) {
                    bookmarkedPosts.push(action.payload)
                }

                return {
                    ...state,
                    bookmark: bookmarkedPosts
                };
            case ActionTypes.LOADED_FILTERED_POSTS:
                return {
                    ...state,
                    filteredPosts: action.payload.filteredPosts,
                    isFiltered: action.payload.isFiltered
                };
            case ActionTypes.LOADED_ALL_KPIS:
                return {
                    ...state,
                    kpis: action.payload
                };

            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                };

            default:
                return state;
        }
    } catch (error) {
        console.error(`Error in appState reducer: ${error.message || error.code || error}`, error);
    };
};
