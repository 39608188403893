const COLORS = {
    "inclusive_culture_average": "Inclusive culture",
    "review_feedback_average": "Unbiased feedback",
    "access_to_network_average": "Access to networks",
    "flexibility_weight_average": "Flexible work",
    "safety_average": "Safe work",
    "recruiting_and_hiring_average": "Recruiting & hiring",
};

const defaultState = COLORS;

export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in kpimap reducer: ${ error.message || error.code || error }`, error);
    }

};