import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import LSOHeader from '../components/LSO/Header';
import LSOInclusionScore from '../components/LSO/InclusionScore';
import DiversityComposition from "../components/Analyze/DiversityComposition";
import HeatMap from "../components/Home/HeatMap";
import TopSolution from '../components/LSO/TopSolution';
import LSOTraining from '../components/LSO/Training';
import LSOUpdateData from '../components/LSO/UpdateData';
import {Row, Column} from '../components/utils/FlexBox';
import * as Sentry from "@sentry/browser";
import {getDefaultHeaders, serverURL} from "../utils/constants";
import store from "../store";


class LSO extends Component {
    componentWillMount() {
        this.props.getInclusionMetricsData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getDiversityCompositionData();
        this.props.getCompanyInformation();
        this.props.getAllSolutions();
        this.props.getOnePager();
    }

    _sendSlackPing(context, detail) {
        const url = `${serverURL}/lso/contact/`;
        const body = JSON.stringify({context, detail});
        const method = "POST";
        const headers = getDefaultHeaders();
        return new Promise((res, rej) => {
            fetch(url, {method, headers, body})
                .then(response => {
                    if (response.status !== 200) {
                        const reason = `Could not send slack ping for ${this.props.home.companyInformation.name}, failed with ${response.status}. Message: ${body}`;
                        throw Error(reason);
                    } else{
                        res();
                    }
                })
                .catch(error => {
                    this.props.showMessage({
                        type: 'error',
                        message: 'Your request could not be sent, please contact a Diversio representative if you continue to face issues.'
                    });
                    rej(error.message);
                    Sentry.captureException(error, {
                        tags: {
                            profile: store?.getState()?.profile
                        }
                    });
                });
        });
    }

    render() {
        let recommendedSolution = [];
        if (this.props.improve.solutions.allSolutions.length) {
            recommendedSolution = this.props.improve.solutions.allSolutions.filter(x => x.is_recommendation);
        }
        return (
            <div className="App">
                <Row>
                    <Column width="100%">
                        <LSOHeader
                            sendSlackPing={this._sendSlackPing.bind(this)}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <LSOInclusionScore data={this.props.onePager.data}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <DiversityComposition data={this.props.home.diversityComposition}
                                              industryData={this.props.home.industryDiversityComposition}
                                              companyName={this.props.home.companyInformation.name}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <HeatMap data={this.props.analyze.inclusionMetrics}
                                 prevData={this.props.analyze.previousInclusionMetrics}
                                 metadata={this.props.analyze.metadata}
                                 companyName={this.props.home.companyInformation.name}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        <TopSolution topSolutions={recommendedSolution}
                                     showMessage={this.props.showMessage}/>
                    </Column>
                </Row>
                <Row>
                    <Column width="50%">
                        <LSOTraining sendSlackPing={this._sendSlackPing.bind(this)}/>
                    </Column>
                    <Column width="50%">
                        <LSOUpdateData sendSlackPing={this._sendSlackPing.bind(this)}/>
                    </Column>
                </Row>
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        home: state.home,
        auth: state.auth,
        analyze: state.analyze,
        improve: state.improve,
        onePager: state.onePager,
        colors: state.colors
    };
};

export default connect(mapStateToProps, Actions)(LSO);
