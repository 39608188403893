import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import AllExperts from '../components/Connect/Experts/Experts';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';

class Experts extends Component {
    state = { experts: [] };
    componentDidMount() {
        fetchExperts(experts =>
          this.setState({ experts: experts }));
      }
    render() {

        return (
            <div>
                <div className="Header"> Experts </div>
                <div>
                    <TopBar />
                </div>

                <div>
                    <AllExperts experts={this.state.experts}/>
                </div>

            </div>
        );
    }
}

const fetchExperts = cb =>
  cb([
    { id: 0, name: "Nancy Cushing-Jones", position: "Professional", topics: ["Culture", "Networks", "Bias", "Pay Equity"], location: "Los Angeles, San Francisco, New York, elsewhere as needed", company: ["Broadthink"], description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempus sollicitudin mollis. Orew, eget rhoncus augue interdum ac. Curabitur egestas" },
    { id: 1, name: "Kelly Nolan", position: "Professional", topics: ["Culture", "Networks", "Flexible Work", "Bias", "Recruiting", "Pay Equity"], location: "Across North America and UK/Europe", company: ["TalentStrategy.org"], description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempus sollicitudin mollis. Orew, eget rhoncus augue interdum ac. Curabitur egestas" },
    { id: 2, name: "Nora Jenkins Townson", position: "Professional", topics: ["Culture", "Networks", "Bias", "Harassment", "Flexible Work", "Recruiting", "Pay Equity"], location: "Toronto, CAD", company: ["Bright + Early"], description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempus sollicitudin mollis. Orew, eget rhoncus augue interdum ac. Curabitur egestas" },
    { id: 3, name: "Rebecca Hill", position: "Professional", topics: ["Culture", "Bias", "Networks", "Flexible Work", "Recruiting"], location: "London, UK", company: ["HaslerHill Consulting"], description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempus sollicitudin mollis. Orew, eget rhoncus augue interdum ac. Curabitur egestas" },
    { id: 4, name: "Dunola Oladapo", position: "Ambassador", topics: ["Culture", "Bias", "Flexible Work", "Recruiting", "Pay Equity"], location: "Beijing, Shanghai, Hong Kong, Tokyo", company: ["Luton Lights"], description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tempus sollicitudin mollis. Orew, eget rhoncus augue interdum ac." }
]);

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(Experts);
