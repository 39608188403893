import { LINE_CHART_COLORS } from '../../utils';

export const COLORS = LINE_CHART_COLORS;
export const MTNM_COLOR = "#b7b3c2"; // "#322352", transparency 0.38
export const CURSOR_GREY = "#EBECF0";
export const CURSOR_LINE = "#322352";
export const CURSOR_OPACITY = 0.54;
export const CURSOR_LINE_OPACITY = 0.38;
export const CURSOR_HALF_WIDTH = 80;

export const CONTAINER_HEIGHT = 300;

export const CHART_STROKE = "#DBD9DB";

export const X_AXIS_HEIGHT = 70;
export const CHART_OFFSET = X_AXIS_HEIGHT + 10;

export const Y_OFFSET = 5;

export const IDATA_SUFFIX = "__i";

