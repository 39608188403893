import React, { Component } from 'react';
import Overview from "../../containers/DiversityVC/Overview";
import Resources from "../../containers/DiversityVC/Resources";
import NextSteps from "../../containers/DiversityVC/NextSteps.jsx";
import './index.scss'
import { Route, Link, Redirect } from "react-router-dom";
import About from "./About";

const pages = {
    overview: 'overview',
    about: 'about',
    nextSteps: "nextsteps",
    resources: 'resources'
};


class DiversityVC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: pages.Overview,
        }
    }

    render() {
        const path = this.props.match.path;
        const isAbout = this.props.location.pathname.endsWith(pages.about);
        const isOverview = this.props.location.pathname.endsWith(pages.overview);
        const isNextSteps = this.props.location.pathname.endsWith(pages.nextSteps);
        const isResources = this.props.location.pathname.endsWith(pages.resources);

        return (
            <div className={'diversity-vc'}>
                <div className='diversity-vc__navigation'>
                    <h1>Diversity VC Standard</h1>
                    <div className="diversity-vc__navigation-options">
                        <Link to={`${path}/${pages.about}`}><div className={isAbout ? "diversity-vc__navigation-options--selected" : ""}>Overview</div></Link>
                        <Link to={`${path}/${pages.overview}`}><div className={isOverview ? "diversity-vc__navigation-options--selected" : ""}>Your Results</div></Link>
                        <Link to={`${path}/${pages.nextSteps}`}><div className={isNextSteps ? "diversity-vc__navigation-options--selected" : ""}>Next Steps</div></Link>
                        <Link to={`${path}/${pages.resources}`}><div className={isResources ? "diversity-vc__navigation-options--selected" : ""}>Resources</div></Link>
                    </div>
                </div>
                <Route exact path={`${path}/${pages.overview}`} component={Overview} />
                <Route exact path={`${path}/${pages.about}`} component={About} />
                <Route exact path={`${path}/${pages.nextSteps}`} component={NextSteps} />
                <Route exact path={`${path}/${pages.resources}`} component={Resources} />
                <Route exact path={path} render={(props) => (
                    <Redirect
                        to={{
                            pathname: `${path}/${pages.about}`
                        }} />
                )} />
            </div>
        );
    }
}

export default DiversityVC;
