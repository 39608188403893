import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Input from '@material-ui/core/Input';
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Select from '@material-ui/core/Select';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const topics = [
    'Your Workplace Score', 'Diversity Composition', 'Inclusion Scorecard', 'Top Recommended Solutions'
];

class TrainingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTopics: [],
            form: {
                request:'',
                topics:[]
            }
        }
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            }
        });
    };

    onChange = (topic) => {
        let topics = [...this.state.form.topics];
        const index = topics.indexOf(topic);
        if (index !== -1) {
            topics.splice(index, 1);
        } else {
            topics.push(topic);
        }
        this.setState({
            form: {...this.state.form, topics}
        });
    };

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="modal-title"
                aria-describedby="modal-content"
                fullWidth={true}
                id="update-data-modal"
            >
                <DialogTitle className="header">How Can We Help You?</DialogTitle>
                <DialogContent className="form-container">
                    <FormControl component="fieldset" className="topic-container">
                        <InputLabel className="text-title">Topic</InputLabel>
                        <Select
                            multiple
                            value={this.state.form.topics}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join(', ')}
                        >
                            <FormGroup className={"field-wrap"}>
                                {
                                    topics.map((topic, index) => (
                                        <FormControlLabel
                                            className="filter-options"
                                            key={index}
                                            control={
                                                <Checkbox className="primary check-box"
                                                        checked={this.state.form.topics.includes(topic)}
                                                        onChange={() => this.onChange(topic)}
                                                        color="primary"
                                                        value={ topic }/>
                                            }
                                            label={ topic }
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Select>
                    </FormControl>
                    <div className="comment-container">
                        <InputLabel className="comment-title">Please provide an explanation of your request</InputLabel>
                        <TextField
                            margin="normal"
                            multiline
                            className="textfield"
                            name="request"
                            variant="outlined"
                            rows="4"
                            value={this.state.form.request}
                            onChange={(e) => this.handleSelect(e)}
                        />
                    </div>
                    
                </DialogContent>
                <DialogActions className="action-container">
                    <Button onClick={() => {this.props.handleSubmit(this.state.form)}} className="save-button">
                        Submit
                    </Button>
                    <Button onClick={() => {this.props.handleCancel()}} className="cancel-button">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

export default TrainingModal;