import React from 'react';
import ContactCard from "../../utils/ContactCard";
import Goals from "../../../containers/DiversityPE/Goals";
import NextStepsCard from "./NextStepsCard"
import TopSolution from "../../Improve/TopSolution";

const NextSteps = (props) => {

    React.useEffect(() => {
        props.getCompanyInformation();
        props.getAllSolutions();
    }, []);

    const recommendations = props.improve.solutions.allSolutions.filter(x => x.is_recommendation);
    const domain = '@diversio.com';

    return (
        <div id={'overview'}>
            {
                Object.keys(props.home.companyInformation).length && recommendations.length
                    ? <TopSolution topSolutions={recommendations}
                        filters={props.home.companyInformation.recommendation_filters}
                        doUpdateCompanySolutions={props.doUpdateCompanySolutions}
                        showMessage={props.showMessage}
                        isDiversityPE
                    />
                    : null
            }
            <NextStepsCard
                company_solutions={props.improve.solutions.allSolutions.filter(x => x.is_next_steps)}
                doUpdateCompanySolutions={props.doUpdateCompanySolutions}
            />
            <Goals />
            <div className="contact-container">
                <ContactCard
                    title="Deploy a Survey"
                    imgSrc="/images/DiversityVC/DeploySurvey.png"
                    detail="Analyze the diversity & inclusion in your workforce, identify and unlock barriers to advancements for women and minorities."
                    link={`info${domain}`}
                    linkText="Get Started"
                />
                <ContactCard
                    title="Start Your Portfolio Assessment"
                    imgSrc="/images/LSO/Update/update.png"
                    detail="Assess the diversity & inclusion of your portfolio. Understand which of your companies are vulnerable to risks such as toxic culture and internal discrimination."
                    link={`info${domain}`}
                    linkText="Assess Your Portfolio"
                />
            </div>
        </div>
    )
}

export default NextSteps;
