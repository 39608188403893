import { ActionTypes } from '../../actions';
import _ from 'underscore';

const defaultState = {
    options: {},
    isCommitted: true,
    isLoading: false,
    labels: [],
    targets: []
};

export default (state = defaultState, action) => {
    try {
        let data,
            targets,
            options,
            office,
            level,
            target_demographic;
        switch(action.type) {

            // case ActionTypes.UPDATE_TARGETS_DATA:
            //     return {
            //         ...state,
            //         options: [
            //             ...state.options,
            //             action.payload.label
            //         ],
            //         progress: {
            //             ...state.progress
            //         },
            //         targets: {
            //             ...state.targets
            //         }
            //     };

            // case ActionTypes.ADD_TARGET:
            //     return {
            //         ...state,
            //         options: [
            //             ...state.options,
            //             action.payload
            //         ],
            //         targets: {
            //             ...state.targets,
            //             [action.payload]: {
            //                 current: null,
            //                 previous: null,
            //                 target: null,
            //                 targetYear: null
            //             }
            //         },
            //         isCommitted: false
            //     };
            case ActionTypes.DONE_UPDATE_TARGET:
                targets = state.targets.map(x=>{
                    if (x.id===action.payload.id){
                        return Object.assign(x, action.payload)
                    }
                    return x
                });

                return {
                    ...state,
                    targets:[...targets]
                }
            case ActionTypes.DONE_DELETE_TARGET:
                // targets = state.targets
                targets = state.targets.filter(x=>x.id!==Number(action.payload.id));

                return {
                    ...state,
                    targets: [...targets]
                }

            case ActionTypes.LOADED_TARGETS:
                targets = action.payload;
                return {
                    ...state,
                    targets: targets
                }

            case ActionTypes.DONE_APPLY_TARGET:
                return {
                    ...state,
                    targets: [...state.targets, action.payload]
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
