import React from 'react';

// At one point these were going to have links, left these in jic they are added again.
export const PROGRAMS = [
    {
        title: "Recruiting & HR",
        items: [
            { label: "Interview Quotas", link: '' },
            { label: "Inclusive Mentorship Programmes", link: '' },
            { label: "Family Leave Policy", link: '' },
            { label: "Returnship Program & Policy", link: '' },
            { label: "Flexible Work Policies", link: '' },
            { label: "Emergency Care Policies", link: '' },
            { label: "Annual Equal Pay Analysis", link: '' },
            { label: "Training & Support Programs for Underrepresented Employees", link: '' },
        ]
    }, {
        title: "Internal Policy & Culture",
        items: [
            { label: "Harassment & Discrimination Policy", link: "" },
            { label: "Organisational Culture Surveys", link: "" },
            { label: "Inclusive Office Spaces", link: "" },
            { label: "Annual Leadership Training", link: "" },
            { label: "Anti-Harassment Training", link: "" },
            { label: "Promotions & Attrition Tracking", link: "" },
            { label: "Employee Resource Groups or Circles", link: "" },
            { label: "Harassment Reporting System", link: "" },
            { label: "Cultural Competency Training", link: "" }
        ]
    }, {
        title: "Dealflow Sources",
        items: [
            { label: "Partnerships with D&I Organisations", link: "" },
            { label: "Unbiased Assessment Process", link: "" },
            { label: "Tracking Demographics of Referred Founders", link: "" },
            { label: "Diversity Goals in Investment Strategy", link: "" },
            { label: "Sponsorship Program", link: "" },
            { label: "D&I Due Diligence Questions", link: "" }
        ]
    }, {
        title: "Portfolio Guidance",
        items: [
            { label: "Additional Guidance for Underrepresented Founders", link: "" },
            { label: "Diversity on Boards of Directors", link: "" },
            { label: "Standard Code of Conduct & Zero-Tolerance Harassment Policy", link: "" },
            { label: "Founder Training", link: "" },
            { label: "Mental Health Supports", link: "" },
            { label: "Independent HR Ombudsperson", link: "" }
        ]
    }
];


export const L1REQUIREMENTS = [
    'Organisational D&I Statement & Strategy',
    'Chief D&I Officer or Champion',
    'D&I as a Portfolio Company KPI',
    'Policies Widening Applicant Pool Beyond Traditional Sources*',
    'Policies Reducing Biases in Hiring Processes*',
    'Tracking and Reporting Dealflow Demographics*'
];

export const L2REQUIREMENTS = [
    <span>All <b>Level 1</b> Requirements</span>,
    'Diverse Investment Strategy*',
    'Portfolio Inclusion Assessment*'
];
