import React, { Component, Text } from 'react';
import { Row, Column } from '../../../utils/FlexBox';
import Tooltip from '@material-ui/core/Tooltip';
import './index.scss';
import {Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis, YAxis} from "recharts";
import { kpiFormatter } from '../../../../utils';

class BarChartContainer extends Component {
    // state = {
    //     active: false
    // }

    // handleEnterHover = () => {
    //     this.setState({
    //         active: true
    //     })
    // }

    // handleLeaveHover = () => {
    //     this.setState({
    //         active: false
    //     })
    // }

    widthCalc = (score) => {
        if (score === "N/A") {
            return 0;
        } else if (score === "INSUFFICIENT_DATA") {
            this.props.showMessage({type: "error", message: "Data cannot be displayed, minimum threshold not met."});
            return 85;
        }
        return score * 10 * .85;
    };

    valueCalc = (score) => {
        if (score === "INSUFFICIENT_DATA") {
            return ''
        } else if (score !== "N/A") {
            return score.toFixed(1);
        }
        return score;
    };

    render() {
        const group_a = this.widthCalc(this.props.data.group_a);
        const group_b = this.widthCalc(this.props.data.group_b);
        const { data } = this.props;

        const CustomizedMostPopularLabel =(props) =>{
            const { x, y, value, fill } = props;
            return (
                <g>
                    <text x={x+10} y={y-5} fill={fill} fontSize={14}>{this.valueCalc(value)}</text>
                </g>
            )
        };

        return (
            <div className="inclusion-breakdown__bar-chart-area">
            <ResponsiveContainer height={300} width="100%">
                <BarChart data={data} barSize={45} barGap={10}>
                    <CartesianGrid stroke="#DBD9DB" vertical={false} />
                    <XAxis
                        interval={0}
                        fontSize={11}
                        fontFamily="Lato"
                        tick={{fill: "#525A64", width: 100}}
                        tickFormatter={kpiFormatter}
                        dataKey="name"
                        tickSize={0}
                        axisLine={false}
                        tickLine={false}
                        tickMargin={15}/>
                    <YAxis 
                    tickCount={6} 
                    fontSize={11} 
                    fontFamily="Lato" orientation='right' 
                    tick={{fill: "#939EAB"}} 
                    axisLine={false} 
                    tickLine={false} 
                    tickMargin={5}
                    padding={{ top: 40 }}
                    />
                    <Bar isAnimationActive={true} dataKey="group_a" >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={"#6221EA"}/>
                            ))
                        }
                        <LabelList dataKey="group_a" position="top" fontSize={12} content={<CustomizedMostPopularLabel />} />
                    </Bar>
                    <Bar isAnimationActive={true} dataKey="group_b" >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={"#CFBCF8"}/>
                            ))
                        }
                        <LabelList dataKey="group_b" position="top" fontSize={12} content={<CustomizedMostPopularLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            </div>
        );
    };
};

export default BarChartContainer;
