import React, {Component} from 'react';
import { Actions } from '../actions';
import { connect } from 'react-redux';
import PostSubmit from '../components/Davos/postSubmit';
import { getQueryParam } from '../utils';
import domtoimage from 'dom-to-image';

class DavosPDFContainer extends Component {
    componentWillMount () {
        this.props.getEmailResponse(getQueryParam("email"));
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.isEmailResponseLoaded) {
            setTimeout(function () {
                var printable = document.getElementById("divIdToPrint");
                var roe = document.getElementById("return-to-equality");
                var tfc = document.getElementById("tools-for-change");
                var us = document.getElementById("unlock-steps");
                const desiredHeight = roe.scrollHeight + tfc.scrollHeight + us.scrollHeight;
                const desiredWidth = roe.scrollWidth;
                printable.style.height = desiredHeight + "px";
                printable.style.width = desiredWidth + "px";

                domtoimage.toPng(printable, { height: desiredHeight, width: desiredWidth })
                    .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;
                        var link = document.createElement('a');
                        link.download = getQueryParam("email") + ".jpeg";
                        link.href = dataUrl;
                        link.click();
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
            }, 3000);
        }
    }

    render() {
        const {response: {annualImpact, actions, hackinclusion} = {}, isEmailResponseLoaded } = this.props;

        return (
            <div id={"divIdToPrint"}>
                {
                    isEmailResponseLoaded
                        ? <PostSubmit
                            annualImpact={annualImpact}
                            actions={actions}
                            showEmail={false}
                            hackinclusion={hackinclusion}/>
                        : "Loading..."
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.davos
    }
};
export default connect(mapStateToProps, Actions)(DavosPDFContainer);