import React, {Component} from 'react';
import {Row, Column} from '../../utils/FlexBox';
import Card from '../../utils/Card';
import classNames from "classnames";
import AddSolution from './AddSolution';
import Filter from './Filter';
import './index.scss';
import SolutionInfo from './SolutionInfo';
import {demographicMap, demographicToName} from "../../../utils/demographicMapping";
import { isPortfolioCompany } from '../../../utils/companyChecks';

// TODO remove this, these should always come from BE
const fallbackKpis = [
    'Culture', 'Feedback', 'Networks', 'Flexible Work', 'Safe Work', 'Recruiting & Hiring'
];

const demographicTarget = [
    {
        'key': 'all',
        'label': 'All',
        options: ['All']
    },
    {
        "key": "gender",
        "label": "Gender Identity",
        options: ["Men", "Women+"]
    },
    {
        "key": "ethnicity",
        "label": "Racial & Ethnic Background",
        options: ["White", "Racial/Ethnic minority"]
    },
    {
        "key": "sexual_orientation",
        "label": "Sexual Identity",
        options: ["Heterosexual", "LGBTQ+"]
    },
    {
        "key": "disability",
        "label": "People with disabilities",
        options: ["Yes", "No"]
    }
]

class MySolution extends Component {
    state = {
        active: 0,
        mySolution: this.props.chosenSolutions,
        selectedSolution: this.props.chosenSolutions[0],
        showSearchModal: false,
        showAddModal: false,
        isAllInfoFilled: false,
        showUserSolutionModal: false,
        solutionIsOurs: false,
        filter: {
            office: "All",
            level: 'All',
            target_demographic: "All"
        },
        form: {
            target_demographic: null,
            level: null,
            department: '',
            office: '',

        },
        solution: {
            painpoints: {},
            kpis: {},
            name: '',
            selectedKPIS: [],
            selectedPainPoints: []
        },
        isEditSolutions: false
    }

    handleClick = (id, solution) => {
        this.setState({
            active: id,
            selectedSolution: solution
        })
    }

    openSearchModal = () => {
        this.setState({
            showSearchModal: true
        })
    }

    handleContinue = (isOurSolution) => {
        if (isOurSolution) {
            this.setState({
                showSearchModal: false,
                showUserSolutionModal: false,
                showAddModal: true,
                solutionIsOurs: true
            })
        } else {
            this.setState({
                showSearchModal: false,
                showUserSolutionModal: true,
                showAddModal: false,
                solutionIsOurs: false
            })
        }
    }

    handleNext = (isEditSolutions) => {
        const resources = [this.state.solution.resource1, this.state.solution.resource2].filter(x => x !== undefined && x !== '');
        const pain_points = this.props.filterMap.pain_points.filter(x => Object.keys(this.state.solution.painpoints).includes(x.title)).map(x => x.id)
        const kpis = this.props.filterMap.kpis.filter(x => Object.keys(this.state.solution.kpis).includes(x.name)).map(x => x.id)

        if (isEditSolutions) {
            // API for PUT
        } else {
            this.props.handleCreateNewSolution({
                "title": this.state.solution.name,
                "description": this.state.solution.solution_description,
                resources,
                kpis,
                pain_points
            })

        }

        this.setState({
            showUserSolutionModal: false,
            showAddModal: true,
        })
    }


    closeModal = () => {
        this.setState({
            showSearchModal: false,
            showAddModal: false,
            showUserSolutionModal: false,
            isEditSolutions: false,
            solution: {
                painpoints: {},
                kpis: {},
                name: '',
                solution_description: '',
                selectedKPIS: [],
                selectedPainPoints: [],
                resource1: '',
                resource2: ''
            }
        })
    }

    handleChange = (e, v) => {
        const name = e.target.name || e.target.getAttribute('name');
        const value = (v !== undefined && v.newValue !== undefined) ? v.newValue : e.target.value;

        this.setState({
            solution: {
                ...this.state.solution,
                [name]: value
            }
        })
    }

    onChange = (type, value) => {
        if (type.painpoint) {
            this.setState(prev => {
                return {
                    ...prev,
                    solution: {
                        ...prev.solution,
                        painpoints: {
                            ...prev.solution.painpoints,
                            [type.painpoint]: this.state.solution.painpoints.hasOwnProperty(type.painpoint) ? value : true
                        }
                    }
                }
            })
        } else if (type.kpi) {
            this.setState(prev => {
                return {
                    ...prev,
                    solution: {
                        ...prev.solution,
                        kpis: {
                            ...prev.solution.kpis,
                            [type.kpi]: this.state.solution.kpis.hasOwnProperty(type.kpi) ? value : true
                        }
                    }
                }
            })
        }
        if (this.state.solution.name && this.state.solution.solution_description &&
            this.state.solution.kpis && this.state.solution.painpoints &&
            this.state.solution.resource1) {
            this.setState({
                isAllInfoFilled: false
            })
        }
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const formValue = e.target.value.option;
        let formName;
        let value;
        if (typeof (e.target.value) === 'object') {
            if (e.target.value.label === 'People with disabilities' && e.target.value.option === 'Yes') {
                value = `People with disabilities`;
            } else if (e.target.value.label === 'People with disabilities' && e.target.value.option === 'No') {
                value = `People without disabilities`;
            } else {
                value = e.target.value.option;
            }

            if (e.target.value.key === 'genders') {
                formName = 'gender';
            } else if (e.target.value.key === 'orientations') {
                formName = 'sexual_orientation';
            } else if (e.target.value.key === 'disabilities') {
                formName = 'disability';
            } else if (e.target.value.key === 'ethnicities') {
                formName = 'ethnicity';
            }
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value,
                    [formName]: formValue
                }
            });
        } else {
            value = e.target.value
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value,
                }
            });
        }
        if (this.state.solution.name && this.state.solution.solution_description &&
            this.state.solution.kpis && this.state.solution.painpoints &&
            this.state.solution.resource1) {
            this.setState({
                isAllInfoFilled: false
            })
        }
    }

    handleFilterSelect = (e) => {
        const name = e.target.name;
        const formValue = e.target.value.option
        let formName;
        let value;
        if (typeof (e.target.value) === 'object') {
            if (e.target.value.label === 'People with disabilities' && e.target.value.option === 'Yes') {
                value = `People with disabilities`;
            } else if (e.target.value.label === 'People with disabilities' && e.target.value.option === 'No') {
                value = `People without disabilities`;
            } else {
                value = e.target.value.option;
            }

            if (e.target.value.key === 'genders') {
                formName = 'gender';
            } else if (e.target.value.key === 'orientations') {
                formName = 'sexual_orientation';
            } else if (e.target.value.key === 'disabilities') {
                formName = 'disability';
            } else if (e.target.value.key === 'ethnicities') {
                formName = 'ethnicity';
            }
            this.setState({
                filter: {
                    ...this.state.filter,
                    [name]: value,
                    [formName]: formValue
                }
            });
        } else {
            value = e.target.value
            this.setState({
                filter: {
                    ...this.state.filter,
                    [name]: value,
                }
            });
        }
    }

    reset = () => {
        this.setState({
            form: {
                target_demographic: null,
                level: null,
                department: '',
                office: '',
                cost: '',
                date: ''
            },
            solution: {
                painpoints: {},
                kpis: {},
                name: '',
                selectedKPIS: [],
                selectedPainPoints: []
            },
            solutionIsOurs: false
        })
    }

    handleSave() {
        let department = this.state.form.department === 'All' ? '' : this.state.form.department;
        let level = this.props.filterMap.levels.find(level => level.label === this.state.form.level);
        level = level === undefined ? null : level.id;
        let office = this.state.form.office === 'All' ? '' : this.state.form.office;

        let companySolution = {
            // if this is an edit op, set 'id' to the id, otherwise it's undefined
            "id": this.state.isEditSolutions ? this.state.selectedSolution.id : undefined,
            // If its one of our solutions filter for the id, otherwise use the id from state.selectedSolution
            "solution": this.state.solutionIsOurs ?
                this.props.filterMap.solutions.find(x => x.title === this.state.solution.name).id :
                this.props.newSolution.id,
            "status": this.state.form.status,
            "program_lead": this.state.form.program_lead,
            "monthly_cost": Number(this.state.form.cost),
            department,
            level,
            office,
            "target_demographic": demographicMap[this.state.form.target_demographic || "All"],
            "launch_date": this.state.form.launch_date,
        }

        const isDiversityVCorPE = this.props.isDiversityVC || this.props.isDiversityPE;

        if (!isDiversityVCorPE) {
            // get first item from this.props.chosenSolutions
            const firstSolution = this.props.chosenSolutions?.[0]
            const year = firstSolution?.year;
            const quarter = firstSolution?.quarter;

            companySolution = {
                ...companySolution,
                "year": year, 
                "quarter": quarter
            }
        }

        if (this.state.isEditSolutions) {
            this.props.handleUpdateCompanySolutions(companySolution)
        } else {
            this.props.handleCreateNewCompanySolution(companySolution)
        }

        this.reset();
        this.closeModal();
    }

    handleEditSolution = (data) => {
        if(Object.keys(this.props.filterMap).length===0){
            console.warn('Solutions still loading...');
            return
        }
        let painpoints = {};
        let kpis = {};
        const level = data.level ? data.level.label : 'All';
        const office = data.office.length ? data.office : 'All';

        this.setState({
            isEditSolutions: true,
            solutionIsOurs: this.props.filterMap.solutions.find(x => x.id === data.solution.id) !== undefined,
            showAddModal: true,
            solution: {
                name: data.solution.title,
                solution_description: data.solution.description,
                resource1: data.solution.resources[0],
                resource2: data.solution.resources[1],
                kpis,
                painpoints
            },
            form: {
                cost: data.monthly_cost,
                office: office,
                level: level,
                department: data.department === '' ? 'All' : data.department,
                target_demographic: demographicToName(data.target_demographic),
                date: data.launch_date.slice(0, 7)
            }
        })
    }

    handleDeleteSolution = () => {
        if (this.props.chosenSolutions[this.state.active].is_recommendation) {
            this.props.handleUpdateCompanySolutions({
                "id": this.props.chosenSolutions[this.state.active].id,
                "solution": this.props.chosenSolutions[this.state.active].solution.id,
                "status": this.props.chosenSolutions[this.state.active].status,
                "program_lead": this.props.chosenSolutions[this.state.active].program_lead,
                "monthly_cost": this.props.chosenSolutions[this.state.active].monthly_cost,
                "office": this.props.chosenSolutions[this.state.active].office,
                "level": this.props.chosenSolutions[this.state.active].level ? this.props.chosenSolutions[this.state.active].level.value : null,
                "department": this.props.chosenSolutions[this.state.active].department,
                "target_demographic": this.props.chosenSolutions[this.state.active].target_demographic,
                "is_my_solution": false,
                "launch_date": this.props.chosenSolutions[this.state.active].launch_date
            })
        } else {
            this.props.handleDeleteSolution({
                id: this.props.chosenSolutions[this.state.active].id.toString()
            })
        }
    }

    handleBackButton = (backTo) => {
        if (backTo === 'search') {
            this.setState({
                showSearchModal: true,
                showUserSolutionModal: false,
                showAddModal: false
            })
        } else if (backTo === 'solution') {
            this.setState({
                showSearchModal: false,
                showUserSolutionModal: true,
                showAddModal: false
            })
        }
    }

    solutionFilter(solution) {
        const levelVal = this.state.filter.level === 'All' ? null : this.state.filter.level; //null is All

        const office = (this.state.filter.office === 'All' || solution.office === this.state.filter.office);
        const demographic = (demographicToName(solution.target_demographic) === this.state.filter.target_demographic || this.state.filter.target_demographic === 'All');
        let level;
        if (solution.level === null) {
            level = levelVal === null
        } else {
            level = ((levelVal === null) || solution.level.value === (this.props.filterMap.levels && this.props.filterMap.levels.find(x => x.label === levelVal).id)); // null is All
        }

        return office && demographic && level;
    }

    getRecommendationScope = () => {

        if (this.props.companyName == "Amazing Procurer") {
            return "currently implemented across your suppliers";
        } else if (isPortfolioCompany(this.props.companyName)) {
            return "currently implemented across your portfolio";
        } else {
            return "your organization is currently implementing";
        }

    }

    render() {

        const recommendationScope = this.getRecommendationScope();
        const isDiversityVCorPE = this.props.isDiversityVC || this.props.isDiversityPE
        const subtitle = isDiversityVCorPE ?
        "Here you will find the programmes and policies you have indicated are already present at your fund.":
        `Programs, policies and solutions ${recommendationScope}.`;

        if (this.props.filters) {
            return (
                <Card className={'certification'}>
                <div id="my-solution">
                    <Row>
                        <div className="overview-title">Current Programming</div>
                    </Row>
                    <Row>
                        <div className="description">
                            {subtitle}
                        </div>
                    </Row>

                    {(!isDiversityVCorPE && <Row>
                        <Filter filters={this.props.filters}
                                 filter={this.state.filter}
                                 demographicTarget={demographicTarget}
                                 handleFilterSelect={this.handleFilterSelect.bind(this)}
                                 companyName={this.props.companyName}
                                 />
                    </Row>)}

                    <Row>
                        <div className="subtitle">Solution</div>
                    </Row>
                    <Row>
                        <div className="solutions-container">
                            {
                                this.props.chosenSolutions.filter(this.solutionFilter.bind(this)).map((solution, id) => {
                                    return (
                                        <div key={id}
                                                onClick={() => this.handleClick(id, this.props.chosenSolutions[id])}
                                                className="solution-button">
                                            <div
                                                className={classNames("solution-pill", this.state.active === id ? "solution-title-active" : "solution-title")}>
                                                <h3>{solution.solution.title}</h3>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                Object.keys(this.props.filterMap).length === 0
                                ? null :
                                    <AddSolution
                                        showSearchModal={this.state.showSearchModal}
                                        showAddModal={this.state.showAddModal}
                                        form={this.state.form}
                                        solution={this.state.solution}
                                        openSearchModal={this.openSearchModal.bind(this)}
                                        closeModal={this.closeModal.bind(this)}
                                        handleContinue={this.handleContinue.bind(this)}
                                        handleChange={this.handleChange.bind(this)}
                                        handleSelect={this.handleSelect.bind(this)}
                                        handleSave={this.handleSave.bind(this)}
                                        filters={this.props.filters}
                                        demographicTarget={demographicTarget}
                                        showUserSolutionModal={this.state.showUserSolutionModal}
                                        onChange={this.onChange.bind(this)}
                                        handleNext={this.handleNext.bind(this)}
                                        isAllInfoFilled={this.state.isAllInfoFilled}
                                        
                                        solutionNames={(this.props.filterMap && this.props.filterMap.solutions) ? this.props.filterMap.solutions.map(x => x.title) : []}
                                        kpis={this.props.filterMap && this.props.filterMap.kpis ? this.props.filterMap.kpis.map(x => x.name) : fallbackKpis}
                                        pain_points={this.props.filterMap && this.props.filterMap.pain_points ? this.props.filterMap.pain_points.map(x => x.title) : []}
                                        handleBackButton={this.handleBackButton.bind(this)}
                                        isDiversityVC={Boolean(this.props.isDiversityVC)}
                                        isDiversityPE={Boolean(this.props.isDiversityPE)}
                                    />
                            }
                        </div>
                    </Row>
                    <div className="solutions-number-container">
                        {
                            this.props.chosenSolutions.length
                                ? <div className="solution-number">
                                    <span
                                        className={this.state.active === 0 ? "solution-left-gray" : "solution-left-purple"}/>
                                    {`${this.state.active + 1}/${this.props.chosenSolutions.length}`}
                                    <span
                                        className={this.state.active + 1 === this.props.chosenSolutions.length ? "solution-right-gray" : "solution-right-purple"}/>
                                </div>
                                : <div className="solution-number">
                                    <span className="solution-left-gray"/>
                                    0/0
                                    <span className="solution-right-gray"/>
                                </div>
                        }
                    </div>
                    {
                        this.props.chosenSolutions.length
                            ? <div>
                                <hr/>
                                <Row>
                                    <SolutionInfo chosenSolutions={this.props.chosenSolutions[this.state.active]}
                                                  handleDeleteSolution={this.handleDeleteSolution.bind(this)}
                                                  handleEditSolution={this.handleEditSolution.bind(this)}
                                                  isEditSolutions={this.state.isEditSolutions}
                                                    filterMapLoaded={Object.keys(this.props.filterMap).length > 0}
                                                    companyName={this.props.companyName}
                                                  />
                                </Row>
                            </div>
                            : null
                    }
                </div>
                </Card>
            );
        } else {
            return null;
        }
    };
};

export default MySolution;
