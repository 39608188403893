import React from 'react';
import { Link } from 'react-router-dom';
import './TopBar.scss';
import classNames from 'classnames';
import { NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

export default class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      active: false
    };
    this.select = this.select.bind(this);
  }

  select() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  render() {
    const path = window.location.pathname;
    return (
      <div class="topBar">
          {/*
        <Dropdown nav class="dropdown" isOpen={this.state.dropdownOpen} className={classNames({ clicked: path === '/connect/topics' }, { buttons: path !== '/connect/topics' })}>
          <DropdownToggle nav caret><Link to="/connect/topics">Topics</Link></DropdownToggle>
          <DropdownMenu class="dropdown-menu">
            <DropdownItem><Link to="/connect/topics#culture">Culture</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#networks">Networks</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#bias">Bias</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#safety">Safety</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#leadership">Leadership</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#recruiting">Recruiting</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#leadership&accountability">Leadership & Accountability</Link></DropdownItem>
            <DropdownItem><Link to="/connect/topics#payequity">Pay Equity</Link></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <span className ="line"></span>


        <NavItem className={classNames({ clicked: path === '/connect/pilots' }, { buttons: path !== '/connect/pilots' })}>
          <Link to="/connect/pilots">Pilots</Link>
        </NavItem>
        <span className ="line"></span>
        <Dropdown nav class="dropdown" isOpen={this.state.dropdownOpen} className={classNames({ clicked: path === '/connect/resources' }, { buttons: path !== '/connect/resources' })}>
          <DropdownToggle nav caret><Link to="/connect/resources">Resources</Link></DropdownToggle>
          <DropdownMenu class="dropdown-menu">
            <DropdownItem><Link to="/connect/resources#culture">Culture</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#networks">Networks</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#bias">Bias</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#safety">Safety</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#leadership">Leadership</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#recruiting">Recruiting</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#leadership&accountability">Leadership & Accountability</Link></DropdownItem>
            <DropdownItem><Link to="/connect/resources#payequity">Pay Equity</Link></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <span className ="line"></span>

        <NavItem className={classNames({ clicked: path === '/connect/experts' }, { buttons: path !== '/connect/experts' })}>
          <Link to="/connect/experts">Experts</Link>
        </NavItem>
        <span className ="line"></span>
        */}
        <NavItem className={classNames({ clicked: path === '/connect/discussion' }, { buttons: path !== '/connect/discussion' })}>
          <Link to="/connect/discussion">Discussion</Link>
        </NavItem>
      </div>
    );
  }
}
