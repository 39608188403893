/* Temp data structures to be removed if the backend is built out. */

export const COMPANY_LIST = [
	"All",
	"ATN",
	"Vaulter",
	"Brightstar Cruises",
	"Brightstar Adventure Park",
	"Dust",
	"PGM"
]

export const EMPLOYEE_DATA_BASE = {
	percentages: [
		{ 
			name: "Analyst",
			value: 80
		},
		{ 
			name: "Associate",
			value: 9
		},
		{ 
			name: "VP",
			value: 5
		},
		{ 
			name: "Director",
			value: 4
		},
		{ 
			name: "Executive",
			value: 2
		},
	],
	count: 200
}

const VARIATIONS = {
	"ATN": [-10, 2, 3, 5, 0, 500],
	"Vaulter": [-12, 0, 4, 4, 4, 200],
	"Brightstar Cruises": [1, 1, 2, -4, 0, 1000],
	"Brightstar Adventure Park": [-20, 10, 3, 7, 0, 2000],
	"Dust": [-15, 2, 3, 10, 0, 30],
	"PGM": [-1, 1, -1, 1, 0, 2100],
	"All": [0, 0, 0, 0, 0, 5830]
}

export var EMPLOYEE_DATA = {};

for (var company in VARIATIONS) {
	EMPLOYEE_DATA[company] = {
		...EMPLOYEE_DATA_BASE
	};

	EMPLOYEE_DATA[company].percentages = EMPLOYEE_DATA[company].percentages.map((p, i) => {
		return {
			...EMPLOYEE_DATA[company].percentages[i],
			value: p.value + VARIATIONS[company][i]
		}
	});

	EMPLOYEE_DATA[company].count = VARIATIONS[company][5];
}



// TODO this should come from reducer in future
export const COLORS = [
    "#D7CEE4",
    "#CFB4F7",
    "#6221EA",
    "#0725E8",
    "#B6BFF6",
    "#FEBECD",
    "#FBC02D",
    "#109CF1",
    "#FF2455",
    "#FF8A00",
    "#8F40EB",
    "#AF5EEC",
    "#CA7DEF",
    "#DF9CF3",
    "#F1BCF8",
    "#FFDCFF",
    "#FBC4ED",
    "#F8ABD6",
    "#F591BC",
    "#F1779E",
    "#E95D7E",
    "#DE425B",
    "#FF005B",
    "#FF533B",
    "#FF811D",
    "#FFA600",
];
