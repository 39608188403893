import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditPost from '../EditPost';
import DeletePost from '../DeleteModal';
import { parseDate } from "../../../utils";
import './index.scss';

class PostCard extends Component {
    state = {
        anchorEl: null,
        isModalOpen: false,
        deleteConfirmationModal: false
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleOpenAction = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        })
    };

    handleCloseAction = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleOpenModal = () => {
        this.setState({
            isModalOpen: true
        })
    };

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false
        });
        this.handleCloseAction();
    };

    handleOpenDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: true
        });
    };

    handleCloseDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: false
        });
        this.handleCloseAction();
    };

    handleEdit = (post) => {
        this.props.handleEditPost({
            id : this.props.specificPost.id.toString(),
            post : {
                "title": post.title,
                "description": post.description,
                "kpi": post.kpi
            }
        });
        this.handleCloseAction();
    };

    handleDelete = () => {
        this.props.handleDeletePost({ id: this.props.post.id.toString() });
        this.handleCloseDeleteModal();
    };

    handleLike = () => {
        if (this.props.postType === "bookmark") {
            if (this.props.post.liked_by_user) {
                this.props.handleLikePost({
                    post_id: this.props.post.id.toString(),
                    like_count: {
                        "is_liked" : this.props.post.likes_count - 1
                    },
                    postType: "bookmark"
                })
            } else {
                this.props.handleLikePost({
                    post_id: this.props.post.id.toString(),
                    like_count: {
                        "is_liked" : this.props.post.likes_count + 1
                    },
                    postType: "bookmark"
                })
            }
        } else {
            if (this.props.post.liked_by_user) {
                this.props.handleLikePost({
                    post_id: this.props.post.id.toString(),
                    like_count: {
                        "is_liked" : this.props.post.likes_count - 1
                    },
                    postType: "post"
                })
            } else {
                this.props.handleLikePost({
                    post_id: this.props.post.id.toString(),
                    like_count: {
                        "is_liked" : this.props.post.likes_count + 1
                    },
                    postType: "post"
                })
            }
        }
    };

    handleBookmark = () => {
        let isBookmarked = 1;
        if (this.props.isBookmarked) {
            isBookmarked = 0;
        }
        this.props.bookmarkID(this.props.post.id);
        
        this.props.handleUpdateBookmark({
            post_id : this.props.post.id.toString(),
            is_bookmarked : {
                "is_bookmarked" : isBookmarked
            }
        });
    };

    render() {
        if (this.props.post && this.props.isSpecificPost) {
            return this.props.post.map((post, id) => {
                if (post.id === this.props.specificPost.id) {
                    return (
                        <div key={id} id="post-card">
                            <div className="post-card-container">
                                <div className="category-container">
                                    <span className="category">{post.kpi.name}</span>
                                </div>
                                <div className="action-container">
                                    <button onClick={() => this.handleBookmark()} className="action"><img src={ this.props.isBookmarked ? "/images/svg/Connect/Post/bookmarked.svg" : "/images/svg/Connect/Post/bookmark.svg"} alt="bookmark icon" />Bookmark</button>
                                    <button onClick={() => this.handleLike()} className="action"><img src={ post.liked_by_user ? "/images/svg/Connect/Post/liked.svg" : "/images/svg/Connect/Post/like.svg"} alt="like icon" />{post.likes_count}</button>
                                    <button className={this.props.profileInfo.company === post.user.company ? "action" : "hidden"}>
                                        <IconButton
                                            id="action-menu"
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => this.handleOpenAction(e)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="post-action-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => this.handleCloseAction()}
                                        >
                                            <MenuItem onClick={() => this.handleOpenModal()}>Edit post</MenuItem>
                                            <MenuItem onClick={() => this.handleOpenDeleteModal()}>Delete post</MenuItem>
                                        </Menu>
                                    </button>
                                    {
                                        this.props.kpis.length
                                        ? <EditPost isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} handleEdit={this.handleEdit.bind(this)} postInfo={post} kpis={this.props.kpis} />
                                        : null
                                    }
                                    <DeletePost isOpen={this.state.deleteConfirmationModal} handleCloseModal={this.handleCloseDeleteModal} handleDelete={this.handleDelete} type={'Post'} />
                                </div>
                                
                                <div className="information-container">
                                    <div className={this.props.isSpecificPost ? "question" : "question-hover"}>
                                        <Link to={{
                                            pathname: "/connect/post-id",
                                            state: {
                                                post: post
                                            }
                                        }} >
                                            {post.title}
                                            <span className={post.created_at.slice(0, 19) !== post.updated_at.slice(0, 19) ? "edited" : "hidden"}>(Edited)</span>
                                        </Link>
                                    </div>
                                    <div className="date">{parseDate(post.created_at)}</div>
                                    <div className="user"><span className="profile-name">{post.user.full_name.toUpperCase().match(/\b(\w)/g)}</span>{post.user.full_name} - {post.user.role}</div>
                                    <hr />
                                    <div className="description">{post.description}</div>
                                    <div className="comment"><img src="/images/svg/Connect/Post/comment.svg" alt="comment icon" /> {post.comments_count} Comments</div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            })
        } else if (this.props.post && !this.props.isSpecificPost) {
            return (
                <div id="post-card">
                    <div className="post-card-container">
                        <div className="category-container">
                            <span className="category">{this.props.post.kpi.name}</span>
                        </div>
                        <div className="action-container">
                            <button onClick={() => this.handleBookmark()} className="action"><img src={ this.props.isBookmarked ? "/images/svg/Connect/Post/bookmarked.svg" : "/images/svg/Connect/Post/bookmark.svg"} alt="bookmark icon" />Bookmark</button>
                            <button onClick={() => this.handleLike()} className="action"><img src={ this.props.post.liked_by_user ? "/images/svg/Connect/Post/liked.svg" : "/images/svg/Connect/Post/like.svg"} alt="like icon" />{this.props.post.likes_count}</button>
                            <button className={this.props.profileInfo.company === this.props.post.user.company ? "action" : "hidden"}>
                                <IconButton
                                    id="action-menu"
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleOpenAction(e)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="post-action-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={() => this.handleCloseAction()}
                                >
                                    <MenuItem onClick={() => this.handleOpenModal()}>Edit post</MenuItem>
                                    <MenuItem onClick={() => this.handleOpenDeleteModal()}>Delete post</MenuItem>
                                </Menu>
                            </button>
                            {
                                this.props.kpis.length
                                ? <EditPost isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} handleEdit={this.handleEdit.bind(this)} postInfo={this.props.post} kpis={this.props.kpis} />
                                : null
                            }
                            <DeletePost isOpen={this.state.deleteConfirmationModal} handleCloseModal={this.handleCloseDeleteModal} handleDelete={this.handleDelete} type={'Post'} />
                        </div>
                        
                        <div className="information-container">
                            <div className={this.props.isSpecificPost ? "question" : "question-hover"}>
                                <Link to={{
                                    pathname: "/connect/post-id",
                                    state: {
                                        post: this.props.post
                                    }
                                }} >
                                    {this.props.post.title}
                                    <span className={this.props.post.created_at.slice(0, 19) !== this.props.post.updated_at.slice(0, 19) ? "edited" : "hidden"}>(Edited)</span>
                                </Link>
                            </div>
                            <div className="date">{parseDate(this.props.post.created_at)}</div>
                            <div className="user"><span className="profile-name">{this.props.post.user.full_name.toUpperCase().match(/\b(\w)/g)}</span>{this.props.post.user.full_name} - {this.props.post.user.role}</div>
                            <hr />
                            <div className="description">{this.props.post.description}</div>
                            <div className="comment"><img src="/images/svg/Connect/Post/comment.svg" alt="comment icon" /> {this.props.post.comments_count} Comments</div>
                        </div>
                    </div>
                </div>
            );
        }
    };
};

export default PostCard;