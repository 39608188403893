import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import _ from 'underscore';

import { Row, Column } from '../../utils/FlexBox';
import MainCard from '../../utils/MainCard';

import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../actions';

import {PROGRAM_DATA, SCORE_DATA} from "./constants";

const PortcoPrograms = ( {companyName, ...props} ) => {
    const [pdfError, setPdfError] = useState(false);
    const [ showHelperModal, setShowHelperModal ] = useState(false);

    const dispatch = useDispatch();

    // source: https://usehooks.com/usePrevious/
    function usePrevious(value) {
        // The ref object is a generic container whose current property is mutable ...
        // ... and can hold any value, similar to an instance property on a class
        const ref = useRef();
        // Store current value in ref
        useEffect(() => {
        ref.current = value;
        }, [value]); // Only re-run if value changes
        // Return previous value (happens before update in useEffect above)
        return ref.current;
    }

    const prevProps = usePrevious(props);
    let prevPdfLink = null;
    if (prevProps) {
        prevPdfLink = prevProps.pdfLink;
    }

	const commitmentDetail = PROGRAM_DATA[companyName || "All"]; /* TODO hack */
	const scoreData = SCORE_DATA; /* TODO hack */
    
    useEffect(
        () => {
            if ((props.pdfLink && props.pdfLink.url) && (prevPdfLink && prevPdfLink.url)) {
                if (props.pdfLink.url !== prevPdfLink.url) {
                    // url changed; trigger download
                    window.open(props.pdfLink.url , "_blank");
                    // now clear the state
                    dispatch({type: ActionTypes.CLEAR_ANALYTICS_PDF_LINK});
                }
            } else if (props.pdfLink && props.pdfLink.url) {
                // first ever download
                window.open(props.pdfLink.url , "_blank");
                // now clear state
                dispatch({type: ActionTypes.CLEAR_ANALYTICS_PDF_LINK});
            }
        },
        [props.pdfLink.url]
    );

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	  }

    const handlePdfDownload = (file) => {
        // props.getAnalyticsPdfLink({data: { pdfKey: file } });

		// hack: go to new URL
		openInNewTab("https://static.googleusercontent.com/media/diversity.google/en//annual-report/static/pdfs/google_2021_diversity_annual_report.pdf?cachebust=2e13d07");
    }

    const CommitmentRow = (props) => {
        const {
            score, title, desc, file
        } = props;

        const inProg = (score > 0);

        return (
            <tr className="commitment-table__row">
                <td className="commitment-table__cell-icon">
                    <img className={`${inProg ? 'icon-inprog' : 'icon-notinprog'}`}/>
                </td>
                <td className="commitment-table__score-cell">
                    {score}/3
                </td>
                <td className="commitment-table__cell">
                    <div className="commitment-table__policy-title">
                    {title}
                    </div>
                    <div className="commitment-table__policy-desc">
                    {desc}
                    </div>
                </td>
                <td className="commitment-table__score-cell">
                    { file && 
                        <span className="commitment-table__file-download" onClick={() => { 
                            // safeMixpanelTrack(
                            //     "Commitment PDF download",
                            //     {
                            //         "title": title,
                            //         "score": score,
                            //         "file": file
                            //     }
                            // );
                            handlePdfDownload(file) 
                            }}>
                        
                            <img className={`icon-download`}/>
                        
                        </span>
                        }
                    
                </td>
            </tr>
        );
    }

	const PlaceholderRows = () => {
		return (
			<div>
				Please select a portfolio company to see the details of their programs.
			</div>
		);
	}

	const PopulatedRows = () => {
		return (
			<>
			<table className="commitment-table__table" border="0" cellSpacing="0">
                <thead className="commitment-table__header">
                    <tr className="commitment-table__row">
                        <th colSpan="4">
                        Program status and details
                        </th>
                    </tr>
                </thead>
                <tbody>
			<CommitmentRow 
                        score={commitmentDetail.policy}
                        title="Policy and Governance"
                        desc="Policies in place to track, review, and improve effectiveness on DEI initiatives"
                        file={commitmentDetail.policy_link} />
                    <CommitmentRow 
                        score={commitmentDetail.recruiting}
                        title="Talent Acquisition Strategy"
                        desc="Programs in place to recruit diverse talent and increase minority representation"
                        file={commitmentDetail.recruiting_link} />
                    <CommitmentRow 
                        score={commitmentDetail.engagement}
                        title="Employee Engagement"
                        desc="Programs in place to support diverse talent and ensure a safe and inclusive workplace"
                        file={commitmentDetail.engagement_link} />
                    <CommitmentRow 
                        score={commitmentDetail.transparency}
                        title="Transparency and Data Disaggregation"
                        desc="Commitment to disclose organizational diversity beyond what is required by regulation"
                        file={commitmentDetail.transparency_link} />
					</tbody>
            	</table>
				<div className="commitment-table__helper-text">
                <span onClick={() => {
                    // safeMixpanelTrack("Trouble seeing downloads", {})
                    setShowHelperModal(true)
                    }}>Trouble seeing downloads?</span>
            </div>
						</>
		)
	}

    const makeCommitmentTable = (inProg = true) => {
        return (
			<div className="portco__width portco__wrapper">
			<Row>
                        <Column grow={1}>
                            <MainCard title={`Programs in place ${companyName == "All" ? "" : "at " + companyName }`}
                            >
            <div className="commitment-table">
            
				{ companyName == "All" ? <PlaceholderRows/> : <PopulatedRows/> }
                    
                

            
        </div>
		</MainCard>
		</Column>
		</Row>
		</div>
        );

    }

    const inProg = makeCommitmentTable(true);

    const notInProg = makeCommitmentTable(false);

    const HelperModal = () => {
        return (
            <Modal show={showHelperModal} id="helper-modal">
            <Modal.Header>Trouble seeing downloads?</Modal.Header>
            <Modal.Body>
            
            Please disable your popup blocker for <strong>analytics.diversio.com</strong>.
            <br/><br/>
            You can find instructions for various browsers below:
            <br/>

            <ul>
                <li><a href="https://support.google.com/chrome/answer/95472" target="_blank">
                Chrome</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting#w_pop-up-blocker-settings" target="_blank">

                Firefox</a></li>
                <li><a href="https://support.apple.com/en-ca/guide/safari/sfri40696/mac" target="_blank">Safari</a></li>
                <li><a href="https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5" target="_blank">Edge</a></li>
            </ul>
            
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setShowHelperModal(false);
                }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>  
        );
    }

    return (
        <>
        {<HelperModal />}
        <div className="org-div__wrapper">
            {parseFloat(scoreData.commitment_score) > 0 ? inProg : notInProg}
        </div>
        </>

        
    );
}

export default PortcoPrograms;
