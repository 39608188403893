import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Actions } from '../../actions';
import { connect } from 'react-redux';
import ResetPassword from '../../components/Auth/reset-password';

class ResetPasswordContainer extends Component {
    _handleOnSubmit (values) {
        const { password, confirmPassword, token } = values;
        this.props.doResetPassword({
            password: password,
            password_confirm: confirmPassword,
            token
        });
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };

        if (this.props.isAuthenticated) {
            return <Redirect to={from} />
        }

        return <ResetPassword
            { ...this.props }
            onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps, Actions)(ResetPasswordContainer);
