import React from 'react';
import Progress from "./Progress";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const RequireUpdates = () => {
    return (
        <div className={"survey-review"}>
            <Progress selected={"review"}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src="/images/survey-attention.svg" alt="Survey Requires Updates"/>
                    <h5>Your Survey Still Needs Some Work</h5>
                    <p>You should have received an email from a Diversio representative regarding the updates that need to be made. Please re-submit your survey for review once you are done.</p>
                    <Link to={"/survey/setup"}>
                        <Button variant={"primary"}>Review Survey</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RequireUpdates;
