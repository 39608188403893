import React from 'react';
import _ from 'underscore';

const ReturnToEquality = ({ annualImpact }) => {
    function calculateAbsoluteProfit (profit) {
        const original = profit;
        var types = ["B", "M", "K"];
        var type = types.shift();

        while (profit < 1 && types.length) {
            profit *= 1000;
            type = types.shift();
        }

        return {
            original,
            value: Number(profit.toFixed()),
            type
        };
    }

    annualImpact = annualImpact.reduce((overall, impact, index) => {
        if (!impact) { return overall; }
        overall[index] = calculateAbsoluteProfit(impact);
        return overall;
    }, []);

    annualImpact = annualImpact.filter(r => !!r);
    let range = _.unique(annualImpact.sort((a, b) => a.original-b.original), true, _.property('value'));

    return (
        <div id={"return-to-equality"}>
            <h2>YOUR RETURN ON EQUALITY</h2>
            <h4>Potential annual increase in profit (USD) from advancing women to leadership</h4>
            <div className={"revenue-container"}>
                <div className={"annual-increase"}>
                        {
                            range.length > 1
                                ? <h1>${ range[0].value }<span>{ range[0].type }</span> - ${ range[1].value }<span>{ range[1].type }</span></h1>
                                : <h1>${ range[0].value }<span>{ range[0].type }</span></h1>
                        }
                    <p>Based on financial analysis of 21,000 companies worldwide</p>
                </div>
            </div>
        </div>
    );
};

export default ReturnToEquality;
