import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import _ from 'underscore';
import SavedSurveys from "./SavedSurveys";
import Loader from "../utils/Loader";

const SurveyInit = ({ previousSurveyExists, setSelectedSurvey, setCurrentSelectedSurveyPage, getAllSurveys, isLoaded }) => {
    useEffect(() => {
        getAllSurveys();
    }, []);

    if (!isLoaded) {
        return <Loader />
    }

    return <>
            <div id={"survey-init"}>
                <div className={"survey-content-container"}>
                    <h5>Welcome to Your D&I Survey Dashboard</h5>
                    <p>Your journey to an inclusive workplace is about to begin.</p>
                    <Link onClick={() => setCurrentSelectedSurveyPage('setup')}>
                        <Button variant={"primary"}>
                            {
                                !previousSurveyExists
                                    ? "Create Your First Survey"
                                    : "Create Survey"
                            }
                        </Button>
                    </Link>
                </div>
            </div>
            {/*<SavedSurveys savedSurveys={ savedSurveys } setSelectedSurvey={setSelectedSurvey} />*/}
        </>
    ;
};

export default SurveyInit;
