/*global io*/
import {doSignout} from './auth.js'
import {generatorRunner} from "./util";

const serverURL = process.env.REACT_APP_DASHBOARD_API_ROOT;

/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 **/

function parseToJsonPromise(data) {
    return new Promise((res, rej) => {
        data.json().then(object => {
            let response = {
                successful: data.status.toString().indexOf("2") > -1,
                data: object,
                code: data.status
            };

            return res(response);
        }).catch(object=> {
            return res(object)
        })
    });
}

const handle401 = function (response) {
    if (response.code === 401) {
        generatorRunner(doSignout)()
    }
    return response
};


const apiGenerator = (uri, method, payload, type) => {
    const accessToken = localStorage.getItem("auth_access_token");

    let headers = {
        'Accept': 'application/json',
        'Authorization': `JWT ${accessToken}`
    };

    if (type !== "file") {
        headers["Content-Type"] = "application/json";
        payload = JSON.stringify(payload);
    }

    return fetch(`${serverURL}${uri}`, {
        method,
        body: payload,
        headers
    }).then(parseToJsonPromise).then(handle401).catch(error => {
        throw error;
    });
};

const socketGenerator = (url, method, payload) => {
    return new Promise((res, rej) => {
        io.socket.request({
            url: url,
            method: method,
            headers: io.sails.headers,
            data: payload
        }, (resData, jwRes) => {
            const successful = jwRes.statusCode === 200;
            res({data: resData, successful});
        })
    })
};

const unauthorizedApiGenerator = (uri, method, payload, type) => {
    let headers = {
        'Accept': 'application/json'
    };

    if (type !== "file") {
        headers["Content-Type"] = "application/json";
        payload = JSON.stringify(payload);
    }

    return fetch(`${serverURL}${uri}`, {
        method,
        body: payload,
        headers
    }).then(parseToJsonPromise).then(handle401).catch(error => {
        throw error
    });
};

const networkAdapter = (generator) => {
    return {
        POST: (url, payload, type) => generator(url, 'POST', payload, type),
        PUT: (url, payload) => generator(url, 'PUT', payload),
        DELETE: (url) => generator(url, 'DELETE'),
        GET: (url, token, payload) => generator(url, 'GET', payload),
        PATCH: (url, payload) => generator(url, 'PATCH', payload)
    }
};

/**
 *
 * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
 * @private
 */
const _requestSocket = networkAdapter(socketGenerator);
const _requestAPI = networkAdapter(apiGenerator);
const _requestUnauthorizedAPI = networkAdapter(unauthorizedApiGenerator);

// Question Network Util
export const getAllQuestions = () => _requestSocket.GET("/question/");

// Calculator Network Util
export const submitResponse = (payload) => _requestSocket.POST("/response/", payload);
export const saveEmail = (payload) => _requestSocket.POST("/saveEmail/", payload);
export const loadEmailResponse = (email) => _requestSocket.GET(`/pdf/?email=${email}`);

// Analyze Network Util
export const loadDiversityBreakdownData = (queryString) => _requestAPI.GET(`/analyze_diversity_breakdown/?${queryString}`);
export const loadInclusionMetricsData = (queryString) => _requestAPI.GET(`/analyze_inclusion_metrics/?${queryString}`);
export const loadNonOverviewMetricsData = (queryString) => _requestAPI.GET(`/non_overview_metrics/?${queryString}`);
export const loadEmployeeOverviewData = () => _requestAPI.GET(`/analyze_employee_overview/`);
export const loadInclusionBreakdownData = (queryString) => _requestAPI.GET("/analyze_inclusion_breakdown/?" + queryString);
export const loadCompanyFilters = (queryString) => _requestAPI.GET(`/company_filters/?` + queryString);
export const loadInclusionTrackerData = queryString => _requestAPI.GET(`/inclusion_tracker/?${queryString}`);
export const loadDiversityTrackerData = queryString => _requestAPI.GET(`/diversity_tracker/?${queryString}`);
export const loadDiversityBreakdownByRoleData = (queryString) => _requestAPI.GET(`/diversity_breakdown_by_role/?${queryString}`);
export const loadFreeText = () => _requestAPI.GET(`/free_texts/`);

// Auth Network Util
export const handleLogin = (payload) => _requestAPI.POST("/login/", payload);
export const handleRegistration = (payload) => _requestUnauthorizedAPI.POST("/register/", payload);
export const refreshToken = (payload) => _requestAPI.POST("/token-refresh/", payload);
export const signout = () => { /* Not required as JWTs are stateless anyway, just invalidate the token from the frontend */
};
export const setActiveDashboard = (payload) => _requestAPI.POST("/set_active_dashboard/", payload)
// Home Network Util
export const loadExecSummaryData = (token) => _requestAPI.GET(`/home_exec_summary/`, token);
export const loadPainpointData = (token) => _requestAPI.GET(`/home_painpoint/`, token);
export const loadDiversityCompositionData = (token) => _requestAPI.GET(`/home_diversity_composition/`, token);
export const loadMetricOverviewData = (token) => _requestAPI.GET(`/home_metric_overview/`, token);
export const loadFocusAreaData = (token) => _requestAPI.GET(`/home_focus_area/`, token);
export const loadInclusionScoreData = (token) => _requestAPI.GET(`/home_inclusion_score/`, token);
export const deploySurvey = () => _requestAPI.GET(`/deploy_survey/`);
export const informPurchase = () => _requestAPI.GET('/inform_purchase/');
export const loadCompanyInformation = () => _requestAPI.GET('/company_information/');

// Admin Network Util
export const uploadCSV = (payload) => _requestAPI.POST("/csv_import/", payload, "file");

// Improve -> Targets
export const getAllTargets = () => _requestAPI.GET("/improve/targets/");
export const applyTarget = (payload) => _requestAPI.POST("/improve/targets/", payload);
export const deleteTarget = (queryString) => _requestAPI.DELETE(`/improve/targets/?${queryString}`);
export const updateTarget = (payload) => _requestAPI.PUT(`/improve/targets/`, payload);
export const getAllTargetOptions = () => _requestAPI.GET(`/analyze_inclusion_metrics/`);

// Improve -> Recommendations
export const getAllRecommendations = () => _requestAPI.GET("/improve/recommendations/");
export const loadAllSolutions = () => _requestAPI.GET("/improve/company_solution/");
export const updateCompanySolutions = (payload) => _requestAPI.PUT("/improve/company_solution/", payload);
export const deleteSolution = (queryString) => _requestAPI.DELETE(`/improve/company_solution/?${queryString}`)
export const createNewCompanySolution = (payload) => _requestAPI.POST('/improve/company_solution/', payload);
export const createNewSolution = (payload) => _requestAPI.POST('/improve/solution/', payload);

// Improve -> Budget
export const updateBudget = (payload) => _requestAPI.PUT('/update_company_solution_budget/', payload);

// Improve -> Action Plan
export const getAllActionPlans = () => _requestAPI.GET("/improve/action_plans/");
export const createActionPlan = payload => _requestAPI.POST("/improve/action_plans/", payload);
export const deleteActionPlan = payload => _requestAPI.DELETE(`/improve/action_plans/${payload}/`);

// Road Map
export const loadRoadMapChecklist = () => _requestAPI.GET('/roadmap/');
export const updateRoadMapChecklist = (payload) => _requestAPI.PUT('/update_roadmap/', payload);

// Connect -> Profile
export const loadProfile = () => _requestAPI.GET('/connect/profile/');
export const updateProfile = (payload) => _requestAPI.PUT('/connect/profile/', payload);
export const uploadProfileImage = (payload) => _requestAPI.PUT('/connect/profile-image/', payload, "file");

// Connect -> Post
export const loadAllPosts = () => _requestAPI.GET('/connect/post/');
export const createPost = (payload) => _requestAPI.POST('/connect/post/', payload);
export const editPost = (queryString, payload) => _requestAPI.PUT(`/connect/post/?${queryString}`, payload);
export const deletePost = (queryString) => _requestAPI.DELETE(`/connect/post/?${queryString}`);
export const likePost = (queryString, payload) => _requestAPI.PUT(`/connect/like/?${queryString}`, payload);

// Connect -> Comment
export const loadComments = (queryString) => _requestAPI.GET(`/connect/comment/?${queryString}`);
export const createComment = (payload) => _requestAPI.POST('/connect/comment/', payload);
export const editComment = (queryString, payload) => _requestAPI.PUT(`/connect/comment/?${queryString}`, payload);
export const deleteComment = (queryString) => _requestAPI.DELETE(`/connect/comment/?${queryString}`);
export const likeComment = (queryString, payload) => _requestAPI.PUT(`/connect/like/?${queryString}`, payload)

// Connect -> Bookmark
export const loadBookmark = () => _requestAPI.GET('/connect/bookmark/');
export const updateBookmark = (queryString, payload) => _requestAPI.PUT(`/connect/bookmark/?${queryString}`, payload);

// Connect -> Get KPIs
export const loadKpis = () => _requestAPI.GET('/all_kpis/');
export const loadFilterMap = () => _requestAPI.GET('/filters_map/');

// LSO
export const loadOnePager = () => _requestAPI.GET('/lso/score/');

// Password
export const changePassword = (payload) => _requestAPI.POST('/change-password/', payload);
export const requestResetPassword = (payload) => _requestUnauthorizedAPI.POST('/request-password-reset/', payload);
export const resetPassword = (payload) => _requestUnauthorizedAPI.POST('/reset-password/', payload);

// Communications
export const loadCommunicationsTokens = () => _requestAPI.GET('/voyager/user/');
export const updateCommunicationsTokens = (payload) => _requestAPI.PUT('/voyager/user/', payload);
export const deleteCommunicationsTokens = (payload) => _requestAPI.DELETE('/voyager/user/', payload);
export const loadCommunicationsSlackAnalytics = (payload) => _requestAPI.POST(`/voyager/slack/`, payload);
export const loadCommunicationsGithubAnalytics = (payload) => _requestAPI.POST(`/voyager/github/`, payload);

// Diversity PE
export const loadDiversityPEPdf = queryString => _requestAPI.GET(`/diversity_pe/get_pdf/?pdfKey=${queryString}`);
export const loadDiversityPECompanyPolicy = () => _requestAPI.GET('/diversity_pe/company_policy/');
export const loadDiversityPEPolicy = () => _requestAPI.GET('/diversity_pe/policy/');
export const loadDiversityPEScore = () => _requestAPI.GET('/diversity_pe/score/');
export const loadDiversityPEBenchmark = () => _requestAPI.GET('/diversity_pe/benchmark/');
export const loadDiversityPEGoal = () => _requestAPI.GET('/diversity_pe/goal/');
export const loadDiversityPEResource = () => _requestAPI.GET('/diversity_pe/resource/');
export const updateDiversityPEGoal = (payload) => _requestAPI.PATCH(`/diversity_pe/goal/${payload.pk}/`, {...payload, pk:undefined});
export const postDiversityPEGoal = (payload) => _requestAPI.POST(`/diversity_pe/goal/`, payload);
export const deleteDiversityPEGoal = (payload) => _requestAPI.DELETE(`/diversity_pe/goal/${payload.pk}/`);

// Diversity VC
export const loadDiversityVCPdf = queryString => _requestAPI.GET(`/diversity_vc/get_pdf/?pdfKey=${queryString}`);
export const loadDiversityVCCompanyPolicy = () => _requestAPI.GET('/diversity_vc/company_policy/');
export const loadDiversityVCPolicy = () => _requestAPI.GET('/diversity_vc/policy/');
export const loadDiversityVCScore = () => _requestAPI.GET('/diversity_vc/score/');
export const loadDiversityVCBenchmark = () => _requestAPI.GET('/diversity_vc/benchmark/');
export const loadDiversityVCGoal = () => _requestAPI.GET('/diversity_vc/goal/');
export const loadDiversityVCResource = () => _requestAPI.GET('/diversity_vc/resource/');
export const updateDiversityVCGoal = (payload) => _requestAPI.PATCH(`/diversity_vc/goal/${payload.pk}/`, {...payload, pk:undefined});
export const postDiversityVCGoal = (payload) => _requestAPI.POST(`/diversity_vc/goal/`, payload);
export const deleteDiversityVCGoal = (payload) => _requestAPI.DELETE(`/diversity_vc/goal/${payload.pk}/`);

// Survey Automation
export const saveSurveyInfo = payload => _requestAPI.POST('/titan/survey/create/', payload);
export const loadCoreSurveys = queryString => _requestAPI.GET(`/titan/survey/core-questions/?${queryString}`);
export const saveSurveys = payload => _requestAPI.POST(`/titan/survey/save-surveys/`, payload);
export const editSurveys = payload => _requestAPI.POST(`/titan/survey/edit-surveys/`, payload);
export const draftSurveys = payload => _requestAPI.POST(`/titan/survey/draft-surveys/`, payload);
export const loadAllSurveys = payload => _requestAPI.GET(`/titan/survey/get-all-surveys/`);
export const refreshResponseCount = (payload) => _requestAPI.GET(`/titan/survey/get-response-count/${payload.surveyinfo_id}/`);
export const resetSurveyInfo = (surveyinfo_id) => _requestAPI.POST(`/titan/survey/cancel-surveys/${surveyinfo_id}/`);

// ILN Dashboard
export const loadILNinitiatives = () => _requestAPI.GET(`/ilndashboard/initiative/`)
export const loadILNmembers = () => _requestAPI.GET(`/ilndashboard/users/`)
export const loadILNmilestones = () => _requestAPI.GET(`/ilndashboard/milestone/`)
export const loadILNinvestmentRegions = () => _requestAPI.GET(`/ilndashboard/investment-region/`)
export const loadILNAUMBreakdown = () => _requestAPI.GET(`/ilndashboard/aum-breakdown/`)
export const loadILNnews = () => _requestAPI.GET(`/ilndashboard/iln-news/`)
export const loadILNCalendar = () => _requestAPI.GET(`/ilndashboard/iln-calendar/`)
