import { ActionTypes } from '../actions';

const defaultDiversitype = {
    score: {},
    benchmark: {},
    goals: [],
    pdf_url: {},
};


export default (diversitype= defaultDiversitype, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_DIVERSITYPE_PDF:{
                const pdf_url = {...diversitype.pdf_url, ...action.payload};
                return {
                    ...diversitype,
                    pdf_url
                };
            }
            case ActionTypes.CLEAR_DIVERSITYPE_PDF:{
                const pdf_url = {};
                return {
                    ...diversitype,
                    pdf_url
                };
            }
            case ActionTypes.LOADED_DIVERSITYPE_COMPANY_POLICY:{
                const company_policy = {...diversitype.company_policy, ...action.payload};
                return {
                    ...diversitype,
                    company_policy
                };
            }
            case ActionTypes.LOADED_DIVERSITYPE_POLICY:{
                const policy = {...diversitype.policy, ...action.payload};
                return {
                    ...diversitype,
                    policy
                };
            }
            case ActionTypes.LOADED_DIVERSITYPE_SCORE:{
                const score = {...diversitype.score, ...action.payload};
                return {
                    ...diversitype,
                    score
                };
            }
            case ActionTypes.LOADED_DIVERSITYPE_BENCHMARK:{
                const benchmark = {...diversitype.benchmark, ...action.payload};
                return {
                    ...diversitype,
                    benchmark
                };
            }
            case ActionTypes.LOADED_DIVERSITYPE_RESOURCE:{
                const resource = action.payload;
                return Object.assign({},diversitype,{resource})
            }
            case ActionTypes.FAILED_LOADING_DIVERSITYPE_RESOURCE:{
                const resource = [];
                return Object.assign({},diversitype,{resource})
            }
            case ActionTypes.LOADED_DIVERSITYPE_GOAL: {
                const goals = action.payload;
                return Object.assign({},diversitype,{goals})
            }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultDiversitype
                }
            default:
                return diversitype;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
        return diversitype
    }
};
