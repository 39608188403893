import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';

class ChooseDashboard extends Component {

    state = {
        dashboardPicked: false
    }

    handleCompanyClick = async (company) => {
        await this.props.doActiveDashboard(company.id)
    }

    render() {
        const { 
            company_permission: permissions = null,
            companies: companyList = null
         } = this.props.companyInformation;
        const dashboards = companyList

        if(this.props.isAuthenticated && permissions && this.props.choseCompany) {
            if(permissions.iln_dashboard_home) return <Redirect to="iln-dashboard/home" />
            if(permissions.diversity_pe) return <Redirect to="diversity_pe/about" />
            if(permissions.diversity_vc) return <Redirect to="diversity_vc/about" />
            if(permissions.home) return <Redirect to="home" />
            if(permissions.lso) return <Redirect to="overview" />
        }
        return (
            <div className="choose-dashboard-content">
                <h1>Choose Your Dashboard</h1>
                <div className="dashboard-list-container">
                    {dashboards && dashboards.map((company, index) => {
                        const name = company.company
                        return (
                            <Button
                                className="dashboard-button-container"
                                onClick={() => this.handleCompanyClick(company)}
                            >
                                {name}
                            </Button>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth,
        ...state.home,
    };
};

export default connect(mapStateToProps, Actions)(ChooseDashboard);
