import {Actions} from '../../actions';
import {connect} from 'react-redux';
import Goals from '../../components/DiversityPE/NextSteps/Goals';

const mapStateToProps = (state, ownProps) => {
    return {
        diversitype: state.diversitype,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(Goals);
