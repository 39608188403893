import React, { Component } from 'react';
import TargetModal from '../TargetModal';
import Button from '@material-ui/core/Button';
import { Row, Column } from '../../../utils/FlexBox';
import {demographicToName} from "../../../../utils/demographicMapping";
import './index.scss';
import { getOfficeRegionLabel } from '../../../../utils/labels';

class Targets extends Component {
    state = {
        openModal: false,
        isEdit: false
    }

    openModal = () => {
        this.setState({
            openModal: true
        })
    }

    closeModal = () => {
        this.setState({
            openModal: false,
            isEdit: false
        })
    }

    handleCancel = () => {
        this.closeModal();
    }

    handleEdit = () => {
        this.setState({
            isEdit: true
        })

        this.openModal();
    }

    removeTarget = () => {
        this.setState({
            targetChosen: false,
            disable: false
        })
        this.props.handleDelete({
            id: this.props.data.id.toString()
        })
    }
    render() {
        return (
            <div id="add-target-modal">
                <div className="target-chosen">
                    <div className="chosen-header">
                        <h3>{demographicToName(this.props.data.target_demographic)}</h3>
                        <div className="edit-button-container">
                            <Button onClick={() => this.handleEdit(this.props.buttonNumber)}><img src={"images/svg/edit.svg"} alt="edit button" /></Button>
                        </div>
                    </div>
                    <div className="chosen-goal">
                        <div className="goal">
                            <h1>{this.props.data.target_percent}%</h1>
                            <h2>Goal Percentage</h2>
                        </div>
                    </div>
                    <div className="chosen-year-container">
                        <div className="target-goal-container">
                            <h5>{this.props.data.current_percent.toFixed()}%</h5>
                            <h6>Current</h6>
                        </div>
                        <div className="target-goal-container">
                            <h5>{this.props.data.target_year}</h5>
                            <h6>Target Year</h6>
                        </div>
                        <div className="target-goal-container">
                            <img src={this.props.data.on_track ? "images/svg/onTrack.svg" : "images/svg/offTrack.svg"} />
                            {
                                this.props.data.on_track
                                ? <h6>On Track</h6>
                                : <h6>Off Track</h6>
                            }
                        </div>
                    </div>
                    <hr />
                    <Row>
                        <Column width="50%">
                            <div className="target-detail">
                                <h5>{this.props.data.office === '' ? 'All' : this.props.data.office}</h5>
                                <h6>{getOfficeRegionLabel(this.props.companyName)}</h6>
                            </div>
                        </Column>
                        <Column width="50%">
                            <div className="target-detail">
                                <h5>{!this.props.data.level ? 'All' : this.props.data.level}</h5>
                                <h6>Level</h6>
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        <Column width="100%">
                            <div className="target-detail">
                                <h5>{this.props.data.department ? this.props.data.department : 'All' }</h5>
                                <h6>Department</h6>
                            </div>
                        </Column>
                    </Row>
                    <div className="delete-button-container">
                        <Button onClick={() => this.removeTarget()} className="delete-button"><img src="images/svg/delete.svg" alt="garbage icon" /></Button>
                    </div>
                    <TargetModal isModalOpen={this.state.openModal}
                                 closeModal={this.closeModal.bind(this)}
                                 handleCancel={this.handleCancel.bind(this)}
                                 handleUpdate={this.props.handleUpdate}
                                 handleSave={this.props.handleSave}
                                 filters={this.props.filters}
                                 data={this.props.data}
                                 isEdit={this.state.isEdit}
                                 filterMap={this.props.filterMap}
                                 showMessage={this.props.showMessage}
                                 demographicTarget={this.props.demographicTarget} />
                </div>
            </div>
            
        );
    };
};

export default Targets;
