import React from 'react';
import "./index.scss";

const Loader = (props) => {
    const { inline = false } = props;
    return (
        <div className={inline ? 'loader-inline': 'loader-container'}>
            <img src="/images/loading.gif" alt="Loading..."/>
        </div>
    );
};

export default Loader;
