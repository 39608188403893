import React, { Component } from 'react';
import PostCard from '../PostCard';
import _ from 'lodash';
import './index.scss';

class Bookmark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookmark: this.props.bookmark,
            bookmarkToggle: true
        }
    }

    reverseChronologicalSort(list) {
        return _.orderBy(list, ['id'], ['desc']);
    }

    bookmarkID = (id) => {
        this.setState({
            bookmarkedFlag : id,
            bookmarkToggle: !this.state.bookmarkToggle
        })
    }

    render() {
        let sortedState = {};
        let isBookmarked = true;
        if (this.state.bookmark) {
            sortedState = this.reverseChronologicalSort(this.state.bookmark);
            const index = _.findIndex(sortedState, (bookmark) => {
                return bookmark.post.id === this.state.bookmarkedFlag
            })
            
            if (index !== -1) {
                isBookmarked = !this.state.bookmark[index].is_bookmarked;
            }
            return (
                <div id="bookmark">
                    {
                        sortedState.map((post, id) => {
                            if (id === index && !this.state.bookmarkToggle) {
                                return (
                                    <PostCard key={id}
                                              post={post.post}
                                              profileInfo={this.props.profileInfo}
                                              handleEditPost={this.props.handleEditPost} 
                                              handleDeletePost={this.props.handleDeletePost}
                                              handleLikePost={this.props.handleLikePost}
                                              handleUpdateBookmark={this.props.handleUpdateBookmark}
                                              isBookmarked={ isBookmarked }
                                              postType={"bookmark"}
                                              bookmarkID={this.bookmarkID}
                                              bookmarkIndex={this.index}
                                              kpis={this.props.kpis}
                                              specificPost={false}
                                              isSpecificPost={false}
                                    />
                                )
                            } else {
                                return (
                                    <PostCard key={id}
                                              post={post.post}
                                              profileInfo={this.props.profileInfo}
                                              handleEditPost={this.props.handleEditPost} 
                                              handleDeletePost={this.props.handleDeletePost}
                                              handleLikePost={this.props.handleLikePost}
                                              handleUpdateBookmark={this.props.handleUpdateBookmark}
                                              isBookmarked={ 1 }
                                              postType={"bookmark"}
                                              bookmarkID={this.bookmarkID}
                                              kpis={this.props.kpis}
                                              specificPost={false}
                                              isSpecificPost={false}
                                    />
                                )
                            }
                        })
                    }
                </div>
            );
        } else {
            return null;
        }
    }
};

export default Bookmark;