import React from 'react';
import './Discover.scss'

function sort_by_kpi(kpi){
	var page = window.location.href;
	var to_display = "inline";
	if(page.search("#") === -1){
		return to_display;
	}
	var kpi_filter = page.split("#").pop().toUpperCase().replace(/\s/g,'');
	var clean_kpi = kpi.replace(/\s/g,'');
	if(clean_kpi === kpi_filter){
		return to_display;
	}
	to_display = "none";
	return to_display;
}

function kpi_color(kpi) {
	var color;
	switch(kpi){
		case "CULTURE":
			color = "purple";
			break;
		case "NETWORKS":
			color = "gold";
			break;
		case "BIAS":
			color = "darkolivegreen";
			break;
		case "SAFETY":
			color = "orange";
			break;
		case "LEADERSHIP":
			color = "slategray";
			break;
		case "RECRUITING":
			color = "#53A8E2";
			break;
		case "PAY EQUITY":
			color = "lemonchiffon";
			break;
		case "LEADERSHIP & ACCOUNTABILITY":
			color = "plum";
			break;
		default:
			color = "#" + Math.random().toString(16).slice(2, 8).toUpperCase();
	}
	return color;
}


const Questions = props => 

		<ul>
		{props.discover.map((discover, i) => (
			<div key={i + 0.1} className="Discover">
				<li key={i}>
						<div style={{display: sort_by_kpi(discover.KPI)}}className="discoverBlock">
							<div className="discoverTitle">{discover.title}</div>
							<div className="discoverContent">{discover.content}</div>
							<span className="discoverAnswers"> {discover.answers}</span>
							<span className="discoverLine"> | </span>
							<span style={{color: kpi_color(discover.KPI)}} className="discoverTopicKPI">{discover.KPI} </span>
						</div>
					</li>
			</div>
		))}
		</ul>
		
		

    

export default Questions;