import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Actions } from '../actions';
import { connect } from 'react-redux';
import NavBar from '../components/NavBar';
import Loader from "../components/utils/Loader";
import DashboardRoutes from '../dashboardRoutes';
import TopNavBar from "../components/TopNavBar";
import IdleTimer from 'react-idle-timer'
import {decode} from "jsonwebtoken";
import { hotjar } from 'react-hotjar';
import _ from 'underscore';
import './App.scss';
import mixpanel from "mixpanel-browser";

const INACTIVE_EXPIRE_TIME = 15; // minutes
const TOKEN_REFRESH_TIME = 0.5; // minutes

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.state = {
            mixpanelGroupSet: ""
        }
    }

    componentWillMount () {
        if (this.props.isAuthenticated) {
            this.props.getCompanyInformation();
            this.props.getProfile();
            // null check for the jwt token ( if the user made it this far in theory the jwt token should be set)
            if(!_.isEmpty(this.props.accessToken)) {
                const userObj = decode(this.props.accessToken);
                const username = userObj.username;
                const email = userObj.email;
                const user_id = userObj.user_id;
                // mixpanel.identify(user_id);
                // mixpanel.people.set({$username: username, $email: email, user_id: user_id});

                // email null check
                if(!_.isEmpty(email)) {
                    mixpanel.track("View Page", {
                        name: "Dashboard",
                    });
                    // check to see if the users email includes @diversio.com so we can filter out internal employee traffic
                    // or if the username contains "diversio_"
                    if (!email.includes("@diversio.com") || !username.includes("diversio_")){
                        // Initialize Google Analytics & Hotjar
                        hotjar.initialize(process.env.REACT_APP_HJID, process.env.HJSV);
                    }
                }
            }
            window.addEventListener('storage', e => {
                if (e.key === 'auth_access_token' && e.oldValue && !e.newValue) {
                    this.props.doSignout();
                }
            })
        }
    }

    handleOnAction () {
        const { accessToken, refreshToken } = this.props;
        this.props.doRefreshToken({ accessToken, refreshToken });
    }

    handleOnIdle () {
        this.props.doSignout();
    }

    makeRedirect = (authCheck, permissions) => {

        const makeRedirectString = (authCheck, permissions) => {
            const {
                homeAuthCheck,
                ilnAuthCheck,
                diversityPeAuthCheck,
                diversityVcAuthCheck
            } = authCheck;

            if (homeAuthCheck) {
                if(permissions.iln_dashboard_home) {
                    return "/iln-dashboard/home";
                }
                if(permissions.diversity_vc) {
                    return "/diversity_vc/about";
                }
                if(permissions.diversity_pe) {
                    return "/diversity_pe/about";
                }
                if(permissions.lso) {
                    return "/overview"
                }
            }

            if (ilnAuthCheck) {
                if(permissions.home) {
                    return "/home"; }
                if(permissions.diversity_vc) {
                    return "/diversity_vc/about"; }
                if(permissions.diversity_pe) {
                    return "/diversity_pe/about"; }
            }

            if (diversityVcAuthCheck) {
                if(permissions.home) {
                    return "/home"; }
                if(permissions.iln_dashboard_home) {
                    return "/iln-dashboard/home"; }
                if(permissions.diversity_pe) {
                    return "/diversity_pe/about"; }
            }

            if (diversityPeAuthCheck) {
                if(permissions.home) {
                    return "/home"; }
                if(permissions.iln_dashboard_home) {
                    return "/iln-dashboard/home"; }
                if(permissions.diversity_vc) {
                    return "/diversity_vc/about"; }
            }

            return null;
        }

        const redirectString = makeRedirectString(authCheck, permissions);

        if (redirectString) {
            return <Redirect to={redirectString} />
        } else {
            return null;
        }
    }

    render() {
        const { company_permission: permissions = null } = this.props.companyInformation;
        const { app: { selectedLanguage }, handleLanguageChange } = this.props;
        if (!this.props.isAuthenticated) {
            return <Redirect to={"/login"}></Redirect>
        }

        if (!permissions) {
            return <Loader />;
        }

        const dashboardSwitch = this.props.history.location.pathname === '/choose-dashboard'
        const url = this.props.history.location.pathname;

        // By adding the trailing slash '/' to each auth check, it will redirect to the base home page for a specific dashboard
        const translatedPages = [
            '/',
            '/home',
            '/analyze/diversity',
            '/analyze/inclusion'
        ];

        const homeAuthUrls = [
            '/',
            '/home',
            '/analyze/diversity',
            '/analyze/inclusion',
            '/improve',
            '/connect',
            '/connect/all-post',
            '/connect/my-post',
            '/connect/bookmark'
        ]

        const ilnAuthUrls = [
            '/',
            '/iln-dashboard/home',
            '/iln-dashboard/impact'
        ]

        const diversityVcAuthUrls = [
            '/',
            '/diversity_vc/about',
            '/diversity_vc/overview',
            '/diversity_vc/resources'
        ]

        const diversityPeAuthUrls = [
            '/',
            '/diversity_pe/about',
            '/diversity_pe/overview',
            '/diversity_pe/resources'
        ]

        const homeAuthCheck = homeAuthUrls.indexOf(url) > -1 && !permissions.home
        const ilnAuthCheck = ilnAuthUrls.indexOf(url) > -1 && !permissions.iln_dashboard_home
        const diversityVcAuthCheck = diversityVcAuthUrls.indexOf(url) > -1 && !permissions.diversity_vc
        const diversityPeAuthCheck = diversityPeAuthUrls.indexOf(url) > -1 && !permissions.diversity_pe

        const authCheck = {
            homeAuthCheck,
            ilnAuthCheck,
            diversityVcAuthCheck,
            diversityPeAuthCheck
        };

        const inChooseDashboard = (url === '/choose-dashboard')

        const possibleRedirect = this.makeRedirect(authCheck, permissions);
        if (possibleRedirect) {
            return possibleRedirect;
        }

        const companyName = _.maybe(this.props, "companyInformation", "name")
        if(this.state.mixpanelGroupSet !== companyName){
            mixpanel.add_group("company", companyName);
            this.setState({
               mixpanelGroupSet: companyName
            });
        }

        return (
            <div className="navBar">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * INACTIVE_EXPIRE_TIME}
                    onIdle={this.handleOnIdle.bind(this)}
                    onAction={this.handleOnAction.bind(this)}
                    throttle={1000 * 60 * TOKEN_REFRESH_TIME}
                />
                {!inChooseDashboard &&
                    <TopNavBar
                        deploySurvey={this.props.doDeploySurvey}
                        isDeploySurveyProcessing={this.props.isDeploySurveyProcessing}
                        isDeploySurveyProcessed={this.props.isDeploySurveyProcessed}
                        selectedLanguage={selectedLanguage}
                        handleLanguageChange={handleLanguageChange}
                        showLanguages={translatedPages.includes(url)}
                        isILN={permissions.iln_dashboard_home}
                    />
                }
                {!dashboardSwitch &&
                    <NavBar { ...this.props } />
                }
                <DashboardRoutes
                    permissions={permissions}
                    url={url}
                    companyInformation={this.props.companyInformation}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.auth,
        ...state.home,
        ...state.ilndashboard,
        profile: state.profile,
        auth: state.auth,
        app: state.app
    }
};

export default connect(mapStateToProps, Actions)(Dashboard);
