import React from 'react';
import './index.scss';
import { getIndustryCompType } from '../../../../utils';
import { withTranslation } from 'react-i18next';

const Legend = ({ companyName, t }) => {
    const compType = getIndustryCompType(companyName);

    return (
        <div className="legend">
            <div className="legend__company">{companyName}</div>
            <div className="legend__industry-average">
                {/* {`${t(compType)} ${t("AVERAGE")}`} */}
                Portfolio Average
            </div>
            <div className="legend__na">{t("HOME.DIVERSITY_COMPOSITION.min_thresh_not_met")}</div>
        </div>
    );
}

export default withTranslation()(Legend);
