import { ActionTypes } from '../../actions';
import plans from './solutions';
import _ from 'underscore';

const defaultState = {
    isSurveyCompleted: true,
    plans,
    recommendations: {},
    action_plans: {},
    topRecommendations: []
};


export default (state = defaultState, action) => {
    try {
        let _clonedRecommendations;
        let data;
        switch(action.type) {
            case ActionTypes.MARK_SOLUTION_SELECTED:
                _clonedRecommendations = {
                    ...state.recommendations
                };

                Object.keys(_clonedRecommendations).map(kpi => {
                    for (var i=0; i<_clonedRecommendations[kpi].length; i++) {
                        if (action.payload.recommendationId === _clonedRecommendations[kpi][i].id) {
                            _clonedRecommendations[kpi][i]["selected"] = true
                        }
                    }

                    return null;
                });

                return {
                    ...state,
                    recommendations: _clonedRecommendations
                };

            case ActionTypes.UNMARK_SOLUTION_SELECTED:
                _clonedRecommendations = {
                    ...state.recommendations
                };

                Object.keys(_clonedRecommendations).map(kpi => {
                    for (var i=0; i<_clonedRecommendations[kpi].length; i++) {
                        if (action.payload.recommendationId === _clonedRecommendations[kpi][i].id) {
                            _clonedRecommendations[kpi][i]["selected"] = false
                        }
                    }

                    return null;
                });

                return {
                    ...state,
                    recommendations: _clonedRecommendations
                };

            case ActionTypes.LOADING_ACTION_PLANS:
            case ActionTypes.LOADING_RECOMMENDATIONS:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.LOADED_RECOMMENDATIONS:
                let { top, ...remaining } = action.payload.recommendations;

                _clonedRecommendations = {
                    ...remaining
                };

                const topRecommendations = _.values(top);

                Object.keys(action.payload.actionPlans).map(kpi => {
                    const selectedRecommendations = action.payload.actionPlans[kpi].reduce((SR, recommendation) => {
                        SR[recommendation.recommendation_id] = recommendation.id;
                        return SR;
                    }, {});

                    for (var i=0; i<_clonedRecommendations[kpi].length; i++) {
                        if (Object.keys(selectedRecommendations).indexOf(_clonedRecommendations[kpi][i].id.toString()) > -1){
                            _clonedRecommendations[kpi][i]["selected"] = true;
                            _clonedRecommendations[kpi][i]["actionPlanId"] = selectedRecommendations[_clonedRecommendations[kpi][i].id];
                        }
                    }

                    return null;
                });


                return {
                    ...state,
                    isLoading: false,
                    recommendations: _clonedRecommendations,
                    actionPlans: action.payload.actionPlans,
                    topRecommendations
                };

            case ActionTypes.DONE_CREATE_ACTION_PLAN:
                data = action.payload.data;
                _clonedRecommendations = {
                    ...state.recommendations
                };

                Object.keys(_clonedRecommendations).forEach(kpi => {
                    for (var i=0; i<_clonedRecommendations[kpi].length; i++) {
                        if (_clonedRecommendations[kpi][i].id === data.recommendation_id) {
                            _clonedRecommendations[kpi][i].actionPlanId = data.id;
                        }
                    }
                });

                return {
                    ...state,
                    recommendations: _clonedRecommendations
                };

            case ActionTypes.DONE_DELETE_ACTION_PLAN:
                _clonedRecommendations = {
                    ...state.recommendations
                };

                Object.keys(_clonedRecommendations).forEach(kpi => {
                    for (var i=0; i<_clonedRecommendations[kpi].length; i++) {
                        if (_clonedRecommendations[kpi][i].id === action.payload.recommendationId) {
                            delete _clonedRecommendations[kpi][i].actionPlanId;
                        }
                    }
                });

                return {
                    ...state,
                    recommendations: _clonedRecommendations
                };

            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }
            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in actionPlans reducer: ${ error.message || error.code || error }`, error);
    }

};
