import {ActionTypes} from '../actions';

const defaultProfile = {
};

export default (profile= defaultProfile, action) => {
    try{
        switch(action.type) {
            case ActionTypes.LOADED_PROFILE:
                return Object.assign({}, profile, action.payload);
            case ActionTypes.DONE_UPDATE_PROFILE:
                return Object.assign({}, profile, action.payload);
            case ActionTypes.LOADED_COMPANY_INFORMATION:
                return {
                    ...profile,
                    company: action.payload.name
                };
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultProfile
                };
            default:
                return profile;

        }
    } catch (e){
        console.warn("Error in profile reducer");
        console.warn(e);
        return profile
    }
};
