import React, { Component } from 'react';
// import CreatePostModal from '../../CreatePostModal';
import CreatePostModal from "../../../../containers/CreatePostModal";
import './index.scss';

class CreatePost extends Component {
    state = {
        isModalOpen: false
    }

    handleOpenModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false
        });
    }

    handlePost = (post) => {
        this.props.handleCreatePost(post);
    }

    render() {
        if (this.props.profileName) {
            return (
                <div id="create-post">
                    <button className="create-post-button" onClick={(() => this.handleOpenModal())}>
                        <h3 className="header">Create Post</h3>
                        <p className="description">What's on your mind?</p>
                    </button>
                    <CreatePostModal isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} handlePost={this.handlePost} kpis={this.props.kpis}/>
                </div>
            );
        } else {
            return (
                <div id="create-post">
                    <button className="create-post-button">
                        <h3 className="header">Enter your profile information to create a post.</h3>
                        <p className="description">You can update your profile on the top right corner.</p>
                    </button>
                </div>
            );
        }
        
    };
};

export default CreatePost;