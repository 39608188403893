import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import Discussion from '../components/Connect/Discussion';
import TopBar from '../components/TopBar/TopBar';
import './App.scss';

class DiscussionContainer extends Component {
    render() {
        return (
            <div>
                <div className="Header"> Discussion </div>
                <div>
                    <TopBar />
                </div>

                <div>
                    <Discussion />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(DiscussionContainer);
