import { ActionTypes } from '../actions';
import _ from 'underscore'

const defaultState = {
    currentSelected: "init",
    clonedSurveys: [],
    coreSurveys: [],
    savedSurveys: [],
    surveyErrors: {},
    isLoaded: false,
    isSurveySaved: true
};

export default (state = defaultState, action) => {
    let savedSurveys;
    try {
        switch(action.type) {
            case 'TEST':
                return defaultState;

            case ActionTypes.RESET_SURVEY_INFO:
                return {
                    ...defaultState
                };

            case ActionTypes.DOING_SAVE_SURVEY_INFO:
            case ActionTypes.DOING_EDIT_SURVEY_INFO:
            case ActionTypes.DOING_RESET_SURVEY_INFO:
                return {
                    ...state,
                    surveyErrors: {}
                };

            case ActionTypes.DONE_EDIT_SURVEY_INFO:
            case ActionTypes.DONE_RESET_SURVEY_INFO:
                savedSurveys = [
                    ...state.savedSurveys
                ];

                savedSurveys = savedSurveys.map(survey => {
                    if(survey.id === action.payload.id) {
                        return {
                            ...action.payload,
                        }
                    } else {
                        return survey
                    }
                }).filter(survey => !["CAN", "CLS"].includes(survey.status) );

                return {
                    ...state,
                    currentSelected: savedSurveys.length ? "create" : "init",
                    savedSurveys: {
                        ...savedSurveys
                    },
                    selectedSurvey: action.payload
                };

            case ActionTypes.DONE_SAVE_SURVEY_INFO:
                return {
                    ...state,
                    currentSelected: "create",
                    selectedSurvey: action.payload,
                    savedSurveys: [
                        { ...action.payload },
                        ...state.savedSurveys
                    ]
                }

            case ActionTypes.LOADING_CORE_SURVEYS:
                return {
                    ...state
                };

            case ActionTypes.LOADED_CORE_SURVEYS:
                return {
                    ...state,
                    coreSurveys: [ ...action.payload ],
                    selectedSurvey: {
                        ...state.selectedSurvey,
                        surveys: [ ...action.payload ]
                    }
                };

            case ActionTypes.UPDATE_SURVEY_INTRO:
                return {
                    ...state,
                    selectedSurvey: {
                        ...state.selectedSurvey,
                        surveys: state.selectedSurvey.surveys.map(survey => {
                            if (survey.id == action.payload.surveyId) {
                                survey.intro = action.payload.intro
                            }
                            return survey;
                        })
                    }
                };

            case ActionTypes.UPDATE_SURVEY_QUESTIONS:
                const question = {...action.payload};

                return {
                    ...state,
                    selectedSurvey: {
                        ...state.selectedSurvey,
                        surveys: state.selectedSurvey.surveys.map(survey => {
                            if (survey.id == question.survey) {
                                survey.questions = survey.questions.map(q => {
                                    return question.id == q.id ? { ...question } : q;
                                })
                            }
                            return survey;
                        })
                    },
                    isSurveySaved: false
                };
            case ActionTypes.DOING_EDIT_SURVEYS:
            case ActionTypes.DOING_SAVE_SURVEYS:
                return {
                    ...state,
                    isSaving: true,
                    surveyErrors: {}
                };

            case ActionTypes.DOING_REFRESH_RESPONSE_COUNT:
                return {
                    ...state,
                    isLoadingRefreshCount: true,
                };

            case ActionTypes.FAILED_EDIT_SURVEYS:
            case ActionTypes.FAILED_SAVE_SURVEYS:
                return {
                    ...state,
                    surveyErrors: action.payload,
                    isSaving: false
                };

            case ActionTypes.DONE_REFRESH_RESPONSE_COUNT:
                return {
                    ...state,
                    isLoadingRefreshCount: false,
                    savedSurveys: state.savedSurveys.map(survey => {
                        if (survey.id === _.maybe(action, "payload", "surveyinfo_id")) {
                            survey.total_responses = _.maybe(action, "payload", "count");
                            survey.last_response_checked = _.maybe(action, "payload", "last_response_checked");
                        }

                        return survey;
                    })
                };

            case ActionTypes.FAILED_REFRESH_RESPONSE_COUNT:
                return {
                    ...state,
                    isLoadingRefreshCount: false
                };

            case ActionTypes.DONE_EDIT_SURVEYS:
            case ActionTypes.DONE_SAVE_SURVEYS:
                savedSurveys = [
                    ...state.savedSurveys
                ];

                savedSurveys = savedSurveys.map(survey => {
                    if(survey.id === action.payload.id) {
                        return {
                            ...action.payload,
                        }
                    } else {
                        return survey
                    }
                }).filter(survey => !["CAN", "CLS"].includes(survey.status) );

                return {
                    ...state,
                    savedSurveys: [
                        ...savedSurveys
                    ],
                    clonedSurveys: [
                        ...savedSurveys
                    ],
                    currentSelected: action.payload.status === "DRF" ? "draft" : "review",
                    isSurveySaved: true,
                    isSaving: false
                };

            case ActionTypes.LOADED_ALL_SURVEYS:
                return {
                    ...state,
                    savedSurveys: [ ...action.payload.filter(survey => !["CAN", "CLS", "SET"].includes(survey.status)) ],
                    previousSurveyExists: !!action.payload.length,
                    isLoaded: true
                };

            case ActionTypes.SET_SELECTED_SURVEY:
                return {
                    ...state,
                    selectedSurvey: action.payload
                };

            case ActionTypes.HANDLE_SURVEY_RESET:
                return {
                    ...state,
                    selectedSurvey: {},
                    currentSelected: "init"
                };

            case ActionTypes.SET_CURRENT_SELECTED_SURVEY_PAGE:
                return {
                    ...state,
                    currentSelected: action.payload
                };
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                };


            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in surveyState reducer: ${ error.message || error.code || error }`, error);
    }
};
