import React, {useEffect} from 'react';
import classNames from "classnames";
import './index.scss';

const Rating = ({ question, company, handleChange }) => {
    const { title, required, type, placeholder, choices } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);
    return (
        <div className={classNames("question", "rating-question", { "required": required })}>
            <p className={"question-title"}>{question.qnum}. {parsedTitle}<span className={"asterisk"}>*</span></p>
            <div className={"rating-question__rate-container"}>
                <p>No, not at all</p>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <img className={"icon icon-star"}/>
                <p>Yes, definitely</p>
            </div>
        </div>
    );
};

export default Rating;
