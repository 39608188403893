import React, { Component } from 'react';
import { ordinal } from '../../../../utils/ordinal';
import './index.scss';

class IndexRanking extends Component {
    render() {
        return (
            <div id="index-ranking-container">
                <div className="title">Index Ranking  (/{this.props.data.rank_out_of} legal workplaces)</div>
                <div className="score"><span className="highlight">{this.props.data.overall_rank}<span className="ordinal">{ordinal(this.props.data.overall_rank)}</span></span></div>
                <div className="breakdown-container">
                    <div className="breakdown">
                        <div className="breakdown-title">Diversity</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.diversity_rank}<span className="ordinal">{ordinal(this.props.data.diversity_rank)}</span></span></div>
                    </div>
                    <div className="breakdown">
                        <div className="breakdown-title">Inclusion</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.inclusion_rank}<span className="ordinal">{ordinal(this.props.data.inclusion_rank)}</span></span></div>
                    </div>
                    <div className="breakdown">
                        <div className="breakdown-title">Commitment</div>
                        <div className="score"><span className="breakdown-highlight">{this.props.data.commitment_rank}<span className="ordinal">{ordinal(this.props.data.commitment_rank)}</span></span></div>
                    </div>
                </div>
            </div>
        );
    };
};

export default IndexRanking;