import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import UpgradeModal from './UpgradeModal';
import CertifiedModal from './CertifiedModal';
import ConfirmationModal from '../../utils/RequestSent';
import './index.scss';

class Header extends Component {
    constructor() {
        super();
        this.state = {
            openUpgradeModal: false,
            openCertifiedModal: false,
            openConfirmationModal: false
        }
    }

    openUpgradeModal = () => {
        this.setState({
            openUpgradeModal: true
        })
    };

    handleCancelUpgradeModal = () => {
        this.setState({
            openUpgradeModal: false
        })
    };

    handleSubmitUpgradeModal = (data) => {
        const features = Object.keys(data).filter(x=>x!=='comment' && data[x]).join(', ');
        const comment = data.comment || '';
        const context = "Upgrade Dashboard";
        const detail = `User would like to upgrade their dashboard. Requested features: ${features}. Comment: ${comment}`;
        this.props.sendSlackPing(context, detail).then(()=>{
            this.setState({
                openUpgradeModal: false,
                openConfirmationModal: true
            })
        }, reason => {
            this.setState({openUpgradeModal:false, openConfirmationModal:false});
        });
    };

    openCertifiedModal = () => {
        this.setState({
            openCertifiedModal: true
        })
    };

    handleCancelCertifiedModal = () => {
        this.setState({
            openCertifiedModal: false
        })
    };

    handleSubmitCertifiedModal = (data) => {
        const dataStr = Object.keys(data).map(x=>`${x}: ${data[x]}`).join("\n");
        const context = "Certification Request";
        const detail = `User would like to request certification. \n${dataStr}`;
        this.props.sendSlackPing(context, detail).then(()=>{
            this.setState({
                openCertifiedModal: false,
                openConfirmationModal: true
            });
        }, reason => {
            this.setState({
                openCertifiedModal: false,
                openConfirmationModal: false
            });
        });
    };

    handleCloseConfirmationModal = () => {
        this.setState({
            openConfirmationModal: false
        })
    };

    render() {
        return (
            <div id="LSO-header">
                <div className="title">Your Inclusion & Diversity Dashboard</div>

                <div className="header-container">
                    <div className="insight-container">
                        <div className="dashboard-container">
                            <div className="subtitle">How to Use This Dashboard</div>
                            <div className="description">This dashboard provides insights and guidance to increasing workplace diversity and improving experiences of  underrepresented employees. Your Top Recommendations were identified using Diversio's advanced analytics and matching algorithms. Your Inclusion Scorecard is designed to be tracked over time</div>
                        </div>
                        <div className="unlock-container">
                            <div className="subtitle">Unlock Additional Insights</div>
                            <div className="unlock">
                                <img src="/images/LSO/Header/unlock.png" alt="unlock insights" />
                                <div className="description-container">
                                    <div className="description">Opt in for features like advanced analytics, custom recommendations, employee insights, and social media sentiment tracking</div>
                                    <Button className="start-button" onClick={this.openUpgradeModal}>Start Now</Button>
                                    <UpgradeModal isOpen={this.state.openUpgradeModal}
                                                  handleCancel={this.handleCancelUpgradeModal}
                                                  handleSubmit={this.handleSubmitUpgradeModal.bind(this)} />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="certification-container">
                        <div className="subtitle">Show Your Commitment by Getting Certified</div>
                        <div className="description">Diversio Certification is a trusted seal recognized by industry associations, corporations, regulators and government. Certification signals your performance on diversity as well as your commitment to continued improvement</div>
                        <Button className="certified-button" onClick={this.openCertifiedModal}>Get Certified</Button>
                        <CertifiedModal isOpen={this.state.openCertifiedModal}
                                        handleCancel={this.handleCancelCertifiedModal}
                                        handleSubmit={this.handleSubmitCertifiedModal} />
                        <ConfirmationModal isOpen={this.state.openConfirmationModal}
                                           handleCancel={this.handleCloseConfirmationModal} />
                        <div className="certificate-container">
                            <img src="/images/LSO/Header/cert_2019.png" alt="Diversio Certificate" />
                            <div className="certificate">
                                <div className="description">Learn more about Diversio Certification tiers to understand your ranking and set future goals</div>
                                <a className="view-button" href="https://diversio-media-prod.s3.ca-central-1.amazonaws.com/DiversioCertification.pdf" target="_blank" rel="noopener noreferrer">View PDF</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default Header;
