import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './index.scss';

class Activity extends Component {
    render () {
        const path = window.location.pathname;

        if (path === '/connect/all-post' || path === '/connect/my-post' || path === '/connect/bookmark' || path === '/connect/post-id') {
            return (
                <div id="activity">
                    <div className="header">
                        <img src="/images/svg/Connect/activity.svg" alt="activity icon" className="activity-icon" />
                        <h3>Activity</h3>
                    </div>
                    <ul className="activity-list">
                        <li className={ classNames(path === '/connect/all-post' && "selected highlight") }><Link to="/connect/all-post">All Posts</Link></li>
                        <li className={ classNames(path === '/connect/my-post' && "selected highlight") }><Link to="/connect/my-post">My Posts</Link></li>
                        <li className={ classNames(path === '/connect/bookmark' && "selected highlight") }><Link to="/connect/bookmark">Bookmarked</Link></li>
                        {/*<li className={ classNames(path === '/connect/resources' && "selected highlight")}><Link to="/connect/resources">Resources</Link> </li>*/}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    };
};

export default Activity;
