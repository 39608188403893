import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {
    loadInclusionBreakdownData,
    loadDiversityBreakdownData,
    loadInclusionMetricsData,
    loadNonOverviewMetricsData,
    loadEmployeeOverviewData,
    loadCompanyFilters,
    loadInclusionTrackerData,
    loadDiversityTrackerData,
    loadDiversityBreakdownByRoleData,
    loadFreeText,
    loadFilterMap
} from './network';
import {stringifyQueryParams} from "../utils";

// All data sagas to add to middleware.
export default [
    [ ActionTypes.GET_INCLUSION_BREAKDOWN_DATA, getInclusionBreakdownData ],
    [ ActionTypes.GET_DIVERSITY_BREAKDOWN_DATA, getDiversityBreakdownData ],
    [ ActionTypes.GET_INCLUSION_METRICS_DATA, getInclusionMetricsData ],
    [ ActionTypes.GET_NON_OVERVIEW_METRICS_DATA, getNonOverviewMetricsData ],
    [ ActionTypes.GET_EMPLOYEE_OVERVIEW_DATA, getEmployeeOverviewData ],
    [ ActionTypes.GET_COMPANY_FILTERS, getCompanyFilters ],
    [ ActionTypes.GET_INCLUSION_TRACKER_DATA, getInclusionTrackerData ],
    [ ActionTypes.GET_DIVERSITY_TRACKER_DATA, getDiversityTrackerData ],
    [ ActionTypes.GET_DIVERSITY_BREAKDOWN_BY_ROLE_DATA, getDiversityBreakdownByRoleData ],
    [ ActionTypes.GET_FREE_TEXT, getFreeText ],
    [ ActionTypes.GET_FILTER_MAP, getFilterMap]
];

function * getInclusionBreakdownData ({ payload }) {
    yield put(Actions.loadingInclusionBreakdownData());
    const response = yield call(loadInclusionBreakdownData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        const data = {inclusion_breakdown:{
                data:response.data.inclusion_breakdown,
                metadata: payload.data
            }};
        yield put(Actions.loadedInclusionBreakdownData(data));
    }
}

function * getDiversityBreakdownData ({payload}) {
    yield put(Actions.loadingDiversityBreakdownData());

    const response = yield call(loadDiversityBreakdownData, stringifyQueryParams(payload.data));
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityBreakdownData(response.data));
    }
}

function * getInclusionMetricsData ({ payload }) {
    yield put(Actions.loadingInclusionMetricsData());

    const response = yield call(loadInclusionMetricsData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionMetricsData(response.data));
    }
}

function * getNonOverviewMetricsData ({ payload }) {
    yield put(Actions.loadingNonOverviewMetricsData());

    const response = yield call(loadNonOverviewMetricsData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedNonOverviewMetricsData(response.data));
    }
}

function * getEmployeeOverviewData () {

    yield put(Actions.loadingEmployeeOverviewData());

    const response = yield call(loadEmployeeOverviewData);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedEmployeeOverviewData(response.data));
    }
}

function * getCompanyFilters () {
    yield put(Actions.loadingCompanyFilters());

    const response = yield call(loadCompanyFilters);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedCompanyFilters(response.data));
    }
}

function * getInclusionTrackerData ({ payload }) {
    yield put(Actions.loadingInclusionTrackerData());

    const response = yield call(loadInclusionTrackerData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedInclusionTrackerData(response.data));
    }
}
function * getDiversityTrackerData ({ payload }) {
    yield put(Actions.loadingDiversityTrackerData());

    const response = yield call(loadDiversityTrackerData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityTrackerData(response.data));
    }
}

function * getDiversityBreakdownByRoleData ({ payload }) {
    yield put(Actions.loadingDiversityBreakdownByRoleData());

    const response = yield call(loadDiversityBreakdownByRoleData, stringifyQueryParams(payload.data));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedDiversityBreakdownByRoleData(response.data));
    }
}

function * getFreeText () {
    yield put(Actions.loadingFreeText());

    const response = yield call(loadFreeText);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedFreeText(response.data));
    }
}

function * getFilterMap () {
    yield put(Actions.loadingFilterMap());

    const response = yield call(loadFilterMap);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedFilterMap(response.data));
    }
}
