import React, { useRef, useState } from 'react';
import {Navbar, Nav, Dropdown, Popover, OverlayTrigger, Button, Form} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import './index.scss';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import i18next from "i18next";

const TopNavBar = ({ t, deploySurvey, isDeploySurveyProcessing, isDeploySurveyProcessed, selectedLanguage, handleLanguageChange, showLanguages, isILN }) => {
    const deploySurveyRef = useRef(null);
    const [ showDeploySurvey, setShowDeploySurvey ] = useState(false);
    const popover = (
        <Popover id="popover-deploy-survey">
            <Popover.Title as="h3">{t("TOPNAVBAR.DEPLOY_SURVEY")}</Popover.Title>
            {
                isDeploySurveyProcessed
                    ? <Popover.Content>
                        {t("TOPNAVBAR.DEPLOY_SURVEY_SUBMITTED")}
                    </Popover.Content>
                    : <Popover.Content>
                        {t("TOPNAVBAR.DEPLOY_SURVEY_REQUEST")}
                        <Form.Group>
                            <Link onClick={() => setShowDeploySurvey(false)}>{t("TOPNAVBAR.CANCEL")}</Link>
                            <Button size={"sm"} onClick={deploySurvey} disabled={isDeploySurveyProcessing}>{t("TOPNAVBAR.AGREE")}</Button>
                        </Form.Group>
                    </Popover.Content>
            }
        </Popover>
    );
    return (
        <div className={"bootstrap-override"} id={"topbar-container"}>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to={"/home"}>
                    <img className="icon icon-diversio-logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    {
                        showLanguages
                            ? <FormControl>
                                <Select
                                    value={selectedLanguage}
                                    onChange={e => {
                                        i18next.changeLanguage(e.target.value);
                                        handleLanguageChange(e.target.value)
                                    }}
                                >
                                    <MenuItem value={"en"}>English</MenuItem>
                                    <MenuItem value={"de"}>German</MenuItem>
                                </Select>
                            </FormControl>
                            : null
                    }

                    {
                        isILN
                        && <Nav ref={deploySurveyRef}>
                            <OverlayTrigger show={showDeploySurvey} rootClose trigger="click" placement="bottom" overlay={popover} container={deploySurveyRef.current}>
                                <Navbar.Brand as={Link}>
                                    <img className="icon-iln-download"/>
                                </Navbar.Brand>
                            </OverlayTrigger>
                        </Nav>
                    }

                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default withTranslation()(TopNavBar);
