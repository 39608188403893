import React from 'react';
import {Column, Row} from "../utils/FlexBox";
import { getSkinnedCompanyName } from "../../containers/Home";
import HeatMap from "./HeatMap";
import InclusionBreakdown from "./InclusionBreakdown";
import CompareSegments from "./CompareSegments";
import InclusionTracker from "./InclusionTracker";
import Feedback from "./Feedback";
import { isMember, HIDE_FREETEXT_COMPANY_NAMES } from "../../utils/companyChecks";
import TimeSeriesGate from "../utils/TimeSeriesGate";
import './index.scss';
import _ from 'underscore';

const Inclusion = (props) => {

    const companyName = getSkinnedCompanyName(
        _.maybe(props, "profile", "company"),
        _.maybe(props, "profile")
        );

    return (
        <div>
            <Row>
                <Column width="100%">
                    <HeatMap data={props.analyze.inclusionMetrics}
                            loadingNonOverviewMetrics={props.analyze.loadingNonOverviewMetrics}
                             prevData={props.analyze.previousInclusionMetrics}
                             overall={props.analyze.overall}
                             previousOverall={props.analyze.previousOverall}
                             companyName={companyName}
                             getInclusionMetricsData={props.getInclusionMetricsData}
                             getNonOverviewMetricsData={props.getNonOverviewMetricsData}
                             allFilters={props.analyze.filters}
                             selected={props.selected}
                             selectTab={props.select}
                             metadata={props.analyze.metadata}
                    />
                </Column>
            </Row>
            <Row>
                <Column grow={1}>
                    <InclusionBreakdown data={props.analyze.inclusionBreakdown}
                                        companyName={companyName}
                                        groups={props.extendedCategories} filters={props.analyze.filters}
                                        handleApplyFilters={data => props.getInclusionBreakdownData({ data })}
                                        colors={props.colors}
                                        showMessage={props.showMessage}/>
                </Column>
            </Row>
            <TimeSeriesGate series={props.analyze.inclusionTracker}>
                <Row>
                <Column grow={1}>
                    <InclusionTracker groups={props.extendedCategories}
                                      metrics={props.analyze.inclusionTracker}
                                      kpimap={props.kpimap}
                                      filters={props.analyze.filters}
                                      handleApplyFilters={data => props.getInclusionTrackerData({ data })}
                                      allFilters={props.allFilters}
                                      companyName={companyName}/>
                    </Column>
                </Row>
            </TimeSeriesGate>
            <Row>
                { isMember(HIDE_FREETEXT_COMPANY_NAMES, props.home.companyInformation.name)
                ? null
                : <Feedback
                    colors={props.colors}
                    freeText={props.analyze.freeText}
                    companyName={companyName}
                    diversityComposition={props.home.diversityComposition}
                    showMessage={props.showMessage}
                    /> }
            </Row>
        </div>
    );
};

export default Inclusion;
