import React, {Component} from 'react';
import './index.scss';
import DeepDive from "./DeepDive";
import FocusAreas from "./FocusAreas";
import slack from './slack.png'
import Summary from "./Summary";
import {getGenderLabel} from "../utils/functions";
import SlackAuthenticate from "./Authenticate";
let requestId = 0;

class Slack extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If just updated/posted slack token, fetch analytics
        if (!prevProps.authentication.slack_token && this.props.authentication.slack_token) {
            this.props.getAnalytics({requestId: requestId++});
        }
    }

    dataHasLoaded() {
        if (this.props.slack_analytics && this.props.slack_analytics.data) {
            const data = this.props.slack_analytics.data;
            return data.features && data.summary && data.features.gender
        }
        return false 
    }

    // Maps M, F, and O to appropriate Men/Women/Non-binary labels
    getMappedGenderData() {
        const data = Object.assign({}, this.props.slack_analytics.data);
        const gender = data.features.gender.map(getGenderLabel);
        data.features.gender = gender;
        return data
    }

    /*
        Reformat this.props.data.features to:
        {
            Men: {
                n_channels: [1,2,3,2],
                ...
            }
            Women:{
                n_channels: [4,2,5,6,2],
                ...
             }
        }
    */
    getGenderFormattedFeatures() {
        // this.props.data, just mapped with correct gender labels
        const inData = this.getMappedGenderData();
        const formatted = {};
        ["Women", "Men", "Unknown"].forEach(g => {
            formatted[g] = Object.keys(inData.features).reduce((acc, v) => {
                acc[v] = [];
                return acc
            }, {})
        });


        for (let feature in inData.features) {
            for (let index in inData.features[feature]) {
                const g = inData.features.gender[index]
                formatted[g][feature].push(inData.features[feature][index])
            }
        }
        return formatted;
    }

    slackIsAuthorized(){
        return Boolean(this.props.authentication.slack_token)
    }

    render() {
        const slackIsAuthorized = this.slackIsAuthorized();
        const hasLoaded = this.dataHasLoaded();
        const genderFeatures = hasLoaded ? this.getGenderFormattedFeatures() : {};

        const body = (
            !slackIsAuthorized?<SlackAuthenticate/>:
                <div>
                    <Summary authentication={this.props.authentication} slack_analytics={this.props.slack_analytics} getAnalytics={this.props.getAnalytics} showMessage={this.props.showMessage}/>
                    {hasLoaded ? <FocusAreas metadata={this.props.slack_analytics.metadata} data={genderFeatures}/> : null}
                    {hasLoaded ? <DeepDive metadata={this.props.slack_analytics.metadata} data={genderFeatures}/> : null}
                </div>
        );

        return (
            <div className={'slack'}>
                <h1>Slack Insights</h1>
                {body}
            </div>
        );
    }
}

export default Slack;
