import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import Snackbar from '@material-ui/core/Snackbar';
import './App.scss';


class GlobalMessage extends Component {
    constructor() {
        super();
        this.state = {
            open: true
        };
        this.messageClose = this.messageClose.bind(this);
    }

    messageClose = () => {
        this.setState(prevState => {
            return {
                open: false
            }
        })
    }

    codeToMessage = ( code ) => {
        switch(code) {
            case 400:
                return {
                    message : "Bad Request: Request sent invalid syntax."
                }
            case 401:
                return {
                    message : "Unauthorized: Sorry, you have not been authenticated."
                }
            case 403:
                return {
                    message : "Forbidden: You do not have permission."
                }
            case 404:
                return {
                    message : "Not Found: Uh oh! We couldn't find what you are looking for!"
                }
            case 500:
                return {
                    message : "Internal Server Error: Cannot process the request."
                }
            case 502:
                return {
                    message : "Bad Gateway: Not receiving a valid response from the servers."
                }
            case 503:
                return {
                    message : "Service Unavailable: Server is overloaded or under maintenance."
                }
            case 504:
                return {
                    message : "Gateway Timeout: Not receiving a response from the servers."
                }
            default:
                return {
                    message: "Something went wrong! :("
                }
        }
    }

    render() {
        const messageInfo = this.codeToMessage(this.props.error.code);
        const { code, message } = this.props;

        if (!(code || (message && message.length))) {
            return null
        } else  {
            return (
                <div className={`${this.props.error.type}-message`}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.open}
                        autoHideDuration={5000}
                        message={<span>{message ? message : messageInfo.message}</span>}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.message
    };
};

export default connect(mapStateToProps, Actions)(GlobalMessage);
