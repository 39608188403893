import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Column } from '../../utils/FlexBox';

const Company = ({ company }) => {
    return (
        <div>
            {
                company.risk === 'High (!!!)'
                ? <div className="company-container-risk">
                    <div className="logo">
                        <img alt={company.id} src={company.imgURL} />
                    </div>
                    {
                        company.certification === 'Platinum'
                        ? <div className="company-detail-platinum">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            : null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            : null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Gold'
                        ? <div className="company-detail-gold">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            : null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Silver'
                        ? <div className="company-detail-silver">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Bronze'
                        ? <div className="company-detail-bronze">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Not'
                        ? <div className="company-detail-not-certified">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                </div>
                : <div className="company-container">
                    <div className="logo">
                        <img alt={company.id} src={company.imgURL} />
                    </div>
                    {
                        company.certification === 'Platinum'
                        ? <div className="company-detail-platinum">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Gold'
                        ? <div className="company-detail-gold">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Silver'
                        ? <div className="company-detail-silver">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Bronze'
                        ? <div className="company-detail-bronze">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                    {
                        company.certification === 'Not'
                        ? <div className="company-detail-not-certified">
                            <Row>
                                <Column width="80%">
                                    <h2 className="certificate">{company.certification} Certified</h2>
                                    <p>Inclusion Score: {company.score}</p>
                                    <p>Risk Factor: <span className="company-risk">{company.risk}</span></p>
                                </Column>
                                <Column width="20%">
                                    <div className="leadership-certificate">
                                        {
                                            company.isWomenLed
                                            ? <img src="images/portfolios/legend/female.svg" />
                                            :  null
                                        }
                                        {
                                            company.isMinorityLed
                                            ? <img src="images/portfolios/legend/minority.svg" />
                                            :  null
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </div>
                        : null
                    }
                </div>
            }
        </div>
    )
}

export default Company;
