import React, {useEffect} from 'react';
import classNames from "classnames";
import './index.scss';

const OpenText = ({ question, company, handleChange }) => {
    const { title, required, type, placeholder, choices } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);
    return (
        <div className={classNames("question", "ot-question", { "required": required })}>
            <p className={"question-title"}>{question.qnum}. {parsedTitle} <span className={"asterisk"}>*</span> </p>
            <div className={"ot-question__text-container"}>
                <textarea placeholder="Description" rows="10" disabled></textarea>
            </div>
        </div>
    );
};

export default OpenText;
