import React, { Component } from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from "recharts";
import { PiePlaceholder } from '../../../utils/Placeholders';
import './index.scss';

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const PiCustomTooltip = ({ active, payload, group }) => {
    if (active) {
        return (
            <div className="tooltip">
                <div className="tooltiptext">
                    <h5>{group}</h5>
                    <div className="tooltip-label">
                        <div className="tooltip-demographic">
                            <span className="label-indicator" style={{backgroundColor: payload[0].payload.fill}}></span>
                            {payload[0].name}: <b>{` ${payload[0].value.toFixed()}%`}</b>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

class PiChart extends Component {
    state = {
        active: 0
    }

    onPieEnter = (data, index) => {
        this.setState({
            active: index
        })
    }

    handleEnterHover = () => {
        this.setState({
            active: true
        })
    }

    handleLeaveHover = () => {
        this.setState({
            active: false
        })
    }


    render() {
        let sortedPieData = this.props.data.sort((a, b) => b.value - a.value);

        if (!sortedPieData[0]) {
            this.props.showMessage({type: "error", message: "Data cannot be displayed, minimum threshold not met."});
            return (
                <div className="pie-container">
                    <PiePlaceholder isLoading={false} />
                </div>
            )
        }
        return (
            <div id="pie-chart">
                <ResponsiveContainer height={250} width="100%">
                    <PieChart>
                        <Pie
                            isAnimationActive={true}
                            activeIndex={this.state.active}
                            activeShape={renderActiveShape}
                            data={this.props.data}

                            innerRadius={30}
                            outerRadius={60}
                            fill="#8884d8"
                            onMouseEnter={this.onPieEnter}
                        >
                            {
                                this.props.data.map((entry, index) => <Cell key={index} fill={this.props.colors[index % this.props.colors.length]}/>)
                            }
                        </Pie>
                        <Tooltip content={<PiCustomTooltip group={this.props.label} />} />
                    </PieChart>
                </ResponsiveContainer>
                <div className="label"> {this.props.label} </div>
                <div className={"all-labels"}>
                    {
                        sortedPieData.map((entry, index) => {
                            return (
                                <div key={index} className={this.state.active === index ? "pie-label-active" : "pie-label"}>
                                    <div className="label-container">
                                        <span className={"pie-label-dot"} style={{backgroundColor: this.props.colors[index % this.props.colors.length]}}></span>
                                        { `${entry.name}` }
                                    </div>
                                    <div className="score">
                                        { `${entry.value? entry.value.toFixed(): 0}%` }
                                    </div>
                                </div>

                                /*
                                    <div key={index} className={"pie-label"}>
                                        <span className={"pie-label-dot"} style={{backgroundColor: this.props.colors[index % this.props.colors.length]}}></span>
                                        { `${entry.name}` }
                                        <div className="demographic-label">
                                            <h6 className="percentage">{ `${entry.value? entry.value.toFixed(): 0}%` }</h6>
                                        </div>
                                    </div>
                                */
                            )
                        })
                    }
                </div>
            </div>
        );
    };
};

export default PiChart;
