import React, { useEffect } from 'react';
import _ from 'underscore';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { stableSort, getComparator } from '../../../../../utils/tableSorting';
import './index.scss';

const ILN_WEBSITE = "https://www.investorleadershipnetwork.org/"

const InitiativeTable = (props) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(3);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('index');
    const [initiative, setInitiative] = React.useState(props.data)
    const [active, setActive] = React.useState(props.data[0] ? props.data[0].name : null)
    const [tab, setTab] = React.useState(props.tableTitle)

    useEffect(() => {
        const filter = filterData(props.data ? props.data : null)
        setActive(filter[0] ? filter[0].name : null)
        setInitiative(filter[0]);
        props.selectProgram(filter[0] ? filter[0].name : null)
        setPage(0)
    }, [props.data[0], tab])

    useEffect(() => {
        setTab(props.tableTitle)
    }, [props.tableTitle]);


    const filterData = (data) => {
        
        let filter = data.filter(function (row) {
            if (row.theme.toString().toLowerCase().indexOf(tab.toLowerCase()) === -1) {
                return
            } else {
                return row.theme.toString().toLowerCase().indexOf(tab.toLowerCase()) >= 0;
            }
        })

        // Assign page numbers to programs
        let count = 0
        let pageNumber = 0
        for (const i in filter) {
            if(count == 3) {
                count = 0
                pageNumber = pageNumber + 1
            }
            filter[i]["pageNumber"] = pageNumber
            count = count + 1
        }

        return filter
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)

        // Change active program when the user changes page
        const data = filterData(props.data)
        for (const i in data) {
            if(data[i]["pageNumber"] == newPage) {
                setActive(data[i].name)
                setInitiative(data[i])
                break
            }
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const btnClick = (row, name, selectProgram) => {
        selectProgram(name)
        setActive(name)

        setInitiative(row)
    }

    const noInitiativeFound = () => {
        return (
            <div className="no-initiative-found">
                <h1>No Initiative Found</h1>
            </div>
        )
    }

    const filterMembers = (members) => {
        const filteredMembers = members.filter(function (member) {
            if (member.theme.toLowerCase().indexOf(props.tableTitle.toLowerCase()) === -1) {
                return
            }
            return member.theme.toLowerCase().indexOf(props.tableTitle.toLowerCase()) >= 0;
        })
        return (
            <div className="iln-initiatives-container">
                <div className="iln-initiative-toprow">
                    {initiative ?
                        <div className="iln-program-info">
                            <div>
                                <h4>Co-Facilitators</h4>
                                {initiative.co_facilitator.map((row, index) => {
                                    return (
                                        <p className="p-desc" key={index}>{row}</p>
                                    )
                                })}
                            </div>
                            <div>
                                <h4>ILN Members</h4>
                                <p className="p-desc">{initiative.members.sort().toString()}</p>
                            </div>
                            {/* To be added once the backend is sorted / Finalized with ILN */}
                            {/* <div>
                                <h4>ILN Tools</h4>
                            </div> */}
                        </div> : null}
                    <div className="iln-initiative-pagination">
                    <div className="iln-initiative-theme-title">
                                <h3>{props.tableTitle === "Infrastructure" ? "Sustainable Infrastructure" : props.tableTitle} Programs</h3>
                            </div>
                        {filteredMembers.length > 3 ? 
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={filteredMembers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> : null}
                    </div>
                </div>
                <TableContainer>
                    <Table>
                        <TableBody style={{ display: 'flex' }}>
                            {stableSort(filteredMembers, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const rowName = row.name.trim()
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Button onClick={() => btnClick(row, row.name, props.selectProgram)} className="solution-button">
                                                    <div className={active === row.name ? "solution-title-active" : "solution-title"}>
                                                        {rowName}
                                                    </div>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {initiative ? <div className="initiative-container">
                    <div className="initiative-info">
                        <div className="initiative-info-title">
                            <h3 className="title-h3">{initiative.name}</h3>
                            <p className="title-desc">{initiative.description}</p>
                        </div>
                        <div className="initiative-links">
                            <a href={ILN_WEBSITE} target="_blank" rel="noopener noreferrer">
                                <p className="p-style">
                                    Website
                                </p>
                            </a>
                        </div>
                        <table className="initiative-child-table">
                            <tbody>
                                <tr className="initiative-child-tr">
                                    <th className="initiative-child-th">
                                        <h4>Partners</h4>
                                        <ul>
                                            {initiative.partners.sort().map((row, index) => {
                                                return (
                                                    <li key={index}><p className="p-desc">{row}</p></li>
                                                )
                                            })}
                                        </ul>
                                    </th>
                                </tr>
                                <tr className="initiative-child-tr">
                                    <th className="initiative-child-th">
                                        <h4>Key Performance Indicators</h4>
                                        <ul>
                                            {initiative.kpi.map((row, index) => {
                                                return (
                                                    <li key={index}><p className="p-desc">{row}</p></li>
                                                )
                                            })}
                                        </ul>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> : null}
            </div>
        )
    }
    return (
        <div>
            {!_.isEmpty(props.data) ? filterMembers(props.data) : props.dataHasLoaded && noInitiativeFound()}
        </div>
    )
}

export default InitiativeTable;
