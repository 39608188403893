import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadOnePager } from './network';

// All data sagas to add to middleware.
export default [
    [
        ActionTypes.GET_ONE_PAGER
        , getOnePager
    ]
];

function * getOnePager () {
    yield put(Actions.loadingOnePager());

    const response = yield call(loadOnePager);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedOnePager(response.data));
    }
}

