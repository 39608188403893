import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

import Card from '../../../utils/Card';
import Select from '../../../utils/Select';

import _ from 'underscore';
import './index.scss';

const ALL_STRING = "All";

const STATUS_MAP = {
    "COMPLETE": {
        text: "Complete",
        color: "green",
    },
    "IN PROGRESS": {
        text: "In Progress",
        color: "orange",
    },
    "ON HOLD": {
        text: "On Hold",
        color: "red",
    },
}

const getStatusTextAndBubble = (value) => {
    if (STATUS_MAP[value]) {
        return (
            <div className="diversityvc-scorebreakdown__status-wrapper">
                <div className={`diversityvc-scorebreakdown__status-${STATUS_MAP[value].color}`} />
                {STATUS_MAP[value].text || value}
            </div>);
    }
    return value;
}

class ScoreBreakdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKPI: "",
            rowsPerPage: 10,
            page: 0,
        }
    }

    componentDidUpdate(prevProps) {
        const kpiList = _.maybe(this.props, "diversityvc", "policy", "category_list");
        if (kpiList !== _.maybe(prevProps, "diversityvc", "policy", "category_list")) {
            const firstItem = _.maybe(kpiList, 0)
            if (firstItem) {
                this.setState({
                    selectedKPI: "Recruiting & HR"
                })
            }
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
    };

    handleChangePage = (event, newPage) => {
        // event is needed for this to work
        this.setState({
            page: newPage
        });
    };

    makeOptionsList = (policy) => {
        if (policy.category_list) {
            return [
                ALL_STRING,
                ...policy.category_list,
            ]
        } else {
            return [
                ALL_STRING
            ];
        }
    }

    onChange = (selectedValues) => {
        this.setState({
            selectedKPI: selectedValues,
            page: 0,
        });
    }

    generatePolicyDict = (policyList) => {
        /* From the list,
        generate a dictionary with the policy names as keys,
        and { kpi, description, industry values } as values.
        */

        let policyObj = {};

        for (const p of policyList) {
            policyObj[p.policy] = {
                "category": p.category,
                "description": p.description,
                "industry_value": p.industry_value
            }
        }

        return policyObj;
    }

    makeRow = (p, policyObj) => {

        const policyName = p.policy;
        const policyStatus = p.status;

        if (!policyName || _.isEmpty(policyObj)) {
            return null;
        }

        return (
            <tr key={`${p.policy}`}>
                <td className="diversityvc-scorebreakdown__table-policy-name">{policyName}</td>
                <td>{_.maybe(policyObj, policyName, "description")}</td>
                <td >{_.maybe(policyObj, policyName, "category")}</td>
                <td >{getStatusTextAndBubble(policyStatus)}</td>
                <td >{`${_.maybe(policyObj, policyName, "industry_value")}%`}</td>
            </tr>
        );
    }

    renderLoading() {
        return (
            <div className="content">
                Loading....
            </div>
        )
    }

    renderLoaded() {

        const {
            policy = {},
            company_policy
        } = this.props.diversityvc;


        let policyObj = {};
        if (policy.policy_list && policy.policy_list.length > 0) {
            policyObj = this.generatePolicyDict(policy.policy_list);
        }

        let filteredPolicies = [];
        if (!_.isEmpty(company_policy)) {
            if (this.state.selectedKPI == ALL_STRING) {
                filteredPolicies = Object.values(company_policy);
            } else {
                filteredPolicies = Object.values(company_policy).filter(cp => {
                    const policyName = cp.policy;


                    if (_.maybe(policyObj, policyName, "category") == this.state.selectedKPI) {
                        return true;
                    }
                    return false;
                })
            }
        }

        return (
            <div className="diversityvc-scorebreakdown__content">
                <div className="diversityvc-scorebreakdown__filter-and-pagination">
                    <div className="diversityvc-scorebreakdown__kpi-filter">
                        <Select
                            multiple={false}
                            options={this.makeOptionsList(policy)}
                            selectedValues={this.state.selectedKPI}
                            label="KPI"
                            onChange={this.onChange}
                            renderValue={(e) => (e)}
                            mapChoiceLabel={(e) => (e)}
                            extraClasses="diversityvc-scorebreakdown__kpi-dropdown"
                            styleClass="diversityVc"
                        />
                    </div>
                    <div className="diversityvc-scorebreakdown__pagination">
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 25]}
                            component="div"
                            count={filteredPolicies.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                <div className="diversityvc-scorebreakdown__table-wrapper">
                    <table className="diversityvc-scorebreakdown__table">
                        <thead>
                            <tr>
                                <th style={{ width: '20%' }}>Policy / Program</th>
                                <th style={{ width: '35%' }}>Description</th>
                                <th style={{ width: '20%' }}>KPI</th>
                                <th style={{ width: '15%' }}>Status</th>
                                <th style={{ width: '10%' }}>Industry</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPolicies
                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map(p => {
                                    return this.makeRow(p, policyObj);
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render() {
        let content;

        if (_.maybe(this.props, 'diversityvc', 'company_policy')) {
            if (_.isEmpty(this.props.diversityvc.company_policy)) {
                // Call is complete, details do not exist.
                // Hide the card
                return null;
            } else {
                // Call is complete, details exist
                content = this.renderLoaded();
            }
        } else {
            // API call not yet complete, show loading message
            content = this.renderLoading();
        }

        return (
            <Card className={'diversityvc-scorebreakdown'}>
                <span className="diversityvc-scorebreakdown__title">
                    Score Breakdown
                </span>
                <div className="diversityvc-scorebreakdown__subtitle">
                    Use this section to develop a detailed understanding of which policies and programs your fund
                    has in place. You can also use this section to track the areas to which your peer funds
                    are allocating resources.
                </div>
                {content}
            </Card>
        );
    }
}

ScoreBreakdown.propTypes = {

};

export default ScoreBreakdown;
