import React, { useContext } from 'react';
import Select from "../../utils/Select";
import { FilterContext } from "./index";
import {withTranslation} from "react-i18next";

const yearQuarterParser = (yyyyQn) => {
    const parsed = yyyyQn.match(/(\d{4})Q(\d)/);

    if ((parsed && parsed[1]) && parsed[2]) {
        return `${parsed[1]} Q${parsed[2]}`;
    }

    return yyyyQn;

}

const Year = React.memo(({ label, valId = "year", dataId = "years", t }) => {
    const { data, onChange, values } = useContext(FilterContext);
    label = label || t("ANALYZE.FILTERS.YEAR");
    return <Select
    renderValue={yearQuarterParser}
    options={data[dataId]}
    label={label}
    optionType={""}
    onChange={value => onChange(valId, value)}
                   selectedValues={values[valId] || data[dataId][0]}/>
});

export default withTranslation()(Year);
