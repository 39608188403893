import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
// import TargetGoal from '../TargetGoal';
import TargetModal from '../TargetModal';
import './index.scss';

const data = {
    "target_demographic": null,
    "year": null,
    "gender": null,
    "sexual_orientation": null,
    "disability": null,
    "ethnicity": null,
    "level": null,
    "department": null,
    "office": null,
    "goal": null,
    "current": null,
    "on_track": null
}

class NewTargetButton extends Component {
    state = {
        openModal: false,
        isEdit: false
    }

    openModal = () => {
        this.setState({
            openModal: true
        })
    }

    closeModal = () => {
        this.setState({
            openModal: false,
            isEdit: false
        })
    }

    handleCancel = () => {
        this.closeModal();
    }

    handleEdit = () => {
        this.setState({
            isEdit: true
        })

        this.openModal();
    }
    render() {
        if (Object.keys(this.props.filters).length) {
            return (
                <div id="new-target-button">
                    {
                        !this.state.openModal
                        ? <Button onClick={() => this.openModal()} className="target">
                            <div className="target-container">
                                <img src="images/svg/add_circle.svg" alt="add button" />
                                <h5>Add Target Goal</h5>
                            </div>
                        </Button>
                        : <div>
                            <Button onClick={() => this.openModal()} className="target">
                                <div className="target-container">
                                    <img src="images/svg/add_circle.svg" alt="add button" />
                                    <h5>Add Target Goal</h5>
                                </div>
                            </Button>
                            <TargetModal isModalOpen={this.state.openModal}
                                        closeModal={this.closeModal.bind(this)}
                                        handleCancel={this.handleCancel.bind(this)}
                                        handleUpdate={this.props.handleUpdate}
                                        handleSave={this.props.handleSave}
                                        filters={this.props.filters}
                                        data={data}
                                        isEdit={this.state.isEdit}
                                        filterMap={this.props.filterMap}
                                        showMessage={this.props.showMessage}
                                        demographicTarget={this.props.demographicTarget} />
                            </div>
                    }
                </div>
            );
        } else {
            return null;
        }
    }
}

export default NewTargetButton;