import React, { Component } from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import mapData from './mapData.json';
import MouseTooltip from 'react-sticky-mouse-tooltip';

class MapChart extends Component {
  state = {
    highlighted: "",
    hovered: false
  };

  handleMove = geo => {
    if (this.state.hovered) return;
    this.setState({
      hovered: true,
      highlighted: geo.properties.CONTINENT
    });
    const { CONTINENT } = geo.properties;
    this.props.setTooltipContent(CONTINENT);
  };

  handleLeave = () => {
    this.setState({
      highlighted: "",
      hovered: false
    });
    this.props.setTooltipContent("")
  };

  render() {
    return (
      <div className="ratio-container">
        <div className="ratio-container-content">
          <ComposableMap>
            <Geographies
              geography="https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"
              disableOptimization
            >
              {/* Geographies returned undefined for some reason,
                  so I downloaded the json for the world map the way it's supposed to be
                  formated by the <Geographies> component */}
              {(geographies, projection) =>
                mapData.map((geography, i) => {
                  return (
                    <Geography
                      key={i}
                      cacheid={geography.properties.ISO_A3 + i}
                      geography={geography}
                      projection={projection}
                      onMouseMove={() => this.handleMove(geography)}
                      onMouseLeave={this.handleLeave}
                      style={{
                        default: {
                          fill:
                            geography.properties.CONTINENT ===
                              this.state.highlighted
                              ? "#27829E"
                              : "#FFF",
                          stroke:
                            geography.properties.CONTINENT ===
                              this.state.highlighted
                              ? "#FFF"
                              : "#27829E",
                          strokeWidth: 0.75,
                          outline: "none",
                          transition: "all 100ms"
                        },
                        hover: {
                          fill: "#27829E",
                          stroke: "#27829E",
                          strokeWidth: 0.75,
                          outline: "none",
                          transition: "all 100ms"
                        },
                        pressed: {
                          fill: "#27829E",
                          stroke: "#27829E",
                          strokeWidth: 0.75,
                          outline: "none",
                          transition: "all 100ms"
                        }
                      }}
                    />
                  )
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
        <MouseTooltip
          visible={this.state.hovered}
          offsetX={-45}
          offsetY={40}
        >
          <div className="iln-tooltip">
            {this.props.toolTipText}
          </div>
        </MouseTooltip>
      </div>
    );
  }
}

export default MapChart;
