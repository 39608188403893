import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Column, Row} from "../utils/FlexBox";
import history from "../../history";
import Diversity from "./Diversity";
import Inclusion from "./Inclusion";
import _ from 'underscore';
import Loader from "../utils/Loader";
import {withTranslation} from "react-i18next";
import { isCifarGlobalCall, isCifarScholars, isBessemerBriefPortfolio, isBessemerAnonymized } from '../../utils/companyChecks'
import './index.scss'

const Analyze = (props) => {
    const { pathname } = history.location;
    const { t } = props;

    useEffect(() => {
        if (pathname === "/analyze") {
            history.replace("/analyze/diversity");
        }
    }, [pathname]);

    const renderComp = (pathname) => {

        switch(pathname) {
            case '/analyze/diversity':
                return <Diversity { ...props } />;
            case '/analyze/inclusion':
                return <Inclusion { ...props } />;
        }
    };

    if (_.isEmpty(props.analyze.filters.years)) {
        return <Loader />
    }

    const isCustomInclusion = () => {
        const companyName = _.maybe(props, 'profile', 'company')
        const isCustom = (isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isBessemerBriefPortfolio(companyName) || isBessemerAnonymized(companyName))

        if (isCustom) {
            return true
        }

        return false;
    }

    return (
        <div>
            <div className="App">
                <Row>
                    <Column grow={1}>
                        <h1 className={"transparent-header"}>{t("ANALYZE.TITLE")}</h1>
                    </Column>
                </Row>
                <Row>
                    <Column grow={1}>
                        <div className={"bootstrap-override"}>
                            <Nav className={"analyze-nav"} justify variant="tabs" defaultActiveKey="/analyze/diversity">
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analyze/diversity"} as={Link} to="/analyze/diversity">{t("ANALYZE.TABS.DIVERSITY")}</Nav.Link>
                                </Nav.Item>
                                {isCustomInclusion() ? null :
                                <Nav.Item>
                                    <Nav.Link active={history.location.pathname === "/analyze/inclusion"} as={Link} to="/analyze/inclusion">{t("ANALYZE.TABS.INCLUSION")}</Nav.Link>
                                </Nav.Item>
                                }
                            </Nav>
                        </div>
                    </Column>
                </Row>

                {
                    renderComp(history.location.pathname)
                }
            </div>
        </div>
    );
};

export default withTranslation()(Analyze);
