import React from 'react';
import { Modal, Button } from "react-bootstrap";
import './index.scss';
import {connect} from "react-redux";
import {Actions} from "../../../actions";

const DialogBox = ({
    title,
    body,
    show,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonText,
    secondaryButtonText
}) => {
    return (
        <Modal
            show={show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                {
                    primaryButtonText
                    && <Button variant="secondary" onClick={onPrimaryButtonClick}>
                        {primaryButtonText}
                    </Button>
                }
                {
                    secondaryButtonText
                    && <Button variant="primary" onClick={onSecondaryButtonClick}>
                        {secondaryButtonText}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        ...state.dialog
    };
};

export default connect(mapStateToProps, Actions)(DialogBox);
