import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './index.scss';
import App from './App';
import store from './store'
import registerServiceWorker from './registerServiceWorker';
import fetchSentryDsn from "./sentry";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import {StylesProvider} from "@material-ui/core/styles";
import {initializeUnderscoreMixins} from "./utils";

fetchSentryDsn();
initializeUnderscoreMixins();

const rootComponent = (
    <StylesProvider injectFirst>
        <I18nextProvider i18n={i18n}>
            <App store={ store }/>
        </I18nextProvider>
    </StylesProvider>
);

ReactDOM.render(rootComponent, document.getElementById('root'));
registerServiceWorker();
