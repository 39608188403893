import React, { Component } from 'react';
import OverallScore from './OverallScore';
import IndexRanking from './IndexRanking';
import './index.scss';

class InclusionScore extends Component {
    render() {
        return (
            <div id="LSO-inclusion">
                <div className="inclusion-container">
                    <div className="title">Your Workplace Score</div>
                    <div className="subtitle">Your workplace ranking relative to other legal workplaces in Ontario with 25 or more legal professionals</div>
                    <OverallScore data={this.props.data} />
                    <IndexRanking data={this.props.data} />
                </div>
                <div className="inclusion-index-container">
                    <div className="index-subtitle">What is the Inclusion Index?</div>
                    <div className="index-description">The Law Society of Ontario partnered with Diversio to create an Inclusion Index which ranks all legal workplaces with more than 25 lawyers or paralegals. LSO collected data from lawyers and workplaces in the 2019 Annual Report, including demographic, experience, and programming data. Diversio applied its inclusion alogorithms to generate a Score and a ranking for each workplace</div>
                    <a href="https://lso.ca/about-lso/initiatives/edi/measuring-progress" target="_blank" rel="noopener noreferrer" className="learn-more">Learn More</a>
                </div>
            </div>
        );
    };
};

export default InclusionScore;
