import React from 'react';
import './Pilots.scss';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

const marks = {
  25: 'Pre-launch',
  50: 'Launch',
  75: 'Feedback',
  100: 'Impact',
};

const marks_reverse = {
    'default': 0,
    'Pre-launch': 25,
    'Launch': 50,
    'Feedback': 75,
    'Impact': 100,
};

function getReverse(pilot){
    return marks_reverse[pilot.progress];
}

function random_color() {
    var color;
    color = "#" + Math.random().toString(16).slice(2, 8).toUpperCase();
    return color;
}

const PilotTemplate = props =>
    <div className="card" id={"pilots"}>
        <div>
            <ul>
                {props.pilots.map((pilot, i) => (
                    <div key={i + 0.1} className="pilots">
                        <li key={i}>

                            <div className="title">{pilot.title} </div>

                            <a className="learnMore" href="https://google.com" target="_blank" rel="noopener noreferrer">Learn more ></a> <br/>

                            <div className="flex">
                                <div className='left'>
                                    <span style={{backgroundColor: random_color()}} className="companyRectangle">{pilot.company}</span> 
                                </div>

                                <div className='center'>
                                    <Slider dots
                                    dotStyle={{ backgroundColor: 'transparent'}}
                                    activeDotStyle={{ backgroundColor: 'transparent' }}
                                    trackStyle={{ backgroundColor: 'transparent' }}
                                    handleStyle={{
                                    borderColor: '#5E77FF',
                                    backgroundColor: '#5E77FF',
                                    }}
                                    min={0} marks={marks} step={25}
                                    defaultValue={getReverse(pilot)} />
                                </div>  

                                <div className="right"> 
                                    <a href="https://google.com" target="_blank" rel="noopener noreferrer"><span className="caseButton">Case Study</span> </a>
                                </div>
                            </div>

                        </li>
                    </div>
                ))}
            </ul>
        </div>
    </div>


export default PilotTemplate;