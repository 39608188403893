import React, { useState, useEffect } from 'react';
import PortcoTopRow from './PortcoTopRow';
import PortcoDiversityComposition from './PortcoDiversityComposition';
import PortcoPrograms from './PortcoPrograms';

import { COMPANY_LIST } from './constants';

import _ from 'underscore';
import './index.scss';
import PortcoChooseBar from './PortcoChooseBar';


const Portco = (props) => {
	
	const [ activeCompany, setActiveCompany ] = useState(["All"]);
	const [ options, setOptions ] = useState([]);
	// const [ triggerReRender, setTriggerReRender ] = useState(false);

	useEffect(() => {
		if (_.isEmpty(options)) {
			// TODO @sometimescasey: replace with redux-saga when backend is built
			setOptions(COMPANY_LIST);
			// setTriggerReRender(!triggerReRender);
		}
	}, [])

	return (
		<div className="portco__container">
			<div className="portco__flexgrow">
			<PortcoTopRow companyInformation={props.companyInformation}
			activeCompany={activeCompany}
			setActiveCompany={setActiveCompany}
			options={options}
		/>
			</div>
			<div className="portco__flexgrow">
				<PortcoChooseBar
					activeCompany={activeCompany}
						setActiveCompany={setActiveCompany}
						options={options}
				/>
			</div>
			<div className="portco__flexgrow">
		
			<PortcoDiversityComposition
				companyName={`${activeCompany[0]}` || "All"}
			/>
			<PortcoPrograms
				companyName={`${activeCompany[0]}` || "All"}
				pdfLink={ {"url": ""} }
			/>
			</div>
		</div>
	);

}

export default Portco;
