import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Actions} from '../actions';
import './App.scss';
import Analyze from "../components/Analyze";
import mixpanel from "mixpanel-browser";
import {createFiltersPayload} from "../components/Analyze/Filters/utils"

class AnalyzeContainer extends Component {
    componentWillMount() {
        mixpanel.track("View Page", {
            name: "Analyze",
        });

        this.props.getInclusionMetricsData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getEmployeeOverviewData();
        this.props.getCompanyFilters();
        this.props.getInclusionBreakdownData({data:createFiltersPayload({group_a:{"genders": ["Male"]}, group_b:{"genders": ["Female"]}})});
        this.props.getDiversityBreakdownData({data: {offices: "%", roles: "%"}});
        this.props.getInclusionTrackerData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getDiversityTrackerData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getDiversityBreakdownByRoleData({data: {year: "", level: "", office: "", department: ""}});
        this.props.getCompanyInformation();
        this.props.getFreeText();
        this.props.getDiversityCompositionData();
    }

    render() {
        return <Analyze { ...this.props } />;
    }
}

const mapStateToProps = state => {
    return {
        ...state.groups,
        analyze: state.analyze,
        home: state.home,
        colors: state.colors,
        kpimap: state.kpimap,
        profile: state.profile,
    };
};

export default connect(mapStateToProps, Actions)(AnalyzeContainer);
