import React, { useState } from 'react';
import Card from '../../../utils/Card';
import Select from '../../../utils/Select';
import waystarLogo from '../../../../images/logos/waystar_royco.jpg';
import './index.scss';

const PortcoNameLogo = (props) => {

    return (
        <Card>
            <div className="portco__name-logo">
                <h3 className="portco__name-logo-card-title">
					{props.companyInformation?.name || "Your Company"}
				</h3>
                <div className="portco__name-logo-container">
                    <div className="portco__logo-image">
                        <img src={waystarLogo} width="200px"/>
                    </div>
                </div>
            </div>
        </Card>
    )
};

export default PortcoNameLogo;
