import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import './modal.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class PaymentModal extends Component {
    state = {
        openOffer: false,
        openConfirmation: false,
        openReconsider: false
    };

    handleOpenOffer = () => {
        this.setState({ openOffer: true });
    };

    handlCloseOffer = () => {
        this.setState({
            openOffer: false,
            openReconsider: true
        });
    };

    handleOpenConfirm = (informPurchase) => {
        this.setState({
            openOffer: false,
            openConfirmation: true
        });
        informPurchase();
    };

    handleCloseConfirm = () => {
        this.setState({
            openConfirmation: false
        });
    };

    handleCloseReconsider = () => {
        this.setState({
            openReconsider: false
        });
    };

    render() {
        return (
            <div className="upgradeButton">
                {
                    !this.props.companyPurchasedStatus
                    ? <div>
                        <h2 className="modal-description">Want full access to your diversity analytics and custom recommendations?</h2>
                        <Button variant="outlined" size="large" onClick={this.handleOpenOffer} className="upgradeButton">
                            Upgrade Now
                        </Button>
                    </div>
                    : <div></div>
                }


                <Dialog
                    open={this.state.openOffer}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseOffer}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                >
                    <DialogTitle id="modal-title">
                        {"We are offering a discount subscription to OneEleven member companies for a limited time."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="modal-content">
                            For $3499, you'll receive a 6 month trial.
                            <ul>
                                <li>- Analyze by role and demographic</li>
                                <li>- Customized recommendations and action plan</li>
                                <li>- Benchmarks against industry</li>
                                <li>- Follow-on diagnostic to track progress</li>
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this.handleOpenConfirm(this.props.informPurchase)}} color="primary">
                            CONFIRM
                        </Button>
                        <Dialog
                            open={this.state.openConfirmation}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleCloseConfirm}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-content"
                        >
                            <DialogTitle id="modal-title">
                                {"Thank you for upgrading! A member of our sales team will be in touch with you shortly."}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={this.handleCloseConfirm} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Button onClick={this.handlCloseOffer} color="primary">
                            CANCEL
                        </Button>
                        <Dialog
                            open={this.state.openReconsider}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleCloseReconsider}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-content"
                        >
                            <DialogTitle id="modal-title">
                                {"If you'd like more detail before subscribing reach out to anna@diversio.com"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={this.handleCloseReconsider} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default PaymentModal;
