import React from 'react';
import './index.scss';
import { Row, Column } from '../../utils/FlexBox';
import Card from '../../utils/Card';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import mixpanel from 'mixpanel-browser';


// todo once we figure out where certification is in the backend
const CERTIFIED = false;

function URL(url) {
    window.open(url);
}

const NotCertifiedCard = ({t}) => (
    <Column width="100%">
                    <h3 className="certification-detail__card-title">{t('HOME.CERTIFICATION.TITLE')}</h3>
                    <div className="certification-detail__award-container">
                        <div className="certification-detail__award-img">
                            <img src="images/purple_seal_2021.png"/>
                        </div>
                        <div className="certification-detail__signal-text">{t("HOME.CERTIFICATION.SIGNAL_COMMITMENT")}</div>
                        <div className="certification-detail__buttons">
                            <div className="certification-detail__contact-us">
                                <Button onClick={() => {
                                    mixpanel.track("Button Click", {
                                        name: "Contact Us"
                                    })
                                    URL('mailto:info@diversio.com?subject=Diversio Certification (dashboard link)')
                                    }}>{t("HOME.CERTIFICATION.CONTACT_US")}</Button>
                            </div>
                            <div className="certification-detail__learn-more">
                                <Button onClick={() => URL('https://diversio.com/certification')}>Learn More</Button>
                            </div>
                        </div>
                    </div>
                </Column>
);

const CertifiedCard = ({t}) => (
        <Column width="100%">
                        <h3 className="certification-detail__card-title">{t('HOME.CERTIFICATION.TITLE')}</h3>
                        <div className="certification-detail__award-container">
                            <div className="certification-detail__award-img-cert">
                                <img src="images/seal.png"/>
                            </div>
                            <div className="certification-detail__download-media">
                                <Button onClick={() => { URL('/downloads/diversio_certification_2021.zip')}}>{t("HOME.CERTIFICATION.DOWNLOAD_META_FILES")}</Button>
                            </div>
                        </div>
                    </Column>
    );

const Certification = ( {t} ) => {

    return (
        <Card className="certification-card">
        <div className="certification-detail">
            <Row>
                { CERTIFIED ? <CertifiedCard t={t}/> : <NotCertifiedCard t={t}/>}
            </Row>
       </div>
       </Card>
    );
};

export default withTranslation()(Certification);
