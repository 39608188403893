const PORTCO_DATA = {
	"All": [
		{
		  "Gender": [
			{name: "Male", value: 42.5},
			{name: "Female+", value: 52.3},
			{name: "Prefer not to answer", value: 5.2},
			{name: "Transgender(yes)", value: 1.8}
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 41.9},
			{name: "POC", value: 58.1},
			{name: "Prefer not to answer", value: 0}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 65.8},
			{name: "LGBTQ+", value: 22.3},
			{name: "Prefer not to answer", value: 11.9}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 71.6},
			{name: "People with disabilities", value: 21.8},
			{name: "Prefer not to answer", value: 6.6}
		  ]
		}
	  ],
	  "ATN": [
		{
		  "Gender": [
			{name: "Male", value: 30.0},
			{name: "Female+", value: 60.0},
			{name: "Prefer not to answer", value: 10.0},
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 40.0},
			{name: "POC", value: 60.0},
			{name: "Prefer not to answer", value: 0}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 60.0},
			{name: "LGBTQ+", value: 20.00},
			{name: "Prefer not to answer", value: 20.00}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 60.0},
			{name: "People with disabilities", value: 20.00},
			{name: "Prefer not to answer", value: 20.00}
		  ]
		}
	  ],
	  "Vaulter": [
		{
		  "Gender": [
			{name: "Male", value: 20},
			{name: "Female+", value: 50},
			{name: "Prefer not to answer", value: 30.0},
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 30},
			{name: "POC", value: 50.0},
			{name: "Prefer not to answer", value: 20}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 65.8},
			{name: "LGBTQ+", value: 22.3},
			{name: "Prefer not to answer", value: 11.9}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 71.6},
			{name: "People with disabilities", value: 21.8},
			{name: "Prefer not to answer", value: 6.6}
		  ]
		}
	  ],
	  "Brightstar Cruises": [
		{
		  "Gender": [
			{name: "Male", value: 70},
			{name: "Female+", value: 20},
			{name: "Prefer not to answer", value: 10},
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 60},
			{name: "POC", value: 10},
			{name: "Prefer not to answer", value: 30}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 65.8},
			{name: "LGBTQ+", value: 22.3},
			{name: "Prefer not to answer", value: 11.9}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 60.0},
			{name: "People with disabilities", value: 25},
			{name: "Prefer not to answer", value: 15}
		  ]
		}
	  ],
	  "Brightstar Adventure Park": [
		{
		  "Gender": [
			{name: "Male", value: 30},
			{name: "Female+", value: 59},
			{name: "Prefer not to answer", value: 11},
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 40},
			{name: "POC", value: 40},
			{name: "Prefer not to answer", value: 20}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 65.8},
			{name: "LGBTQ+", value: 22.3},
			{name: "Prefer not to answer", value: 11.9}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 71.6},
			{name: "People with disabilities", value: 21.8},
			{name: "Prefer not to answer", value: 6.6}
		  ]
		}
	  ],
	  "Dust": [
		{
		  "Gender": [
			{name: "Male", value: 42.5},
			{name: "Female+", value: 52.3},
			{name: "Prefer not to answer", value: 5.2},
			{name: "Transgender(yes)", value: 1.8}
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 41.9},
			{name: "POC", value: 58.1},
			{name: "Prefer not to answer", value: 0}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 65.8},
			{name: "LGBTQ+", value: 22.3},
			{name: "Prefer not to answer", value: 11.9}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 71.6},
			{name: "People with disabilities", value: 21.8},
			{name: "Prefer not to answer", value: 6.6}
		  ]
		}
	  ],
	  "PGM": [
		{
		  "Gender": [
			{name: "Male", value: 40.0},
			{name: "Female+", value: 50.0},
			{name: "Prefer not to answer", value: 10},
		  ]
		},
		{
		  "Ethnicity": [
			{name: "White", value: 41.9},
			{name: "POC", value: 58.1},
			{name: "Prefer not to answer", value: 0}
		  ]
		},
		{
		  "Sexual Orientation": [
			{name: "Heterosexual", value: 20.0},
			{name: "LGBTQ+", value: 50},
			{name: "Prefer not to answer", value: 30}
		  ]
		},
		{
		  "Disability": [
			{name: "People without disabilities", value: 71.6},
			{name: "People with disabilities", value: 21.8},
			{name: "Prefer not to answer", value: 6.6}
		  ]
		}
	  ]
}

export const MOCKDATA = {
	"breakdown": [
	  {
		"Gender": {
		  "Male": 42.5,
		  "Female": 51.1,
		  "Gender Fluid": 0,
		  "Intersex": 0,
		  "Non-Binary": 1,
		  "Two-Spirit": 0,
		  "Prefer not to answer": 5.2,
		  "Other": 0.2,
		  "Agender": 0,
		  "Bigender": 0,
		  "Genderqueer": 0,
		  "Pangender": 0
		}
	  },
	  {
		"Ethnicity": {
		  "White": 41.9,
		  "Asian": 26,
		  "Black": 6,
		  "Indigenous": 0.4,
		  "Latin / Hispanic": 5.8,
		  "Middle Eastern": 9.1,
		  "Jewish": 1.4,
		  "Mixed Race": 4.8,
		  "Other": 4.6,
		  "Prefer not to answer": 0
		}
	  },
	  {
		"Sexual Orientation": {
		  "Asexual": 7,
		  "Bisexual": 5.2,
		  "Gay": 6.8,
		  "Heterosexual": 65.8,
		  "Lesbian": 1,
		  "Pansexual": 0.7,
		  "Queer": 1.6,
		  "Two-Spirit": 0,
		  "Prefer not to answer": 11.9,
		  "Other": 0
		}
	  },
	  {
		"Disability": [
		  "{name: \"People with Physical disability\", value: Ar…}",
		  "{name: \"People with Cognitive disability\", value: A…}",
		  "{name: \"People with Mental health issue\", value: Ar…}",
		  "{name: \"People with multiple disabilities\", value: …}"
		]
	  }
	],
	"portco_data": PORTCO_DATA,
	"ndg": {
	  "gender": "Female+",
	  "ethnicity": "POC",
	  "sexual_orientation": "LGBTQ+",
	  "disability": "People with disabilities"
	},
	"colors": [
	  "#D7CEE4",
	  "#CFB4F7",
	  "#6221EA",
	  "#0725E8",
	  "#B6BFF6",
	  "#FEBECD",
	  "#FBC02D",
	  "#109CF1",
	  "#FF2455",
	  "#FF8A00",
	  "#8F40EB",
	  "#AF5EEC",
	  "#CA7DEF",
	  "#DF9CF3",
	  "#F1BCF8",
	  "#FFDCFF",
	  "#FBC4ED",
	  "#F8ABD6",
	  "#F591BC",
	  "#F1779E",
	  "#E95D7E",
	  "#DE425B",
	  "#FF005B",
	  "#FF533B",
	  "#FF811D",
	  "#FFA600"
	],
	"industryData": {
	  "Gender": [
		{
		  "name": "Male",
		  "value": 42.5
		},
		{
		  "name": "Female+",
		  "value": 52.3
		},
		{
		  "name": "Prefer not to answer",
		  "value": 5.2
		}
	  ],
	  "Ethnicity": [
		{
		  "name": "White",
		  "value": 41.9
		},
		{
		  "name": "POC",
		  "value": 58.1
		},
		{
		  "name": "Prefer not to answer",
		  "value": 0
		}
	  ],
	  "Sexual Orientation": [
		{
		  "name": "Heterosexual",
		  "value": 65.8
		},
		{
		  "name": "LGBTQ+",
		  "value": 22.3
		},
		{
		  "name": "Prefer not to answer",
		  "value": 11.9
		}
	  ],
	  "Disability": [
		{
		  "name": "People without disabilities",
		  "value": 71.6
		},
		{
		  "name": "People with disabilities",
		  "value": 21.8
		},
		{
		  "name": "Prefer not to answer",
		  "value": 6.6
		}
	  ]
	},
	"companyName": "Waystar Royco",
	"profile": {
	  "company": "Diversio Demo",
	  "username": "diversio_demo",
	  "email": "diversio_demo@diversio.com",
	  "role": null,
	  "full_name": null,
	  "profile_image": null
	},
  }
