import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadRoadMapChecklist, updateRoadMapChecklist } from './network';

export default [
    [
        ActionTypes.GET_ROAD_MAP_CHECKLIST
        , getRoadMapChecklist
    ],
    [
        ActionTypes.DO_UPDATE_ROAD_MAP_CHECKLIST
        , doUpdateRoadMapChecklist
    ]
];

function * getRoadMapChecklist ({payload}) {
    yield put(Actions.loadingRoadMapChecklist());

    const response = yield call(loadRoadMapChecklist);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedRoadMapChecklist(response.data));
    }
}

function * doUpdateRoadMapChecklist ({payload}) {
    yield put(Actions.doingUpdateRoadMapChecklist());

    const response = yield call (updateRoadMapChecklist, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put (Actions.doneUpdateRoadMapChecklist(response.data));
    }
}
