import { ActionTypes } from '../../actions';

const defaultState = {
    isSurveyCompleted: true,
};


export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            case ActionTypes.SET_SURVEY_COMPLETED:
                return {
                    ...state,
                    isSurveyCompleted: true
                };
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
