import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import './index.scss';

class EditComment extends Component {
    constructor (props) {
        super(props);
        this.state = {
            form: {
                comment: this.props.commentInfo.comment
            }
        }
    }

    handleComment = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
          }
        });
    }

    handleEdit = () => {
        if (this.state.form.comment) {
            this.props.handleEditComment({
                id : this.props.commentInfo.id.toString(),
                comment : {
                    "comment": this.state.form.comment,
                    "post": this.props.postInfo.id
                }
            })
        }
        // this.setState({
        //     form: {
        //         comment: ''
        //     }
        // })
        this.props.handleCloseEdit();
    }

    handleCancel = () => {
        this.props.handleCloseEdit();
    }

    render() {
        return (
            <div id="edit-comment">
                <FormControl className="comment" variant="outlined">
                    <OutlinedInput
                        id="comment-section"
                        aria-describedby="outlined-comment"
                        inputProps={{
                        'aria-label': 'comment',
                        }}
                        labelWidth={0}
                        placeholder="Edit your comment"
                        margin='dense'
                        fullWidth={true}
                        name="comment"
                        multiline
                        value={this.state.form.comment}
                        onChange={(e) => this.handleComment(e)}
                    />
                </FormControl>

                <button onClick={() => this.handleEdit()} className="save-button">Save Changes</button>
                <button onClick={() => this.handleCancel()} className="cancel-button">Cancel</button>
            </div>
        );
    };
};

export default EditComment;