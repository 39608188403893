import React from 'react';
import ReturnToEquality from './ReturnToEquality';
import ToolsForChange from './ToolsForChange';
import UnlockSteps from './UnlockSteps';
import EmailResults from './EmailResults';
import './index.scss';

const PostSubmit = ({ annualImpact, actions, hackinclusion, handleEmailSubmit, handleEmailChange, email, showEmail }) => {
    return (
        <div id={"davos-postsubmit"}>
            <ReturnToEquality annualImpact={annualImpact}/>
            <ToolsForChange actions={actions} hackinclusion={hackinclusion}/>
            <UnlockSteps />
            {
                showEmail
                    ? <EmailResults handleEmailSubmit={handleEmailSubmit} handleEmailChange={handleEmailChange} email={email}/>
                    : null
            }
        </div>
    );
};

export default PostSubmit;
