import React from 'react';
import {Actions} from '../actions';
import {connect} from 'react-redux';
import Account from "../components/Account";

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
        company_permission: state.home.companyInformation.company_permission,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(Account);