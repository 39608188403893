import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import './index.scss';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    }
});

class Help extends Component {
    render () {
        const { description, classes } = this.props;

        return (
            <Tooltip className="help-tooltip" title={description} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                <div className={"help-container"}>
                    {(this.props.icon==='?'||this.props.icon===undefined)?
                        <img src="/images/svg/help.svg" alt="help icon" />
                        :<div className={'i-icon'}><span>i</span></div>
                    }
                </div>
            </Tooltip>

        );
    }
};

export default withStyles(styles)(Help);
