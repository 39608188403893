import React, {Component} from 'react';
import './index.scss';
import _ from 'underscore';
import {Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import classNames from "classnames";
import Department from './Department';
import Kpis from './Kpis';
import Level from './Level';
import Office from './Office';
import Tenure from './Tenure';
import Year from './Year';
import FiltersSelected from "./FiltersSelected";
import {loadCompanyFilters} from "../../../sagas/network";
import {createFiltersPayload} from "./utils";

const FilterContext = React.createContext();
class Filters extends Component {
    state = {
        // component to backend data key mapping
        dataMap: {
            'Year': 'years',
            'Office': 'offices',
            'Level': 'levels',
            'Department': 'departments',
            'Tenure': 'tenures',
            'Kpis': 'kpis',
            'Group': 'groups'
        },
        valMap: {
            'Year': 'year',
            'Office': 'office',
            'Level': 'level',
            'Department': 'department',
            'Tenure': 'tenure',
            'Kpis': 'kpi',
            'Group': 'group'
        },
        values: {
            year: this.props.filters?.years?.[0]
        },
        filters: this.props.filters
    };

    _updateYearWiseFilters (year) {
        loadCompanyFilters(year ? `year=${year}` : "")
            .then(res => {
                const years = res.data.quarters?
                    res.data.quarters.map(quarter => `${quarter[0]}Q${quarter[1]}`):
                    [];
                this.setState({
                    ...this.state,
                    filters: { ...this.state.filters, ...res.data, years },
                    values: {
                        year
                    },
                });
            });
    }

    _onChange (type, value) {
        this.setState({
            values: !value ? _.omit(this.state.values, type) : {
                ...this.state.values,
                [type]: value
            }
        });

        if (type === "year") {
            this._updateYearWiseFilters(value);
        }

        // Send back the filters values on next tick
        setTimeout(() => this.props.onChange && this.props.onChange(this.state.values), 0);
    }

    _applyFilters () {
        this.props.applyFilters(createFiltersPayload(this.state.values, this.props.filters));

        this.props.filterWasApplied && this.props.filterWasApplied();
    }

    _clearFilters () {
        this.setState({
            ...this.state,
            values: {
                ...Object.keys(this.state.values).reduce((all, key) => {
                    if (key === "year") {
                        all[key] = this.state?.filters?.years[0];
                    } else {
                        all[key] = [];
                    }
                    return all;
                }, {})
            }
        });

        setTimeout(() => {
            this._updateYearWiseFilters(this.state?.values?.year);
        }, 0);

        this.props.clearFilters && this.props.clearFilters(this.state.values);
    }

    render() {
        const { submittable = true, hidden, t, values: propValues } = this.props;
        const { filters, values: stateValues, dataMap, valMap } = this.state;
        const values = propValues || stateValues;
        if (_.isEmpty(filters.years)) { return null; }

        return (
            <React.Fragment>
                <div className={classNames("filters-container bootstrap-override", { hidden: hidden })}>
                    <FilterContext.Provider value={{
                        data: filters,
                        metadata: filters.metadata,
                        onChange: this._onChange.bind(this),
                        values: values,
                        dataMap: dataMap,
                        valMap: valMap
                    }}>
                        { this.props.children }
                    </FilterContext.Provider>
                    {
                        submittable
                            ? <div className="filter-actions">
                                <Button variant="primary" className="apply-filter" onClick={this._applyFilters.bind(this)}>{ t("ANALYZE.FILTERS.SUBMIT") }</Button>
                                <Button variant="light" className="clear-filter" onClick={this._clearFilters.bind(this)}>{ t("ANALYZE.FILTERS.RESET") }</Button>
                            </div>
                            : null
                    }
                </div>


            </React.Fragment>
        );
    }
}

export default withTranslation()(Filters);
export {
    Department,
    Kpis,
    Level,
    Office,
    Tenure,
    FilterContext,
    FiltersSelected,
    Year
}
