
export function generatorRunner(gen){
    const iterator = gen();
    const run = function(x){
        const result = iterator.next();
        if(result.done){
            return result.value
        } else {
            return Promise.resolve(result.value).then(run)
        }
    };
    return run
}
