const DEMO_COMPANIES = [
    "diversio demo"
]

const HIDE_LONGITUDINAL_COMPANY_NAMES = [
    "biocanrx", "sheertex", "infrastructure ontario"
];

export const HIDE_FREETEXT_COMPANY_NAMES = [
    "hggc",
]

export const PORTFOLIO_COMPANIES = [
    "amazing investor",
    "mcrock",
    "mcrock portfolio(without offices)",
    "bdc",
    "omers portfolio",
    "interac",
    "bessemer venture partners brief portfolio",
    "hggc portfolio",
    "bdc-industrial innovation venture fund",
    "bdc-it ventures",
    "bdc-industrial, clean energy",
    "bdc-co-investments",
    "oxford sciences innovation portfolio",
    "m12 portfolio"
]

export const ORGANIZATION_COMPANIES = [
    "hrpa",
    "canadian institute of actuaries",
    "equalby30",
]

const OFFICE_IS_COUNTRY_COMPANIES = [
    "equalby30",
]

export const OLX_COMPANIES = [
    "OLX-uzbekistan", "OLX-united states", "OLX-united arab emirates",
    "OLX-ukraine", "OLX-turkey", "OLX-spain", "OLX-south africa",
    "OLX-russia", "OLX-romania", "OLX-portugal", "OLX-poland",
    "OLX-peru", "OLX-pakistan", "OLX-netherlands", "OLX-lebanon", "OLX-kazakhstan",
    "OLX-indonesia", "OLX-india", "OLX-germany", "OLX-egypt", "OLX-ecuador",
    "OLX-columbia", "OLX-bulgaria", "OLX-brazil", "OLX-bosnia and herzegovina",
    "OLX-argentina",
]

export const BENCHMARKING_COMPANIES = [
    "pensions",
    "pensions & financial services"
]

// Random "regular" companies chosen for test purposes only
export const REGULAR_COMPANIES = [
    "arterra", 
    "peerwell", 
    "transalta", 
    "danone", 
    "flipgive",
]

// if a company name check happens more than once in the code,
// create a is<CompanyName>() function below using nameMatches()
// so we don't have to specify the comparison string more than once
export const nameMatches = (companyName, match_str) => {
    if (typeof (companyName) === 'string' && match_str.toLowerCase() === companyName.toLowerCase()) {
        return true
    }
    return false;
};

export const isDemoCompany = (companyName) => {
    if (typeof (companyName) === 'string' && DEMO_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isOrganizationCompany = (companyName) => {
    if (typeof (companyName) === 'string' && ORGANIZATION_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const officeIsCountry = (companyName) => {
    if (typeof (companyName) === 'string' && OFFICE_IS_COUNTRY_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isPortfolioCompany = (companyName) => {
    if (typeof (companyName) === 'string' && PORTFOLIO_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isBenchmarkingCompany = (companyName) => {
    if (typeof (companyName) === 'string' && BENCHMARKING_COMPANIES.includes(companyName.toLowerCase())) {
        return true
    }
    return false;
}

export const isMcrockDueDiligence = (companyName) => {
    if (typeof (companyName) === 'string' && companyName.toLowerCase() === "mcrock due diligence") {
        return true
    }
    return false;
};

export const isHondaHTC = (companyName) => {
    return nameMatches(companyName, "honda htc");
};

export const isHRPA = (companyName) => {
    return nameMatches(companyName, "hrpa");
};

export const isCia = (companyName) => {
    return nameMatches(companyName, "canadian institute of actuaries");
};

export const isBessemerBriefPortfolio = (companyName) => {
    return nameMatches(companyName, "bessemer venture partners brief portfolio");
};

export const isBessemerAnonymized = (companyName) => {
    return nameMatches(companyName, "acme capital");
};

export const hideLongitudinal = (companyName) => {
    if (typeof (companyName) === 'string' && (HIDE_LONGITUDINAL_COMPANY_NAMES.includes(companyName.toLowerCase()))) {
        return true;
    }
    return false;
}

export const isMember = (listName, companyName) => {
    if (typeof (companyName) === 'string' && (listName.includes(companyName.toLowerCase()))) {
        return true;
    }
    return false;
}

export const isCPAOntario = (companyName) => {
    return nameMatches(companyName, "CPA Ontario");
}

export const isCPAMembersOntario = (companyName) => {
    return nameMatches(companyName, "CPAO-Members");
}

export const isCifarScholars = (companyName) => {
    return nameMatches(companyName, "CIFAR Global Scholars")
}

export const isCifarGlobalCall = (companyName) => {
    return nameMatches(companyName, "CIFAR Global Call")
}

export const isBrucePower = (companyName) => {
    return nameMatches(companyName, "Bruce Power")
}

export const isKingSettCapital = (companyName) => {
    return nameMatches(companyName, "KingSett Capital")
}
