import React, {Component} from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:false
        }
    }

    toggleShow(){
        this.setState({show:!this.state.show});
    }

    render() {
        return (
            [
                // <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>,
                <Input
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className}
                    required={this.props.required}
                    type={this.state.show ? 'text' : 'password'}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.toggleShow.bind(this)}
                            >
                                {this.state.show ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            ]
        )
    }
}

export default PasswordInput;