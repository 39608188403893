import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import './index.scss';
import Autosuggest from 'react-autosuggest';
import AddButtonIcon from '../../../../images/add_circle'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const _getRenderValue = (value) => {
    return <MenuItem className="menu-item" value={value}>{value}</MenuItem>
}

class AddSolution extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: props.solutionNames
        }
    }

    render(){
        let { openSearchModal, showSearchModal, closeModal, solution,
            handleContinue, showAddModal, form,
            handleSelect, handleSave, handleChange, filters, demographicTarget,
            showUserSolutionModal, onChange, handleNext, isAllInfoFilled, solutionNames, kpis, pain_points,
            handleBackButton} = this.props;

        let selectedPainPoints = pain_points.filter(painpoint => Object.keys(solution.painpoints).includes(painpoint));
        let selectedKPIs = kpis.filter(kpi => Object.keys(solution.kpis).filter(x=>solution.kpis[x]).includes(kpi));
        let offices = ["All",...filters.offices];
        let departments = ["All",...filters.departments];
        let levels = ["All",...filters.levels];
        let modalClass = '';
        if (this.props.isDiversityVC) {
            modalClass = 'diversity-vc-modal'
        } else if (this.props.isDiversityPE) {
            modalClass = 'diversity-pe-modal'
        }
        return (
            <div className={`${modalClass} add-solution-container`}>
                <Button className="add-solution" onClick={openSearchModal}>
                    <AddButtonIcon/>
                    <div className="add-button-text">Add Your Own</div>
                </Button>
                <Dialog
                    open={showSearchModal}
                    TransitionComponent={Transition}
                    keepMounted
                    classes={{root:modalClass}}
                    onClose={closeModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                    maxWidth='lg'
                    id="my-solutions-modal"
                >
                    <DialogTitle id="form-dialog-title" className="target-header">New Solution</DialogTitle>
                    <DialogContent className="add-solution-modal">
                        <DialogContentText className="modal-subtitle">
                            Type the name of your solutions
                        </DialogContentText>
                        <Paper className="search-engine-container">
                            <Autosuggest
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={x=>{
                                    const newSuggestions = solutionNames.filter(y=>y.toLowerCase().includes(x.value.toLowerCase()));
                                    this.setState({suggestions:newSuggestions})
                                }}
                                onSuggestionsClearRequested={x=>x}
                                getSuggestionValue={x=>x}
                                renderSuggestion={x=><div name="name">{x}</div>}
                                inputProps={{
                                    value:solution.name===undefined?'':solution.name,
                                    onChange:handleChange,
                                    name:'name'
                                }}
                            />
                        </Paper>
                        <div>
                            <Dialog
                                open={showUserSolutionModal}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={closeModal}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-content"
                                maxWidth='lg'
                                id="my-solutions-add-modal"
                                classes={{root:modalClass}}
                            >
                                <DialogTitle className="target-header">New Solution</DialogTitle>
                                <DialogContent className="add-solution-detail-modal">
                                    <DialogContentText className="modal-subtitle">
                                        Please input details of your new solution
                                    </DialogContentText>
                                    <div className="target-form">
                                        <div className="input-group-container">
                                            <div className="input-group">
                                                <InputLabel>Solution Name</InputLabel>
                                                <TextField
                                                    required
                                                    type="string"
                                                    name="name"
                                                    value={solution.name}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    className="full-textfield"
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="input-group-container">
                                            <div className="input-group">
                                                <InputLabel>Solution Description</InputLabel>
                                                <TextField
                                                    required
                                                    type="string"
                                                    name="solution_description"
                                                    value={solution.solution_description}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    className="full-textfield"
                                                    multiline
                                                    row="4"
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        <div className="input-group-container">
                                            <div className="select-input-group">
                                                <InputLabel>KPI</InputLabel>
                                                <FormControl className="textfield"
                                                             required >
                                                    <Select multiple
                                                            value={selectedKPIs}
                                                            input={<Input id="select-multiple-checkbox" />}
                                                            renderValue={selected => selected.join(', ')}
                                                            autoWidth >
                                                        <FormGroup className="form-set">
                                                            {
                                                                kpis.map((kpi, id) => (
                                                                    <FormControlLabel
                                                                        key={id}
                                                                        control={
                                                                            <Checkbox className="primary check-box"
                                                                                      onChange={e => onChange({ kpi }, e.target.checked)}
                                                                                      checked={solution.kpis[kpi]}
                                                                                      value={ kpi }
                                                                                      color="primary" />}
                                                                        label={ kpi }
                                                                    />
                                                                ))
                                                            }
                                                        </FormGroup>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="select-input-group">
                                                <InputLabel>Improvement Area</InputLabel>
                                                <FormControl className="textfield"
                                                             required >
                                                    <Select multiple
                                                            value={selectedPainPoints}
                                                            input={<Input id="select-multiple-checkbox" />}
                                                            renderValue={selected => selected.join(', ')}
                                                            autoWidth >
                                                        <FormGroup className="form-set">
                                                            {
                                                                pain_points.map((painpoint, id) => (
                                                                    <FormControlLabel
                                                                        key={id}
                                                                        control={
                                                                            <Checkbox className="primary check-box"
                                                                                      onChange={e => onChange({ painpoint }, e.target.checked)}
                                                                                      checked={solution.painpoints[painpoint]}
                                                                                      value={ painpoint }
                                                                                      color="primary" />}
                                                                        label={ painpoint }
                                                                    />
                                                                ))
                                                            }
                                                        </FormGroup>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="input-group-container">
                                            <div className="input-group">
                                                <InputLabel>Resources</InputLabel>
                                                <TextField
                                                    required
                                                    type="string"
                                                    name="resource1"
                                                    value={solution.resource1}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    className="textfield"
                                                    placeholder="Resource URL"
                                                >
                                                </TextField>
                                            </div>
                                            <div className="input-group">
                                                <InputLabel>Additional Resource (Optional)</InputLabel>
                                                <TextField
                                                    type="string"
                                                    name="resource2"
                                                    value={solution.resource2}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    className="textfield"
                                                    placeholder="Resource URL"
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions className="modal-action">
                                    <Button className="back-button" onClick={() => handleBackButton("search")}>
                                        Go Back
                                    </Button>
                                    <Button disabled={isAllInfoFilled} onClick={() => handleNext(this.props.isEditSolution)} className={isAllInfoFilled ? "continue-disabled" : "continue-button"}>
                                        Continue
                                    </Button>
                                    <Button onClick={closeModal} className="cancel-button">
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </DialogContent>
                    <DialogActions className="modal-action">
                        <Button disabled={!solution.name.length} onClick={() => handleContinue(solutionNames.includes(solution.name))} className={!solution.name.length ? "continue-disabled" : "continue-button"}>
                            Continue
                        </Button>
                        <Dialog
                            open={showAddModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={closeModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-content"
                            maxWidth='lg'
                            id="my-solutions-add-modal"
                            classes={{root:modalClass}}
                        >
                            <DialogTitle className="target-header">New Solution</DialogTitle>
                            <DialogContent className="add-solution-detail-modal">
                                <DialogContentText className="modal-subtitle">
                                    How is this solution implemented
                                </DialogContentText>
                                <div className="target-form">
                                    <div className="input-group-container">
                                        <div className="input-group">
                                            <InputLabel>Target Group</InputLabel>
                                            <TextField
                                                required
                                                select
                                                name="target_demographic"
                                                value={form.target_demographic}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                                SelectProps={{
                                                    renderValue: selected => _getRenderValue(selected)
                                                }}
                                            >
                                                {
                                                    demographicTarget.map((category,index)=>{
                                                        let options = category.options.map(option=>{
                                                            return <MenuItem value={{key:category.key, label:category.label, option}}>{option}</MenuItem>
                                                        });
                                                        return [
                                                            category.key==='all'?null:(<MenuItem value={category.key} disabled>{category.label}</MenuItem>),
                                                            ...options
                                                        ]
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                        <div className="input-group">
                                            <InputLabel>Level</InputLabel>
                                            <TextField
                                                required
                                                select
                                                name="level"
                                                value={form.level}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                            >
                                                {
                                                    levels.map((option, id) => {
                                                        if (option !== 'Prefer not to answer') {
                                                            return (
                                                                <MenuItem key={id} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            )
                                                        }
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="input-group-container">
                                        <div className="select-input-group">
                                            <InputLabel>Department</InputLabel>
                                            <TextField
                                                required
                                                select
                                                name="department"
                                                value={form.department}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                            >
                                                {
                                                    departments.map(option => {
                                                        return(
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                        <div className="select-input-group">
                                            <InputLabel>Office / Region</InputLabel>
                                            <TextField
                                                required
                                                select
                                                name="office"
                                                value={form.office}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                            >
                                                {
                                                    offices.map(option => {
                                                        return(
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="input-group-container">
                                        <div className="input-group">
                                            <InputLabel>Launch Date</InputLabel>
                                            <TextField
                                                required
                                                type="month"
                                                name="date"
                                                value={form.date}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                            >
                                            </TextField>
                                        </div>
                                        <div className="input-group">
                                            <InputLabel>Annual Cost</InputLabel>
                                            <TextField
                                                required
                                                type="number"
                                                name="cost"
                                                value={form.cost}
                                                onChange={(e) => handleSelect(e)}
                                                margin="normal"
                                                className="textfield"
                                            >
                                            </TextField>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions className="modal-action">
                                <Button className="back-button" onClick={() => handleBackButton(solutionNames.includes(solution.name) ? "search" : "solution")}>
                                    Go Back
                                    </Button>
                                <Button onClick={() => handleSave(this.props.isEditSolution)} className="continue-button">
                                    Save
                                </Button>
                                <Button onClick={closeModal} className="cancel-button">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Button onClick={closeModal} className="cancel-button" >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default AddSolution;
