/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 **/
import { combineReducers } from 'redux';
import app from './app';
import improve from './improve';
import groups from './groups';
import colors from './colors';
import davos from './davos';
import analyze from './analyze';
import auth from './auth';
import home from './home';
import message from './message';
import roadmap from './roadmap';
import kpimap from './kpimap';
import connect from './connect';
import profile from "./profile";
import communications from "./communications";
import onePager from './lso';
import diversitype from "./diversitype";
import diversityvc from "./diversityvc";
import survey from "./survey";
import ilndashboard from "./ilndashboard";

const appReducer = combineReducers({
    /* Reducer aggregator */
    app,
    analyze,
    auth,
    colors,
    communications,
    connect,
    davos,
    diversitype,
    diversityvc,
    groups,
    home,
    improve,
    kpimap,
    message,
    onePager,
    profile,
    roadmap,
    survey,
    ilndashboard,
  })

  const rootReducer = (state, action) => {
    // Clear Redux store when a user signs out
    return appReducer(state, action)
  };


export default rootReducer
