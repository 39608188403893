import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const PurpleCheckbox = withStyles({
    root: {
      color: "#66686A",
      '&$checked': {
        color: "#6221EA",
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

class UpgradeModal extends Component {
    constructor(props) {
        super(props);
        this._initState = {
            form: {
                analytics: false,
                recommendation: false,
                roadmap: false,
                barometer: false,
                connect: false,
                comment: ''
            }
        };
        this.state = {form:{...this._initState.form}};
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            }
        });
    };

    handleClick = (e) => {
        const checked = e.target.checked;
        const name = e.target.name;

        this.setState({
            form: {
                ...this.state.form,
                [name]: checked
            }
        })
    };

    handleSubmit = (data) => {
        this.props.handleSubmit(data);
        this.setState(this._initState)
    };

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="modal-title"
                aria-describedby="modal-content"
                fullWidth={true}
                id="upgrade-modal"
            >
                <DialogTitle className="header">Upgrade Dashboard</DialogTitle>
                <DialogContent className="form-container">
                    <div className="form">
                        <div className="detail">What features best suit your inclusion needs?</div>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox />
                            }
                            className="checkbox-label"
                            label="Detailed Analytics"
                            name="analytics"
                            checked={this.state.form.analytics}
                            onChange={(e) => this.handleClick(e)}
                        />
                        <div className="detail">Access granular insights on your challenges and strengths by role, department, office, etc.</div>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox />
                            }
                            className="checkbox-label"
                            label="Customized Recommendations"
                            name="recommendation"
                            checked={this.state.form.recommendation}
                            onChange={(e) => this.handleClick(e)}
                        />
                        <div className="detail">Receive a tailored Action Plan of programs, policies, rules and other solutions. </div>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox />
                            }
                            className="checkbox-label"
                            label="Individual Roadmap"
                            name="roadmap"
                            checked={this.state.form.roadmap}
                            onChange={(e) => this.handleClick(e)}
                        />
                        <div className="detail">Plan your journey to inclusion using a step-by-step, custom guide.</div>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox checked={this.state.form.barometer} />
                            }
                            className="checkbox-label"
                            label="Social Media Meter"
                            name="barometer"
                            checked={this.state.form.barometer}
                            onChange={(e) => this.handleClick(e)}
                        />
                        <div className="detail">Understand how diverse and inclusive you are perceived to be by the public.</div>
                        <FormControlLabel
                            control={
                            <PurpleCheckbox />
                            }
                            className="checkbox-label"
                            label="Connect with Peers"
                            name="connect"
                            checked={this.state.form.connect}
                            onChange={(e) => this.handleClick(e)}
                        />
                        <div className="detail">Collaborate with other law firms through our private forum. Ask questions, share insights, and learn from each other.</div>
                        
                        <InputLabel>Please provide any additional comments below</InputLabel>
                        <TextField
                            required
                            label="Comment"
                            margin="normal"
                            multiline
                            className="textfield"
                            name="comment"
                            variant="outlined"
                            rows="4"
                            value={this.state.form.comment || ''}
                            onChange={(e) => this.handleSelect(e)}
                        />
                    </div>
                    
                </DialogContent>
                <DialogActions className="action-container">
                    <Button onClick={() => {this.handleSubmit(this.state.form)}} className="save-button">
                        Submit
                    </Button>
                    <Button onClick={() => {this.props.handleCancel()}} className="cancel-button">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
};

export default UpgradeModal;