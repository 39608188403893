import React from 'react';
import './Regulatory.scss';
import im0 from'../../../images/8.jpg';
import im1 from'../../../images/9.jpg';
import im2 from'../../../images/10.jpg';
import im3 from'../../../images/11.jpg';

function sort_by_kpi(kpi){
    var page = window.location.href;
    var to_display = "block";
    if(page.search("#") === -1){
        return to_display;
    }
    var kpi_filter = page.split("#").pop().toUpperCase().replace(/\s/g,'');
    var clean_kpi = kpi.replace(/\s/g,'');
    if(clean_kpi === kpi_filter){
        return to_display;
    }
    to_display = "none";
    return to_display;
}

const Regulatory = props => {
    var trending = [];
    var images = [im0, im1, im2, im3]
    for (var i = 0; i < props.regulatory.length; i++) {
        trending.push(
            <div style={{display: sort_by_kpi(props.regulatory[i].KPI)}}className="cellTrending">
                <div>
                    <img className="trendingImage" alt="" src={images[i]} />
                </div>
                <div>
                    <a href="http://google.com">
                        <div className="smallTopicTrending">{props.regulatory[i].title}</div>
                    </a>
                    <span className="trendingCompany"> {props.regulatory[i].company} </span>
                    <span className="trendingLine"> | </span>
                    <span className="trendingKPI"> {props.regulatory[i].KPI}</span>
                </div>
            </div>);
    }  
    return (
        <div className="regulatory-card">
             <div className="cell">
                <div className="trendingTitle">Regulatory</div>
                <div >{trending}</div>
            </div>
        </div>
    )
};

export default Regulatory;