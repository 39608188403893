import React, { Component } from 'react';
import './index.scss';

class Discussion extends Component {
    componentWillMount () {
        const script = document.createElement("script");


        script.src = "https://cdn.commento.io/js/commento.js";
        script.async = true;

        script.onload = this._updateLabels.bind(this);

        document.body.appendChild(script);
    }

    _updateLabels () {
        setTimeout(() => {
            var commentoTextBox = document.querySelector("#commento-textarea-root")
            commentoTextBox ? commentoTextBox.setAttribute("placeholder", "Add a Question") : this._updateLabels();
        }, 0);
    }

    render() {
        return (
            <div id="discussion">
                <div id="commento" data-css-override="./index.css"></div>
            </div>
        );
    }
};

export default Discussion;
