import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { Actions } from '../../actions';
import { connect } from 'react-redux';
import ForgotPassword from '../../components/Auth/forgot-password';

class ForgotPasswordContainer extends Component {
    _handleOnSubmit (values) {
        this.props.doRequestResetPassword(values);
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };

        if (this.props.isAuthenticated) {
            return <Redirect to={from} />
        }

        return <ForgotPassword
                    {...this.props}
                    onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps, Actions)(ForgotPasswordContainer);
