import React, { Component } from 'react';
import './index.scss';

const FilterIcons = [
    {
        "category": "All",
        "url": "all"
    },
    {
        "category": "Culture",
        "url": "culture"
    },
    {
        "category": "Feedback",
        "url": "feedback"
    },
    {
        "category": "Networks",
        "url": "network"
    },
    {
        "category": "Flexible Work",
        "url": "flexible"
    },
    {
        "category": "Safe Work",
        "url": "safe"
    },
    {
        "category": "Recruiting and Hiring",
        "url": "recruiting"
    },

]

class Filter extends Component {
    state = {
        onHover: 0
    }

    handleClick = (id) => {
        this.setState({
            onHover: id
        })
        this.props.handleFilter(id);
    }

    render() {
        return (
            <div id="post-filter">
                <div className="header">Explore Categories</div>
                <div className="filter-categories">
                    {
                        FilterIcons.map((icon, id) => {
                            return (
                                <div key={id} className="icon-container">
                                    <button className="category-container" onClick={() => this.handleClick(id)}>
                                        <div className={this.state.onHover === id ? "icon-container-selected" : "icon-container"}>
                                            <div className={this.state.onHover === id ? `icon-selected icon-${icon.url}` : `icon icon-${icon.url}` }></div>
                                        </div>
                                        <div className={this.state.onHover === id ? "category-selected" : "category"}>{icon.category}</div>
                                    </button>
                                    {/* <div className="vertical-line"></div> */}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };
};

export default Filter;