import React, {useEffect} from 'react';
import './index.scss';
import DOMPurify from "dompurify";
import {DefaultWhitelist, sanitizeHtml} from "bootstrap/js/src/tools/sanitizer";

const Intro = ({ content, company, handleChange }) => {
    let sanitizedContent = content.replace(/{{company}}/gm, company);
    sanitizedContent = sanitizeHtml(sanitizedContent, DefaultWhitelist);

    useEffect(() => {
        handleChange({
            intro: sanitizedContent
        });
    }, []);

    return (
        <div className="question intro-question" dangerouslySetInnerHTML={{__html: sanitizedContent}}>
        </div>
    );
};

export default Intro;
