import React from 'react';
import { Row, Column } from '../../utils/FlexBox';
import './index.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import CompanyCard from './CompanyCard';

const Portfolio = () => {
    return (
        <div id="portfolio">
            <h2 className="overview_title">How do companies within your portfolio differ in diversity and inclusion?</h2>
            <Row>
                <Column width="70%">
                    <form noValidate autoComplete="off">
                        <TextField
                            className="search-bar"
                            id="search"
                            label="Search"
                            margin="normal"
                            variant="outlined"
                        />
                    </form>
                </Column>
                <Column width="30%">
                    <div className="button-container">
                        <Button className="search-button">SEARCH COMPANY</Button>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column width="20%">
                    <FormControl component="fieldset" className="form-set fieldset fund-filters">
                        <InputLabel>Filter by fund</InputLabel>
                        <Select>
                        </Select>
                    </FormControl>
                </Column>
                <Column width="20%">
                    <FormControl component="fieldset" className="form-set fieldset leadership-filters">
                        <InputLabel>Filter by leadership</InputLabel>
                        <Select>
                        </Select>
                    </FormControl>
                </Column>
                <Column width="20%">
                    <FormControl component="fieldset" className="form-set fieldset certification-filters">
                        <InputLabel>Filter by certification</InputLabel>
                        <Select>
                        </Select>
                    </FormControl>
                </Column>
                <Column width="40%">
                    <div className="legend-alignment">
                        <div className="legend-container">
                            <div className="risk-legend">High Risk Alert</div>
                            <div className="female-legend-container"><img src="images/portfolios/legend/female.svg" className="female-legend" />Women Led</div>
                            <div><img src="images/portfolios/legend/minority.svg" className="minority-legend" />Minority led</div>
                        </div>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column width="100%">
                    <CompanyCard />
                </Column>
            </Row>
        </div>
    );
};

export default Portfolio;
