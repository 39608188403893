import { KEY_MAP } from './utils';
import { initializeUnderscoreMixins } from '../../../utils';
import _ from 'underscore';

initializeUnderscoreMixins();

/* Rearrange data objects into format required by Recharts.
See ./testReshapeData.js for more details. */
export const reshapeData = (data, iData, ndg, breakdown) => {
    const reshapedData = data.map((dimension, i) => {
        const dimName = Object.keys(dimension)[0];
        let ndList;

        if (!(breakdown[i] && breakdown[i][dimName])){
            ndList = []
        } else {
            if (dimName == "Disability") {
                // Shape of this payload is different: https://app.clickup.com/t/7ew52q
                // It is also sometimes not present for companies whose surveys did not include disability breakdowns
                ndList = breakdown[i][dimName].map(i => i["name"]);
            } else {
                ndList = Object.keys(breakdown[i][dimName]);
            }
        }

        let companyData;

        // returned value is [] if threshold not met
        // or all 0; i.e.
        // [{"name":"White","value":0},{"name":"POC","value":0},{"name":"Prefer not to answer","value":0}]
        if (dimension[dimName].length == 0 ||
            dimension[dimName].map(i => i.value).reduce((a,b) => (parseFloat(a)+parseFloat(b))) == 0) {
            companyData = {
                    th: 100,
                }
        } else {
            companyData = {
                d: _.maybe(dimension[dimName], 0, "value"),
                nd: _.maybe(dimension[dimName], 1, "value"),
                pnts: _.maybe(dimension[dimName], 2, "value")
            }
        }

        return {
            "type": dimName,
            "data": companyData,
            "ndList": ndList,
        }
    });

    let newList = [];

    for (const dim of reshapedData) {
        const dimName = dim.type;
        let ndList = dim.ndList.filter(group => !["Male", "Prefer not to answer", "White", "Heterosexual"].includes(group));

        if (!iData[dimName]) {
            continue;
        }

        let industryData = {
            d: _.maybe(iData[dimName], 0, "value"),
            nd: _.maybe(iData[dimName], 1, "value"),
            pnts: _.maybe(iData[dimName], 2, "value")
        }

        let domKey = {
            d: _.maybe(iData[dimName], 0, "name"),
            nd: _.maybe(iData[dimName], 1, "name"),
            pnts: _.maybe(iData[dimName], 2, "name"),
        }

        newList.push(
            {...dim,
                "idata": industryData,
                "domKey": domKey,
                "ndList": ndList,
                });
    }

    return newList;
};
