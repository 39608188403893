import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class EditPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: this.props.postInfo.kpi.name,
            kpi: this.mapKPI(this.props.postInfo.kpi.name)[1],
            form: {
                question: this.props.postInfo.title,
                description: this.props.postInfo.description
            },
            isEditing: true
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // if (nextProps.postInfo !== this.props.postInfo) {
        //     const { postInfo } = nextProps;
        //     this.setState({
        //         form: {
        //             question: postInfo.title,
        //             description: postInfo.description,
        //         }
        //     })
        // }
    }

    mapKPI = (kpiName) => {
        let kpiID;
        let kpiIndex;
        this.props.kpis.map((kpi, id) => {
            if (kpi.name === kpiName) {
                kpiID = kpi.id;
                kpiIndex = id;
            }
        })
        return [kpiIndex, kpiID];
    } 

    onChange = (selected) => {
        const kpi = this.mapKPI(selected.category);
        this.setState({
            category: selected.category,
            kpi: kpi[1],
            isEditing: false
        })
    }

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            }
        });
    }

    handleEdit = () => {
        this.props.handleEdit({
            "title": this.state.form.question,
            "description": this.state.form.description,
            "kpi": this.state.kpi
        })

        this.handleCancel();
    }

    handleCancel = () => {
        this.props.handleCloseModal();
    }

    render() {
        const { form } = this.state;
        let selectedCategory = this.state.category;
        const [kpiIndex, kpiID] = this.mapKPI(this.props.postInfo.kpi.name);    
        const kpiName = this.props.kpis[kpiIndex].name;
        
        return (
            <div id="edit-post-modal">
                <Dialog
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                    fullWidth={true}
                    id="edit-post-modal"
                >
                    <DialogTitle className="header">Edit Post</DialogTitle>
                    <DialogContent>
                        <div className="question-container">
                            <TextField
                                required
                                multiline
                                label="What is your question?"
                                margin="normal"
                                className="textfield"
                                name="question"
                                value={form.question}
                                onChange={(e) => this.handleSelect(e)}
                            />
                            <TextField
                                required
                                multiline
                                label="Give more detail."
                                margin="normal"
                                className="textfield"
                                name="description"
                                value={form.description}
                                onChange={(e) => this.handleSelect(e)}
                            />
                            <FormControl className="category-textfield">
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={this.state.isEditing ? kpiName : selectedCategory}
                                    renderValue={value => value}
                                >
                                    <FormGroup>
                                        {
                                            this.props.kpis.map((category, id) => {
                                                if (this.state.isEditing) {
                                                    return (
                                                        <MenuItem value={category.name}
                                                                    key={id}
                                                                    selected={category.id === kpiID}
                                                                    onClick={e => this.onChange({ category: e.target.innerText })}>
                                                            { category.name }
                                                        </MenuItem>
                                                    )
                                                } else {
                                                    return (
                                                        <MenuItem value={category.name}
                                                                    key={id}
                                                                    selected={category.id === kpiID}
                                                                    onClick={e => this.onChange({ category: e.target.innerText })}>
                                                            { category.name }
                                                        </MenuItem>
                                                    )
                                                }
                                            })
                                        }
                                    </FormGroup>
                                </Select>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions className="action-container">
                        <Button disabled={!(this.state.form.question && this.state.form.description && this.state.category)} onClick={() => this.handleEdit()} className={!(this.state.form.question && this.state.form.description && this.state.category) ? "disable-save-button" : "save-button" } >
                            Save
                        </Button>
                        <Button onClick={() => {this.handleCancel()}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
};

export default EditPost;