import { ActionTypes } from '../actions';

const defaultOnePager = {
    data: []
};


export default (onePager = defaultOnePager, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADED_ONE_PAGER:{
                return {
                    ...onePager,
                    data: action.payload
                };
            }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultOnePager
                };
            default:
                return onePager;
        }
    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
        return onePager
    }
};
