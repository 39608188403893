import React, { Component } from 'react';
import Card from '../../../utils/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from 'react-bootstrap/Table';
import classNames from "classnames";
import InitiativeTable from './Table';
import { DashboardHoverTooltip } from '../../../utils/DashboardHoverTooltip';
import _ from 'underscore';
import './index.scss';
import MilestoneTable from '../../Milestones/index'

class Initiative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "Diversity",
            sustainableFilterText: 'All Sustainable',
            partnerFilterText: 'All Partners',
            memberList: [],
            partnerList: [],
            sustainableList: [],
            filteredData: [],
            dataHasLoaded: false,
            selectedProgram: 'empty'
        };
    };

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            const initiatives = this.props.data

            const partnerList = this.getPartner(initiatives, "All Partners", "partners")
            const sustainableList = this.getSustainable(initiatives, "All Sustainable", "goals")

            this.setState({
                partnerList: partnerList,
                sustainableList: sustainableList,
                filteredData: initiatives,
                dataHasLoaded: true
            })
        }
    }

    selectTab = tab => {
        this.setState({
            tab: tab
        });
    };

    selectProgram = program => {
        this.setState({
            selectedProgram: program
        })
    }

    handlePartnerChange = (e) => {
        this.setState({
            partnerFilterText: e.target.value
        })
    }

    handleSustainableChange = (e) => {
        this.setState({
            sustainableFilterText: e.target.value
        })
    }

    clearFilters = () => {
        this.setState({
            sustainableFilterText: '',
            partnerFilterText: ''
        })
    }

    parseData = (data) => {
        if (_.isEmpty(data)) return null

        const { sustainableFilterText, partnerFilterText } = this.state

        const partnerFilter = data.filter(function (row) {
            // Partners
            if (partnerFilterText === 'All Partners') {
                return true
            }
            return row.partners.toString().toLowerCase().indexOf(partnerFilterText.toLowerCase()) >= 0;

        })

        const sustainableFilter = partnerFilter.filter(function (row) {
            //Sustainable Goals
            if (sustainableFilterText === 'All Sustainable') {
                return true
            }
            return row.goals.toString().toLowerCase().indexOf(sustainableFilterText.toLowerCase()) >= 0;
        })

        this.setState({
            filteredData: sustainableFilter
        })
    }

    getPartner = (filter, allFilters, filterColumn) => {
        let arr = []
        for (var i in filter) {
            const typeCheck = typeof _.maybe(filter[i], filterColumn) === 'object'
            if (!typeCheck) filter[i][filterColumn] = []
            for (var j in filter[i][filterColumn]) {
                const newFilter = filter[i][filterColumn][j]
                if (typeof(newFilter) === 'string') {
                    arr.push(newFilter.trim())
                }
            }
        }
        const filterList = [...new Set(arr)].sort()
        const result = [allFilters].concat(filterList.sort())
        return result
    }

    getSustainable = (filter, allFilters, filterColumn) => {
        let arr = []
        for(var i in filter) {
            const typeCheck = typeof filter[i][filterColumn] === 'string'
            if(!typeCheck) filter[i][filterColumn] = ''
            const newFilter = filter[i][filterColumn].trim()
            arr.push(newFilter)
        }
        const filterList = [...new Set(arr)].sort()

        // Do this to add "All Members" to top of list
        const result = [allFilters].concat(filterList)
        return result
    }

    render() {
        const {
            tab,
            selectedProgram,
            sustainableFilterText,
            partnerFilterText,
            partnerList,
            sustainableList,
            filteredData,
            dataHasLoaded
        } = this.state;

        const initiatives = this.props.data
        return (
            <Card>
                <div className='iln-impact-card'>
                    <h2 className="iln-impact-h2">Initiatives</h2>
                    <div className="iln-filter-container">
                        <div>
                            <div className="iln-filter-text">
                                <div className="iln-dropdown">
                                    <h4 className="h4-initiative">Partners</h4>
                                    <TextField
                                        required
                                        select
                                        value={partnerFilterText}
                                        onChange={this.handlePartnerChange.bind(this)}
                                        margin="normal"
                                        className="iln-text-field"
                                    >
                                        {
                                            partnerList.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                                {/* Comment out sustainable filter for now */}
                                {/* <div className="iln-dropdown">
                                    <h4 className="h4-initiative">Sustainable</h4>
                                    <TextField
                                        required
                                        select
                                        value={sustainableFilterText}
                                        onChange={this.handleSustainableChange.bind(this)}
                                        margin="normal"
                                        className="iln-text-field"
                                    >
                                        {
                                            sustainableList.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div> */}
                            </div>
                        </div>
                        <div className='iln-filter-btn'>
                            <Button onClick={() => this.parseData(initiatives)} className="iln-filter-enter">
                                Submit
                        </Button>
                        </div>
                    </div>
                    <div className="iln-datatable-container">
                        <Table className={"datatable"}>
                            <thead>
                                <tr className={"datatable-filters bootstrap-override"}>
                                    <th onClick={() => this.selectTab("Diversity")} className={classNames({ "selected": tab === "Diversity" })}>{"Diversity in Investment"}</th>
                                    <th onClick={() => this.selectTab("Infrastructure")} className={classNames({ "selected": tab === "Infrastructure" })}>{"Sustainable Infrastructure"}</th>
                                    <th onClick={() => this.selectTab("Climate Change")} className={classNames({ "selected": tab === "Climate Change" })}>{"Climate Change"}</th>
                                    <th onClick={() => this.selectTab("Communication")} className={classNames({ "selected": tab === "Communication" })}>{"Communication"}</th>
                                    <th className={"empty-cell"}></th>
                                </tr>
                                <tr className={"datatable-border"}></tr>
                            </thead>
                            <tbody className="iln-initiative-table">
                                {initiatives ?
                                    <InitiativeTable
                                        tableTitle={tab}
                                        data={filteredData}
                                        dataHasLoaded={dataHasLoaded}
                                        selectProgram={(program) => this.selectProgram(program)}
                                    /> : null}
                            </tbody>
                            <tfoot>
                            </tfoot>
                        </Table>
                    </div>
                    <MilestoneTable
                        milestones={this.props.milestones}
                        selectedProgram={selectedProgram}
                    />
                </div>
            </Card>
        )
    }
}

export default Initiative
