import React, {Component} from 'react';
import moment from 'moment';
import Survey from "../components/Survey";
import {connect} from "react-redux";
import {Actions} from "../actions";
import _ from "underscore";
import DialogBox from "../components/utils/DialogBox";

const pageCategories = {
    "Experience": 1,
    "Workforce": 2,
    "Demographic": 3
};

class SurveyContainer extends Component {
    state = {
        dialogBoxOptions: {}
    };

    handleSetupNext (values) {
        const { selectedSurvey } = this.props;

        if (selectedSurvey) {
            const payload = {
                ...selectedSurvey,
                deploying_for: values.deployingWith,
                supported_languages: values.language,
                draft: true
            };
            this.props.doEditSurveyInfo(payload);
        } else {
            const payload = {
                deploying_for: values.deployingWith,
                supported_languages: values.language,
                name: `${this.props.company}'s Diversity & Inclusion Survey`
            };
            this.props.doSaveSurveyInfo(payload);
        }
    }

    handleSetupBack (values) {

    }

    _createSurveyPayload (data) {
        if (!data.surveys) {
            return data;
        }

        return {
            ...data,
            surveys: _.isEmpty(data.surveys) ? null : data.surveys.map(survey => {
                return {
                    ...survey,
                    questions: survey.questions && [...survey.questions.map(question => {
                        return {
                            ...question,
                            page_category: pageCategories[question.page_category],
                            choices: question.choices ? [
                                ...question.choices.filter(choice => !!choice.label)
                            ] : []
                        };
                    })]
                }
            })
        }
    }

    saveSurvey (options) {
        const { selectedSurvey } = this.props;

        const payload = this._createSurveyPayload({
            ...selectedSurvey,
            ...options
        });

        if (selectedSurvey.status === "DRF") {
            this.props.doEditSurveys(payload);
        } else {
            this.props.doSaveSurveys(payload);
        }
    }

    handleCreateNext () {
        this.saveSurvey({ draft: false })
    }

    handleCreateBack () {

    }

    handleDraftSurveys() {
        this.saveSurvey({ draft: true })
    }

    handleSurveyReset () {
        if (this.props.currentSelected !== "setup") {
            this.props.doResetSurveyInfo(this.props.selectedSurvey.id);
        } else {
            this.props.resetSurveyInfo();
        }

    }

    handleSurveyClose () {
        const payload = this._createSurveyPayload({
            ...this.props.selectedSurvey,
            status: "CLS"
        });

        this.props.doEditSurveyInfo(payload);
    }

    componentWillUnmount() {
        this.props.resetConfirmDialog();
        this.props.resetSurveyInfo();
        window.onbeforeunload = () => {};
    }

    componentDidMount() {
        window.onbeforeunload = (e) => {
            if (!this.props.isSurveySaved) {
                return window.confirm("Are you sure you want to exit? All of your unsaved changes will be gone")
            }
        };
    }

    render() {
        return <>
            <DialogBox {...this.state.dialogBoxOptions}></DialogBox>
            <Survey
                handleSetupBack={this.handleSetupBack.bind(this)}
                handleSetupNext={this.handleSetupNext.bind(this)}
                handleCreateNext={this.handleCreateNext.bind(this)}
                handleCreateBack={this.handleCreateBack.bind(this)}
                handleReset={this.handleSurveyReset.bind(this)}
                handleClose={this.handleSurveyClose.bind(this)}
                draftSurveys={this.handleDraftSurveys.bind(this)}
                { ...this.props }/>
        </>
    }
}

const mapStateToProps = state => {
    return {
        ...state.survey,
        ...state.profile
    }
};

export default connect(mapStateToProps, Actions)(SurveyContainer);
