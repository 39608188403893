import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import UpdateDataModal from './UpdateDataModal';
import ConfirmationModal from '../../utils/RequestSent';
import './index.scss';

class UpdateData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openUpdateDataModal: false,
            openConfirmationModal: false
        };
    }

    openUpdateDataModal = () => {
        this.setState({
            openUpdateDataModal: true
        })
    };

    handleUpdateData = ({request, topics}) => {
        const context = "Update Data";
        const detail = `User would like to update their data.\nTopics: ${topics.join(', ')}\nRequest: ${request}`;
        this.props.sendSlackPing(context, detail).then(() => {
            this.setState({openUpdateDataModal: false, openConfirmationModal: true});
        }, reason => {
            this.setState({openUpdateDataModal: false, openConfirmationModal: false})
        });
    };

    handleCancelUpdateData = () => {
        this.setState({
            openUpdateDataModal: false
        })
    };

    handleCloseConfirmationModal = () => {
        this.setState({
            openUpdateDataModal: false,
            openConfirmationModal: false
        })
    };

    render() {
        return (
            <div id="update-data">
                <div className="title">Update Your Data</div>
                <div className="detail-container">
                    <img src="/images/LSO/Update/update.png" alt="Update data icon" />
                    <div className="detail">
                        <div className="description">Did we miss something in your report? Feel free to leave a message and our team will reach out as soon as possible</div>
                        <Button className="update-button" onClick={() => this.openUpdateDataModal()}>Let Us Know</Button>
                        <UpdateDataModal isOpen={this.state.openUpdateDataModal}
                                         handleSubmit={this.handleUpdateData}
                                         handleCancel={this.handleCancelUpdateData} />
                        <ConfirmationModal isOpen={this.state.openConfirmationModal}
                                           handleCancel={this.handleCloseConfirmationModal} />
                    </div>
                </div>
            </div>
        );
    };
};

export default UpdateData;