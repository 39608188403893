import React, {Component} from 'react';
import {Row, Column} from '../../utils/FlexBox';
import Card from '../../utils/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import {demographicToName} from '../../../utils/demographicMapping';
import './index.scss';
import { getOfficeRegionLabel, } from '../../../utils/labels';
import { isPortfolioCompany } from '../../../utils/companyChecks';
import * as m from '../../../translations/mapping';
import {getTranslation, mapT} from '../../../translations/utils';


class TopSolution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.topSolutions[0].id,
            form: {
                department: "All"
            },
            filterIndex: 0 //Index of what filter is currently applied, default to 0 = Company-Wide
        }
    }

    handleClick = (id) => {
        this.setState({
            active: id
        })
    };

    handleSelect = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    };

    _initializeFilter = (filters) => {
        let flag = false;
        filters.find(filter => {
            if (filter.filter_name === 'All') {
                flag = true;
            }
        });
        if (flag === false) {
            filters.unshift({'filter_name': 'All'})
        }
        return filters;
    };

    handleFilterChange(e) {
        const filterIndex = e.target.value;
        let companyWideFilter = {
            department: "All",
            filter_name: "All",
            office: "All",
            solutions: this.props.topSolutions.map(x => x.id)
        };
        let filters = [companyWideFilter, ...this.props.filters];
        // const activeSolutions = this.props.topSolutions.filter(x => this.solutionsFilter.call(this, x, filters));
        const activeSolutions = this.props.topSolutions.filter(x => filters[filterIndex].solutions.includes(x.id));
        let active = activeSolutions.find(x => x.id === this.state.active);
        active = active ? active.id : (activeSolutions && activeSolutions[0] ? activeSolutions[0].id : null);
        this.setState({filterIndex: e.target.value, active});
    }

    handleAddSolution = () => {
        const activeSolution = this.props.topSolutions.find(x => x.id === this.state.active);

        // If DiversityVC or DiversityPE, add to 'Next Steps' otherwise add to 'My solutions'
        if ((this.props.isDiversityVC || this.props.isDiversityPE) && !activeSolution.is_next_steps) {
            const payload = {
                id: activeSolution.id,
                is_next_steps: true
            };
            this.props.doUpdateCompanySolutions(payload);
            this.props.showMessage({
                type: "success",
                message: "The recommendation has been added to Next Steps"
            })
        } else if (!activeSolution.is_my_solution){

            this.props.doUpdateCompanySolutions({
                id: activeSolution.id,
                is_my_solution: true
            });

            this.props.showMessage({
                type: "success",
                message: "The recommended solution has been added to 'Current Programming'"
            })
        }
    };

    solutionsFilter(solution, filters) {
        return filters[this.state.filterIndex].solutions.includes(solution.id);
    }

    render() {
        if (!(this.props.topSolutions.length && this.props.filters)) {
            return null;
        }
        let companyWideFilter = {
            department: "All",
            filter_name: "All",
            office: "All",
            solutions: this.props.topSolutions.map(x => x.id)
        };
        let filters = [companyWideFilter, ...this.props.filters];
        const activeSolution = this.props.topSolutions.find(x => x.id === this.state.active);
        const activeSolutions = this.props.topSolutions.filter(x => this.solutionsFilter.call(this, x, filters));
        let index = activeSolutions.findIndex(x => x.id === this.state.active);
        index = index < 0 ? 0 : index;
        const count = activeSolutions.length;


        const addButtonText = (this.props.isDiversityVC || this.props.isDiversityPE) ?
        "Add to Next Steps" :
        "Add to My Solutions";

        const getRecommendationScope = () => {
            if (this.props.companyName == "Amazing Procurer") {
                return "across your suppliers";
            }
            else if (isPortfolioCompany(this.props.companyName)) {
                return "across your portfolio";
            } else {
                return "your organization is currently implementing";
            }
        }

        const recommendationScope = getRecommendationScope();

        const title = (this.props.isDiversityVC || this.props.isDiversityPE) ? "Top Recommendations": "Top Recommended Solutions";

        let subtitle = `New programs, policies and solutions that are most likely to improve diversity & inclusion ${recommendationScope}.`;
        if (this.props.isDiversityVC) {
            subtitle = <><p>{"The recommendations listed here have been identified as the best-in-class D&I programmes and policies implemented by investors globally. These recommendations have been selected specifically for you based on the unique nature of your fund."}</p>
            <p>{`By implementing these recommendations you will be able to reach Level 2 of the Diversity VC Standard. Look through these programmes and select the ones you would like to work on by clicking '${addButtonText}'.`}</p></>
        } else if (this.props.isDiversityPE) {
            subtitle = <><p>{"The recommendations listed here have been identified as the best-in-class D&I programmes and policies implemented by investors globally. These recommendations have been selected specifically for you based on the unique nature of your fund."}</p>
            <p>{`By implementing these recommendations you will be able to reach Level 2 of the Diversio PE Standard. Look through these programmes and select the ones you would like to work on by clicking '${addButtonText}'.`}</p></>
        }

        return (
            <Card>
            <div id="top-solution">
                <Row>
                    <h2 className="overview-title">{title}</h2>
                </Row>
                <Row>
                    <div className="subtitle">{subtitle}</div>
                </Row>
                <Row>
                    <div className="filter-container">
                        <div className="filter">
                            <InputLabel className="filter-title">Filter Solutions</InputLabel>
                            <TextField
                                required
                                select
                                value={this.state.filterIndex}
                                onChange={this.handleFilterChange.bind(this)}
                                margin="normal"
                                className="text-field"
                            >
                                {
                                    filters.map((option, index) => (
                                        <MenuItem key={index} value={index}>
                                            {mapT(m.KPI_CODES, option.filter_name)}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="subtitle">Solutions</div>
                </Row>
                <Row>
                    <div className="solutions-container">
                        {
                            activeSolutions.map((solution) => {
                                const id = solution.id;
                                return (
                                    <Column key={id}>
                                        <div onClick={() => this.handleClick(id)} className="solution-button">
                                            <div
                                                className={classNames("solution-pill", this.state.active === id ? "solution-title-active" : "solution-title")}>
                                                <h3>{solution.solution.title}</h3>
                                            </div>
                                        </div>
                                    </Column>
                                )
                            })
                        }

                    </div>
                </Row>
                <div className="solutions-number-container">
                    <div className="solution-number">
                        <span
                            className={this.state.active === 0 ? "solution-left-gray" : "solution-left-purple"}/>
                        {`${index + 1}/${count}`}
                        <span
                            className={this.state.active + 1 === this.props.topSolutions.length ? "solution-right-gray" : "solution-right-purple"}/>
                    </div>
                </div>
                <hr/>
                { activeSolution ? 
                    <Row>
                    <div className="solution-detail-container">
                        <div className="info">
                            <h3>{activeSolution.solution.title}</h3>
                            <p>{activeSolution.solution.description}</p>
                            {
                                activeSolution.solution.resources.map((url, id) => {
                                    if (activeSolution.solution.resources.length > 1) {
                                        return (
                                            <a key={id} href={url} target="_blank"
                                               rel="noopener noreferrer">{`Resource ${id + 1}`}</a>
                                        )
                                    } else {
                                        return (
                                            <a key={id} href={url} target="_blank"
                                               rel="noopener noreferrer">{`Resource`}</a>
                                        )
                                    }
                                })
                            }
                            <Button onClick={() => this.handleAddSolution()} className="add-solution-button">
                                {addButtonText}
                            </Button>
                        </div>

                        <div className="target">
                            <div className="detail-subtitle">Target Group</div>
                            <p>{demographicToName(activeSolution.target_demographic)}</p>
                        </div>

                        <div className="cost">
                            <div className="detail-subtitle">Annual Cost</div>
                            <p>{activeSolution.monthly_cost}</p>
                        </div>

                        <div className="office">
                            <div className="detail-subtitle">{getOfficeRegionLabel(this.props.companyName)}</div>
                            <p>{activeSolution.office}</p>
                        </div>

                        <div className="level">
                            <div className="detail-subtitle">Level</div>
                            <p>{activeSolution.level ? activeSolution.level.label : 'All'}</p>
                        </div>

                        <div className="relevant">
                            <div className="detail-subtitle">Relevant KPI</div>
                            {
                                activeSolution.solution.kpis.map((kpi, id) => {
                                    return (
                                        <p key={id}>{kpi.name}</p>
                                    )
                                })
                            }
                        </div>

                        <div className="painpoint">
                            <div className="detail-subtitle">Pain Point</div>
                            {
                                activeSolution.solution.pain_points.map((painpoint, id) => {
                                    return (
                                        <p key={id}>{painpoint.title}</p>
                                    )
                                })
                            }
                        </div>
                        <div className="department">
                            <div className="detail-subtitle">Department</div>
                            <p>{activeSolution.department}</p>
                        </div>
                    </div>
                </Row> :
                null
                }
                
            </div>
            </Card>
        );
    }
}

export default TopSolution;
