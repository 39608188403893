import React, { Component } from 'react';
import Filters, { Office, Level, Department, FiltersSelected, FilterContext } from '../Filters';
import { getOfficeRegionLabel, getDepartmentLabel } from "../../../utils/labels";
import { isMcrockDueDiligence } from "../../../utils/companyChecks";
import { withTranslation } from 'react-i18next';
import MainCard from '../../utils/MainCard';
import _ from 'underscore';
import _l from 'lodash';
import './index.scss';
import InclusionTrackerLineChart from './InclusionTrackerLineChart';
import KpiSelector from './KpiSelector';
import Legend from './Legend';
import equal from 'fast-deep-equal';

class InclusionTracker extends Component {
    state = {
        kpimap: this.props.kpimap,
        availableKpis: [],
        selectedKpis: [],
        activeDetail: 0
        };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.kpimap !== this.props.kpimap) {
            this.setState({
                kpimap: nextProps.kpimap
            })
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if(!equal(this.props.kpimap,
            prevProps.kpimap)) 
        {
          this.loadData();
        }
    }

    loadData = async () => {
        if (this.props.kpimap) {
            await this.setState({
                selectedKpis: Object.values(this.props.kpimap),
                availableKpis: Object.values(this.props.kpimap),
            })
        }
    }

    _parseKPIs (metricData) {
        const { kpimap } = this.state;
        return Object.keys(kpimap).reduce((metricMap, kpi) => {
            if (!metricData) {
                return {};
            }

            return {
                ...metricMap,
                [kpimap[kpi]]: metricData[kpi]
            };
        }, {})
    }

    _parseMetricsData (metrics) {
        return metrics.map(metric => {
            return {
                date: `${metric.title}`,
                ...this._parseKPIs(metric)
            }
        }).reverse();
    }

    _handleApplyFilters (filterValues) {
        const { kpis, ...rest } = filterValues;
        
        const { kpimap } = this.props;
        const invertedKpimap = _.invert(kpimap);
        this.setState({
            kpimap: kpis.reduce((kpimap, kpiKey) => {
                kpimap[invertedKpimap[kpiKey]] = kpiKey;
                return kpimap;
            }, {})
        });

        const data = {...rest}
        this.props.handleApplyFilters(data);
    }

    addKpisToValues = (values) => {
        return {
            ...values,
            "KPI": this.state.selectedKpis
        };

    }

    addKpisToAllFilters = (allFilters) => {
        return {
            ...allFilters,
            "KPIs": this.state.availableKpis
        }
    }

    onKpiChange = (value, checked) => {
        let selectedKpis = this.state.selectedKpis;
        if (checked && selectedKpis.indexOf(value) === -1) {
            selectedKpis.push(value)
        } else if (!checked) {
            selectedKpis = _.without(selectedKpis, value)
        }
        this.setState({ selectedKpis });
    };

    render() {
        const { metrics, companyName, t } = this.props;

        if (isMcrockDueDiligence(companyName)) {
            return null;
        }
        if (!metrics) { return null; }

        let filters;
        if (!_.isEmpty(this.props.filters)) {
            filters = {
                ...this.props.filters,
                kpis: _.values(this.props.kpimap)
            };
        }
        this.metricsData = this._parseMetricsData(metrics);

        let kpiFilterList = {};
        if (!equal(this.state.selectedKpis, this.state.availableKpis)) {
            kpiFilterList = {"KPIs": this.state.selectedKpis}
        }

        return (
            <MainCard title={t("ANALYZE.CARD_HEADERS.INCLUSION_TRACKER.title")}
            toolTipContent={t("ANALYZE.CARD_HEADERS.INCLUSION_TRACKER.tooltip")}>
            <div className="inclusion-tracker">
                <div className="inclusion-tracker__filters">
                    <Filters filters={filters} 
                    applyFilters={this.props.handleApplyFilters}
                    >
                        <Office label={getOfficeRegionLabel(companyName)}/>
                        <Level />
                        <Department label={getDepartmentLabel(companyName)}/>
                        {
                        <FilterContext.Consumer>
                            {data => {
                                if (this.state.selectedFilters !== data.values) {
                                    this.setState({
                                        ...this.state,
                                        selectedFilters: data.values
                                    })
                                }
                            }}
                        </FilterContext.Consumer>
                    }
                    </Filters>
                </div>
                <div className="inclusion-tracker__kpi-filter">
                    <KpiSelector 
                        availableKpis={this.state.availableKpis}
                        selectedKpis={this.state.selectedKpis}
                        onKpiChange={this.onKpiChange}/>
                </div>
                <div className="inclusion-tracker__pre-chart">
                    <div className="inclusion-tracker__subtitle">
                        {t('ANALYZE.INCLUSION_TRACKER.SUBTITLE')}
                    </div>
                </div>
                <div className="inclusion-tracker__chart">
                    <InclusionTrackerLineChart data={this.metricsData} selectedKpis={this.state.selectedKpis}/>
                </div>
                <div className="legend">
                    <Legend
                            selected={this.state.selectedKpis}/>
                </div>
                <div className="analyze-diversity-snapshot__filter-box">
                    <FiltersSelected 
                    values={this.addKpisToValues(this.state.selectedFilters)} 
                    allFilters={this.addKpisToAllFilters(this.props.filters)}
                        companyName={this.props.companyName}
                    />
                </div>
            </div>
            </MainCard>
        );
    };
};

export default withTranslation()(InclusionTracker);
