import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import CreatePostModal from "../components/Connect/CreatePostModal";

const mapStateToProps = state => {
    return {
        kpis:state.connect.kpis
    };
};

export default connect(mapStateToProps, Actions)(CreatePostModal);