import React, { Component } from 'react';
// import CreatePostModal from '../CreatePostModal';
import CreatePostModal from '../../../containers/CreatePostModal';
import Tooltip from '@material-ui/core/Tooltip';
import './index.scss';

class CreatePost extends Component {
    state = {
        isModalOpen: false
    }

    handleOpenModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false
        });
    }

    render() {
        return (
            <div id="create-post-container">
                {
                    this.props.profileName
                    ? <button className="create-post-button" onClick={(() => this.handleOpenModal())} disabled={false}>
                        Create New Post
                    </button>
                    : <Tooltip title="You must fill in your name before posting. You can edit your name on the top right corner." placement="top">
                        <div> 
                            <button className="disabled-button" disabled={true}>
                                Create New Post
                            </button>
                        </div>
                    </Tooltip>
                }
                
                <CreatePostModal isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} handlePost={this.props.handleCreatePost} kpis={this.props.kpis}/>
            </div>
        );
    };
};

export default CreatePost;